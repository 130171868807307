import React from "react";
import PropTypes from "prop-types";

const Slider = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect
        width="20"
        height="20"
        transform="matrix(-1 0 0 1 20 0)"
        fill="url(#pattern0)"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1268_1887" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_1268_1887"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNElEQVR4nO3cT0obYRjH8a/d2Qu0Lpw4Z+gY3TTYKxUFW2+hFgtVL2FJQEuv0dYTqIXqRklWr0Qm0EWkmb7/5kl+H3i28Z0nMoPDF0FERERERERERKxYAt4Bp8AlcF/PL+AE2Mp9wHlQAt8B94/5BqzlPqxVXeD3DEuezA1Q5T60NZ16ca7hXANF7sNbMviPJU/ma+7DW7HuseTJbOa8gJfAJ+AuwIW4DDME3gOvgNfANjDy/Mxb4BBYDrnozy1YlvOY3SnX9DHQZ493E8QL4KEFy3IeUzzz0Azx2ff1jrRopi+6aNui5+HW8WHKNe0F+uwjAhrf8A/qB4AzOKP6AbhSz06Ah+EfYD/0w9CKKsCXspH7Iqzoeyz5LPfhLSnqP6ebLvkKWM19eIu3kJsGSx5/MW9yH9rya9KLGZZ8rtekYfSAY+DnXy/+fwBfgLeBfoaIiIiIPFF3kUCp7iK+rrqL+DrqLtIYeLzGVHexKN2F9a5jGKG7iD0mu47diN1F7DHVdRQRu4vYY6rrKCJ2F2YWnevWsdeCJc5d1zGK0F3EnlZ3HVWAC1R3MaO+x5LVXTRQqLtIp1J3kU6p7iKtnroLERERkUW1pP+/EV+pDiS+rjqQ+DrqQNIYeLxWVQcyo4XrQNr2/zqGLehATHYdznAHYqrrcIY7EFNdhzPcgZjqOpzhDsRU1+EMdiCt7jqaUgeSUN/jt04dSAPqQBKq1IGkU6oDSaunDkREREREREREhKweAZKxWCjDycVfAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

Slider.propTypes = {
  className: PropTypes.string,
};

export default Slider;
