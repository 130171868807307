import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
    },
  };
});

export { useStyles };
