import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => ({
  slider: {
    color: `${theme.palette.themeColor.primary} !important`,
  },
  input: {
    maxWidth: "40%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23) !important",
    },
    "& .MuiFormLabel-root": {
      color: "rgba(0, 0, 0, 0.54) !important",
      backgroundColor: "white !important",
    },
  },
  input_fields_container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
}));

export { useStyles };
