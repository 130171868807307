import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    regular_text_field: {
      paddingBottom: theme.spacing(2),
    },
    multiple_textfields_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
    },
    multiple_textfields: {
      width: "40%",
    },
    text_container: {
      paddingBottom: theme.spacing(3),
    },
    contact_information_label: {
      marginBottom: 0,
    },
  };
});

export { useStyles };
