import React from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";

const Map = ({ markerPosition }) => {
  return (
    <GoogleMap defaultZoom={15} defaultCenter={markerPosition}>
      <Marker position={markerPosition} />
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
