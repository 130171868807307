import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    general_container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      height: "67vh",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down(960)]: {
        height: "78vh",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    text_container: {
      textAlign: "center",
      width: "100%",
    },
    text_with_links: {
      padding: `0px ${theme.spacing(25)}px`,
      fontSize: "17px",
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: theme.palette.dark.black,
      "& a": {
        color: theme.palette.themeColor.secondary,
        textDecoration: "underline",
      },
      [theme.breakpoints.down(960)]: {
        padding: "0px",
      },
    },
    svg_vector: {
      [theme.breakpoints.down(960)]: {
        width: "90%",
      },
    },
    other_errors: {
      fontSize: "300px",
      fontFamily: "Montserrat",
      [theme.breakpoints.down(960)]: {
        fontSize: "150px",
      },
    },
  };
});

export { useStyles };
