import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./Actions.style";
import Edit from "../../Actions/Edit";
import Delete from "../../Actions/Delete";

const REAL_ESTATE_URL = "/real_estate_listings/";

const Actions = ({ canEdit, canDelete, editUrl, slug }) => {
  const classes = useStyles();

  return (
    <div className={classes.actions}>
      {canEdit && editUrl && <Edit editUrl={editUrl} />}
      {canDelete && <Delete deleteUrl={`${REAL_ESTATE_URL}${slug}`} />}
    </div>
  );
};

Actions.propTypes = {
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  editUrl: PropTypes.string,
  slu: PropTypes.string,
};

export default Actions;
