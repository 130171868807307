import React, { useState } from "react";
import Slider from "@material-ui/core/Slider";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./SliderRange.style";
import { TextField, Tooltip } from "@material-ui/core";

const MIN_DEFAULT = 0;
const MAX_DEFAULT = 100000;
const STEP_DEFAULT = 10;

const SliderRange = ({
  value,
  step,
  min,
  max,
  customClasses,
  onChange,
  options,
}) => {
  const classes = useStyles();
  const [minNum, setMinNum] = useState(value[0] || MIN_DEFAULT);
  const [maxNum, setMaxNum] = useState(value[1] || MAX_DEFAULT);
  const [sliderRangeValue, setSliderRangeValue] = useState(
    value[0] && value[1] ? value : [MIN_DEFAULT, MAX_DEFAULT]
  );

  const sliderRangeHandleChange = (event, newValue) => {
    setMinNum(newValue[0]);
    setMaxNum(newValue[1]);
    setSliderRangeValue(newValue);
    onChange(newValue);
  };

  const handleInputChange = (e, type) => {
    let numberValue = Number(e.target.value);

    if (type === "min") {
      setMinNum(numberValue);
      setSliderRangeValue([numberValue, sliderRangeValue[1]]);
      onChange([numberValue, sliderRangeValue[1]]);
    } else if (type === "max") {
      setMaxNum(numberValue);
      setSliderRangeValue([sliderRangeValue[0], numberValue]);
      onChange([sliderRangeValue[0], numberValue]);
    }
  };

  const CustomTooltipLabel = (props) => {
    const { children, open, value } = props;
    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value}
        arrow
      >
        {children}
      </Tooltip>
    );
  };

  return (
    <>
      <Slider
        value={sliderRangeValue}
        step={step ? step : STEP_DEFAULT}
        marks
        min={min ? min : MIN_DEFAULT}
        max={max ? max : MAX_DEFAULT}
        onChange={sliderRangeHandleChange}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        classes={{
          root: clsx(classes.slider, customClasses?.root && customClasses.root),
          valueLabel: customClasses?.valueLabel ? customClasses.valueLabel : "",
        }}
        {...(options?.withCustomTooltipLabel && {
          ValueLabelComponent: CustomTooltipLabel,
        })}
        {...(options?.customLabelFormat && {
          valueLabelFormat: options.customLabelFormat,
        })}
      />

      {options?.withInputFields && (
        <div className={classes.input_fields_container}>
          <TextField
            label="Min"
            type="number"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={minNum}
            onChange={(e) => handleInputChange(e, "min")}
            classes={{
              root: clsx(
                classes.input,
                customClasses?.input && customClasses.input
              ),
            }}
          />

          <TextField
            label="Max"
            type="number"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={maxNum}
            onChange={(e) => handleInputChange(e, "max")}
            classes={{
              root: clsx(
                classes.input,
                customClasses?.input && customClasses.input
              ),
            }}
          />
        </div>
      )}
    </>
  );
};

SliderRange.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  customClasses: PropTypes.shape({
    root: PropTypes.string,
    valueLabel: PropTypes.string,
    input: PropTypes.string,
  }),
  options: PropTypes.shape({
    withInputFields: PropTypes.bool,
    customLabelFormat: PropTypes.func,
    withCustomTooltipLabel: PropTypes.bool,
  }),
};

export default SliderRange;
