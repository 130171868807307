import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below },
    palette: { themeColor: themeColor, dark: dark },
    breakpoints: { values: values },
  } = theme;
  return {
    main_container: {
      paddingLeft: "10%",
      paddingRight: "10%",
      [below(values.xs)]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    button_container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      height: spacing(4),
      textTransform: "none !important",
      backgroundColor: `${themeColor.primary} !important`,
      borderRadius: "0px !important",
      padding: `${spacing(1.25)}px ${spacing(2.5)}px !important`,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: dark.black,
      },
    },
    margin_top: {
      marginTop: spacing(8),
    },
  };
});

export { useStyles };
