import React, { useState } from "react";
import PropTypes from "prop-types";
import { defineIconColor } from "../../utils/helper";

const PriceFree = ({
  onHoverColor,
  fillColor,
  onClickColor,
  inactiveColor,
  disabled,
  selectedStatus,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <svg
      width="98"
      height="98"
      viewBox="0 0 98 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        fill={defineIconColor(
          disabled,
          selectedStatus,
          fillColor,
          onClickColor,
          inactiveColor,
          isHovered,
          onHoverColor
        )}
        d="M20.37 44.18V48.99H28.794V52.37H20.37V59H16.158V40.8H29.912V44.18H20.37ZM43.8395 59L40.3295 53.93H40.1215H36.4555V59H32.2435V40.8H40.1215C41.7335 40.8 43.1288 41.0687 44.3075 41.606C45.5035 42.1433 46.4221 42.906 47.0635 43.894C47.7048 44.882 48.0255 46.052 48.0255 47.404C48.0255 48.756 47.6961 49.926 47.0375 50.914C46.3961 51.8847 45.4775 52.63 44.2815 53.15L48.3635 59H43.8395ZM43.7615 47.404C43.7615 46.3813 43.4321 45.6013 42.7735 45.064C42.1148 44.5093 41.1528 44.232 39.8875 44.232H36.4555V50.576H39.8875C41.1528 50.576 42.1148 50.2987 42.7735 49.744C43.4321 49.1893 43.7615 48.4093 43.7615 47.404ZM64.9346 55.62V59H50.8426V40.8H64.5966V44.18H55.0286V48.132H63.4786V51.408H55.0286V55.62H64.9346ZM81.858 55.62V59H67.766V40.8H81.52V44.18H71.952V48.132H80.402V51.408H71.952V55.62H81.858Z"
      />
      <path
        d="M90.1878 48.9999C90.1878 71.7286 71.5801 90.1864 48.5861 90.1864C25.592 90.1864 6.98438 71.7286 6.98438 48.9999C6.98438 26.2713 25.592 7.81348 48.5861 7.81348C71.5801 7.81348 90.1878 26.2713 90.1878 48.9999Z"
        strokeWidth="4"
        stroke={defineIconColor(
          disabled,
          selectedStatus,
          fillColor,
          onClickColor,
          inactiveColor,
          isHovered,
          onHoverColor
        )}
      />
      <circle
        cx="49"
        cy="49"
        r="47"
        stroke={defineIconColor(
          disabled,
          selectedStatus,
          fillColor,
          onClickColor,
          inactiveColor,
          isHovered,
          onHoverColor
        )}
        strokeWidth="4"
      />
    </svg>
  );
};

PriceFree.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  onClickColor: PropTypes.string,
  fillColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
  selectedStatus: PropTypes.bool,
};

PriceFree.defaultProps = {
  disabled: false,
  fillColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default PriceFree;
