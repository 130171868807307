import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import TextField from "../../../../common/TextField/TextField";
import { useStyles } from "./RealEstateFields.style";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import {
  MANDATORY_FIELDS_FOR_REAL_ESTATE,
  EMAIL,
  STATE,
  ZIPCODE,
  GRAY,
  PHONE_NUMBER,
  BEDROOMS,
  BATHROOMS,
  LOT_SIZE,
  PRICE,
  MLS_NUMBER,
  MLS_URL,
  LISTING_URL,
  REALTOR_NAME,
  WEBSITE,
  AGENCY_NAME,
  AGENCY_PHONE_NUMBER,
  ADDRESS,
  HEADLINE,
  GREEN,
  UNDER_CONTRACT,
  SOLD,
  FOR_RENT,
  FOR_SALE,
  CONTENT_ID,
  TOWN,
} from "../../diyConstants";
import Checkbox from "../../../../common/Checkbox/Checkbox";
import Typography from "../../../../common/Typography/Typography";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../../../hooks/useMediaQuery";
import Dropdown from "../../../../common/Dropdown/Dropdown";
import { getDropdownChoices } from "../../../../services/townService";

const HTTPS = "https://";

const RealEstateFields = ({ formErrors, usStates, content }) => {
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();
  const [sold, setSold] = useState(content?.sold || false);
  const [forRent, setForRent] = useState(content?.for_rent || false);
  const [forSale, setForSale] = useState(content?.for_sale || false);
  const [underContract, setUnderContract] = useState(
    content?.under_contract || false
  );
  const [chosenStateId, setChosenStateId] = useState();

  const shouldMarkError = (prop) => {
    let validation;

    validation = MANDATORY_FIELDS_FOR_REAL_ESTATE.find(
      (mandatory) => mandatory.key === prop
    );
    if (formErrors.includes(validation.error)) {
      return true;
    }
  };

  useEffect(() => {
    setProperty(SOLD, sold);
  }, [sold]);

  useEffect(() => {
    setProperty(FOR_RENT, forRent);
  }, [forRent]);

  useEffect(() => {
    setProperty(FOR_SALE, forSale);
  }, [forSale]);

  useEffect(() => {
    setProperty(UNDER_CONTRACT, underContract);
  }, [underContract]);

  const handleStateChoice = (choice) => {
    setChosenStateId(choice);
  };

  useEffect(() => {
    setProperty(STATE, chosenStateId);
  }, [chosenStateId]);

  useEffect(() => {
    setProperty(CONTENT_ID, content?.id);
    setProperty(HEADLINE, content?.name);
    setProperty(ADDRESS, content?.address);
    setProperty(TOWN, content?.town);
    setProperty(STATE, content?.state);
    setProperty(ZIPCODE, content?.zip_code);
    setProperty(BEDROOMS, content?.bedrooms);
    setProperty(BATHROOMS, content?.bathrooms);
    setProperty(LOT_SIZE, content?.lot_size);
    setProperty(PRICE, content?.price);
    setProperty(MLS_NUMBER, content?.mls_number);
    setProperty(MLS_URL, content?.mls_url);
    setProperty(LISTING_URL, content?.listing_url);
    setProperty(SOLD, content?.sold);
    setProperty(FOR_RENT, content?.for_rent);
    setProperty(FOR_SALE, content?.for_sale);
    setProperty(UNDER_CONTRACT, content?.under_contract);
    setProperty(REALTOR_NAME, content?.realtor_name);
    setProperty(EMAIL, content?.realtor_email);
    setProperty(PHONE_NUMBER, content?.realtor_phone);
    setProperty(WEBSITE, content?.website);
    setProperty(AGENCY_NAME, content?.agency_name);
    setProperty(AGENCY_PHONE_NUMBER, content?.phone_number);
  }, [content]);

  return (
    <>
      <Typography
        level={screenWidthIsHigherThan(900) ? "h3_sub_header" : "medium_17_px"}
        bold
        color={GREEN}
        className={classes.green_label}
      >
        {labels.LISTING_INFORMATION}
      </Typography>
      <div>
        <TextField
          label={labels.HEADLINE}
          onChange={(e) => setProperty(HEADLINE, e.target.value)}
          error={shouldMarkError(HEADLINE)}
          defaultValue={content?.name}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <Typography
          level={
            screenWidthIsLowerThan(900) ? "medium_17_px" : "h4_sub_header_4"
          }
          color={GRAY}
          className={classes.display_block}
        >
          {labels.EXPIRE_REAL_ESTATES}
        </Typography>
      </div>
      <Typography
        level={screenWidthIsHigherThan(900) ? "h3_sub_header" : "medium_17_px"}
        bold
        color={GREEN}
        className={classes.green_label}
      >
        {labels.PROPERTY_INFORMATION}
      </Typography>
      {screenWidthIsLowerThan(899) && (
        <>
          <div className={classes.checkbox_with_custom_label_container}>
            <div className={classes.checkbox_label_container}>
              <Typography level="medium_17_px" bold color={GRAY}>
                {labels.FOR_RENT}
              </Typography>
            </div>
            <Checkbox
              className={classes.real_estate_checkbox}
              onChange={() => {
                setForRent(!forRent);
              }}
              checked={forRent}
            />
          </div>

          <div className={classes.checkbox_with_custom_label_container}>
            <div className={classes.checkbox_label_container}>
              <Typography level="medium_17_px" bold color={GRAY}>
                {labels.FOR_SALE}
              </Typography>
            </div>
            <Checkbox
              className={classes.real_estate_checkbox}
              onChange={() => {
                setForSale(!forSale);
              }}
              checked={forSale}
            />
          </div>

          <div className={classes.checkbox_with_custom_label_container}>
            <div className={classes.checkbox_label_container}>
              <Typography level="medium_17_px" bold color={GRAY}>
                {labels.SOLD}
              </Typography>
            </div>
            <Checkbox
              className={classes.real_estate_checkbox}
              onChange={() => {
                setSold(!sold);
              }}
              checked={sold}
            />
          </div>
          <div className={classes.checkbox_with_custom_label_container}>
            <div className={classes.checkbox_label_container}>
              <Typography level="medium_17_px" bold color={GRAY}>
                {labels.UNDER_CONTRACT}
              </Typography>
            </div>
            <Checkbox
              className={classes.real_estate_checkbox}
              onChange={() => {
                setUnderContract(!underContract);
              }}
              checked={underContract}
            />
          </div>
        </>
      )}
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.ADDRESS}
          onChange={(e) => setProperty(ADDRESS, e.target.value)}
          error={shouldMarkError(ADDRESS)}
          defaultValue={content?.address}
          required
        />
      </div>
      {screenWidthIsHigherThan(900) && (
        <>
          <div className={classes.multiple_textfields_container}>
            <div className={classes.multiple_textfields_width_30}>
              <TextField
                onChange={(e) => setProperty(TOWN, e.target.value)}
                label={labels.TOWN}
                error={shouldMarkError(TOWN)}
                defaultValue={content?.town}
                required
              />
            </div>
            <div className={classes.multiple_textfields_width_30}>
              <Dropdown
                label={labels.STATE}
                items={getDropdownChoices(usStates, handleStateChoice, false)}
                getChosenOptionId={handleStateChoice}
                error={shouldMarkError(STATE)}
                defaultValue={{
                  label: content?.state,
                  action: () =>
                    handleStateChoice(
                      usStates.find((s) => s.name === content?.state)?.id
                    ),
                  labelComponent: content?.state,
                }}
                required
                typographyLevel={"h4"}
                shouldPreselectFirstOption={false}
              />
            </div>
            <div className={classes.multiple_textfields_width_30}>
              <TextField
                onChange={(e) => setProperty(ZIPCODE, e.target.value)}
                label={labels.ZIP_CODE}
                defaultValue={content?.zip_code}
                error={shouldMarkError(ZIPCODE)}
                required
              />
            </div>
          </div>
          <div className={classes.multiple_textfields_container}>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(BEDROOMS, e.target.value)}
                label={labels.BEDROOMS}
                defaultValue={content?.bedrooms}
              />
            </div>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(BATHROOMS, e.target.value)}
                label={labels.BATHROOMS}
                placeholder={labels.NUMBER_BATHROOMS_EXAMPLE}
                defaultValue={content?.bathrooms}
              />
            </div>
          </div>
          <div className={classes.multiple_textfields_container}>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(LOT_SIZE, e.target.value)}
                label={labels.LOT_SIZE}
                defaultValue={content?.lot_size}
              />
            </div>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(PRICE, e.target.value)}
                label={labels.PRICE}
                defaultValue={content?.price}
                error={shouldMarkError(PRICE)}
                required
              />
            </div>
          </div>
          <div className={classes.multiple_textfields_container}>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(MLS_NUMBER, e.target.value)}
                label={labels.MLS_NUMBER}
                defaultValue={content?.mls_number}
              />
            </div>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(MLS_URL, e.target.value)}
                defaultValue={content?.mls_url ? content?.mls_url : HTTPS}
                label={labels.MLS_URL}
              />
            </div>
          </div>
        </>
      )}

      {screenWidthIsLowerThan(899) && (
        <>
          <div className={classes.multiple_textfields_container}>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(TOWN, e.target.value)}
                label={labels.TOWN}
                error={shouldMarkError(TOWN)}
                defaultValue={content?.town}
                required
              />
            </div>
            <div className={classes.multiple_textfields_width_45}>
              <Dropdown
                label={labels.STATE}
                items={getDropdownChoices(usStates, handleStateChoice, false)}
                getChosenOptionId={handleStateChoice}
                error={shouldMarkError(STATE)}
                required
                typographyLevel={"medium_17_px"}
                shouldPreselectFirstOption={false}
                defaultValue={{
                  label: content?.state,
                  action: handleStateChoice,
                  labelComponent: content?.state,
                }}
              />
            </div>
          </div>
          <div className={classes.multiple_textfields_container}>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(ZIPCODE, e.target.value)}
                label={labels.ZIP_CODE}
                defaultValue={content?.zip_code}
              />
            </div>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(BEDROOMS, e.target.value)}
                label={labels.BEDROOMS}
                defaultValue={content?.bedrooms}
                type={"number"}
              />
            </div>
          </div>
          <div className={classes.multiple_textfields_container}>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(BATHROOMS, e.target.value)}
                label={labels.BATHROOMS}
                defaultValue={content?.bathrooms}
                type={"number"}
                placeholder={labels.NUMBER_BATHROOMS_EXAMPLE}
              />
            </div>
            <div className={classes.multiple_textfields_width_45}>
              <TextField
                onChange={(e) => setProperty(LOT_SIZE, e.target.value)}
                label={labels.LOT_SIZE}
                defaultValue={content?.lot_size}
              />
            </div>
          </div>
          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setProperty(PRICE, e.target.value)}
              label={labels.PRICE}
              defaultValue={content?.price}
            />
          </div>

          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setProperty(MLS_NUMBER, e.target.value)}
              label={labels.MLS_NUMBER}
              defaultValue={content?.mls_number}
            />
          </div>
          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setProperty(MLS_URL, e.target.value)}
              label={labels.MLS_URL}
              defaultValue={content?.mls_url}
            />
          </div>
        </>
      )}
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.LISTING_URL}
          defaultValue={content?.listing_url ? content?.listing_url : HTTPS}
          onChange={(e) => setProperty(LISTING_URL, e.target.value)}
        />
      </div>
      {screenWidthIsHigherThan(900) && (
        <>
          <div className={classes.checkbox_with_custom_label_container}>
            <div className={classes.checkbox_label_container}>
              <Typography level="h3_sub_header" bold color={GRAY}>
                {labels.FOR_RENT}
              </Typography>
            </div>
            <Checkbox
              className={classes.real_estate_checkbox}
              onChange={() => {
                setForRent(!forRent);
              }}
              checked={forRent}
            />
          </div>
          <div className={classes.checkbox_with_custom_label_container}>
            <div className={classes.checkbox_label_container}>
              <Typography level="h3_sub_header" bold color={GRAY}>
                {labels.FOR_SALE}
              </Typography>
            </div>
            <Checkbox
              className={classes.real_estate_checkbox}
              onChange={() => {
                setForSale(!forSale);
              }}
              checked={forSale}
            />
          </div>
          <div className={classes.checkbox_with_custom_label_container}>
            <div className={classes.checkbox_label_container}>
              <Typography level="h3_sub_header" bold color={GRAY}>
                {labels.SOLD}
              </Typography>
            </div>
            <Checkbox
              className={classes.real_estate_checkbox}
              onChange={() => {
                setSold(!sold);
              }}
              checked={sold}
            />
          </div>
          <div className={classes.checkbox_with_custom_label_container}>
            <div className={classes.checkbox_label_container}>
              <Typography level="h3_sub_header" bold color={GRAY}>
                {labels.UNDER_CONTRACT}
              </Typography>
            </div>
            <Checkbox
              className={classes.real_estate_checkbox}
              onChange={() => {
                setUnderContract(!underContract);
              }}
              checked={underContract}
            />
          </div>
        </>
      )}
      <Typography
        level={screenWidthIsHigherThan(900) ? "h3_sub_header" : "medium_17_px"}
        bold
        color={GREEN}
        className={classes.green_label}
      >
        {labels.REALTOR_INFORMATION}
      </Typography>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.REALTOR_NAME}
          onChange={(e) => setProperty(REALTOR_NAME, e.target.value)}
          defaultValue={content?.realtor_name}
        />
      </div>
      <div className={classes.multiple_textfields_container}>
        <div className={classes.multiple_textfields_width_45}>
          <TextField
            onChange={(e) => setProperty(PHONE_NUMBER, e.target.value)}
            label={labels.PHONE}
            defaultValue={content?.realtor_phone}
          />
        </div>
        <div className={classes.multiple_textfields_width_45}>
          <TextField
            onChange={(e) => setProperty(EMAIL, e.target.value)}
            label={labels.EMAIL}
            defaultValue={content?.realtor_email}
          />
        </div>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.WEBSITE}
          defaultValue={content?.website ? content?.website : HTTPS}
          onChange={(e) => setProperty(WEBSITE, e.target.value)}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.AGENCY_NAME}
          onChange={(e) => setProperty(AGENCY_NAME, e.target.value)}
          defaultValue={content?.agency_name}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.AGENCY_PHONE_NUMBER}
          onChange={(e) => setProperty(AGENCY_PHONE_NUMBER, e.target.value)}
          defaultValue={content?.agency_phone}
        />
      </div>
    </>
  );
};

RealEstateFields.propTypes = {
  formErrors: PropTypes.arrayOf(PropTypes.string),
  usStates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  content: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    town: PropTypes.string,
    state: PropTypes.string,
    zop_code: PropTypes.string,
    bedrooms: PropTypes.string,
    bathrooms: PropTypes.string,
    lot_size: PropTypes.string,
    price: PropTypes.string,
    mls_number: PropTypes.string,
    mls_url: PropTypes.string,
    listing_url: PropTypes.string,
    sold: PropTypes.bool,
    for_sale: PropTypes.bool,
    for_rent: PropTypes.bool,
    under_contract: PropTypes.bool,
    realtor_name: PropTypes.string,
    realtor_phone: PropTypes.string,
    realtor_email: PropTypes.string,
    website: PropTypes.string,
    agency_name: PropTypes.string,
    agency_phone: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
};

export default RealEstateFields;
