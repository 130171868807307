import React from "react";
import PropTypes from "prop-types";
import AdCard from "../../Homepage/AdCard/AdCard";
import Card from "../../../common/Card/Card";
import VideoAd from "../../Ad/VideoAd";
import { useStyles } from "./RightBlocksGames.style";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import values from "../../../common/Theme/themes/breakpoints/values";


const RightBlocksGames = ({ adBlocks, adTargets, videoAd }) => {

    const classes = useStyles();
    const isMobile = screenWidthIsLowerThan(values.sm);

 
  return (
    <>
      {videoAd && Object.keys(videoAd).length > 0 && (
        <Card
          children={<VideoAd videoAd={videoAd} />}
          noRounded={true}
          withShadow={false}
          classes={{ root: classes.video_ad_card }}
        />
      )}
      {adBlocks.map((block, index) => (
        <AdCard
          key={index}
          adUnit={
            isMobile ? block?.mobile_ad_units[0] : block?.desktop_ad_units[0]
          }
          adTargets={adTargets}
          adSize={block.size}
          options={{
            forTopStories: false,
          }}
        />
      ))}
    </>
  );
};

RightBlocksGames.propTypes = {
  adBlocks: PropTypes.array,
  adTargets: PropTypes.object,
  videoAd: PropTypes.object,
};

export default RightBlocksGames;
