import React from "react";
import PropTypes from "prop-types";
import ContentBodyWithContactInformation from "../../ContentBody/WithContactInformation/ContentBodyWithContactInformation";
import { useStyles } from "./ClassifiedBody.style";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";

const ClassifiedBody = ({ classified, isContentPreview }) => {
  const classes = useStyles();
  return (
    <ContentBodyWithContactInformation
      contactInformationData={{
        published_at: classified.published_at,
        name: classified.contact_information.contact_person,
        phone: classified.contact_information.phone,
        email: classified.contact_information.email,
        website: classified.contact_information.url,
        facebook: classified.contact_information.facebook,
        twitter: classified.contact_information.twitter,
      }}
      contactInformationTemplateStyleProps={{
        title: {
          level: "t3_text_3",
          color: "black",
          bold: true,
          underlined: false,
          className: classes.contact_info_header,
        },
        links: {
          level: "t3_text_3",
          color: "black",
          bold: false,
          underlined: true,
          className: "",
        },
        content: {
          level: "t3_text_3",
          color: "black",
          bold: false,
          underlined: false,
          className: "",
        },
        publishedAt: {
          level: "t2_text_2",
          color: "gray",
          bold: true,
          underlined: false,
          className: "",
        },
      }}
      contactInformationTemplatePosition={
        screenWidthIsLowerThan(960) ? "bottom" : "top"
      }
      mediaContent={classified.media_content}
      description={classified.description}
      classes={{ information_container: classes.contact_info }}
      isContentPreview={isContentPreview}
      {...(screenWidthIsLowerThan(960)
        ? { videoPlayerProps: { height: "220px", width: "90%" } }
        : { videoPlayerProps: { height: "320px", width: "100%" } })}
    />
  );
};

ClassifiedBody.propTypes = {
  classified: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    published_at: PropTypes.string,
    description: PropTypes.string,
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    contact_information: PropTypes.shape({
      contact_person: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      url: PropTypes.string,
      facebook: PropTypes.string,
      twitter: PropTypes.string,
    }),
  }),
  isContentPreview: PropTypes.bool,
};

export default ClassifiedBody;
