import { makeStyles } from "../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    icon_container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down(961)]: {
        "& svg": {
          width: theme.spacing(8),
          height: theme.spacing(8),
        },
        "& span": {
          display: "block",
        },
      },
    },
    first_icon: {
      [theme.breakpoints.down(900)]: {
        marginTop: `0px`,
      },
    },

    payments_container: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
    icon_double_lower_text: {
      width: "30%",
      textAlign: "center",
    },
  };
});

export { useStyles };
