import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      marginTop: theme.spacing(2),
    },
    divider: {
      margin: `${theme.spacing(1)}px 0px`,
      border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
      [theme.breakpoints.down(960)]: {
        margin: `${theme.spacing(2)}px 0px`,
      },
    },
    pagination_container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    columns_pagination: {
      "& .Mui-selected": {
        backgroundColor: "unset !important",
        color: theme.palette.tapintoBranding.greenVariationDark,
        fontWeight: "bold",
      },
      "& .MuiPaginationItem-page": {
        fontWeight: "bold",
      },
    },
  };
});

export { useStyles };
