import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./ContactButtons.style";
import Button from "../../../../common/Button/Button";
import labels from "../../../../config/labels";
import { handleModalActions } from "../../../../utils/helper";
import SendToAFriendForm from "../../Forms/SendToAFriend/SendToAFriend";
import RealEstateModalForForms from "../../Forms/ModalForForms/ModalForForms";
import RealtorInfo from "../../Forms/RealtorInfo/RealtorInfo";
import { realtorInfo } from "../../../../services/realEstates";

const ContactButtons = ({ buttonToShow, slug, disabled }) => {
  const classes = useStyles();
  const [openSendToAFriendForm, setOpenSendToAFriendForm] = useState(false);
  const [openRealtorInfo, setOpenRealtorInfo] = useState(false);
  const [realtor, setRealtor] = useState(false);

  useEffect(() => {
    const fetchRealtorInfo = async () => {
      const response = await realtorInfo(slug);

      if (response?.status === 200) {
        setRealtor(response?.data?.realtor_info);
      }
    };

    fetchRealtorInfo();
  }, [slug]);

  const handleClose = () => {
    let setOpenStatus;

    if (openSendToAFriendForm) {
      setOpenStatus = setOpenSendToAFriendForm;
    } else if (openRealtorInfo) {
      setOpenStatus = setOpenRealtorInfo;
    }

    handleModalActions({
      type: "realEstateContactForms",
      action: "close",
      setOpenModal: setOpenStatus,
    });
  };

  const handleOpen = (setOpenStatus) => {
    handleModalActions({
      type: "realEstateContactForms",
      action: "open",
      setOpenModal: setOpenStatus,
    });
  };

  const VisibleButtons = () => {
    if (buttonToShow === "contactRealtor") {
      return realtor ? (
        <Button
          level="simple_button"
          color="primary"
          children={labels.CONTACT_REALTOR}
          onClick={() => handleOpen(setOpenRealtorInfo)}
          classes={{
            root: clsx(
              classes.button,
              classes.button_label,
              classes.contact_realtor_button
            ),
          }}
        />
      ) : (
        <></>
      );
    } else if (buttonToShow === "sendToAFriend") {
      return (
        <Button
          disabled={disabled}
          level="simple_button"
          color="primary"
          children={labels.SEND_TO_A_FRIEND}
          onClick={() => handleOpen(setOpenSendToAFriendForm)}
          classes={{
            root: clsx(
              classes.button,
              classes.button_label,
              classes.send_to_a_friend_button
            ),
          }}
        />
      );
    }

    return (
      <>
        <Button
          level="simple_button"
          color="primary"
          disabled={disabled}
          children={labels.SEND_TO_A_FRIEND}
          onClick={() => handleOpen(setOpenSendToAFriendForm)}
          classes={{
            root: clsx(
              classes.button,
              classes.button_label,
              classes.send_to_a_friend_button
            ),
          }}
        />
        {realtor && (
          <Button
            level="simple_button"
            color="primary"
            children={labels.CONTACT_REALTOR}
            onClick={() => handleOpen(setOpenRealtorInfo)}
            classes={{
              root: clsx(
                classes.button,
                classes.button_label,
                classes.contact_realtor_button
              ),
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <VisibleButtons />

      {openRealtorInfo && (
        <RealEstateModalForForms
          title={""}
          content={<RealtorInfo slug={slug} info={realtor} />}
          openState={openRealtorInfo}
          handleClose={handleClose}
          isRealtorInfo
        />
      )}

      {openSendToAFriendForm && (
        <RealEstateModalForForms
          title={labels.SEND_TO_A_FRIEND}
          content={<SendToAFriendForm slug={slug} handleClose={handleClose} />}
          openState={openSendToAFriendForm}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

ContactButtons.propTypes = {
  buttonToShow: PropTypes.oneOf(["contactRealtor", "sendToAFriend"]),
  slug: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ContactButtons;
