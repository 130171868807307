import React from "react";
import PropTypes from "prop-types";
import ClassifiedBody from "../../../components/Classified/Body/ClassifiedBody";
import ClassifiedShowHeader from "../../../components/Classified/Headers/Show/ClassifiedShowHeader";
import { useStyles } from "./ClassifiedShow.style";
import StickySocialNetworksShareButtons from "../../../components/StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import { isMobile } from "react-device-detect";

const OFFSET_HEIGHT = 250;

const ClassifiedShow = ({
  classified,
  town_slug,
  edit_url,
  show_content_options,
  sponsor_info,
  is_advertisement,
  is_content_preview,
}) => {
  const classes = useStyles(isMobile);

  return (
    <>
      <div className={classes.mobile_padding}>
        <ClassifiedShowHeader
          isAdvertisement={is_advertisement}
          showContentOptions={show_content_options}
          sponsorInfo={sponsor_info}
          categories={classified.categories}
          editUrl={edit_url}
          contentTitle={classified.name}
          townSlug={town_slug}
          isContentPreview={is_content_preview}
        />
        {!is_content_preview && (
          <StickySocialNetworksShareButtons offsetHeight={OFFSET_HEIGHT} />
        )}
        <ClassifiedBody
          classified={classified}
          isContentPreview={is_content_preview}
        />
      </div>
    </>
  );
};

ClassifiedShow.propTypes = {
  classified: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    published_at: PropTypes.string,
    description: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    contact_information: PropTypes.shape({
      contact_person: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      url: PropTypes.string,
      facebook: PropTypes.string,
      twitter: PropTypes.string,
    }),
  }),
  town_slug: PropTypes.string,
  edit_url: PropTypes.string,
  show_content_options: PropTypes.bool,
  sponsor_info: PropTypes.shape({
    page_type: PropTypes.string,
    sponsor_attr: PropTypes.string,
    top_local_news: PropTypes.bool,
    town: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    columns_slug: PropTypes.arrayOf(PropTypes.string),
    sections_slug: PropTypes.arrayOf(PropTypes.string),
  }),
  is_advertisement: PropTypes.bool,
  is_content_preview: PropTypes.bool,
};

export default ClassifiedShow;
