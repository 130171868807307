import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    banner_container: {
      "& .MuiPaper-root": {
        backgroundColor: "#EDBCC2!important",
      },
      "& .MuiSnackbarContent-root": {
        color: "#75040E!important",
      },
    },
    banner_cms: {
      "& .MuiSnackbar-root": {
        top: `${theme.spacing(10)}px !important`,
      },
    },
    banner: {
      "& .MuiSnackbar-root": {
        top: `${theme.spacing(22.5)}px !important`,
      },
    },
    banner_cms_scrolled: {
      "& .MuiSnackbar-root": {
        top: `${theme.spacing(3.13)}px !important`,
      },
    },
    banner_scrolled: {
      "& .MuiSnackbar-root": {
        top: `${theme.spacing(9.38)}px !important`,
      },
    },
  };
});

export { useStyles };
