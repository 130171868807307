import React, { useEffect } from "react";
import Masterhead from "../../components/Masterhead/Masterhead";
import Footer from "../../components/Footer/Footer";
import { useStyles } from "./GamesLayout.style";
import Games from "../../pages/Games/Games";
import { useTown, withTownContextProvider } from "../../context/TownContext";
import PropTypes from "prop-types";
import Sponsor from "../../components/Sponsor/Sponsor";

const GamesLayout = ({ town_info, master_head_info, page_type, sponsor_info, data_set }) => {
  const classes = useStyles();
  
  const { setTown } = useTown();
  useEffect(() => {
    if (town_info) {
      setTown({ ...town_info, slug_for_gam: town_info.slug.slice(0, 40) });
    }
  }, [town_info]);

  return (
    <>
      <div className={classes.master_head_container}>
        <Masterhead {...master_head_info} />
      </div>
      <Games pageType={page_type} sponsor_info={sponsor_info} dataSet={data_set}/>
      <Footer town={town_info} />
    </>
  );
};

GamesLayout.propTypes = {
  town_info: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default withTownContextProvider(GamesLayout);
