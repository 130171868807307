import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  horizontal_divider: {
    margin: `${theme.spacing(2)}px 0px`,
    border: `1px solid ${theme.palette.grayVariation.grayForBorders}`,
  },
  content_name: {
    opacity: "0.6",
  },
  content_block: {
    marginBottom: theme.spacing(1.5),
  },
  content_name_link: {
    "& :hover": {
      color: theme.palette.light.gray,
      textDecoration: "underline",
    },
  },
}));

export { useStyles };
