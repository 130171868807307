import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ContentOptionsPopUp.style";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import labels from "../../../config/labels";
import ContentOptionsDialogBody from "./ContentOptionsDialogBody";
import restClient from "../../../utils/restClient";

const ContentOptionsPopUp = ({ setOpenPopUp, openPopUp, url, type }) => {
  const [deleteMessage, setDeleteMessage] = useState(
    labels.DELETE_CONTENT_POP_UP_TEXT
  );
  const [deleteAction, setDeleteAction] = useState("regular");
  const [confirmAction, setConfirmAction] = useState(labels.YES);

  const onDelete = async () => {
    const response = await restClient.remove(url);
    if (response?.status === 200) {
      setDeleteMessage(labels.REAL_ESTATE_SUCCESSFULLY_REMOVED);
      setDeleteAction("removed");
      setConfirmAction(labels.CONTINUE);
    }
  };

  const handleCloseDelete = () => {
    if (deleteAction === "regular") {
      setOpenPopUp(false);
    } else {
      let path = window.location.href;
      let split = path.split("/");
      let splicedStr = split.slice(0, split.length - 1).join("/") + "/";
      window.location.href = splicedStr;
    }
  };

  const actions = {
    edit: {
      title: labels.EDIT_CONTENT,
      content: labels.EDIT_CONTENT_POP_UP_TEXT,
      button: labels.CONTINUE,
      action: () => (window.location.href = url),
      color: "green",
      onClose: () => setOpenPopUp(false),
    },
    delete: {
      title: labels.DELETE_CONTENT,
      content: deleteMessage,
      button: confirmAction,
      action: () =>
        deleteAction === "regular" ? onDelete() : handleCloseDelete(),
      color: "red",
      onClose: handleCloseDelete,
    },
  };
  const classes = useStyles(actions[type]["color"]);

  return (
    <PopUpDialog
      onClose={actions[type]["onClose"]}
      openState={openPopUp}
      title={actions[type]["title"]}
      content={
        <ContentOptionsDialogBody
          contentText={actions[type]["content"]}
          buttonText={actions[type]["button"]}
          onButtonClick={actions[type]["action"]}
          buttonTypographyProps={{ color: actions[type]["color"] }}
          buttonClassName={classes.button}
        />
      }
      titleClass={classes.title}
      titleTypographyClass={classes.title_typography}
      closeButtonClass={classes.close_button}
    />
  );
};

ContentOptionsPopUp.propTypes = {
  setOpenPopUp: PropTypes.func,
  openPopUp: PropTypes.bool,
  url: PropTypes.string,
  type: PropTypes.oneOf(["edit", "delete"]),
};

export default ContentOptionsPopUp;
