import React from "react";
import PropTypes from "prop-types";

const VerticalSeparator = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="2"
      height="32"
      viewBox="0 0 2 32"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="32"
        width="32"
        height="2"
        transform="rotate(-90 0 32)"
        fill={fillColor}
      />
    </svg>
  );
};
VerticalSeparator.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};
VerticalSeparator.defaultProps = {
  fillColor: "#F98C10",
};

export default VerticalSeparator;
