import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  games_title: {
    fontSize: theme.spacing(4),
    fontWeight: 700,
    paddingBottom: `${theme.spacing(2)}px`,
    marginBottom: 0,
  },
  header_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: `${theme.spacing(6)}px`,
  }
}));

export { useStyles };
