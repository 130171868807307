import React from "react";
import clsx from "clsx";
import PaymentsByFormType from "../../ByFormType/PaymentsByFormType";
import ExemptionLink from "../../../ExemptionLink/ExemptionLink";
import { useStyles } from "./ArticlePayments.style";

const ArticlePayments = ({
  handleProfitSelection,
  setPriceSelectStatus,
  priceSelectStatus,
  nonProfitCheckboxStatus,
  canApplyForExemption,
  isContributor,
  townsWithClusters,
  envUrl,
  currentUserId,
  handleExemptionRequest,
  handleGovernmentSelection,
  colors,
  handleNonProfitSelection,
  setNonProfitCheckboxStatus,
  handlePoliticalCandidatesSelection,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={clsx(classes.row, classes.icons_container)}>
        <PaymentsByFormType
          handleProfitSelection={handleProfitSelection}
          setPriceSelectStatus={setPriceSelectStatus}
          priceSelectStatus={priceSelectStatus}
          nonProfitCheckboxStatus={nonProfitCheckboxStatus}
          handleGovernmentSelection={handleGovernmentSelection}
          colors={colors}
          handleNonProfitSelection={handleNonProfitSelection}
          setNonProfitCheckboxStatus={setNonProfitCheckboxStatus}
          handlePoliticalCandidatesSelection={
            handlePoliticalCandidatesSelection
          }
        />
      </div>

      <ExemptionLink
        canApplyForExemption={canApplyForExemption}
        isContributor={isContributor}
        priceSelectStatus={priceSelectStatus}
        townsWithClusters={townsWithClusters}
        envUrl={envUrl}
        currentUserId={currentUserId}
        handleExemptionRequest={handleExemptionRequest}
      />
    </>
  );
};

export default ArticlePayments;
