import { makeStyles } from "../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    // STARTS SERVICE BLOCK
    horizontal_divider: {
      border: `1px solid ${theme.palette.themeColor.primary}`,
      borderRadius: "8px",
      margin: `${theme.spacing(4)}px 0px ${theme.spacing(2)}px 0px`,
    },
    margin_top_0: {
      marginTop: 0,
    },
    date_time_range_pickers_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    padding_left_32: {
      paddingLeft: theme.spacing(4),
    },
    time_range_picker_container: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(20),
      width: "50%",
      [theme.breakpoints.down(900)]: {
        width: "100%",
        marginLeft: 0,
      },
    },
    obituary_time_picker: {
      "& div.MuiInputBase-root": {
        width: theme.spacing(19.75),
        "& input.MuiInputBase-input": {
          width: theme.spacing(19.75),
        },
        "&.Mui-focused": {
          "& input.MuiInputBase-input": {
            width: theme.spacing(19.75),
          },
        },
      },
    },
    add_more: {
      marginTop: theme.spacing(2),
    },
    add_more_link: {
      cursor: "pointer",
    },
  };
});

export { useStyles };
