import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    subheader: {
      fontWeight: 600,
    },
    container: {
      marginBottom: theme.spacing(5),
    },
  };
});

export { useStyles };
