import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    dialog_title: {
      "&.MuiDialogTitle-root": {
        margin: 0,
        boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
      },
    },
    no_title: {
      "&.MuiDialogTitle-root": {
        padding: 0,
      },
    },
    dialog_close_button: {
      height: theme.spacing(4),
      width: theme.spacing(4),
      position: "absolute",
      right: theme.spacing(2),
      cursor: "pointer",
    },
    dialog_form_container: {
      width: "30vw",
      [theme.breakpoints.down(1490)]: {
        width: "35vw",
      },
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    dialog_info_container: {
      width: "fit-content",
    },
    no_close_button: {
      display: "none",
    },
  };
});

export { useStyles };
