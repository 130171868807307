import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Link from "../../../common/Link/Link";
import { useStyles } from "./ArticleBox.style";
import labels from "../../../config/labels";
import { screenWidthIsHigherThan } from "../../../hooks/useMediaQuery";
import Item from "../../../common/Carousel/Item";
import Button from "../../../common/Button/Button";
import Typography from "../../../common/Typography/Typography";
import { redirectToPage } from "../../../utils/helper";
import SocialNetworkShareButtons from "../../SocialNetworkShareButtons/SocialNetworkShareButtons";
import withConfig from "../../../utils/withConfig";

const ArticleBox = ({ article }) => {
  const classes = useStyles();

  const getThumbnailUrl = () => {
    if (article.video_url) {
      return article.video_url;
    } else {
      return article.photo_url;
    }
  };

  const isVideoThumbnail = () => {
    if (article.video_url) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.article_box}>
      <div className={classes.thumbnail_container}>
        <Link
          children={
            <Item
              contentUrl={getThumbnailUrl()}
              caption=""
              credits=""
              alt_text={
                screenWidthIsHigherThan(960) ? getThumbnailUrl() : article.name
              }
              title=""
              isVideo={isVideoThumbnail()}
              className={clsx(
                classes.thumbnail_item,
                article.video_url && classes.thumbnail_video
              )}
            />
          }
          {...(!isVideoThumbnail() && { url: article.path })}
          className={classes.thumbnail_link}
        />
      </div>

      <div className={classes.info_container}>
        <Link
          children={<div dangerouslySetInnerHTML={{ __html: article.name }} />}
          url={article.path}
          level="t4_text_4"
        />

        <div className={classes.bottom_section}>
          <SocialNetworkShareButtons
            url={article.path}
            title={article.name}
            socialNetworks={["facebook", "twitter"]}
            iconsSize={25}
            paddingWithinButtons={10}
          />
          <Button
            className={classes.read_more_button}
            level="simple_button"
            color="primary"
            children={
              <Typography level="t1_text_1">{labels.READ_MORE}</Typography>
            }
            variant="text"
            onClick={() => redirectToPage(article.path, "_top")}
          />
        </div>
      </div>
    </div>
  );
};

ArticleBox.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    path: PropTypes.string,
    photo_url: PropTypes.string,
  }),
};

export default ArticleBox;
