import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    regular_text_field: {
      paddingBottom: theme.spacing(2),
    },
  };
});

export { useStyles };
