import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    obituary_show_container: {
      marginTop: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "&.header_container": {
        "&.left_container": {
          width: "50% !important",
        },
      },
      [theme.breakpoints.down(960)]: {
        alignItems: "center",
      },
    },
    title_container: {
      marginTop: theme.spacing(3),
      display: "flex",
    },
    date_container: {
      width: "100%",
    },
    image_container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& div.swiper-slide": {
        maxWidth: "100% !important",
      },
      "& img": {
        height: "100% !important",
        maxWidth: "100% !important",
      },
      [theme.breakpoints.down(960)]: {
        height: "auto",
      },
    },
    description: {
      marginTop: theme.spacing(2),
      fontFamily: "Montserrat",
      fontSize: "20px",
      color: theme.palette.dark.black,
      [theme.breakpoints.down(960)]: {
        marginTop: theme.spacing(1),
        maxWidth: "100%",
        wordBreak: "break-word",
      },
    },
    return_to_index_container: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      [theme.breakpoints.down(960)]: {
        justifyContent: "center",
      },
    },
    return_to_index: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "110%",
      [theme.breakpoints.down(960)]: {
        width: "125%",
        marginLeft: `-${theme.spacing(5)}px`,
        "& svg": {
          marginRight: theme.spacing(2),
        },
      },
    },
    top_section: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    top_container_left_section: {
      width: "65%",
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    top_container_right_section: {
      width: "30%",
      [theme.breakpoints.down(960)]: {
        width: "75%",
      },
    },
    full_width: {
      width: "100%",
    },
    title: {
      display: "block",
      width: "85%",
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
    splitted: {
      [theme.breakpoints.down(600)]: {
        width: "100%",
        display: "block",
      },
    },
    edit_button_container: {
      width: "15%",
      textAlign: "right",
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
  };
});

export { useStyles };
