import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    layout_container: {
      display: "flex",
      height: "100vh",
      marginTop: spacing(5),
    },
    column: {
      border: `1px solid ${palette.grayVariation.grayForBorders} !important`,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      width: "50%",
    },
    static_section: {
      height: "fit-content",
      flexGrow: 1,
    },
    static_section_top_bar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${palette.grayVariation.grayForBorders}`,
      padding: `${spacing(3)}px 0px`,
    },
    static_section_information: {
      padding: spacing(3),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderBottom: `1px solid ${palette.grayVariation.grayForBorders}`,
    },
    title_bedrooms_and_bathrooms_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: spacing(4),
    },
    scroll_section: {
      overflow: "scroll",
      overflowX: "hidden",
      flexGrow: 3,
    },
    back_to_real_estates_link: {
      textTransform: "uppercase",
      "& :hover": {
        textDecoration: "underline !important",
      },
    },
    back_to_real_estates_items_container: {
      display: "flex",
      alignItems: "center",
    },
    back_icon: {
      marginRight: spacing(1),
    },
    social_icons_container: {
      paddingRight: spacing(3),
    },
    separator: {
      height: spacing(1.5),
      margin: `0px ${spacing(1)}px`,
    },
    price: {
      fontWeight: "600 !important",
      margin: "0px !important",
    },
    address_container: {
      width: "40%",
      marginBottom: spacing(3),
    },
    status_and_button_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      columnGap: spacing(2),
    },
    contact_buttons_container: {
      width: "60%",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      rowGap: spacing(2),
    },
    property_status_container: {
      width: "40%",
    },
    scroll_section_gallery: {
      overflow: "hidden",
      overflowY: "scroll",
      maxHeight: "100%",
    },
    button: {
      height: spacing(4),
      borderRadius: "0px !important",
      padding: `${spacing(1.25)}px 0px !important`,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "14px",
        fontFamily: "Montserrat",
        color: palette.dark.black,
      },
    },
    nav_button: {
      height: "100%",
      borderRight: `1px solid ${palette.grayVariation.grayForBorders} !important`,
      minWidth: `${spacing(16.5)}px !important`,
      flexGrow: 1,
      "& .MuiButton-label": {
        fontWeight: 700,
      },
    },
    address_and_realtor_info_container: {
      display: "flex",
      columnGap: spacing(2),
    },
  };
});

export { useStyles };
