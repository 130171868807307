import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ServicesAndVisitationsSection.style";
import Typography from "../../../common/Typography/Typography";
import Link from "../../../common/Link/Link";
import labels from "../../../config/labels";
import Location from "../../../common/Icons/Location";
import AddToCalendarButton from "../../../common/AddToCalendarButton/AddToCalendarButton";
import { getViewInMapsUrl } from "../../../services/map";

const ServicesAndVisitationsSection = ({
  obituaryServices,
  obituaryName,
  isObituaryPreview,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.services_and_visitations_section}>
        {obituaryServices.map(
          (blockData, id) =>
            blockData.name && (
              <div className={classes.data_block} key={id}>
                <div>
                  <Typography level="h5_sub_header_2" bold>
                    {blockData.type && blockData.type}
                  </Typography>
                </div>
                <div>
                  <Typography level="t4_text_4">
                    {blockData.date +
                      " " +
                      labels.FROM +
                      " " +
                      blockData.time_from +
                      " " +
                      labels.TO +
                      " " +
                      blockData.time_to}

                    {blockData.name && (
                      <>
                        <br /> {blockData.name}
                      </>
                    )}
                    <br />
                    {blockData.address}
                  </Typography>
                </div>
                <div>
                  {blockData.address && (
                    <Link
                      color="gray"
                      level="t4_text_4"
                      bold
                      underlined
                      className={classes.view_in_google_maps_link}
                      url={getViewInMapsUrl(blockData.address)}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="none"
                      children={
                        <>
                          {labels.VIEW_IN_GOOGLE_MAPS}
                          <Location
                            fillColor="gray"
                            className={classes.location_icon}
                          />
                        </>
                      }
                    />
                  )}
                  {!isObituaryPreview && (
                    <AddToCalendarButton
                      starts={
                        blockData.date + " " + blockData.time_from_standard
                      }
                      ends={blockData.date + " " + blockData.time_to_standard}
                      title={blockData.type + ": " + obituaryName}
                      description={""}
                      address={blockData.address}
                      timezone={""}
                      className={classes.add_to_calendar_button_obituaries}
                      textProps={{
                        color: "white",
                        bold: true,
                        level: "t1_text_1",
                      }}
                    />
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </>
  );
};

ServicesAndVisitationsSection.propTypes = {
  obituaryServices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      address: PropTypes.string,
      time_from: PropTypes.any,
      time_to: PropTypes.any,
      date: PropTypes.string,
      time_from_standard: PropTypes.any,
      time_to_standard: PropTypes.any,
    })
  ),
  obituaryName: PropTypes.string,
  isObituaryPreview: PropTypes.bool,
};

export default ServicesAndVisitationsSection;
