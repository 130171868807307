import React from "react";
import PropTypes from "prop-types";

const WebsiteBox = ({ className, backgroundFillColor, iconFillColor }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill={backgroundFillColor}
      />
      <g clipPath="url(#clip0_8_206)">
        <path
          d="M15.99 6C10.47 6 6 10.48 6 16C6 21.52 10.47 26 15.99 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 15.99 6ZM22.92 12H19.97C19.65 10.75 19.19 9.55 18.59 8.44C20.43 9.07 21.96 10.35 22.92 12ZM16 8.04C16.83 9.24 17.48 10.57 17.91 12H14.09C14.52 10.57 15.17 9.24 16 8.04ZM8.26 18C8.1 17.36 8 16.69 8 16C8 15.31 8.1 14.64 8.26 14H11.64C11.56 14.66 11.5 15.32 11.5 16C11.5 16.68 11.56 17.34 11.64 18H8.26ZM9.08 20H12.03C12.35 21.25 12.81 22.45 13.41 23.56C11.57 22.93 10.04 21.66 9.08 20V20ZM12.03 12H9.08C10.04 10.34 11.57 9.07 13.41 8.44C12.81 9.55 12.35 10.75 12.03 12V12ZM16 23.96C15.17 22.76 14.52 21.43 14.09 20H17.91C17.48 21.43 16.83 22.76 16 23.96ZM18.34 18H13.66C13.57 17.34 13.5 16.68 13.5 16C13.5 15.32 13.57 14.65 13.66 14H18.34C18.43 14.65 18.5 15.32 18.5 16C18.5 16.68 18.43 17.34 18.34 18ZM18.59 23.56C19.19 22.45 19.65 21.25 19.97 20H22.92C21.96 21.65 20.43 22.93 18.59 23.56V23.56ZM20.36 18C20.44 17.34 20.5 16.68 20.5 16C20.5 15.32 20.44 14.66 20.36 14H23.74C23.9 14.64 24 15.31 24 16C24 16.69 23.9 17.36 23.74 18H20.36Z"
          fill={iconFillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_8_206">
          <rect
            width="24"
            height="24"
            fill={iconFillColor}
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

WebsiteBox.propTypes = {
  className: PropTypes.string,
  backgroundFillColor: PropTypes.string,
  iconFillColor: PropTypes.string,
};

WebsiteBox.defaultProps = {
  iconFillColor: "white",
  backgroundFillColor: "#7FC035",
};

export default WebsiteBox;
