import React, { useState } from "react";
import DatePicker from "../../../../../common/DatePicker/DatePicker";
import ButtonIcon from "../../../../../common/Button/ButtonIcon";
import clsx from "clsx";
import labels from "../../../../../config/labels";
import Button from "../../../../../common/Button/Button";
import { useStyles } from "./ScheduledDate.style";
import Trashcan from "../../../../../common/Icons/Trashcan";
import { ORANGE_HEX } from "../../../diyConstants";
import TimePicker from "../../../../../common/TimePicker/TimePicker";

const DATE = new Date();
DATE.setMinutes(0);
DATE.setSeconds(0);

const ScheduledDate = ({ dates, setDates, times, setTimes }) => {
  const classes = useStyles();

  const addDateField = () => {
    setDates([...dates, DATE]);
    setTimes([...times, DATE]);
  };

  const handleDateChange = (index, date) => {
    const newDates = [...dates];
    newDates[index] = date;
    setDates(newDates);
  };

  const removeDateField = (index) => {
    const newDates = [...dates];
    newDates.splice(index, 1);
    setDates(newDates);
  };

  return (
    <div className={classes.scheduled_dates}>
      {dates.map((date, index) => (
        <div key={index} className={classes.date_container}>
          <DatePicker
            defaultValue={date}
            selected={date}
            onChange={(date) => handleDateChange(index, date)}
            className={classes.date_picker}
            disablePast
          />
          <TimePicker
            defaultValue={times[index]}
            selected={times[index]}
            onChange={(time) => {
              const newTimes = [...times];
              newTimes[index] = time;
              setTimes(newTimes);
            }}
            className={classes.time_picker}
            minutesStep={60}
          />
          {index > 0 && (
            <ButtonIcon
              children={<Trashcan fillColor={ORANGE_HEX} />}
              onClick={() => removeDateField(index)}
              className={classes.button_icon}
            />
          )}
        </div>
      ))}
      {dates.length <= 2 && (
        <Button
          onClick={addDateField}
          children={labels.ADD_MORE_DATES}
          level="simple_button"
          color="primary"
          classes={{
            root: clsx(classes.button, classes.button_label),
          }}
        />
      )}
    </div>
  );
};

export default ScheduledDate;
