import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Gallery from "react-photo-gallery";
import Lightbox from "../Lightbox/Lightbox";

const PhotoGallery = ({ photos, targetRowHeight, direction }) => {
  const [parsedPhotos, setParsedPhotos] = useState([]);
  const [lightboxControls, setLightboxControls] = useState({
    toggler: false,
    slide: 1,
  });

  const parsePhotos = () => {
    setParsedPhotos(
      photos.map((photo) => {
        if (photo.isVideo) {
          return {
            src: photo.thumbnail,
            width: photo.width,
            height: photo.height,
          };
        } else {
          return {
            src: photo.contentUrl,
            width: photo.width,
            height: photo.height,
          };
        }
      })
    );
  };

  useEffect(() => {
    parsePhotos();
  }, [photos]);

  const handleOpenLightbox = (event, { index }) => {
    setLightboxControls({
      toggler: !lightboxControls.toggler,
      slide: index,
    });
  };

  return (
    <div>
      <Gallery
        photos={parsedPhotos}
        onClick={handleOpenLightbox}
        targetRowHeight={targetRowHeight}
        direction={direction}
      />

      <Lightbox items={photos} controls={lightboxControls} />
    </div>
  );
};
PhotoGallery.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      contentUrl: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      isVideo: PropTypes.bool,
      alt_text: PropTypes.string,
      captions: PropTypes.string,
      credits: PropTypes.string,
    })
  ).isRequired,
  targetRowHeight: PropTypes.number,
  direction: PropTypes.oneOf(["row", "column"]),
};

PhotoGallery.defaultProps = {
  direction: "row",
};

export default PhotoGallery;
