import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    obituary_block: {
      height: "auto",
      maxHeight: theme.spacing(30),
      width: theme.spacing(40),
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    image_container: {
      width: "25%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    image: {
      maxHeight: "100%",
      borderRadius: "16px",
    },
    info_container: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      justifyContent: "space-between",
      "& a": {
        lineHeight: "16px !important",
      },
    },
    links_container: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      marginTop: theme.spacing(2.5),
    },
    share_this_container: {
      width: "30%",
    },
  };
});

export { useStyles };
