import React from "react";
import clsx from "clsx";
import labels from "../../../../../config/labels";
import ClickableIcon from "../../../../../common/Icons/ClickableIcon/ClickableIcon";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import PriceTwentyFiveDollars from "../../../../../common/Icons/PriceTwentyFiveDollars";
import Typography from "../../../../../common/Typography/Typography";
import PriceTenDollars from "../../../../../common/Icons/PriceTenDollars";
import ExemptionLink from "../../../ExemptionLink/ExemptionLink";
import NonProfitCheckbox from "../../../NonProfitCheckbox/NonProfitCheckbox";
import { useDiy } from "../../../../../context/DiyContext";
import { useStyles } from "./EventPayments.style";

const EventPayments = ({
  handleProfitSelection,
  setPriceSelectStatus,
  priceSelectStatus,
  handlePoliticalCandidatesSelection,
  setNonProfitCheckboxStatus,
  canApplyForExemption,
  isContributor,
  townsWithClusters,
  envUrl,
  currentUserId,
  handleExemptionRequest,
  nonProfitCheckboxStatus,
  colors,
  handleNonProfitSelection,
  handleGovernmentSelection,
}) => {
  const isMobile = screenWidthIsLowerThan(values.md);
  const classes = useStyles();
  const { town } = useDiy();

  const PerOccurrence = (color) => {
    return (
      <Typography
        level={isMobile ? "medium_16_px" : "h5_sub_header_2"}
        bold
        color={color}
      >
        {labels.PER_OCCURRENCE}
      </Typography>
    );
  };

  return (
    <>
      <div className={classes.payments_container}>
        <ClickableIcon
          onClick={() => {
            handleProfitSelection(),
              setPriceSelectStatus({
                profit: true,
                politicalCandidates: false,
                nonProfit: false,
                government: false,
              });
          }}
          icon={
            <PriceTwentyFiveDollars
              fillColor={colors.profit.hex}
              onHoverColor={colors.primary.hex}
              onClickColor={colors.primary.hex}
              selectedStatus={priceSelectStatus.profit}
            />
          }
          upperText={labels.FOR_PROFIT}
          lowerText={
            <>
              {town?.under_license_contract
                ? labels.PER_PUBLICATION
                : labels.PER_SITE}
              <PerOccurrence color={colors.profit.key} />
            </>
          }
          textColor={colors.profit.key}
          textHoverColor={colors.primary.key}
          className={clsx(classes.icon_container, classes.first_icon)}
          typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
        />

        <ClickableIcon
          onClick={() => {
            handlePoliticalCandidatesSelection(),
              setPriceSelectStatus({
                profit: false,
                politicalCandidates: true,
                nonProfit: false,
                government: false,
              });
          }}
          icon={
            <PriceTwentyFiveDollars
              fillColor={colors.political_candidates.hex}
              onHoverColor={colors.primary.hex}
              onClickColor={colors.primary.hex}
              selectedStatus={priceSelectStatus.politicalCandidates}
            />
          }
          upperText={labels.POLITICAL_CANDIDATES}
          lowerText={
            <>
              {town?.under_license_contract
                ? labels.PER_PUBLICATION
                : labels.PER_SITE}
              <PerOccurrence color={colors.political_candidates.key} />
            </>
          }
          textColor={colors.political_candidates.key}
          textHoverColor={colors.primary.key}
          className={classes.icon_container}
          typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
        />

        <ClickableIcon
          onClick={() => {
            handleNonProfitSelection(),
              setPriceSelectStatus({
                profit: false,
                politicalCandidates: false,
                nonProfit: true,
                government: false,
              });
          }}
          icon={
            <PriceTenDollars
              fillColor={colors.non_profit.hex}
              onHoverColor={colors.primary.hex}
              onClickColor={colors.primary.hex}
              selectedStatus={priceSelectStatus.nonProfit}
            />
          }
          upperText={labels.NONPROFIT}
          lowerText={
            <>
              {town?.under_license_contract
                ? labels.PER_PUBLICATION
                : labels.PER_SITE}
              <PerOccurrence color={colors.non_profit.key} />
            </>
          }
          textColor={colors.non_profit.key}
          textHoverColor={colors.primary.key}
          className={classes.icon_container}
          typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
        />

        <ClickableIcon
          onClick={() => {
            handleGovernmentSelection(),
              setPriceSelectStatus({
                profit: false,
                politicalCandidates: false,
                nonProfit: false,
                government: true,
              });
          }}
          icon={
            <PriceTenDollars
              fillColor={colors.government.hex}
              onHoverColor={colors.primary.hex}
              onClickColor={colors.primary.hex}
              selectedStatus={priceSelectStatus.government}
            />
          }
          upperText={labels.GOVERNMENT}
          lowerText={
            <>
              {town?.under_license_contract
                ? labels.PER_PUBLICATION
                : labels.PER_SITE}
              <PerOccurrence color={colors.government.key} />
            </>
          }
          textColor={colors.government.key}
          textHoverColor={colors.primary.key}
          className={classes.icon_container}
          typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
        />
      </div>

      <ExemptionLink
        canApplyForExemption={canApplyForExemption}
        isContributor={isContributor}
        priceSelectStatus={priceSelectStatus}
        townsWithClusters={townsWithClusters}
        envUrl={envUrl}
        currentUserId={currentUserId}
        handleExemptionRequest={handleExemptionRequest}
      />

      <NonProfitCheckbox
        isNonProfitOptionSelected={priceSelectStatus.nonProfit}
        isChecked={nonProfitCheckboxStatus.selected}
        handleChange={() =>
          setNonProfitCheckboxStatus({
            selected: !nonProfitCheckboxStatus.selected,
          })
        }
      />
    </>
  );
};

export default EventPayments;
