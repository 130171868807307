import React, { useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import { useStyles } from "./ApplyForExemptionConfirmationModal.style";
import Typography from "../../../common/Typography/Typography";
import BubbledButton from "../../../common/Button/BubbledButton";

const ApplyForExemptionConfirmationModal = ({
  closeConfirmationDialog,
  openTownSelectionDialog,
}) => {
  const classes = useStyles();

  const handleOpenTownSelectionDialog = () => {
    openTownSelectionDialog();
    closeConfirmationDialog();
  };

  return (
    <div className={classes.modal_content}>
      <div className={classes.text_container}>
        <Typography level="paragraph_1" bold color={"orange"}>
          {labels.EXEMPTION_CONFIRMATION_1}
          <br />
          <br />
          {labels.EXEMPTION_CONFIRMATION_2}
        </Typography>
      </div>
      <div className={classes.button_container}>
        <BubbledButton
          level="button_orange"
          color="primary"
          className={classes.button}
          onClick={handleOpenTownSelectionDialog}
        >
          {labels.YES}
        </BubbledButton>
        <BubbledButton
          level="button_orange"
          color="primary"
          className={classes.button}
          onClick={closeConfirmationDialog}
        >
          {labels.NO}
        </BubbledButton>
      </div>
    </div>
  );
};

ApplyForExemptionConfirmationModal.propTypes = {
  closeConfirmationDialog: PropTypes.func,
  openTownSelectionDialog: PropTypes.func,
};

export default ApplyForExemptionConfirmationModal;
