import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    obituaries_list: {
      width: "95%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      [theme.breakpoints.down(960)]: {
        width: "100%",
        justifyContent: "center",
      },
    },
    all_obituaries: {
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2.25),
      [theme.breakpoints.down(960)]: {
        textAlign: "center",
      },
    },
    obituaries_pagination: {
      "& .Mui-selected": {
        backgroundColor: "unset !important",
        color: theme.palette.themeColor.secondary,
        fontWeight: "bold",
      },
      "& .MuiPaginationItem-page": {
        fontWeight: "bold",
      },
    },
    pagination_container: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
  };
});

export { useStyles };
