import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  row: { marginBottom: theme.spacing(1) },
  modal_content: {
    width: theme.spacing(75),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down(960)]: {
      width: theme.spacing(40),
      margin: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  click_here_container: {
    display: "flex",
  },
  button: {
    width: "100%",
  },
}));

export { useStyles };
