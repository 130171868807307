import React from "react";
import PropTypes from "prop-types";

const Calendar = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="45"
      height="42"
      viewBox="0 0 45 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6032 4.74347H6.0011C3.79196 4.74347 2.0011 6.53433 2.0011 8.74347V35.0367C2.0011 37.2458 3.79196 39.0367 6.0011 39.0367H38.4671C40.6762 39.0367 42.4671 37.2458 42.4671 35.0367V8.74347C42.4671 6.53433 40.6762 4.74347 38.4671 4.74347H35.2655M15.7184 4.74347H30.8074"
        stroke={fillColor}
        strokeWidth="4"
      />
      <path
        d="M12.2902 2V7.48691"
        stroke={fillColor}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M31.4933 2V7.48691"
        stroke={fillColor}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M42.4659 12.288L1.99997 12.288"
        stroke={fillColor}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle cx="9.20416" cy="17.4319" r="1.71466" fill={fillColor} />
      <circle cx="18.122" cy="17.4319" r="1.71466" fill={fillColor} />
      <circle cx="27.0366" cy="17.4319" r="1.71466" fill={fillColor} />
      <circle cx="35.954" cy="17.4319" r="1.71466" fill={fillColor} />
      <circle cx="9.20404" cy="22.9189" r="1.71466" fill={fillColor} />
      <circle cx="9.20404" cy="28.4058" r="1.71466" fill={fillColor} />
      <circle cx="9.20404" cy="33.8927" r="1.71466" fill={fillColor} />
      <circle cx="18.122" cy="22.9189" r="1.71466" fill={fillColor} />
      <circle cx="18.122" cy="28.4058" r="1.71466" fill={fillColor} />
      <circle cx="18.122" cy="33.8927" r="1.71466" fill={fillColor} />
      <circle cx="27.0366" cy="22.9189" r="1.71466" fill={fillColor} />
      <circle cx="27.0366" cy="28.4058" r="1.71466" fill={fillColor} />
      <circle cx="27.0366" cy="33.8927" r="1.71466" fill={fillColor} />
      <circle cx="35.954" cy="22.9189" r="1.71466" fill={fillColor} />
      <circle cx="35.954" cy="28.4058" r="1.71466" fill={fillColor} />
      <circle cx="35.954" cy="33.8927" r="1.71466" fill={fillColor} />
    </svg>
  );
};

Calendar.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

Calendar.defaultProps = {
  fillColor: "#F98C10",
};

export default Calendar;
