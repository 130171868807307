import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    form_container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down(930)]: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
    },
    alert_container: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  };
});

export { useStyles };
