import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    pickers_container: {
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%",
      marginBottom: theme.spacing(2),
    },
    picker_labels: {
      padding: 0,
      margin: `${theme.spacing(3)}px 0px 0px 0px`,
    },
    datepicker: {
      "&.MuiFormControl-marginNormal": {
        marginRight: 0,
      },
      "& div.MuiInputBase-root": {
        width: theme.spacing(19.75),
        "& input.MuiInputBase-input": {
          width: theme.spacing(19.75),
        },
        "&.Mui-focused": {
          "& input.MuiInputBase-input": {
            width: theme.spacing(19.75),
          },
        },
      },
    },
    multiple_textfields_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
      width: "100%",
    },
    multiple_textfields: {
      width: "44%",
    },
    multiple_datepickers_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%",
      [theme.breakpoints.down(900)]: {
        width: "100%",
        marginTop: theme.spacing(2),
      },
    },
  };
});

export { useStyles };
