import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./PropertyInformationBlockContent.style";
import Carousel from "../../../../../common/Carousel/Carousel";
import Typography from "../../../../../common/Typography/Typography";
import VerticalSeparator from "../../../../../common/Icons/VerticalSeparator";
import SocialNetworkShareButtons from "../../../../SocialNetworkShareButtons/SocialNetworkShareButtons";
import ContactButtons from "../../ContactButtons/ContactButtons";
import { currencyFormatter } from "../../../../../utils/helper";
import Actions from "../../../Actions/Actions";
import RealtorInfo from "../../../Forms/RealtorInfo/RealtorInfo";

const PropertyInformationBlockContent = ({
  carouselContent,
  realEstateListing,
  handleOpenGallery,
  editUrl,
  canEdit,
  canDelete,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.carousel}>
        <Carousel
          items={carouselContent}
          withPagination={true}
          onImageClick={() => handleOpenGallery(true)}
        />
      </div>

      <div className={classes.property_information}>
        {(canEdit || canDelete) && (
          <Actions
            canEdit={canEdit}
            canDelete={canDelete}
            slug={realEstateListing.slug}
            editUrl={editUrl}
          />
        )}

        <div className={classes.title_bedrooms_and_bathrooms_container}>
          <Typography level="h3_sub_header" className={classes.price}>
            {currencyFormatter.format(realEstateListing.price)}
          </Typography>

          <div>
            <Typography
              children={realEstateListing.bedrooms + " bed"}
              level="t2_text_2"
              color="gray"
            />

            <VerticalSeparator fillColor="gray" className={classes.separator} />
            <Typography
              children={realEstateListing.bathrooms + " bath"}
              level="t2_text_2"
              color="gray"
            />
          </div>
        </div>

        <div className={classes.status_container}>
          <Typography
            children={realEstateListing.property_status}
            level="t2_text_2"
            color="tapintoOrange"
            bold
          />
        </div>

        <div className={classes.address_container}>
          <Typography level="paragraph_2">
            {realEstateListing.address}
          </Typography>

          <RealtorInfo slug={realEstateListing.slug} nameOnly />
        </div>

        <div className={classes.actions_container}>
          <SocialNetworkShareButtons
            url={realEstateListing.social_media_information.url}
            title={realEstateListing.name}
            socialNetworks={["facebook", "twitter", "email"]}
            iconsSize={25}
            paddingWithinButtons={6}
            alignment="left"
          />
          <div className={classes.button_container}>
            <ContactButtons
              buttonToShow="contactRealtor"
              slug={realEstateListing.slug}
            />
          </div>
        </div>
      </div>
    </>
  );
};

PropertyInformationBlockContent.propTypes = {
  carouselContent: PropTypes.arrayOf(
    PropTypes.shape({
      contentUrl: PropTypes.string,
      caption: PropTypes.string,
      credits: PropTypes.string,
      alt_text: PropTypes.string,
      isVideo: PropTypes.bool,
    })
  ),
  realEstateListing: PropTypes.shape({
    price: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    address: PropTypes.string,
    url: PropTypes.string,
    status: PropTypes.string,
    information_blocks: PropTypes.arrayOf(
      PropTypes.shape({
        section_id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            })
          ),
        ]),
      })
    ),
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        isVideo: PropTypes.bool,
        alt_text: PropTypes.string,
        captions: PropTypes.string,
        credits: PropTypes.string,
      })
    ),
  }),
  handleOpenGallery: PropTypes.func,
  editUrl: PropTypes.string,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default PropertyInformationBlockContent;
