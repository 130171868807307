import { makeStyles } from "../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    search_results_container: {
      backgroundColor: theme.palette.miscellaneous.white,
      borderRadius: theme.spacing(0.5),
      padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px ${theme.spacing(
        2
      )}px ${theme.spacing(1.5)}px`,
      maxHeight: theme.spacing(38.25),
      overflowY: "scroll",
      overflowX: "hidden",
      [theme.breakpoints.down(961)]: {
        maxHeight: theme.spacing(26.65),
      },
    },
    search_field: {
      width: "100%",
    },
    options: {
      "& label.MuiFormControlLabel-root": {
        marginLeft: 0,
        "& span.MuiButtonBase-root": {
          padding: 0,
          paddingLeft: theme.spacing(1),
          color: theme.palette.themeColor.secondary,
          "&.PrivateSwitchBase-input-8": {
            padding: `0px ${theme.spacing(1)}px 0px 0px`,
          },
          "&.PrivateSwitchBase-root-5": {
            padding: `0px ${theme.spacing(1)}px 0px 0px`,
          },
        },
        "& span.MuiTypography-root": {
          fontFamily: "Montserrat",
          fontSize: "14px",
          color: theme.palette.dark.gray,
        },
      },
    },
    no_display: {
      display: "none",
    },
    padding_bottom_8: {
      paddingBottom: theme.spacing(1),
    },
  };
});

export { useStyles };
