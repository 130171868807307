import React from "react";
import PropTypes from "prop-types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

const Code = ({ code, language }) => {
  return (
    <SyntaxHighlighter
      language={language}
      style={prism}
      customStyle={{ marginTop: 0, marginBottom: 0 }}
    >
      {code}
    </SyntaxHighlighter>
  );
};

Code.propTypes = {
  code: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

Code.defaultProps = {
  language: "html",
};
export default Code;
