import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_obituary_container: {
      width: "100%",
      minHeight: theme.spacing(30),
      borderBottom: `4px ${theme.palette.dark.gray} solid`,
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down(960)]: {
        flexWrap: "wrap",
      },
    },
    image: {
      maxHeight: theme.spacing(40),
      maxWidth: theme.spacing(37.5),
      borderRadius: "16px",
    },
    image_container: {
      width: "45%",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(960)]: {
        width: "100%",
        maxHeight: theme.spacing(40),
      },
    },
    info_container: {
      width: "55%",
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    info: {
      paddingLeft: theme.spacing(2),
    },
    description_container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "auto",
      backgroundColor: theme.palette.miscellaneous.grayDivider,
      borderRadius: "20px 20px 0px 20px",
      padding: theme.spacing(2),
    },
    description: {
      width: "100%",
    },
    links_container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(1),
      [theme.breakpoints.down(960)]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
    share_this_container: {
      width: "30%",
    },
  };
});

export { useStyles };
