import React from "react";
import PropTypes from "prop-types";

const Back = ({ className }) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="8" y="8" width="24" height="24" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1163_6395" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_1163_6395"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABOUlEQVR4nO3asUoDURRF0ROLOwr+txYiaW38CIvgH1kJokLKyEAqC4Wgb/TOWjBdII/N4SXFJAAAAAD8N1OSmyTPSd6T3CW5WPpQHSPvkhw+PXNsfjny/Lz+1Jes3fRF5Pl5WfqAa4h8SHK79CHXEHl3/BwnqiQP30R+THJ+6hcQkUewZJF7KHeyyC2UJYvcQlmyyC2UJYvcQlmyyC2UJYvcQlmyyC2UJYvcQlmyyC2UJYvcwuTlljGuvUE0xpM3iIRu5crVMcbkx3Cc8h9a7JbKssVuqSxb7JbKssVuqSxb7JbKssVuqSxb7JbKssVuqSxb7JbKssVuqSxb7JbKsscReyAv6fyx2NuRB1pz7Lckm6UPuYbY+yRnSx9wDbHvlz5Y19jb43WxP0a+XPpQnW3cywAAAJDOPgCEQhT7smKmsQAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

Back.propTypes = {
  className: PropTypes.string,
};

export default Back;
