import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { up: above },
    palette,
    breakpoints: { values: values },
  } = theme;
  return {
    header_container: {
      borderTop: `2px solid ${palette.miscellaneous.grayDivider}`,
      borderBottom: `2px solid ${palette.miscellaneous.grayDivider}`,
      padding: `${spacing(3)}px ${spacing(1)}px`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [above(values.md)]: {
        marginTop: spacing(3),
      },
    },
    header_container_index: {
      padding: `${spacing(4)}px 0px ${spacing(8)}px 0px`,
      borderBottom: "none",
    },
    header_container_index_mobile: {
      flexWrap: "wrap",
      borderBottom: `2px solid ${palette.miscellaneous.grayDivider}`,
      padding: `${spacing(2)}px ${spacing(1.25)}px`,
      marginBottom: spacing(1),
    },
    header_container_show_mobile: {
      flexWrap: "wrap",
      padding: `${spacing(1.5)}px ${spacing(2)}px`,
    },
    justify_space_between: {
      justifyContent: "space-between",
    },
    button: {
      height: spacing(4),
      textTransform: "none !important",
      backgroundColor: `${palette.themeColor.primary} !important`,
      borderRadius: "0px !important",
      padding: `${spacing(1.25)}px ${spacing(2.5)}px !important`,
    },
    button_mobile: {
      padding: `${spacing(1.25)}px ${spacing(0.5)}px !important`,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: palette.dark.black,
      },
    },
    mobile_logo: {
      width: spacing(10),
    },
    sponsor_container: {
      "& [class^='sponsor_container']": {
        width: "fit-content",
      },
    },
    sponsor_container_full_width: {
      display: "flex",
      justifyContent: "center",
    },
  };
});

export { useStyles };
