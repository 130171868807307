import { makeStyles } from "../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    dialog_title: {
      "&.MuiDialogTitle-root": {
        margin: 0,
        background: theme.palette.miscellaneous.white,
      },
    },
    dialog_close_button: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      position: "absolute",
      right: theme.spacing(2),
      "&.MuiIconButton-root": {
        height: theme.spacing(2.5),
        width: theme.spacing(2.5),
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
      },
    },
    sticky_shopping_cart: {
      backgroundColor: `${theme.palette.themeColor.secondary} !important`,
      position: "fixed !important",
      top: theme.spacing(7.5),
      right: theme.spacing(0.5),
      zIndex: 1,
    },
    shopping_cart_button_icon: {
      marginRight: theme.spacing(1),
      transition: "background-color .2s linear",
    },
  };
});

export { useStyles };
