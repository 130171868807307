import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    row: {
      display: "flex",
    },
    header_title: {
      textAlign: "center",
      lineHeight: "normal",
      [theme.breakpoints.down(900)]: {
        marginBottom: theme.spacing(2.5),
      },
    },
    towns_container: {
      display: "block",
    },
    regular_text_field: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(961)]: {
        "& h4": {
          fontSize: "17px",
        },
      },
    },
    write_description_label: {
      marginTop: theme.spacing(2),
    },
    choose_towns: {
      marginTop: theme.spacing(2),
    },
    loading_container: {
      height: theme.spacing(20),
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pickers_container: {
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%",
      marginBottom: theme.spacing(2),
    },
    picker_labels: {
      padding: 0,
      margin: `${theme.spacing(3)}px 0px 0px 0px`,
    },
    datepicker: {
      "&.MuiFormControl-marginNormal": {
        marginRight: theme.spacing(10),
      },
    },
    multiple_textfields_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    multiple_textfields: {
      width: "40%",
    },
    event_confirmation_container: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    event_confirmation_checkbox: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: theme.spacing(2),
      },
      "& span.MuiCheckbox-root": {
        color: theme.palette.themeColor.secondary,
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.themeColor.secondary,
      },
    },
    multiple_event_dates_popup: {
      width: theme.spacing(37),
      maxHeight: theme.spacing(56),
      paddingTop: theme.spacing(1),
    },
    dialog_title: {
      "&.MuiDialogTitle-root": {
        margin: 0,
        background: theme.palette.miscellaneous.white,
      },
    },
    dialog_close_button: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      position: "absolute",
      "&.MuiIconButton-root": {
        height: theme.spacing(2.5),
        width: theme.spacing(2.5),
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
      },
    },
    popup_sub_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: `${theme.spacing(1)}px 0px`,
    },
    popup_buttons_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    popup_datepicker: {
      "&.MuiFormControl-root": {
        width: "65%",
        marginTop: 0,
      },
      "& div.MuiInputBase-root": {
        "& input.MuiInputBase-input": {
          fontSize: "12px",
        },
      },
    },
    popup_number_picker: {
      "& div.MuiInputBase-root": {
        "& input.MuiInputBase-input.MuiOutlinedInput-input": {
          fontSize: "12px",
        },
      },
    },
    radio_button_form: {
      "& div.MuiFormGroup-root": {
        "& label.MuiFormControlLabel-root": {
          margin: 0,
        },
      },
    },
    radio_button: {
      "&.MuiButtonBase-root.Mui-checked": {
        color: theme.palette.themeColor.primary,
      },
    },
    ends_after_container: {
      width: "85%",
    },
    ends_after_subcontainer: {
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "-webkit-fill-available",
    },
    popup_button: {
      width: theme.spacing(15.25),
      height: theme.spacing(3),
      "&.MuiButtonBase-root": {
        marginRight: theme.spacing(1),
      },
      "&.MuiButton-text": {
        fontSize: "12px",
        lineHeight: "12px",
        fontWeight: 700,
        fontFamily: "Montserrat",
      },
    },
    lte_policy_link: {
      "& span": {
        color: theme.palette.themeColor.primary,
      },
    },
    lte_policy_link_container: {
      marginTop: theme.spacing(8),
    },
    town_selector: {
      marginTop: theme.spacing(7),
    },
  };
});

export { useStyles };
