import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "../../../common/TextField/TextField";
import useSubmitableContentForm from "../useSubmitableContentForm";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import Checkbox from "../../../common/Checkbox/Checkbox";
import FacebookBox from "../../../common/Icons/Social/FacebookBox";
import PriceFiftyDollars from "../../../common/Icons/PriceFiftyDollars";
import { useStyles } from "./FacebookSites.style";
import clsx from "clsx";
import { useDiy, withDiyContextProvider } from "../../../context/DiyContext";
import { showOrHideFacebookBlockComponentListener } from "../../../utils/listeners";
import {
  FACEBOOK_EXCERPT,
  FACEBOOK_TOWN_IDS,
  FACEBOOK_URL,
  POST_ON_FACEBOOK,
  PRESS_RELEASE,
  COLUMN,
  EVENT,
  CLASSIFIED,
  BUSINESS_DIRECTORY_LISTING,
  REAL_ESTATE_LISTING,
  OBITUARY,
} from "../diyConstants";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../../hooks/useMediaQuery";

const TAPINTO_GREEN = "#7FC035";
const FACEBOOK_BLUE = "#475993";

const FacebookSites = ({
  form_type,
  contribution_type,
  content,
  is_licensed,
}) => {
  const classes = useStyles();
  const [facebookChecked, setFacebookChecked] = useState(content?.is_selected);
  const { checkFacebookOption } = useDiy();
  const [showFacebookBlock, setShowFacebookBlock] = useState(true);

  showOrHideFacebookBlockComponentListener(setShowFacebookBlock);

  useEffect(() => {
    if (!showFacebookBlock) {
      setFacebookChecked(false);
    }
  }, [showFacebookBlock]);
  const { setProperty } = useSubmitableContentForm();

  useEffect(() => {
    if (!facebookChecked) {
      setProperty(FACEBOOK_TOWN_IDS, []);
    }
    setProperty(POST_ON_FACEBOOK, facebookChecked === true ? 1 : 0);
    checkFacebookOption();
  }, [facebookChecked]);

  const YesLabel = () => {
    return (
      <div className={classes.facebook_yes_container}>
        <Typography
          level="h4_sub_header_4"
          color="facebookBlue"
          bold
          className={classes.yes}
        >
          {labels.YES}
        </Typography>
        <FacebookBox fillColor={FACEBOOK_BLUE} />
      </div>
    );
  };

  const PriceLabel = () => {
    return (
      <div>
        <PriceFiftyDollars
          fillColor={FACEBOOK_BLUE}
          className={classes.fifty_dollars}
        />
        <Typography
          level="t3_text_3"
          color="facebookBlue"
          bold
          className={classes.per_page}
        >
          {labels.PER_FB_PAGE}
        </Typography>
      </div>
    );
  };

  const getFormTypeLabelByFormType = () => {
    switch (form_type) {
      case COLUMN:
        return labels.COLUMN;
      default:
        return labels.PRESS_RELEASE;
    }
  };

  const getFormTypeLabel = () => {
    switch (contribution_type) {
      case PRESS_RELEASE:
        return getFormTypeLabelByFormType();
      case EVENT:
        return labels.EVENT;
      case CLASSIFIED:
        return labels.CLASSIFIED;
      case BUSINESS_DIRECTORY_LISTING:
        return labels.BUSINESS_DIRECTORY_LISTING;
      case OBITUARY:
        return labels.OBITUARY;
      case REAL_ESTATE_LISTING:
        return labels.REAL_ESTATE_LISTING;
      default:
        break;
    }
  };

  const FacebookTownsBlock = () => {
    return (
      <div className={clsx(classes.row, classes.facebook_inputs_container)}>
        <div className={classes.facebook_excerpt}>
          <Typography level="h4_sub_header_4" color="facebookBlue" bold>
            {labels.EXCERPT}
          </Typography>
          <TextField
            isMultiline
            className={classes.excerpt}
            onChange={(event) =>
              setProperty(FACEBOOK_EXCERPT, event.target.value)
            }
            defaultValue={content?.excerpt}
          />
          <Typography
            level="h4_sub_header_4"
            color="facebookBlue"
            bold
            className={classes.excerpt_header}
          >
            {labels.FACEBOOK_URL}
          </Typography>
          <TextField
            className={classes.facebook_url}
            defaultValue={
              !!content?.facebook_client_url
                ? content?.facebook_client_url
                : "https://www.facebook.com/"
            }
            onChange={(event) => setProperty(FACEBOOK_URL, event.target.value)}
          />
        </div>
      </div>
    );
  };

  const handleCheckboxClick = () => {
    setFacebookChecked(!facebookChecked);
  };

  const FacebookBlockForMobile = () => {
    return (
      <>
        <div className={clsx(classes.row, classes.facebook_sites_container)}>
          <div className={classes.mobile_fb_box}>
            <FacebookBox fillColor={FACEBOOK_BLUE} />
          </div>
          <div className={classes.left_container}>
            <Typography level="medium_16_px" color="facebookBlue" bold>
              {labels.FACEBOOK_QUESTION_PART_1}
              {getFormTypeLabel()}
              {is_licensed
                ? labels.FACEBOOK_QUESTION_PART_2_FOR_PUBLICATIONS
                : labels.FACEBOOK_QUESTION_PART_2}
            </Typography>
          </div>
        </div>
        <div className={classes.mobile_facebook_container}>
          <Checkbox
            label={<PriceLabel />}
            onChange={handleCheckboxClick}
            checkboxColor={TAPINTO_GREEN}
            checked={facebookChecked}
          />
        </div>
        {facebookChecked && <FacebookTownsBlock />}
      </>
    );
  };

  const FacebookBlockForDesktop = () => {
    return (
      <>
        <div className={clsx(classes.row, classes.facebook_sites_container)}>
          <div className={classes.left_container}>
            <Typography level="h4_sub_header_4" color="facebookBlue" bold>
              {labels.FACEBOOK_QUESTION_PART_1}
              {getFormTypeLabel()}
              {is_licensed
                ? labels.FACEBOOK_QUESTION_PART_2_FOR_PUBLICATIONS
                : labels.FACEBOOK_QUESTION_PART_2}
            </Typography>
            <Checkbox
              label={<YesLabel />}
              onChange={handleCheckboxClick}
              checkboxColor={TAPINTO_GREEN}
              checked={facebookChecked}
            />
          </div>
          <div className={classes.right_container}>
            <PriceFiftyDollars
              fillColor={FACEBOOK_BLUE}
              className={classes.fifty_dollars}
            />
            <Typography
              level="t3_text_3"
              color="facebookBlue"
              bold
              className={classes.per_page}
            >
              {labels.PER_FB_PAGE}
            </Typography>
          </div>
        </div>
        {facebookChecked && <FacebookTownsBlock />}
      </>
    );
  };

  return (
    <>
      {screenWidthIsHigherThan(901) && showFacebookBlock && (
        <FacebookBlockForDesktop />
      )}
      {screenWidthIsLowerThan(900) && showFacebookBlock && (
        <FacebookBlockForMobile />
      )}
    </>
  );
};

FacebookSites.propTypes = {
  form_type: PropTypes.string,
  contribution_type: PropTypes.string,
  content: PropTypes.shape({
    is_selected: PropTypes.bool,
    facebook_client_url: PropTypes.string,
    excerpt: PropTypes.string,
  }),
  is_licensed: PropTypes.bool,
};

export default withDiyContextProvider(FacebookSites);
