import React from "react";
import PropTypes from "prop-types";

const ShoppingCartIcon = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="40"
      height="35"
      viewBox="0 0 40 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.28049 6.00117e-07C0.573276 6.00117e-07 0 0.573298 0 1.28049C0 1.98768 0.573276 2.56098 1.28049 2.56098H6.21571L10.6174 23.3289C10.7915 24.1107 11.3039 24.7613 11.9512 24.7561H33.2928C33.9692 24.7655 34.5915 24.1522 34.5915 23.4756C34.5915 22.7991 33.9692 22.1856 33.2928 22.1951H12.9916L12.4447 19.6342H35.0001C35.5734 19.6299 36.1151 19.1932 36.2405 18.6338L39.2283 5.8289C39.3948 5.08576 38.7494 4.27376 37.9879 4.2683H9.20353L8.50993 1.01372C8.38875 0.445142 7.83746 -0.000596962 7.25611 6.00117e-07H1.28049ZM9.73706 6.82928H16.4063L17.0465 10.6708H10.5507L9.73706 6.82928ZM19.0206 6.82928H27.077L26.4368 10.6708H19.6609L19.0206 6.82928ZM29.6914 6.82928H36.374L35.4803 10.6708H29.0511L29.6914 6.82928ZM11.0976 13.2317H17.4734L18.1136 17.0732H11.9112L11.0976 13.2317ZM20.0877 13.2317H26.01L25.3697 17.0732H20.7279L20.0877 13.2317ZM28.6243 13.2317H34.8801L33.9864 17.0732H27.9841L28.6243 13.2317ZM16.6464 26.4634C14.3042 26.4634 12.3781 28.3895 12.3781 30.7317C12.3781 33.0738 14.3042 35 16.6464 35C18.9885 35 20.9147 33.0738 20.9147 30.7317C20.9147 28.3896 18.9885 26.4634 16.6464 26.4634ZM28.5976 26.4634C26.2555 26.4634 24.3293 28.3895 24.3293 30.7317C24.3293 33.0738 26.2555 35 28.5976 35C30.9398 35 32.8659 33.0738 32.8659 30.7317C32.8659 28.3896 30.9398 26.4634 28.5976 26.4634ZM16.6464 29.0244C17.6045 29.0244 18.3537 29.7736 18.3537 30.7317C18.3537 31.6898 17.6045 32.439 16.6464 32.439C15.6884 32.439 14.9391 31.6898 14.9391 30.7317C14.9391 29.7736 15.6884 29.0244 16.6464 29.0244ZM28.5976 29.0244C29.5557 29.0244 30.3049 29.7736 30.3049 30.7317C30.3049 31.6898 29.5557 32.439 28.5976 32.439C27.6396 32.439 26.8903 31.6898 26.8903 30.7317C26.8903 29.7736 27.6396 29.0244 28.5976 29.0244Z"
        fill={fillColor}
      />
    </svg>
  );
};

ShoppingCartIcon.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

ShoppingCartIcon.defaultProps = {
  fillColor: "black",
};

export default ShoppingCartIcon;
