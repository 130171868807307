import React from "react";
import { useStyles } from "./ShoppingListRow.style";
import PropTypes from "prop-types";
import Typography from "../../../../common/Typography/Typography";
import labels from "../../../../config/labels";
import Trashcan from "../../../../common/Icons/Trashcan";
import { GRAY, LETTERS_TO_THE_EDITOR } from "../../diyConstants";

const ShoppingListRow = ({ town, formType, itemQuantity }) => {
  const classes = useStyles();

  const getTownPriceAccordingToFormType = (item) => {
    if (formType !== LETTERS_TO_THE_EDITOR) {
      return item.price;
    } else {
      return 0;
    }
  };

  const getTownPriceAccordingToFormTypeWithQuantity = (item, quantity) => {
    if (formType !== LETTERS_TO_THE_EDITOR) {
      return item.price * quantity;
    } else {
      return 0;
    }
  };

  const removeTown = (town_id) => {
    $(`#checkbox-${town_id}`).parent("span").trigger("click");
  };

  return (
    <div className={classes.white_row}>
      <div className={classes.town_name_container}>
        <Typography level="t1_text_1" color={GRAY} bold>
          {town.label}
          {itemQuantity ? ` (x ${itemQuantity})` : ""}
        </Typography>
      </div>
      <div className={classes.state_name_container}>
        <Typography level="t1_text_1" color={GRAY}>
          {town.state[1]}
        </Typography>
      </div>
      <div className={classes.town_price_container}>
        <Typography level="t1_text_1" color={GRAY}>
          {labels.DOLLAR_SIGN}
          {itemQuantity
            ? getTownPriceAccordingToFormTypeWithQuantity(town, itemQuantity)
            : getTownPriceAccordingToFormType(town)}
        </Typography>
      </div>
      <div
        className={classes.trashcan_icon_container}
        onClick={() => removeTown(town.id)}
      >
        <Trashcan fillColor={GRAY} />
      </div>
    </div>
  );
};

ShoppingListRow.propTypes = {
  town: PropTypes.shape({
    label: PropTypes.string,
    price: PropTypes.number,
    state: PropTypes.arrayOf(PropTypes.string),
  }),
  formType: PropTypes.string,
  itemQuantity: PropTypes.number,
};

export default ShoppingListRow;
