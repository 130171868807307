import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      paddingLeft: "10%",
      paddingRight: "10%",
      [theme.breakpoints.down(960)]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  };
});

export { useStyles };
