import clsx from "clsx";
import React, { Fragment } from "react";
import {
  CANDIDATE_STATEMENTS,
  COLUMN,
  FREELANCE,
  LETTERS_TO_THE_EDITOR,
} from "../../diyConstants";
import ClickableIcon from "../../../../common/Icons/ClickableIcon/ClickableIcon";
import PriceFree from "../../../../common/Icons/PriceFree";
import PriceFiftyDollars from "../../../../common/Icons/PriceFiftyDollars";
import PriceSeventyFiveDollars from "../../../../common/Icons/PriceSeventyFiveDollars";
import PriceTwentyFiveDollars from "../../../../common/Icons/PriceTwentyFiveDollars";
import labels from "../../../../config/labels";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import values from "../../../../common/Theme/themes/breakpoints/values";
import { useStyles } from "./PaymentsByFormType.style";
import { useDiy } from "../../../../context/DiyContext";
import NonProfitCheckbox from "../../NonProfitCheckbox/NonProfitCheckbox";

const PaymentsByFormType = ({
  handleProfitSelection,
  setPriceSelectStatus,
  priceSelectStatus,
  nonProfitCheckboxStatus,
  handleGovernmentSelection,
  colors,
  handleNonProfitSelection,
  setNonProfitCheckboxStatus,
  handlePoliticalCandidatesSelection,
}) => {
  const isMobile = screenWidthIsLowerThan(values.xs);
  const classes = useStyles();
  const { formType, town } = useDiy();

  console.log({ colors });

  switch (formType) {
    case COLUMN:
      return <></>;
    case LETTERS_TO_THE_EDITOR:
      return (
        <ClickableIcon
          icon={<PriceFree fillColor={colors.free.hex} />}
          textColor={colors.free.key}
          className={clsx(classes.icon_container, classes.first_icon)}
        />
      );
    case FREELANCE:
      return (
        <ClickableIcon
          icon={<PriceFree fillColor={colors.free.hex} />}
          lowerText={labels.FREELANCE_ARTICLE_NEWS_INFO}
          textColor={colors.free.key}
          textHoverColor={colors.free.key}
          className={clsx(classes.icon_container, classes.first_icon)}
          typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
        />
      );
    case CANDIDATE_STATEMENTS:
      return (
        <ClickableIcon
          icon={<PriceFiftyDollars fillColor={colors.single.hex} />}
          lowerText={
            town?.under_license_contract
              ? labels.PER_PUBLICATION
              : labels.PER_SITE
          }
          textColor={colors.single.key}
          textHoverColor={colors.single.key}
          className={clsx(classes.icon_container, classes.first_icon)}
          typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
          upperText={labels.POLITICAL_CANDIDATES}
        />
      );
    default:
      return (
        <>
          <Fragment>
            <ClickableIcon
              onClick={() => {
                handleProfitSelection(),
                  setPriceSelectStatus({
                    profit: true,
                    politicalCandidates: false,
                    nonProfit: false,
                    government: false,
                  });
              }}
              icon={
                <PriceSeventyFiveDollars
                  fillColor={colors.profit.hex}
                  onHoverColor={colors.primary.hex}
                  onClickColor={colors.primary.hex}
                  selectedStatus={priceSelectStatus.profit}
                />
              }
              upperText={labels.FOR_PROFIT}
              lowerText={
                town?.under_license_contract
                  ? labels.PER_PUBLICATION
                  : labels.PER_SITE
              }
              textColor={colors.profit.key}
              textHoverColor={colors.primary.key}
              className={clsx(classes.icon_container, classes.first_icon)}
              typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
            />
            <ClickableIcon
              onClick={() => {
                handlePoliticalCandidatesSelection(),
                  setPriceSelectStatus({
                    profit: false,
                    politicalCandidates: true,
                    nonProfit: false,
                    government: false,
                  });
              }}
              icon={
                <PriceSeventyFiveDollars
                  fillColor={colors.political_candidates.hex}
                  onHoverColor={colors.primary.hex}
                  onClickColor={colors.political_candidates.hex}
                  selectedStatus={priceSelectStatus.politicalCandidates}
                />
              }
              upperText={labels.POLITICAL_CANDIDATES}
              lowerText={
                town?.under_license_contract
                  ? labels.PER_PUBLICATION
                  : labels.PER_SITE
              }
              textColor={colors.political_candidates.key}
              textHoverColor={colors.primary.key}
              className={clsx(
                classes.icon_container,
                classes.political_candidates_icon
              )}
              typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
            />
            <ClickableIcon
              onClick={() => {
                handleNonProfitSelection(),
                  setPriceSelectStatus({
                    profit: false,
                    politicalCandidates: false,
                    nonProfit: true,
                    government: false,
                  });
              }}
              icon={
                <PriceTwentyFiveDollars
                  fillColor={colors.non_profit.hex}
                  onHoverColor={colors.primary.hex}
                  onClickColor={colors.primary.hex}
                  selectedStatus={priceSelectStatus.nonProfit}
                />
              }
              upperText={labels.NONPROFIT}
              lowerText={
                town?.under_license_contract
                  ? labels.PER_PUBLICATION
                  : labels.PER_SITE
              }
              textColor={colors.non_profit.key}
              textHoverColor={colors.primary.key}
              className={classes.icon_container}
              typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
            />
            <ClickableIcon
              onClick={() => {
                handleGovernmentSelection(),
                  setPriceSelectStatus({
                    profit: false,
                    politicalCandidates: false,
                    nonProfit: false,
                    government: true,
                  });
              }}
              icon={
                <PriceTwentyFiveDollars
                  fillColor={colors.government.hex}
                  onHoverColor={colors.primary.hex}
                  onClickColor={colors.primary.hex}
                  selectedStatus={priceSelectStatus.government}
                />
              }
              upperText={labels.GOVERNMENT}
              lowerText={
                town?.under_license_contract
                  ? labels.PER_PUBLICATION
                  : labels.PER_SITE
              }
              textColor={colors.government.key}
              textHoverColor={colors.primary.key}
              className={classes.icon_container}
              typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
            />
          </Fragment>
          <NonProfitCheckbox
            isNonProfitOptionSelected={priceSelectStatus.nonProfit}
            isChecked={nonProfitCheckboxStatus.selected}
            handleChange={() =>
              setNonProfitCheckboxStatus({
                selected: !nonProfitCheckboxStatus.selected,
              })
            }
          />
        </>
      );
  }
};

export default PaymentsByFormType;
