import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    column_articles_container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      width: "100%",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(960)]: {
        marginTop: theme.spacing(3),
      },
    },
    column_title_link: {
      width: "fit-content !important",
      display: "inline-block !important",
      "& h2, h3": {
        color: `${theme.palette.themeColor.primary} !important`,
        textUnderlineOffset: `${theme.spacing(1)}px`,
      },
    },
    more_columns_link_container: {
      width: "100%",
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    more_columns_link: {
      width: "fit-content",
      display: "inline-block",
      "& h3, h5": {
        color: `${theme.palette.themeColor.secondary} !important`,
      },
    },
    divider: {
      margin: `${theme.spacing(1)}px 0px`,
      border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
      [theme.breakpoints.down(960)]: {
        margin: `${theme.spacing(2)}px 0px`,
      },
    },
  };
});

export { useStyles };
