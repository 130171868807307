import React, { useEffect, useState } from "react";
import "date-fns";
import clsx from "clsx";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Clock from "../Icons/Clock";
import { useStyles } from "./TimePicker.style";
import Typography from "../Typography/Typography";
import { getClockTime, isValidDate } from "../../utils/dateUtils";

const TimePicker = ({
  className,
  label,
  labelStyleProps,
  sublabel,
  sublabelStyleProps,
  endIcon,
  onChange,
  disabled,
  defaultValue,
  minutesStep,
}) => {
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState(defaultValue);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (isValidDate(value)) {
      const clockTime = getClockTime(value);
      setValue(clockTime);
    }
    onChange(value);
  }, [value]);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setValue(time);
  };

  return (
    <>
      {label && (
        <div>
          <Typography
            level={labelStyleProps.level}
            color={labelStyleProps.color}
            bold={labelStyleProps.bold}
            className={labelStyleProps.className}
          >
            {label}
          </Typography>
        </div>
      )}
      {sublabel && (
        <Typography
          level={sublabelStyleProps.level}
          color={sublabelStyleProps.color}
          bold={sublabelStyleProps.bold}
          className={sublabelStyleProps.className}
        >
          {sublabel}
        </Typography>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          disabled={disabled}
          className={clsx(classes.time_picker, className)}
          margin="normal"
          value={disabled ? null : selectedTime}
          onChange={handleTimeChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
          keyboardIcon={
            endIcon ? (
              endIcon
            ) : (
              <Clock className={classes.time_picker_end_icon} />
            )
          }
          inputVariant="outlined"
          variant="inline"
          PopoverProps={{ className: classes.time_picker_popover }}
          autoOk={true}
          minutesStep={minutesStep}
          InputProps={{ readOnly: true }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

TimePicker.propTypes = {
  className: PropTypes.any,
  label: PropTypes.string,
  labelStyleProps: PropTypes.shape({
    level: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
    className: PropTypes.any,
  }),
  sublabel: PropTypes.string,
  sublabelStyleProps: PropTypes.shape({
    level: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
    className: PropTypes.any,
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  minutesStep: PropTypes.number,
};

export default TimePicker;
