import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { useStyles } from "./EventListHeader.style";
import DateRangePicker from "../../../common/DateRangePicker/DateRangePicker";
import { getSubmitNewEventButton } from "../../../services/events";
import IndexHeaderWithFilters from "../../Headers/Index/WithFilters/IndexHeaderWithFilters";
import MultipleAutocompleteWithChips from "../../../common/MultipleAutocompleteWithChips/MultipleAutocompleteWithChips";

const EventListHeader = ({
  townName,
  startDateValue,
  endDateValue,
  onSubmitSearch,
  eventCategories,
}) => {
  const classes = useStyles();
  const [chosenCategories, setChosenCategories] = useState([]);
  const [dateRange, setDateRange] = useState();

  useEffect(() => {
    if (dateRange !== undefined) {
      onSubmitSearch(
        chosenCategories.map((category) => category.value),
        dateRange
      );
    }
  }, [chosenCategories, dateRange]);

  const handleRangeDateChange = (dateRangeChange) => {
    setDateRange(dateRangeChange);
  };

  return (
    <IndexHeaderWithFilters
      title={
        townName
          ? labels.CALENDAR_OF_EVENTS + ": " + townName
          : labels.CALENDAR_OF_EVENTS
      }
      submitContentButton={getSubmitNewEventButton(labels.SUBMIT_AN_EVENT)}
      bottomFilters={
        <div className={classes.filters_container}>
          <div className={classes.width}>
            <DateRangePicker
              labelStyleProps={{
                color: "gray",
                level: "paragraph_1",
                bold: false,
              }}
              defaultStartValue={startDateValue}
              defaultEndValue={endDateValue}
              onDateRangeChange={handleRangeDateChange}
            />
          </div>
          <div className={classes.width}>
            <MultipleAutocompleteWithChips
              label={
                <Typography level="paragraph_1" color="gray" bold>
                  {labels.EVENT_CATEGORIES}
                </Typography>
              }
              options={eventCategories}
              handleChange={setChosenCategories}
              queryParams="category_ids"
            />
          </div>
        </div>
      }
    />
  );
};

EventListHeader.propTypes = {
  townName: PropTypes.string,
  startDateValue: PropTypes.any,
  endDateValue: PropTypes.any,
  onSubmitSearch: PropTypes.func,
};

export default EventListHeader;
