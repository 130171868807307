import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    parent_bold: {
      marginLeft: "0px !important",
      "& span.MuiTypography-root": {
        fontWeight: 700,
      },
    },
    triangle_icon_container: {
      display: "inline-block",
      width: theme.spacing(3),
    },
    triangle_icon_normal: {
      transform: "rotateZ(0)",
      transition: "transform .1s linear",
      "& :hover": {
        fill: theme.palette.light.grey,
        cursor: "pointer",
      },
    },
    triangle_icon_down: {
      transform: "rotateZ(90deg)",
      transition: "transform .1s linear",
      "& :hover": {
        fill: theme.palette.light.grey,
        cursor: "pointer",
      },
    },
    options: {
      "& label.MuiFormControlLabel-root": {
        "& span.MuiButtonBase-root": {
          padding: 0,
          paddingLeft: theme.spacing(1),
          color: theme.palette.themeColor.secondary,
          "&.PrivateSwitchBase-input-8": {
            padding: `0px ${theme.spacing(1)}px 0px 0px`,
          },
          "&.PrivateSwitchBase-root-5": {
            padding: `0px ${theme.spacing(1)}px 0px 0px`,
          },
        },
        "& span.MuiTypography-root": {
          fontFamily: "Montserrat",
          fontSize: "14px",
          color: theme.palette.dark.gray,
        },
      },
    },
    hidden: {
      display: "none",
    },
    unfolded_children: {
      maxHeight: theme.spacing(100),
      paddingLeft: theme.spacing(3),
      "& label.MuiFormControlLabel-root": {
        "& span.MuiButtonBase-root": {
          padding: 0,
          paddingLeft: theme.spacing(1),
          color: theme.palette.themeColor.secondary,
          "&.PrivateSwitchBase-input-8": {
            padding: `0px ${theme.spacing(1)}px 0px 0px`,
            marginLeft: 0,
          },
          "&.PrivateSwitchBase-root-5": {
            padding: `0px ${theme.spacing(1)}px 0px 0px`,
            marginLeft: 0,
          },
        },
      },
    },
    county_selector: {
      cursor: "pointer",
    },
  };
});

export { useStyles };
