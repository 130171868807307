import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./Finder.style";
import labels from "../../../config/labels";
import Button from "../../../common/Button/Button";
import Slider from "../../../common/Icons/Slider";
import MultipleAutocompleteWithChips from "../../../common/MultipleAutocompleteWithChips/MultipleAutocompleteWithChips";
import Select from "../../../common/Select/Select";
import Dropdown from "../../../common/Dropdown/Dropdown";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";

const selectStatusItems = [
  "For Sale",
  "For Rent",
  "Under Contract",
  "Sold",
  "Any",
];

const RealEstatesFinder = ({
  handleOpenFilterModal,
  handleRealEstateByFilter,
  filtersParams,
  allTowns,
  allCategories,
  currentTown,
}) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState("Any");
  const handleSelectedStatus = (event) => {
    setSelectedType(event.target.value);
    filtersParams.property_status = event.target.value;
    handleRealEstateByFilter();
  };

  const handleSelectedCategory = (event) => {
    filtersParams.category = event.map((item) => item.value);
  };

  const handleSelectedTown = (town_name) => {
    filtersParams.town_name = town_name;
    handleRealEstateByFilter();
  };

  const handleGoButton = () => {
    handleRealEstateByFilter();
  };

  const getCategoriesForDropdown = () => {
    return allCategories.map((category) => ({
      name: category.name,
      value: category.id,
    }));
  };

  const getTownsForDropdown = () => {
    return allTowns?.map((town) => ({
      labelComponent: (
        <Typography
          level="t2_text_2"
          color="black"
          className={classes.dropdown_item}
        >
          {town.name}
        </Typography>
      ),
      label: town.name,
      id: town.id,
      action: () => handleSelectedTown(town.name),
    }));
  };
  return (
    <div className={classes.image_background} id="real-estates-finder">
      <div className={classes.contrast_background}>
        <div className={classes.content}>
          <Typography
            level="h1_header_1"
            color="white"
            className={classes.title}
          >
            {labels.REAL_ESTATES_FINDER}
          </Typography>

          <div className={classes.form_container}>
            <div className={classes.dropdown_container}>
              <Dropdown
                items={getTownsForDropdown() || []}
                shouldPreselectFirstOption={false}
                placeholder={labels.TOWN_PLACE_HOLDER}
                dropdownClassName={classes.dropdown}
                defaultValue={{ label: currentTown.name, id: currentTown.id }}
              />
            </div>

            <div className={classes.property_type_select_container}>
              <Select
                selectItems={selectStatusItems}
                value={selectedType}
                onChange={handleSelectedStatus}
                selectClasses={{ root: classes.property_type_select }}
                dropdownItemClasses={{
                  root: classes.property_type_select_menu_item,
                }}
                menuItemsWithDivider={true}
              />
            </div>

            <Button
              level="simple_button"
              color="primary"
              children={
                <>
                  <Typography level="t1_text_1" bold>
                    {labels.FILTERS}
                  </Typography>
                  <Slider />
                </>
              }
              onClick={handleOpenFilterModal}
              classes={{
                root: clsx(
                  classes.button,
                  classes.button_label,
                  classes.filters_button
                ),
              }}
            />

            <div className={classes.autocomplete_container}>
              <MultipleAutocompleteWithChips
                options={getCategoriesForDropdown() || []}
                handleChange={handleSelectedCategory}
                customClasses={{
                  autocomplete: classes.autocomplete,
                  chip: classes.chip,
                  paper: classes.autocomplete_options,
                }}
                chipColor="black"
                placeholder="Property Type"
              />
              <Button
                level="simple_button"
                color="primary"
                children="Go"
                onClick={handleGoButton}
                classes={{
                  root: clsx(
                    classes.button,
                    classes.button_label,
                    classes.go_button,
                    classes.go_button_label
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RealEstatesFinder.propTypes = {
  handleSelectedStatus: PropTypes.func,
  selectedType: PropTypes.string,
};

export default RealEstatesFinder;
