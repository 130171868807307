import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import RealEstatesHeader from "../../Header/Header";
import labels from "../../../../config/labels";
import Back from "../../../../common/Icons/Back";
import Link from "../../../../common/Link/Link";
import { useStyles } from "./ShowLayoutMobile.style";
import Navbar from "../../../../common/Navbar/Navbar";
import InformationBlock from "../InformationBlock/InformationBlock";
import PropertyInformationBlockContent from "./PropertyInformationBlockContent/PropertyInformationBlockContent";
import PhotoGallery from "../../../../common/PhotoGallery/PhotoGallery";
import Button from "../../../../common/Button/Button";
import { useTown } from "../../../../context/TownContext";
import { useMasthead } from "../../../../hooks/useMasthead";

const MASTHEAD_HEIGHT = 56;

const ShowLayoutMobile = ({
  realEstateListing,
  backToRealEstateListingsUrl,
  sponsorInfo,
  editUrl,
  canEdit,
  isContentPreview,
  canDelete,
}) => {
  const [staticHeightWithTopOffset, setStaticHeightWithTopOffset] = useState();
  const [openGallery, setOpenGallery] = useState(false);
  const { isHalstonMedia } = useTown();
  const { sticky: stickyMasthead } = useMasthead();

  const heightsForMargins = () => {
    setStaticHeightWithTopOffset(
      openGallery
        ? document.getElementById("static-section-top-bar").offsetHeight +
            MASTHEAD_HEIGHT
        : document.getElementById("static-section").offsetHeight +
            MASTHEAD_HEIGHT
    );
  };

  useEffect(() => {
    heightsForMargins();
  }, [openGallery]);

  const classes = useStyles(staticHeightWithTopOffset);
  return (
    <>
      <div
        id="static-section"
        className={clsx(
          classes.static_section,
          stickyMasthead && classes.reduced_top
        )}
      >
        <div
          id="static-section-top-bar"
          className={classes.static_section_top_bar}
        >
          {openGallery ? (
            <Button
              level="simple_button"
              color="primary"
              children={<Back />}
              onClick={() => setOpenGallery(false)}
              classes={{ root: classes.button }}
            />
          ) : (
            <Link
              children={
                <div className={classes.back_to_real_estates_items_container}>
                  <Back className={classes.back_icon} />
                  {labels.BACK_TO_REAL_ESTATES}
                </div>
              }
              url={backToRealEstateListingsUrl}
              className={classes.back_to_real_estates_link}
              level="t1_text_1"
              color="black"
            />
          )}
        </div>

        {!openGallery && (
          <>
            {!isHalstonMedia && <RealEstatesHeader sponsorInfo={sponsorInfo} />}
            <Navbar
              items={realEstateListing.information_blocks}
              options={{ scroll: true, redirect: false, isMobile: true }}
            />
          </>
        )}
      </div>

      <div
        className={clsx(
          classes.scroll_section,
          openGallery && classes.scroll_section_for_gallery,
          isHalstonMedia && classes.reduced_top_offset
        )}
      >
        {openGallery ? (
          <PhotoGallery photos={realEstateListing.media_content} />
        ) : (
          <>
            <InformationBlock
              content={
                <PropertyInformationBlockContent
                  carouselContent={realEstateListing.media_content}
                  realEstateListing={realEstateListing}
                  handleOpenGallery={setOpenGallery}
                  editUrl={editUrl}
                  canEdit={canEdit}
                  canDelete={canDelete}
                />
              }
              customClasses={{ block: classes.property_information_block }}
              slug={realEstateListing.slug}
              isContentPreview={isContentPreview}
            />

            {realEstateListing.information_blocks.map((block) => (
              <div id={block.section_id} key={block.section_id}>
                <InformationBlock
                  title={block.title}
                  content={block.content}
                  shouldShowContactRealtorButton
                  slug={realEstateListing.slug}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

ShowLayoutMobile.propTypes = {
  realEstateListing: PropTypes.shape({
    price: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    address: PropTypes.string,
    url: PropTypes.string,
    status: PropTypes.string,
    information_blocks: PropTypes.arrayOf(
      PropTypes.shape({
        section_id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            })
          ),
        ]),
      })
    ),
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        isVideo: PropTypes.bool,
        alt_text: PropTypes.string,
        captions: PropTypes.string,
        credits: PropTypes.string,
      })
    ),
  }),
  backToRealEstateListingsUrl: PropTypes.string,
  sponsorInfo: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  canEdit: PropTypes.bool,
  editUrl: PropTypes.string,
  isContentPreview: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default ShowLayoutMobile;
