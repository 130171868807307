import React from "react";
import ClickableIcon from "../../../../../common/Icons/ClickableIcon/ClickableIcon";
import PriceTwoHundredFortyDollars from "../../../../../common/Icons/PriceTwoHundredFortyDollars";
import { ORANGE_HEX, ORANGE, ORGANIZATION_TYPES } from "../../../diyConstants";
import labels from "../../../../../config/labels";
import { useDiy } from "../../../../../context/DiyContext";
import PriceHundredDollars from "../../../../../common/Icons/PriceHundredDollars";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import ExemptionLink from "../../../ExemptionLink/ExemptionLink";
import NonProfitCheckbox from "../../../NonProfitCheckbox/NonProfitCheckbox";
import { useStyles } from "./DirectoryPayments.style";
import Typography from "../../../../../common/Typography/Typography";

const DirectoryPayments = ({
  priceSelectStatus,
  handleProfitSelection,
  setPriceSelectStatus,
  canApplyForExemption,
  isContributor,
  townsWithClusters,
  envUrl,
  currentUserId,
  handleExemptionRequest,
  nonProfitCheckboxStatus,
  setNonProfitCheckboxStatus,
  handleNonProfitSelection,
  colors,
}) => {
  const { town } = useDiy();
  const isMobile = screenWidthIsLowerThan(values.md);
  const classes = useStyles();

  return (
    <>
      <div className={classes.payments_container}>
        <ClickableIcon
          icon={
            <PriceTwoHundredFortyDollars
              fillColor={colors.profit.hex}
              onHoverColor={colors.primary.hex}
              onClickColor={colors.profit.hex}
              selectedStatus={priceSelectStatus.profit}
            />
          }
          onClick={() => {
            handleProfitSelection(),
              setPriceSelectStatus({
                profit: true,
                nonProfit: false,
                government: false,
              });
          }}
          upperText={labels.FOR_PROFIT}
          lowerText={
            <>
              {town?.under_license_contract
                ? labels.PER_PUBLICATION
                : labels.PER_SITE}
              <br />
              {labels.PER_YEAR}
            </>
          }
          textColor={colors.profit.key}
          textHoverColor={colors.primary.key}
          className={classes.icon_container}
          typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
        />
        <ClickableIcon
          icon={
            <PriceHundredDollars
              fillColor={colors.non_profit.hex}
              onHoverColor={colors.primary.hex}
              onClickColor={colors.non_profit.hex}
              selectedStatus={priceSelectStatus.nonProfit}
            />
          }
          onClick={() => {
            handleNonProfitSelection(),
              setPriceSelectStatus({
                profit: false,
                nonProfit: true,
                government: false,
              });
          }}
          upperText={labels.NONPROFIT}
          lowerText={
            <>
              {town?.under_license_contract
                ? labels.PER_PUBLICATION
                : labels.PER_SITE}
              <br />
              {labels.PER_YEAR}
            </>
          }
          textColor={colors.non_profit.key}
          textHoverColor={colors.primary.key}
          className={classes.icon_container}
          typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
        />
      </div>
      <ExemptionLink
        canApplyForExemption={canApplyForExemption}
        isContributor={isContributor}
        priceSelectStatus={priceSelectStatus}
        townsWithClusters={townsWithClusters}
        envUrl={envUrl}
        currentUserId={currentUserId}
        handleExemptionRequest={handleExemptionRequest}
      />

      <NonProfitCheckbox
        isNonProfitOptionSelected={priceSelectStatus.nonProfit}
        isChecked={nonProfitCheckboxStatus.selected}
        handleChange={() =>
          setNonProfitCheckboxStatus({
            selected: !nonProfitCheckboxStatus.selected,
          })
        }
      />
    </>
  );
};

export default DirectoryPayments;
