import React from "react";
import clsx from "clsx";
import ClickableIcon from "../../../../../common/Icons/ClickableIcon/ClickableIcon";
import PriceTwentyDollars from "../../../../../common/Icons/PriceTwentyDollars";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import labels from "../../../../../config/labels";
import { useStyles } from "./ClassifiedPayments.style";
import { useDiy } from "../../../../../context/DiyContext";
import values from "../../../../../common/Theme/themes/breakpoints/values";

const ClassifiedPayments = ({ colors, handleProfitSelection }) => {
  const isMobile = screenWidthIsLowerThan(values.md);
  const classes = useStyles();
  const { town } = useDiy();

  return (
    <div className={clsx(classes.row, classes.single_icon_container)}>
      <ClickableIcon
        onClick={handleProfitSelection}
        icon={<PriceTwentyDollars fillColor={colors.single.hex} />}
        lowerText={
          town?.under_license_contract
            ? labels.PER_PUBLICATION
            : labels.PER_SITE
        }
        textColor={colors.single.key}
        textHoverColor={colors.single.key}
        className={classes.icon_container}
        typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
      />
    </div>
  );
};

export default ClassifiedPayments;
