import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import DocumentsUploader from "../../../../components/DocumentsUploader/DocumentsUploader";
import {
  useDocuments,
  withDocumentsContextProvider,
} from "../../../../context/CMS/DocumentsContext/DocumentsContext";
import Snackbar from "../../../../common/Snackbar/Snackbar";
import {
  snackbarInitialState,
  snackbarReducer,
} from "../../../../reducers/snackbars/snackbarsReducers";
import BulkActionSelector from "../../../../components/DocumentsUploader/BulkActionSelector/BulkActionSelector";
import Typography from "../../../../common/Typography/Typography";

const DocumentsManagement = ({ formInfo, acceptedDocumentType, label }) => {
  const {
    setContribution,
    documentInfo,
    setDocumentInfo,
    files,
    haveBulkActions,
  } = useDocuments();
  const [snackbarProps, setSnackbarProps] = useReducer(
    snackbarReducer,
    snackbarInitialState
  );

  useEffect(() => {
    setContribution(formInfo);
  }, [formInfo]);

  const handleClose = () => {
    setDocumentInfo();
    setSnackbarProps(snackbarInitialState);
  };

  useEffect(() => {
    if (documentInfo) {
      setSnackbarProps(documentInfo);
    }
  }, [documentInfo]);

  return (
    <>
      <Snackbar
        open={snackbarProps.open}
        message={snackbarProps.message}
        variant={snackbarProps.variant}
        onClose={handleClose}
      />
      {label && (
        <Typography level={"h4_sub_header_4"} color="gray" bold>
          {label}
        </Typography>
      )}
      <DocumentsUploader acceptedDocumentType={acceptedDocumentType} />
      {files.length > 0 && haveBulkActions && (
        <BulkActionSelector
          contributionId={formInfo.contribution_id}
          contributionType={formInfo.contribution_type}
        />
      )}
    </>
  );
};

DocumentsManagement.propTypes = {
  formInfo: PropTypes.shape({
    contribution_id: PropTypes.number,
    contribution_type: PropTypes.string,
    current_user_id: PropTypes.number,
    content_documents: PropTypes.array,
  }),
};

export default withDocumentsContextProvider(DocumentsManagement);
