import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./IndexSubheader.style";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";

const IndexSubheader = ({ townName, filtersParams }) => {
  const classes = useStyles();

  const filtersApplied = () => {
    return (
      filtersParams.hasOwnProperty("category") ||
      filtersParams.hasOwnProperty("property_status") ||
      filtersParams.hasOwnProperty("max_price") ||
      filtersParams.hasOwnProperty("number_of_bedrooms") ||
      filtersParams.hasOwnProperty("number_of_bathrooms")
    );
  };

  return townName && !filtersApplied() ? (
    <div className={classes.container}>
      <Typography
        level="h3_sub_header"
        color="gray"
        className={classes.subheader}
      >
        {labels.RECENTLY_ADDED_IN + townName}
      </Typography>
    </div>
  ) : (
    <></>
  );
};

IndexSubheader.propTypes = {
  filtersParams: PropTypes.shape({
    town_id: PropTypes.number,
    category: PropTypes.any, //This should probably be an array
    property_status: PropTypes.string,
    min_price: PropTypes.number,
    max_price: PropTypes.number,
    number_of_bedrooms: PropTypes.number,
    number_of_bathrooms: PropTypes.number,
  }),
  townName: PropTypes.string,
};

export default IndexSubheader;
