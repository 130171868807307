import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    categories_container: {
      width: "90%",
      lineHeight: "12px",
    },
    categories: {
      "&.MuiTypography-colorPrimary": {
        color: `${theme.palette.grayVariation.grayForEventCategories} !important`,
      },
      "& span": {
        color: `${theme.palette.grayVariation.grayForEventCategories} !important`,
      },
      "&:hover": {
        textDecoration: "underline !important",
        color: `${theme.palette.grayVariation.grayForEventCategories} !important`,
      },
    },
    address: {
      overflowWrap: "break-word",
      [theme.breakpoints.down(1000)]: {
        maxHeight: theme.spacing(4),
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
      },
    },
    bottom_section: {
      paddingTop: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
    },
    add_to_calendar: {
      width: "55%",
      "& .addeventatc": {
        width: "100% !important",
      },
    },
    social_networks_sharing: {
      width: "40%",
      alignSelf: "end",
    },
  };
});

export { useStyles };
