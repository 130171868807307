import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    copy_button: {
      width: "100%",
      border: `0.5px solid black !important`,
      borderRadius: `${theme.spacing(1)}px !important`,
      backgroundColor: `${theme.palette.tapintoBranding.tapintoOrange} !important`,
      color: `${theme.palette.miscellaneous.white} !important`,
      textTransform: "capitalize !important",
      fontSize: `${theme.spacing(2)}px !important`,
      lineHeight: "19.5px !important",
    },
  };
});

export { useStyles };
