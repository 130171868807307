import React, { useCallback, useReducer, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useStyles } from "./SendToAFriend.style";
import TextField from "../../../../common/TextField/TextField";
import Button from "../../../../common/Button/Button";
import labels from "../../../../config/labels";
import {
  sendToAFriendFormInitialState,
  sendToAFriendFormReducer,
} from "../../../../reducers/forms/formReducers";
import {
  getErrorMessage,
  handleSubmitRealtorForms,
  handleTextfieldChange,
  shouldShowError,
} from "../../../../utils/formsHelper";
import FormSent from "../FormSent/FormSent";
import Spinner from "../../../../common/Spinner/Spinner";
import { replaceInHtmlText } from "../../../../utils/helper";
import withConfig from "../../../../utils/withConfig";
import FormErrors from "../Errors/Errors";

const ACTION = "sendRealEstateListingToAFriend";

const SendToAFriendForm = ({ slug, handleClose }) => {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useReducer(
    sendToAFriendFormReducer,
    sendToAFriendFormInitialState
  );
  const [submitProgress, setSubmitProgress] = useState({
    sending: false,
    sent: false,
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const responseToken = await executeRecaptcha(ACTION);
    setToken(responseToken);
  }, [executeRecaptcha]);

  return submitProgress.sent ? (
    <FormSent handleClose={handleClose} />
  ) : (
    <form
      autoComplete="off"
      onSubmit={async (e) =>
        await handleSubmitRealtorForms(e, {
          formData: formData,
          formType: "sendToAFriend",
          contentSlug: slug,
          setErrors: setErrors,
          submitProgress: setSubmitProgress,
          token: token,
        })
      }
      className={classes.form}
    >
      <TextField
        nativeLabel={labels.YOUR_FRIENDS_EMAIL + "*"}
        type="email"
        useDefaultClasses={false}
        customClasses={{ root: classes.text_field }}
        useNativeError={true}
        onChange={(e) =>
          handleTextfieldChange({
            value: { email: e.target.value },
            setFormPayload: setFormData,
          })
        }
        helperText={getErrorMessage({ errors: errors, fieldName: "email" })}
        error={shouldShowError({ errors: errors, fieldName: "email" })}
      />
      <TextField
        nativeLabel={labels.MESSAGE + "*"}
        isMultiline={true}
        useDefaultClasses={false}
        customClasses={{ root: classes.text_field }}
        useNativeError={true}
        onChange={(e) =>
          handleTextfieldChange({
            value: { message: e.target.value },
            setFormPayload: setFormData,
          })
        }
        helperText={getErrorMessage({ errors: errors, fieldName: "message" })}
        error={shouldShowError({ errors: errors, fieldName: "message" })}
      />

      <GoogleReCaptcha action={ACTION} onVerify={handleReCaptchaVerify} />

      <FormErrors errors={errors} />

      <Button
        type="submit"
        level="simple_button"
        color="primary"
        children={labels.SEND_EMAIL}
        classes={{
          root: clsx(classes.button, classes.button_label),
        }}
      />

      {replaceInHtmlText(
        labels.RECAPTCHA_CLAUSE_TEXT,
        [
          {
            toReplace: "Privacy Policy",
            toReplaceWith: `<a href="${withConfig(
              "SIGNUP_LINK_RECAPTCHA_PRIVACY"
            )}" target="_blank">Privacy Policy</a>`,
          },
          {
            toReplace: "Terms of Service",
            toReplaceWith: `<a href="${withConfig(
              "SIGNUP_LINK_TOS_RECAPTCHA"
            )}" target="_blank">Terms of Service</a>`,
          },
        ],
        classes.recaptcha_clause_text
      )}

      {submitProgress.sending && <Spinner withLoadingOverlay={true} />}
    </form>
  );
};

SendToAFriendForm.propTypes = {
  slug: PropTypes.string,
  handleClose: PropTypes.func,
};

export default SendToAFriendForm;
