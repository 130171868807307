import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import labels from "../../config/labels";
import Typography from "../../common/Typography/Typography";
import TimePicker from "../TimePicker/TimePicker";
import { useStyles } from "./TimeRangePicker.style";

const BLACK = "black";

const TimeRangePicker = ({
  getStartTime,
  getEndTime,
  className,
  timePickerClassName,
  label,
  labelStyleProps,
  sublabel,
  sublabelStyleProps,
  defaultStartTime,
  defaultEndTime,
  minutesStep,
}) => {
  const classes = useStyles();
  return (
    <>
      {label && (
        <Typography
          level={labelStyleProps.level}
          color={labelStyleProps.color}
          bold={labelStyleProps.bold}
          className={labelStyleProps.className}
        >
          {label}
        </Typography>
      )}
      {sublabel && (
        <Typography
          level={sublabelStyleProps.level}
          color={sublabelStyleProps.color}
          bold={sublabelStyleProps.bold}
          className={sublabelStyleProps.className}
        >
          {sublabel}
        </Typography>
      )}
      <div className={clsx(classes.container, className && className)}>
        <TimePicker
          className={clsx(
            classes.timepicker,
            timePickerClassName && timePickerClassName
          )}
          onChange={getStartTime}
          defaultValue={defaultStartTime}
          minutesStep={minutesStep}
        />
        <Typography
          level={labelStyleProps.level}
          color={BLACK}
          bold
          className={classes.to}
        >
          {labels.TO}
        </Typography>
        <TimePicker
          className={clsx(
            classes.timepicker,
            timePickerClassName && timePickerClassName
          )}
          onChange={getEndTime}
          defaultValue={defaultEndTime}
          minutesStep={minutesStep}
        />
      </div>
    </>
  );
};

TimeRangePicker.propTypes = {
  getEndTime: PropTypes.func,
  getStartTime: PropTypes.func,
  className: PropTypes.any,
  timePickerClassName: PropTypes.any,
  label: PropTypes.string,
  labelStyleProps: PropTypes.shape({
    level: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
    className: PropTypes.any,
  }),
  sublabel: PropTypes.string,
  sublabelStyleProps: PropTypes.shape({
    level: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
    className: PropTypes.any,
  }),
  defaultStartTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  defaultEndTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  minutesStep: PropTypes.number,
};

export default TimeRangePicker;
