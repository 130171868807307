import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    bottom_actions_container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    bottom_action_buttons_container: {
      width: "60% !important",
      [theme.breakpoints.down(760)]: {
        width: "100% !important",
      },
    },
    loading_overlay: {
      position: "fixed",
      background: "rgba(0, 0, 0, 0.9)",
    },
    spinner: {
      top: "45%",
      left: "49%",
      [theme.breakpoints.down(960)]: {
        left: "45%",
      },
    },
  };
});

export { useStyles };
