import React from "react";
import PropTypes from "prop-types";

const EmailSVG = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="60"
      height="45"
      viewBox="0 0 60 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.269 0H3.3897L25.0838 18.8369C26.1572 19.6904 27.7955 20.6657 29.9988 20.6657C32.0326 20.6657 34.0665 19.6904 34.9139 18.8369L56.269 0Z"
        fill={fillColor}
      />
      <path
        d="M22.033 22.3117L0 3.29189V39.8684C0 44.2576 3.16372 45.111 4.74558 44.9891H54.9131C59.1163 44.9891 60.0541 41.5753 59.9976 39.8684V3.29189L37.9646 22.3117C36.1003 23.9576 33.219 26.1522 29.9988 26.1522C26.7786 26.1522 23.3889 23.5919 22.033 22.3117Z"
        fill={fillColor}
      />
    </svg>
  );
};

EmailSVG.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

EmailSVG.defaultProps = {
  fillColor: "white",
};

export default EmailSVG;
