import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Typography from "../../../../common/Typography/Typography";
import Sponsor from "../../../Sponsor/Sponsor";
import SponsoredTag from "../../../SponsoredTag/SponsoredTag";
import Edit from "../../../Actions/Edit";
import { useStyles } from "./HeaderWithCategories.style";

const HeaderWithCategories = ({
  isAdvertisement,
  showContentOptions,
  sponsorInfo,
  title,
  titleProps,
  categories,
  editUrl,
  contentTitle,
  contentTitleProps,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.header_container}>
      {isAdvertisement && <SponsoredTag />}
      <div className={classes.title_and_categories_container}>
        <Typography
          level={titleProps.level}
          color={titleProps.color}
          bold={titleProps.bold}
          underlined={titleProps.underlined}
          className={clsx(
            classes.header,
            titleProps.className && titleProps.className
          )}
        >
          {title}
        </Typography>

        {categories}
      </div>

      {showContentOptions && editUrl && <Edit editUrl={editUrl} />}

      <div>
        <Typography
          level={contentTitleProps.level}
          color={contentTitleProps.color}
          bold={contentTitleProps.bold}
          underlined={titleProps.underlined}
          className={clsx(
            classes.content_title,
            contentTitleProps.className && contentTitleProps.className
          )}
        >
          {contentTitle}
        </Typography>
      </div>

      {sponsorInfo && <Sponsor sponsor_info={sponsorInfo} />}
    </div>
  );
};

HeaderWithCategories.propTypes = {
  isAdvertisement: PropTypes.bool,
  showContentOptions: PropTypes.bool,
  sponsorInfo: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  title: PropTypes.string,
  titleProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    className: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  editUrl: PropTypes.string,
  contentTitle: PropTypes.string,
  contentTitleProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    className: PropTypes.string,
  }),
};

HeaderWithCategories.defaultProps = {
  titleProps: {
    color: "tapintoOrange",
    level: "h1_header_1",
    bold: true,
    underlined: false,
  },
  contentTitleProps: {
    color: "black",
    level: "h1_header_1",
    bold: true,
    underlined: false,
  },
};

export default HeaderWithCategories;
