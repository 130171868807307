import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;

  return {
    container: {
      paddingTop: spacing(2),
    },
    bottom_container: {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    },
    bottom_actions: {
      paddingTop: `${spacing(1)}px !important `,
      borderTop: `1px solid ${palette.light.lightGray}`,
    },
    remove_button: {
      width: "100%",
      height: spacing(4),
      backgroundColor: `${palette.dark.red} !important`,
      color: `${palette.miscellaneous.white} !important`,
      "& svg": {
        marginRight: 6,
      },
      textTransform: "capitalize !important",
      borderRadius: `${spacing(1)}px !important`,
      "&.MuiButtonBase-root": {
        border: `0.5px solid black`,
        fontSize: `${spacing(2)}px !important`,
      },
    },
    save_button: {
      width: "100%",
      height: spacing(4),
      backgroundColor: `${palette.miscellaneous.green} !important`,
      color: `${palette.miscellaneous.white} !important`,
      textTransform: "capitalize !important",
      borderRadius: `${spacing(1)}px !important`,
      "&.MuiButtonBase-root": {
        border: `0.5px solid black`,
        fontSize: `${spacing(2)}px !important`,
      },
    },

    add_pdf_to_carousel_text: {
      alignSelf: "center",
    },
    custom_root: {
      "&.MuiSelect-select.MuiSelect-select": {
        paddingTop: `${spacing(0.5)}px !important`,
        paddingBottom: `${spacing(0.5)}px !important`,
      },
    },
    embed_text: {
      display: "block",
    },
    block_title: {
      width: "100%",
      borderBottom: "1px solid black",
      paddingBottom: spacing(1),
    },
    row: {
      paddingTop: spacing(3),
    },
    preview_label: {
      alignSelf: "center",
    },
    embed_code: {
      paddingTop: `${spacing(3)}px !important`,
    },
    caption: {
      paddingTop: `${spacing(3)}px !important`,
    },
    caption_limit: {
      color: `${palette.grayVariation.grayForSubLabel} !important`,
      paddingLeft: spacing(1),
    },
    text_field: {
      "& fieldset": {
        borderRadius: "1px !important",
      },
    },
    image_thumbnail: {
      border: `1px solid ${palette.light.borderGray}`,
      padding: spacing(1),
    },
  };
});

export { useStyles };
