import React, { useState } from "react";
import Button from "../../../common/Button/Button";
import Select from "../../../common/Select/Select";
import { useDocuments } from "../../../context/CMS/DocumentsContext/DocumentsContext";
import { useStyles } from "./BulkActionSelector.style";
import labels from "../../../config/labels";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import Grid from "../../../common/Grid/Grid";
import Typography from "../../../common/Typography/Typography";
import TextField from "../../../common/TextField/TextField";
import clsx from "clsx";

const BLACK = "#000000";

const BulkActionSelector = ({ contributionId, contributionType }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {
    bulkAction,
    setBulkAction,
    selectedDocuments,
    bulkCaptionAndCreditsUpdate,
    setBulkCaptions,
    setBulkCredits,
    bulkCaptions,
    bulkCredits,
  } = useDocuments();

  const [selectOption, setSelectOption] = useState(bulkAction);
  const [credits] = useState(bulkCredits);
  const [captions] = useState(bulkCaptions);

  const handleGo = () => {
    if (selectedDocuments.length === 0) {
      alert("You should select at least one document");
      return;
    }

    if (bulkAction === "Add captions and credits") {
      setOpen(true);
    } else if (bulkAction === "Delete") {
      if (confirm(labels.PDF_ACTION_LABELS.REMOVAL_CONFIRMATION)) {
        bulkCaptionAndCreditsUpdate(contributionId, contributionType);
      }
    }
  };

  const handleSelectChange = (value) => {
    setSelectOption(value);
    setBulkAction(value);
  };

  const handleSaveCaptionsAndCredits = () => {
    bulkCaptionAndCreditsUpdate(contributionId, contributionType);
    setOpen(false);
  };

  return (
    <div className={classes.bulk_selector}>
      <PopUpDialog
        content={
          <Grid container>
            <Grid item xs={12} className={classes.block_title}>
              <Typography level={"medium_16_px"} italic>
                {labels.CREDITS_AND_CAPTIONS}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <TextField
                defaultValue={credits}
                onChange={(event) => setBulkCredits(event.target.value)}
                label={labels.CREDITS}
                error={false}
                required
                labelLevel={"cms_form_label"}
                labelColor={"black"}
                boldLabel={false}
                className={classes.text_field}
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.row, classes.caption)}>
              <TextField
                defaultValue={captions}
                onChange={(event) => setBulkCaptions(event.target.value)}
                isMultiline
                labelComponent={
                  <div>
                    <Typography level={"cms_form_label"}>
                      {labels.CAPTION}
                    </Typography>
                    <Typography
                      level={"cms_form_label"}
                      className={classes.caption_limit}
                    >
                      {labels.CAPTION_LIMIT}
                    </Typography>
                  </div>
                }
                className={classes.text_field}
              />
            </Grid>
            <Grid container spacing={4} className={classes.bottom_actions}>
              <Grid item xs={4}>
                <Button
                  level="cms_button"
                  variant="contained"
                  onClick={() => setOpen(false)}
                  className={classes.cancel_button}
                >
                  {labels.CANCEL}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  level="cms_button"
                  variant="contained"
                  onClick={handleSaveCaptionsAndCredits}
                  className={classes.save_button}
                >
                  {labels.SAVE}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
        openState={open}
        onClose={() => setOpen(false)}
        className={classes.pop_up}
        contentClass={classes.content}
        titleClass={classes.no_title}
        closeButtonColor={BLACK}
        closeButtonClass={classes.close_button}
      />
      <Select
        selectItems={["Add captions and credits", "Delete"]}
        value={selectOption}
        onChange={(event) => handleSelectChange(event.target.value)}
        variant="outlined"
        selectClasses={{ root: classes.custom_root }}
      />
      <Button
        level="cms_button"
        variant="contained"
        className={classes.go_button}
        onClick={handleGo}
      >
        {labels.GO}
      </Button>
    </div>
  );
};

export default BulkActionSelector;
