import React, { useState } from "react";
import Button from "../../../../../common/Button/Button";
import Typography from "../../../../../common/Typography/Typography";
import { useStyles } from "./LegalNoticeEntityInputForm.style";
import SubmitLegalNoticeRequestModal from "../../../../Modals/SubmitLegalNoticeRequestModal";
import labels from "../../../../../config/labels";

const LegalNoticeEntityInputForm = () => {
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();

  return (
    <>
      <div className={classes.add_new_entity_button}>
        <Button
          level={"legal_notice_button"}
          color={"primary"}
          children={<Typography>{labels.CREATE_NEW_ENTITY}</Typography>}
          onClick={() => setOpenModal(true)}
        />
      </div>
      <SubmitLegalNoticeRequestModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default LegalNoticeEntityInputForm;
