import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    upcoming_events_title: {
      width: "100%",
      textAlign: "center",
      marginTop: theme.spacing(4),
      backgroundColor: theme.palette.light.grey,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      [`@media print`]: {
        display: "none !important",
      },
      [theme.breakpoints.down(960)]: {
        marginLeft: theme.spacing(0.85),
      },
    },
    events_list: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginBottom: theme.spacing(10),
      [`@media print`]: {
        display: "none !important",
      },
    },
  };
});

export { useStyles };
