import React from "react";
import PropTypes from "prop-types";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import Typography from "../../../common/Typography/Typography";
import ColumnWithArticlesDisplay from "../../../components/Column/ColumnWithArticlesDisplay/ColumnWithArticlesDisplay";
import labels from "../../../config/labels";
import { useStyles } from "./ColumnsIndex.style";
import Pagination from "../../../common/Pagination/Pagination";
import {
  buildRedirectUrl,
  getPageNumber,
  redirectToPage,
} from "../../../utils/helper";

const ColumnsIndex = ({ columns, pagination_attributes }) => {
  const classes = useStyles();

  const handleGotoNextPage = (e, p) => {
    const pageNumber = getPageNumber(p, pagination_attributes.current_page);
    const redirectUrl = buildRedirectUrl({
      type: "pagination",
      params: { pageNumber: pageNumber },
    });
    redirectToPage(redirectUrl, "_top");
  };

  return (
    <div className={classes.main_container}>
      <Typography level="h3">{labels.COLUMNS}</Typography>
      <ColumnWithArticlesDisplay itemsForIndex={columns} />
      <HorizontalDivider className={classes.divider} />

      {pagination_attributes.show_pagination && (
        <div className={classes.pagination_container}>
          <Pagination
            count={pagination_attributes.total_pages}
            page={parseInt(pagination_attributes.current_page)}
            onChange={handleGotoNextPage}
            className={classes.columns_pagination}
          />
        </div>
      )}
    </div>
  );
};

ColumnsIndex.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        path: PropTypes.string,
      }),
      articles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          path: PropTypes.string,
          photo_url: PropTypes.string,
        })
      ),
    })
  ),
  pagination_attributes: PropTypes.shape({
    show_pagination: PropTypes.bool,
    total_pages: PropTypes.number,
    current_page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    per: PropTypes.number,
  }),
};

export default ColumnsIndex;
