import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import Link from "../../../common/Link/Link";
import { useStyles } from "./ColumnWithArticlesDisplay.style";
import labels from "../../../config/labels";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import ArticleList from "../ArticlesList/ArticlesList";

const ColumnWithArticlesDisplay = ({
  itemsForIndex,
  numberOfArticlesToDisplay,
}) => {
  const classes = useStyles();

  return itemsForIndex.map((item, index) => {
    if (item.articles.length > 0) {
      let columnArticles = item.articles;

      if (item.articles.length > numberOfArticlesToDisplay) {
        columnArticles = item.articles.slice(0, numberOfArticlesToDisplay);
      }

      return (
        <Fragment key={index}>
          <HorizontalDivider className={classes.divider} />

          <Link
            level={screenWidthIsLowerThan(960) ? "h3_sub_header_2" : "h3"}
            children={item.column.name}
            url={item.column.path}
            bold
            className={classes.column_title_link}
            underlined
          />

          <ArticleList articles={columnArticles} />

          {columnArticles.length >= 3 && (
            <div className={classes.more_columns_link_container}>
              <Link
                level={
                  screenWidthIsLowerThan(960)
                    ? "h5_sub_header_2"
                    : "h3_sub_header_2"
                }
                color="tapintoGreen"
                children={labels.MORE_COLUMNS}
                url={item.column.path}
                underlined
                className={classes.more_columns_link}
              />
            </div>
          )}
        </Fragment>
      );
    }
  });
};

ColumnWithArticlesDisplay.propTypes = {
  itemsForIndex: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        path: PropTypes.string,
      }),
      articles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          path: PropTypes.string,
          photo_url: PropTypes.string,
        })
      ),
    })
  ),
  numberOfArticlesToDisplay: PropTypes.number,
};

ColumnWithArticlesDisplay.defaultProps = {
  numberOfArticlesToDisplay: 3,
};

export default ColumnWithArticlesDisplay;
