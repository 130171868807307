import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DateRangePicker from "../../../common/DateRangePicker/DateRangePicker";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./ObituariesHeader.style";
import labels from "../../../config/labels";
import Dropdown from "../../../common/Dropdown/Dropdown";
import { getDropdownChoices } from "../../../services/townService";
import withConfig from "../../../utils/withConfig";
import { format } from "date-fns";

const ObituariesHeader = ({
  availableNames,
  townId,
  townSlug,
  onSubmitSearch,
  setObituariesData,
  setMainObituary,
  setIsSearch,
  isShowPage,
}) => {
  const classes = useStyles();
  const queryParams = new URLSearchParams(window.location.search);
  const startDateParam = queryParams.get("start");
  const endDateParam = queryParams.get("end");
  const [dateRange, setDateRange] = useState({
    start: startDateParam ? startDateParam : null,
    end: endDateParam ? endDateParam : null,
  });

  const handleRangeDateChange = (dateRangeChange) => {
    setDateRange(dateRangeChange);
  };

  const redirectToShowPage = (name) => {
    if (townSlug !== undefined) {
      window.location.href = `/towns/${townSlug}${withConfig(
        "OBITUARIES"
      )}/${name}`;
    } else {
      window.location.href = `${withConfig("OBITUARIES")}/${name}`;
    }
  };

  const parseDate = (date) => {
    let parsedDate = "";
    let dateStr = String(date);
    if (dateStr.includes("T")) {
      parsedDate = dateStr.split("T")[0].replace(/-/gi, "/");
    } else {
      parsedDate = dateStr.replace(/-/gi, "/");
    }

    parsedDate = format(new Date(parsedDate), "MM/dd/yyyy");

    return parsedDate;
  };

  const redirectToIndexPage = (dates) => {
    if (dates?.start !== undefined && dates?.end !== undefined) {
      if (townSlug !== undefined) {
        window.location.href = `/towns/${townSlug}${withConfig(
          "OBITUARIES"
        )}?start=${parseDate(dates.start)}&end=${parseDate(dates.end)}`;
      } else {
        window.location.href = `${withConfig("OBITUARIES")}?start=${parseDate(
          dates.start
        )}&end=${parseDate(dates.end)}`;
      }
    }
  };

  useEffect(() => {
    if (isShowPage) {
      if (dateRange?.start !== null && dateRange?.end !== null) {
        redirectToIndexPage(dateRange);
      }
    } else {
      onSubmitSearch(
        townId,
        dateRange,
        setMainObituary,
        setObituariesData,
        setIsSearch
      );
    }
  }, [dateRange]);

  return (
    <div className={classes.header_container}>
      <div className={classes.header_title_container}>
        <Typography color="black" bold level="h2_header_2">
          {labels.IN_MEMORIAM}
        </Typography>
      </div>
      <div className={classes.left_container}>
        <Typography level="h5_sub_header_2" color="gray">
          {labels.SEARCH_BY_DATE_OF_PASSING}
        </Typography>
        <DateRangePicker
          labelStyleProps={{
            color: "black",
            level: "paragraph_1",
            bold: true,
          }}
          defaultStartValue={dateRange?.start}
          defaultEndValue={dateRange?.end}
          onDateRangeChange={handleRangeDateChange}
          endIconFillColor={"#777777"}
        />
      </div>
      <div className={classes.right_container}>
        <Dropdown
          label={labels.SEARCH_BY_NAME}
          items={getDropdownChoices(
            availableNames || [],
            redirectToShowPage,
            false
          )}
          shouldPreselectFirstOption={false}
          typographyLevel={"h5_sub_header_2"}
          bold={false}
          labelClassName={classes.dropdown_label}
        />
      </div>
    </div>
  );
};

ObituariesHeader.propTypes = {
  availableNames: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  townId: PropTypes.number,
  townSlug: PropTypes.string,
  onSubmitSearch: PropTypes.func,
  setObituariesData: PropTypes.func,
  setMainObituary: PropTypes.func,
  setIsSearch: PropTypes.func,
  isShowPage: PropTypes.bool,
};

export default ObituariesHeader;
