import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import { useStyles } from "./ListTypeContentBlock.style";
import Link from "../../../common/Link/Link";
import VideoPlayer from "../../../common/VideoPlayer/VideoPlayer";

const ListTypeContentBlock = ({
  contentData,
  titleProps,
  readMoreLinkProps,
  body,
  shouldShowReadMoreLink,
  bottomActions,
  className,
  videoContainerHeight,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.list_type_block, className)}>
      <div className={classes.top_section}>
        <div className={classes.info_container}>
          <Link
            bold={titleProps.bold}
            color={titleProps.color}
            level={titleProps.level}
            underlined={titleProps.underlined}
            url={contentData.path}
            className={clsx(
              classes.link,
              titleProps.className && titleProps.className
            )}
            children={contentData.name}
          />

          {body}

          {shouldShowReadMoreLink && (
            <Link
              bold={readMoreLinkProps.bold}
              color={readMoreLinkProps.color}
              level={readMoreLinkProps.level}
              underlined={readMoreLinkProps.underlined}
              url={contentData.path}
              className={clsx(
                classes.link,
                readMoreLinkProps.className && readMoreLinkProps.className
              )}
              children={labels.READ_MORE}
            />
          )}
        </div>
        <div className={classes.image_container}>
          {contentData?.media?.videoUrl ? (
            <VideoPlayer
              url={contentData.media.videoUrl}
              width={"100%"}
              height={videoContainerHeight}
              showControls={true}
            />
          ) : (
            <a href={contentData.path} alt={contentData.media.photoAltText}>
              <img
                alt={contentData.media.photoAltText}
                src={contentData.media.photoUrl}
              />
            </a>
          )}
        </div>
      </div>
      {bottomActions}
    </div>
  );
};

ListTypeContentBlock.propTypes = {
  contentData: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    media: PropTypes.shape({
      videoUrl: PropTypes.string,
      photoUrl: PropTypes.string,
      photoAltText: PropTypes.string,
    }),
  }),
  titleProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    className: PropTypes.string,
  }),
  readMoreLinkProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    className: PropTypes.string,
  }),
  body: PropTypes.node,
  shouldShowReadMoreLink: PropTypes.bool,
  bottomActions: PropTypes.node,
  className: PropTypes.string,
};

ListTypeContentBlock.defaultProps = {
  titleProps: {
    color: "black",
    level: "t3_text_3",
    bold: true,
    underlined: false,
  },
  readMoreLinkProps: {
    color: "black",
    level: "t2_text_2",
    bold: true,
    underlined: true,
  },
  shouldShowReadMoreLink: true,
  videoContainerHeight: "190px",
};

export default ListTypeContentBlock;
