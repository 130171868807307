import { makeStyles } from "../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    article_box: {
      display: "flex",
      width: "30%",
      flexWrap: "wrap",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down(960)]: {
        width: "80%",
        justifyContent: "space-between",
        marginBottom: theme.spacing(7.5),
      },
    },
    thumbnail_container: {
      width: "100%",
      height: theme.spacing(20),
      marginBottom: theme.spacing(2),
    },
    thumbnail_link: {
      width: "100%",
      maxHeight: "100%",
      maxWidth: "100%",
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        display: "contents",
        "& span": {
          display: "contents",
        },
      },
    },
    thumbnail_item: {
      height: "100% !important",
      minHeight: `${theme.spacing(10)}px !important`,
      maxHeight: `${theme.spacing(20)}px !important`,
      maxWidth: "100%",
      "& img": {
        borderRadius: "10px",
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        maxHeight: `${theme.spacing(20)}px !important`,
      },
    },
    thumbnail_video: {
      "& :first-child": {
        maxHeight: `${theme.spacing(20)}px !important`,
      },
    },
    description: {
      textAlign: "justify",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "12px",
      fontFamily: "Montserrat",
      [theme.breakpoints.down(960)]: {
        paddingRight: theme.spacing(1),
      },
    },
    bottom_section: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(960)]: {
        paddingRight: theme.spacing(1),
      },
    },
    read_more_button: {
      border: `1px solid ${theme.palette.grayVariation.secondaryGrey} !important`,
      borderRadius: "0px !important",
      textTransform: "none !important",
    },
    info_container: {
      display: "flex",
      alignContent: "space-between",
      flexWrap: "wrap",
      width: "100%",
      height: "35%",
    },
  };
});

export { useStyles };
