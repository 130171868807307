import React from "react";
import { object } from "prop-types";
import ContributionsBlock from "../../../components/ContributionsBlock/ContributionsBlock";
import UserInformationBlock from "../../../components/UserInformationBlock/UserInformationBlock";
import { useStyles } from "./UserShowPage.style";

const UserShowPage = ({ user }) => {
  const classes = useStyles();
  const { user_profile, avatar_url, slug, email, admin_towns } = user || {};

  return (
    <div className={classes.main_container}>
      <UserInformationBlock
        userProfile={user_profile}
        userEmail={email}
        thumbnailImageUrl={avatar_url}
        townSocialLinks={admin_towns}
      />
      <ContributionsBlock userSlug={slug} />
    </div>
  );
};

UserShowPage.propTypes = {
  user: object,
};

export default UserShowPage;
