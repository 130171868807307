import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import labels from "../../../../../config/labels";
import { useStyles } from "./ObituarySectionBlock.style";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import Typography from "../../../../../common/Typography/Typography";
import HorizontalDivider from "../../../../../common/HorizontalDivider/HorizontalDivider";
import ServicePlace from "../FuneralHome/ServicePlace/ServicePlace";
import { GREEN, ORANGE } from "../../../diyConstants";

const VISITATION = "visitation";
const SERVICE = "service";
const BURIAL = "burial";
const CREMATION = "cremation";

const ObituarySectionBlock = ({
  funeralHomeNames,
  onChange,
  type,
  content,
}) => {
  const getContentSize = () => {
    if (content && Array.isArray(content) && content.length > 1) {
      return content.length;
    } else {
      return 1;
    }
  };

  const getServicesPlacesInfo = () => {
    let places = [];
    if (content && Array.isArray(content)) {
      places = content.map((place, index) => {
        return {
          id: place.id,
          at_funeral_home: place.at_funeral_home,
          name: place.name,
          address: place.address,
          funeral_home_id: content.funeral_home_id,
          funeralHomeCounter: index,
          date: place.date,
          time_from: place.time_from,
          time_to: place.time_to,
        };
      });
    }

    if (content?.id) {
      places = [
        {
          id: content?.id,
          at_funeral_home: content.at_funeral_home,
          name: content.name,
          address: content.address,
          funeral_home_id: content.funeral_home_id,
          funeralHomeCounter: 0,
          date: content.date,
          time_from: content.time_from,
          time_to: content.time_to,
        },
      ];
    }

    return places;
  };
  const classes = useStyles();
  const [servicePlacesInfo, setServicePlacesInfo] = useState(
    getServicesPlacesInfo()
  );
  const [servicePlaceCounter, setServicePlaceCounter] = useState(
    getContentSize()
  );

  const handleServicePlaceChange = (servicePlaceInfo) => {
    let existingServices = [];
    if (servicePlacesInfo.length === 0) {
      existingServices = [servicePlaceInfo];
    } else {
      const existingSingleServicePlaceInfo = servicePlacesInfo.find(
        (service) =>
          service.funeralHomeCounter === servicePlaceInfo.funeralHomeCounter
      );
      if (existingSingleServicePlaceInfo === undefined) {
        existingServices = [...servicePlacesInfo, servicePlaceInfo];
      } else {
        existingServices = servicePlacesInfo.map((service) => {
          if (
            service.funeralHomeCounter === servicePlaceInfo.funeralHomeCounter
          ) {
            return servicePlaceInfo;
          } else {
            return service;
          }
        });
      }
    }
    setServicePlacesInfo(existingServices);
  };

  useEffect(() => {
    onChange(servicePlacesInfo, type);
  }, [servicePlacesInfo]);

  const printServicePlaces = (servicePlace) => {
    return servicePlace();
  };

  const handleServicePlaceRemoval = (funeralHomeCounter, contentId) => {
    setServicePlaceCounter(servicePlaceCounter - 1);
    const services = servicePlacesInfo.filter(
      (service) => service.funeralHomeCounter !== funeralHomeCounter
    );
    setServicePlacesInfo(services);
  };

  const printLabel = () => {
    switch (type) {
      case VISITATION:
        return labels.VISITATION;
      case SERVICE:
        return labels.SERVICE;
      case BURIAL:
        return labels.BURIAL;
      case CREMATION:
        return labels.CREMATION;
      default:
        break;
    }
  };

  const shouldShowAddOneOption = () => {
    return type === VISITATION || type === SERVICE;
  };

  const getServiceContent = (position) => {
    if (content) {
      return content.length > 0 ? content[position] : content;
    }
    return null;
  };

  return (
    <>
      <div>
        <HorizontalDivider className={classes.horizontal_divider} />
        <Typography
          className={classes.margin_top_0}
          level={screenWidthIsLowerThan(900) ? "medium_17_px" : "h4"}
          color={GREEN}
          bold
        >
          {printLabel()}
        </Typography>

        {printServicePlaces(() => {
          const row = [];
          for (var i = 0; i < servicePlaceCounter; i++) {
            row.push(
              <ServicePlace
                funeralHomeNames={funeralHomeNames}
                funeralHomeCounter={i}
                onChange={handleServicePlaceChange}
                onRemoval={handleServicePlaceRemoval}
                isLastOne={i === servicePlaceCounter - 1 && i !== 0}
                key={`${type}-service-${i}`}
                allowToSelectFuneralHome={shouldShowAddOneOption()}
                content={getServiceContent(i)}
              />
            );
          }
          return row;
        })}
        {shouldShowAddOneOption() && (
          <div
            onClick={() => setServicePlaceCounter(servicePlaceCounter + 1)}
            className={classes.add_more}
          >
            <Typography
              className={clsx(classes.add_more_link, classes.padding_left_32)}
              level="t3_text_3"
              color={ORANGE}
              underlined
            >
              {labels.ADD_MORE}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

ObituarySectionBlock.propTypes = {
  funeralHomeNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  type: PropTypes.string,
};

export default ObituarySectionBlock;
