import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    navbar: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.grayVariation.grayForBorders}`,
      overflowX: "auto",
      "& :last-child": {
        borderRight: "none",
      },
    },
    button: {
      borderRadius: "0px !important",
      padding: `${theme.spacing(1.25)}px 0px !important`,
      height: "100%",
      borderRight: `1px solid ${theme.palette.grayVariation.grayForBorders} !important`,
      minWidth: `${theme.spacing(16.5)}px !important`,
      flexGrow: 1,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: theme.palette.dark.black,
        fontWeight: 600,
      },
    },
  };
});

export { useStyles };
