import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    services_and_visitations_section: {
      width: "100%",
      height: theme.spacing(73.88),
      overflowY: "scroll",
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down(960)]: {
        marginBottom: theme.spacing(3),
      },
    },
    data_block: {
      height: "auto",
      width: "100%",
      backgroundColor: theme.palette.miscellaneous.grayDivider,
      borderRadius: "20px 20px 0px 20px",
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
      "& h5": {
        marginBottom: 0,
      },
      [theme.breakpoints.down(960)]: {
        "& h5": {
          fontSize: "16px !important",
        },
      },
    },
    location_icon: {
      height: theme.spacing(2),
      width: "auto",
      paddingLeft: theme.spacing(1),
    },
    view_in_google_maps_link: {
      "& a": {
        color: theme.palette.dark.gray,
        textDecoration: "none",
      },
      "&:hover": {
        color: theme.palette.dark.gray,
        textDecoration: "none !important",
      },
    },
    add_to_calendar_button_obituaries: {
      backgroundColor: `${theme.palette.grayVariation.grayForCredits} !important`,
      height: theme.spacing(3),
      paddingRight: `${theme.spacing(0.5)}px !important`,
      paddingLeft: `${theme.spacing(0.5)}px !important`,
      paddingBottom: "0 !important",
      paddingTop: `${theme.spacing(0.3)}px !important`,
      marginTop: theme.spacing(1),
      "&.addeventatc:hover": {
        backgroundColor: `${theme.palette.grayVariation.grayForCredits} !important`,
      },
      "& span.addeventatc_dropdown": {
        top: `-${theme.spacing(0.5)}px !important`,
        left: `-${theme.spacing(3.5)}px !important`,
      },
      "& span.addeventatc_dropdown.topdown.addeventatc-selected": {
        transform: "scale(0.75) translateY(0px) !important",
      },
    },
  };
});

export { useStyles };
