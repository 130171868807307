import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      minHeight: "67vh",
      paddingTop: theme.spacing(4),
    },
  };
});

export { useStyles };
