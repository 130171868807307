import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  exemption_dialog_title: {
    "&.MuiDialogTitle-root": {
      margin: 0,
      background: theme.palette.miscellaneous.white,
    },
  },
  exemption_dialog_close_button: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: "absolute",
    right: theme.spacing(2.5),
    cursor: "pointer",
    "&.MuiIconButton-root": {
      height: theme.spacing(4),
      width: theme.spacing(4),
      position: "absolute",
      right: theme.spacing(3),
      top: theme.spacing(3),
    },
  },
  exemption_dialog_back_button: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: "absolute",
    cursor: "pointer",
    "&.MuiIconButton-root": {
      height: theme.spacing(4),
      width: theme.spacing(4),
      position: "absolute",
      left: theme.spacing(3),
      top: theme.spacing(3),
    },
  },
}));

export { useStyles };
