import React from "react";
import PropTypes from "prop-types";
import Button from "../../../common/Button/Button";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import {
  getCategoriesLinks,
  getTextFromHtml,
  redirectToPage,
} from "../../../utils/helper";
import ListTypeContentBlock from "../../ContentBlocks/ListType/ListTypeContentBlock";
import { useStyles } from "./ClassifiedSingleBlock.style";
import SocialNetworkShareButtons from "../../SocialNetworkShareButtons/SocialNetworkShareButtons";

const ClassifiedSingleBlock = ({ classified, townSlug }) => {
  const classes = useStyles();

  const parseCategories = () => {
    return getCategoriesLinks(
      classified.categories || [],
      townSlug,
      false,
      {
        className: classes.categories,
        level: "t1_text_1",
      },
      "classifieds"
    );
  };

  return (
    <ListTypeContentBlock
      contentData={{
        name: getTextFromHtml(classified.name),
        path: classified.path,
        media: {
          photoUrl: classified.photo_url,
          videoUrl: classified.video_url,
          photoAltText: classified.photo_alt_text,
        },
      }}
      shouldShowReadMoreLink={false}
      body={
        <div className={classes.body_container}>
          <Typography
            level="t1_text_1"
            color="gray"
            bold
            className={classes.published_at}
          >
            {String(classified.published_at)}
          </Typography>
          <Typography level="t1_text_1">
            {getTextFromHtml(classified.excerpt)}
          </Typography>
          <div className={classes.categories_container}>
            {parseCategories()}
          </div>
        </div>
      }
      bottomActions={
        <>
          <div className={classes.bottom_section}>
            <div className={classes.social_networks_sharing}>
              <SocialNetworkShareButtons
                url={classified.path}
                title={classified.name}
                socialNetworks={["facebook", "twitter"]}
                iconsSize={30}
                paddingWithinButtons={10}
                containerWidth={140}
              />
            </div>

            <Button
              level="simple_button"
              color="primary"
              children={
                <Typography level="t1_text_1" color="white" bold>
                  {labels.READ_FULL_CLASSIFIED}
                </Typography>
              }
              classes={{ root: classes.button }}
              onClick={() => redirectToPage(classified.path, "_top")}
            />
          </div>
        </>
      }
      className={classes.single_block}
      videoContainerHeight={"160px"}
      titleProps={{
        color: "black",
        level: "t3_text_3",
        bold: true,
        underlined: false,
        className: classes.classified_link,
      }}
    />
  );
};

ClassifiedSingleBlock.propTypes = {
  classified: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    published_at: PropTypes.string,
    excerpt: PropTypes.string,
    photo_url: PropTypes.string,
    video_url: PropTypes.string,
    photo_alt_text: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
  }),
  townSlug: PropTypes.string,
};

export default ClassifiedSingleBlock;
