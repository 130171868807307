import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, up: above, values: values },
    mastheadHeights,
  } = theme;
  return {
    head_container: {
      [below(values.sm)]: {
        height: mastheadHeights.mobile,
        backgroundColor: "black",
        marginBottom: "0px !important",
      },
    },
  };
});

export { useStyles };
