import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    gray_row: {
      color: theme.palette.dark.gray,
      backgroundColor: theme.palette.miscellaneous.grayDivider,
      borderRadius: theme.spacing(0.2),
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
        0.5
      )}px ${theme.spacing(1)}px`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    white_row: {
      color: theme.palette.dark.gray,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
        0.5
      )}px ${theme.spacing(1)}px`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    facebook_blue: {
      color: theme.palette.miscellaneous.white,
      backgroundColor: theme.palette.facebook.facebookBlue,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
        0.5
      )}px ${theme.spacing(1)}px`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    town_name_container: {
      width: "50%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    state_name_container: {
      width: "30%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    town_price_container: {
      width: "10%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    trashcan_icon_container: {
      width: "auto",
      padding: `${theme.spacing(0.5)}px 0px 0px ${theme.spacing(1)}px`,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
});

export { useStyles };
