import React from "react";
import PropTypes from "prop-types";

const FacebookBox = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="24px"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor}
        d="M23.3616 0H3.63833C1.62898 0 0 1.6289 0 3.6383V23.3616C0 25.371 1.62891 26.9999 3.63833 26.9999H13.3658L13.3823 17.3516H10.8757C10.5499 17.3516 10.2855 17.0882 10.2843 16.7624L10.2723 13.6524C10.271 13.3248 10.5362 13.0587 10.8637 13.0587H13.3658V10.0536C13.3658 6.56619 15.4957 4.66728 18.6067 4.66728H21.1595C21.4861 4.66728 21.751 4.93207 21.751 5.25875V7.88115C21.751 8.2077 21.4863 8.47242 21.1598 8.47262L19.5932 8.47335C17.9014 8.47335 17.5738 9.2773 17.5738 10.4571V13.0587H21.2914C21.6456 13.0587 21.9204 13.368 21.8787 13.7198L21.51 16.8299C21.4748 17.1275 21.2224 17.3517 20.9227 17.3517H17.5903L17.5738 27H23.3617C25.3711 27 27 25.3711 27 23.3618V3.6383C26.9999 1.6289 25.371 0 23.3616 0Z"
      />
    </svg>
  );
};

FacebookBox.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

FacebookBox.defaultProps = {
  fillColor: "#475993",
};

export default FacebookBox;
