import React from "react";
import PropTypes from "prop-types";

const RealEstatesLogo = ({ className }) => {
  return (
    <svg
      className={className}
      width="120"
      height="69"
      viewBox="0 0 120 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_852_1480)">
        <path
          d="M1.159 60.694C1.69989 60.6174 2.47258 60.5408 3.24528 60.5408C4.40433 60.5408 5.09976 60.7707 5.64064 61.2304C6.02699 61.6135 6.2588 62.1499 6.2588 62.7629C6.2588 63.8357 5.56337 64.6019 4.71341 64.8318C5.40883 65.1383 5.79518 65.6747 5.94972 66.5176C6.2588 67.667 6.41334 68.4332 6.56788 68.7397H5.4861C5.33156 68.5099 5.17703 67.8202 4.94522 66.7475C4.71341 65.5981 4.24979 65.2149 3.24528 65.1383H2.24077V68.8164H1.159V60.694ZM2.24077 64.4487H3.32255C4.4816 64.4487 5.17702 63.8357 5.17702 62.9162C5.17702 61.8434 4.40433 61.3837 3.24528 61.3837C2.70439 61.3837 2.39531 61.4603 2.1635 61.4603V64.4487H2.24077Z"
          fill="#00222E"
        />
        <path
          d="M17.6948 64.9084H14.4495V67.8969H18.0811V68.8164H13.4449V60.5408H17.9266V61.4603H14.5267V64.0656H17.7721V64.9084H17.6948Z"
          fill="#00222E"
        />
        <path
          d="M26.2716 66.2111L25.4217 68.8164H24.3399L27.1216 60.6174H28.4352L31.2942 68.8164H30.1351L29.2852 66.2111H26.2716ZM29.0534 65.3682L28.2034 62.9928C28.0489 62.4565 27.8943 61.9967 27.7398 61.4603C27.5852 61.9201 27.508 62.4565 27.3534 62.9162L26.5035 65.2916H29.0534V65.3682Z"
          fill="#00222E"
        />
        <path
          d="M38.0168 60.5408H39.0986V67.8969H42.653V68.8164H38.0168V60.5408Z"
          fill="#00222E"
        />
        <path
          d="M61.8158 64.9084H58.5705V67.8969H62.2022V68.8164H57.566V60.5408H62.0476V61.4603H58.6478V64.0656H61.8158V64.9084Z"
          fill="#00222E"
        />
        <path
          d="M69.0019 67.5137C69.4655 67.8202 70.1609 68.0501 70.9336 68.0501C72.0154 68.0501 72.6336 67.5137 72.6336 66.6708C72.6336 65.9046 72.17 65.4448 71.0882 65.0617C69.6973 64.6019 68.9246 63.9123 68.9246 62.7629C68.9246 61.4603 70.0064 60.5408 71.6291 60.5408C72.479 60.5408 73.0972 60.7706 73.4835 60.9239L73.1745 61.7668C72.8654 61.6135 72.3245 61.3837 71.6291 61.3837C70.47 61.3837 70.0837 62.0733 70.0837 62.6097C70.0837 63.3759 70.6246 63.7591 71.7063 64.2188C73.0972 64.7552 73.7926 65.4448 73.7926 66.6708C73.7926 67.9735 72.8654 69.0462 70.8564 69.0462C70.0837 69.0462 69.1564 68.8164 68.7701 68.5099L69.0019 67.5137Z"
          fill="#00222E"
        />
        <path
          d="M82.3696 61.4603H79.8197V60.5408H86.0013V61.4603H83.4514V68.8164H82.3696V61.4603Z"
          fill="#00222E"
        />
        <path
          d="M92.8011 66.2111L91.9511 68.8164H90.8693L93.651 60.6174H94.9646L97.8236 68.8164H96.6646L95.8146 66.2111H92.8011ZM95.5828 65.3682L94.7328 62.9928C94.5783 62.4565 94.4237 61.9967 94.2692 61.4603C94.1147 61.9201 94.0374 62.4565 93.8828 62.9162L93.0329 65.2916H95.5828V65.3682Z"
          fill="#00222E"
        />
        <path
          d="M105.164 61.4603H102.614V60.5408H108.796V61.4603H106.246V68.8164H105.164V61.4603Z"
          fill="#00222E"
        />
        <path
          d="M119.614 64.9084H116.368V67.8969H120V68.8164H115.364V60.5408H119.845V61.4603H116.446V64.0656H119.691V64.9084H119.614Z"
          fill="#00222E"
        />
        <path
          d="M6.68633 37.6849H0V33.0859H19.115V37.6849H12.35V57.3072H6.68633V37.6849Z"
          fill="#82BE44"
        />
        <path
          d="M24.4762 51.0986L22.7014 57.3072H16.9911L24.4762 33.0859H31.7298L39.292 57.3072H33.3503L31.4983 51.0986H24.4762ZM30.6495 46.9595L29.1062 41.824C28.6432 40.3676 28.2573 38.6047 27.8715 37.1484H27.7943C27.4085 38.6047 27.0999 40.4443 26.6369 41.824L25.1707 46.9595H30.6495Z"
          fill="#82BE44"
        />
        <path
          d="M42.4779 32.5893C44.2199 32.2714 46.6745 32.033 50.1584 32.033C53.6423 32.033 56.1761 32.6688 57.8389 34.0199C59.4225 35.2916 60.531 37.3581 60.531 39.8219C60.531 42.2858 59.7392 44.3522 58.2348 45.7828C56.3345 47.6109 53.4048 48.4056 50.0792 48.4056C49.3666 48.4056 48.654 48.4056 48.1789 48.3262V57.3073H42.5571V32.5893H42.4779ZM48.0997 43.9548C48.5748 44.0343 49.2082 44.1138 50 44.1138C53.0089 44.1138 54.83 42.6037 54.83 39.9809C54.83 37.676 53.2464 36.3248 50.3959 36.3248C49.2082 36.3248 48.4956 36.4043 48.0205 36.5633V43.9548H48.0997Z"
          fill="#82BE44"
        />
        <path
          d="M66.9026 35.0725C66.9026 36.1422 66.278 36.9827 65.2785 36.9827C64.3415 36.9827 63.7168 36.1422 63.7168 35.0725C63.7168 33.9264 64.4039 33.0859 65.341 33.0859C66.278 33.0859 66.9026 33.9264 66.9026 35.0725ZM64.0291 57.3072V39.9626H66.5903V57.3072H64.0291Z"
          fill="#F58B38"
        />
        <path
          d="M72.3648 44.4757C72.3648 42.7085 72.3648 41.1718 72.2123 39.7887H75.0333L75.1858 42.7085H75.262C76.1007 41.0181 78.1592 39.4045 80.9802 39.4045C83.3437 39.4045 87.0796 40.8644 87.0796 46.8576V57.3072H83.9537V47.1649C83.9537 44.322 82.8863 42.0169 79.9128 42.0169C77.8543 42.0169 76.2532 43.4768 75.7195 45.244C75.567 45.6282 75.4908 46.1661 75.4908 46.7039V57.2304H72.3648V44.4757Z"
          fill="#F58B38"
        />
        <path
          d="M95.1472 34.1392V39.2268H99.823V41.6532H95.1472V51.2022C95.1472 53.3938 95.7812 54.6461 97.604 54.6461C98.4757 54.6461 99.1097 54.5678 99.506 54.4113L99.6645 56.8377C99.0305 57.0725 98.0002 57.3073 96.7322 57.3073C95.2265 57.3073 93.9585 56.8377 93.166 55.9767C92.215 55.0374 91.898 53.472 91.898 51.3587V41.7314H89.2035V39.305H91.9772V35.0784L95.1472 34.1392Z"
          fill="#F58B38"
        />
        <path
          d="M118.938 48.2054C118.938 54.524 114.499 57.3072 110.289 57.3072C105.621 57.3072 101.947 53.9222 101.947 48.5063C101.947 42.7895 105.774 39.4045 110.596 39.4045C115.494 39.4045 118.938 43.0152 118.938 48.2054ZM105.085 48.4311C105.085 52.1921 107.304 54.9753 110.366 54.9753C113.351 54.9753 115.647 52.1922 115.647 48.3559C115.647 45.4975 114.193 41.8116 110.442 41.8116C106.692 41.8116 105.085 45.1214 105.085 48.4311Z"
          fill="#F58B38"
        />
        <path
          d="M41.4159 8.0749L0 30.9798V23.3448L41.4159 0.439941V8.0749Z"
          fill="#83BF44"
        />
        <path
          d="M42.4779 8.0749L81.7699 30.9798V23.3448L42.4779 0.439941V8.0749Z"
          fill="#83BF44"
        />
        <path
          d="M77.5221 8.0749L118.938 30.9798V23.3448L77.5221 0.439941V8.0749Z"
          fill="#83BF44"
        />
        <path
          d="M111.504 9.91772H118.938V20.4487L111.504 16.8402V9.91772Z"
          fill="#83BF44"
        />
      </g>
      <defs>
        <clipPath id="clip0_852_1480">
          <rect width="120" height="68.8918" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

RealEstatesLogo.propTypes = {
  className: PropTypes.string,
};

export default RealEstatesLogo;
