import theme from "../../common/Theme/themes/default";
import { makeStyles } from "../../common/Theme/Theme";

export const defaultStyles = {
  base_container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: theme.palette.dark.gray,
    borderStyle: "dashed",
    backgroundColor: theme.palette.grayVariation.grayForBackground,
    color: theme.palette.dark.gray,
    outline: "none",
    transition: "border .24s ease-in-out",
    justifyContent: "center",
    textAlign: "center",
  },

  focused_container: {
    borderColor: theme.palette.light.blue,
  },

  accept_container: {
    borderColor: theme.palette.tapintoBranding.darkerGreen,
  },

  reject_container: {
    borderColor: theme.palette.miscellaneous.red,
  },
};

const useStyles = makeStyles((theme) => {
  return {
    button: {
      padding: `${theme.spacing(1)}px !important`,
      borderRadius: `${theme.spacing(1)}px !important`,
      flexGrow: 1,
      width: theme.spacing(30),
      marginTop: `${theme.spacing(3)}px !important`,
      marginBottom: `${theme.spacing(3)}px !important`,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "15px",
        fontFamily: "Montserrat, sans-serif",
        color: theme.palette.dark.black,
        fontWeight: 600,
      },
    },
    choose_photos_button: {
      border: `2px solid ${theme.palette.themeColor.secondary} !important`,
    },
    submit_button: {
      backgroundColor: `${theme.palette.themeColor.secondary} !important`,
    },
    delete_button: {
      border: `2px solid ${theme.palette.themeColor.secondary} !important`,
      marginRight: `${theme.spacing(2)}px !important`,
      "& .MuiButton-label": {
        color: theme.palette.themeColor.secondary,
      },
    },
    disabled_button: {
      backgroundColor: `${theme.palette.light.grey} !important`,
      border: "none !important",
      "& .MuiButton-label": {
        color: theme.palette.dark.gray,
      },
    },
    actions_container: {
      display: "flex",
      justifyContent: "space-between",
    },
    thumbs_container: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-evenly",
      backgroundColor: theme.palette.miscellaneous.white,
      color: theme.palette.dark.gray,
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
    },
    thumb: {
      display: "inline-flex",
      height: "100%",
      boxSizing: "border-box",
    },
    thumb_inner: {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    },
    img: {
      display: "block",
      width: "auto",
      maxHeight: "100%",
    },
    form: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      position: "relative",
    },
    facebook_thumb: {
      width: "600px",
      height: "315px",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: `1px solid ${theme.palette.grayVariation.grayForBorder}`,
      [theme.breakpoints.down(700)]: {
        width: "300px",
        height: "158px",
      },
    },
    alert_container: {
      marginBottom: theme.spacing(1),
    },
    row: {
      display: "flex",
    },
    instructions: {
      margin: "auto",
      width: "70%",
      paddingTop: theme.spacing(6),
    },
  };
});

export { useStyles };
