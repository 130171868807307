import React from "react";
import PropTypes from "prop-types";
import Typography from "../../common/Typography/Typography";
import labels from "../../config/labels";
import Link from "../../common/Link/Link";
import ContactInformationLink from "../ContactInformationLinks/ContactInformationLinks";
import { isValidString, isValidUrl } from "../../utils/helper";

const ContactInformationTemplate = ({ data, styleProps }) => {
  const showContactInformation = () => {
    if (
      data &&
      (isValidString(data.name) ||
        isValidString(data.phone) ||
        isValidString(data.email) ||
        isValidUrl(data.website) ||
        isValidUrl(data.facebook) ||
        isValidUrl(data.twitter) ||
        isValidUrl(data.instagram)
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    showContactInformation() && (
      <>
        {data.published_at && (
          <Typography
            level={styleProps.publishedAt.level}
            color={styleProps.publishedAt.color}
            bold={styleProps.publishedAt.bold}
            underlined={styleProps.publishedAt.underlined}
            className={styleProps.publishedAt.className}
          >
            {data.published_at}
          </Typography>
        )}

        <Typography
          level={styleProps.title.level}
          color={styleProps.title.color}
          bold={styleProps.title.bold}
          underlined={styleProps.title.underlined}
          className={styleProps.title.className}
        >
          {labels.CONTACT_INFORMATION}
        </Typography>

        <Typography
          level={styleProps.content.level}
          color={styleProps.content.color}
          underlined={styleProps.content.underlined}
          className={styleProps.content.className}
          bold={styleProps.content.bold}
        >
          {data.name}
        </Typography>

        <Link
          level={styleProps.links.level}
          color={styleProps.links.color}
          underlined={styleProps.links.underlined}
          className={styleProps.links.className}
          bold={styleProps.links.bold}
          url={`tel:${data.phone}`}
          children={data.phone}
          target="_blank"
          rel="noopener noreferrer"
        />

        <Link
          level={styleProps.links.level}
          color={styleProps.links.color}
          underlined={styleProps.links.underlined}
          className={styleProps.links.className}
          bold={styleProps.links.bold}
          url={`mailto:${data.email}`}
          children={data.email}
          target="_blank"
          rel="noopener noreferrer"
        />

        {isValidUrl(data.website) && (
          <ContactInformationLink type="website" url={data.website} />
        )}

        <div>
          {isValidUrl(data.facebook) && (
            <ContactInformationLink type="facebook" url={data.facebook} />
          )}

          {isValidUrl(data.twitter) && (
            <ContactInformationLink
              type="twitter"
              url={data.twitter}
              isSecondarySocialLink={isValidUrl(data.facebook)}
            />
          )}

           {isValidUrl(data.instagram) && ( 
            <ContactInformationLink
              type="instagram"
              url={data.instagram}
              isSecondarySocialLink={
                isValidUrl(data.facebook) || isValidUrl(data.twitter)
              }
            />
          )} 
        </div>
      </>
    )
  );
};
ContactInformationTemplate.propTypes = {
  data: PropTypes.shape({
    published_at: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
  }),
  styleProps: PropTypes.shape({
    title: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      bold: PropTypes.bool,
      underlined: PropTypes.bool,
    }),
    links: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      bold: PropTypes.bool,
      underlined: PropTypes.bool,
    }),
    content: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      bold: PropTypes.bool,
      underlined: PropTypes.bool,
    }),
    publishedAt: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      bold: PropTypes.bool,
      underlined: PropTypes.bool,
    }),
  }),
};

ContactInformationTemplate.defaultProps = {
  styleProps: {
    title: {
      level: "h3_sub_header",
      color: "tapintoOrange",
      bold: true,
      underlined: false,
      className: "",
    },
    links: {
      level: "paragraph_1",
      color: "black",
      bold: false,
      underlined: true,
      className: "",
    },
    content: {
      level: "h3_sub_header",
      color: "black",
      bold: false,
      underlined: false,
      className: "",
    },
    publishedAt: {
      level: "t2_text_2",
      color: "gray",
      bold: true,
      underlined: false,
      className: "",
    },
  },
};

export default ContactInformationTemplate;
