import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { useStyles } from "./Navbar.style";
import Link from "../Link/Link";

const Navbar = ({ items, options, customClasses }) => {
  const classes = useStyles();

  const executeScroll = (elementId) => {
    const element = document.getElementById(elementId);

    element.scrollIntoView({
      behavior: "smooth",
      block: options.isMobile ? "start" : "nearest",
    });
  };

  return (
    <nav
      className={clsx(
        classes.navbar,
        customClasses?.container && customClasses.container
      )}
    >
      {items.map((item) => (
        <Fragment key={item.section_id}>
          {options.scroll ? (
            <Button
              level="simple_button"
              color="primary"
              children={item.title}
              onClick={() => executeScroll(item.section_id)}
              classes={{
                root: clsx(
                  classes.button,
                  classes.button_label,
                  customClasses?.button && customClasses.button
                ),
              }}
              ariaLabel={`navbar-button-${item.section_id}`}
            />
          ) : (
            <Link
              level="paragraph_2"
              className={customClasses?.link ? customClasses.link : ""}
              children={item.title}
              url={item.url}
            />
          )}
        </Fragment>
      ))}
    </nav>
  );
};

Navbar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      section_id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  options: PropTypes.shape({
    scroll: PropTypes.bool,
    redirect: PropTypes.bool,
    isMobile: PropTypes.bool,
  }),
  customClasses: PropTypes.shape({
    container: PropTypes.string,
    button: PropTypes.string,
    link: PropTypes.string,
  }),
};

Navbar.defaultProps = {
  options: {
    scroll: true,
    redirect: false,
    isMobile: false,
  },
};

export default Navbar;
