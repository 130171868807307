import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    button: {
      borderRadius: "0px !important",
      padding: `${spacing(0.5)}px ${spacing(3)}px !important`,
      backgroundColor: `${palette.themeColor.primary} !important`,
      width: "fit-content",
      textTransform: "none !important",
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: palette.dark.black,
      },
    },
    button_icon: {
      position: "relative !important",
      padding: "0px !important",
    },
    date_picker: {
      margin: "0px !important",
    },
    scheduled_dates: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      rowGap: spacing(2),
      marginTop: spacing(2),
      marginBottom: spacing(4),
    },
    date_container: {
      display: "flex",
      alignItems: "stretch",
      columnGap: spacing(2),
    },
    time_picker: {
      "&.MuiFormControl-marginNormal": {
        marginRight: theme.spacing(5),
        marginTop: 0,
        [theme.breakpoints.down(900)]: {
          marginRight: 0,
        },
      },
      "& div.MuiInputBase-root": {
        "& input.MuiInputBase-input": {
          padding: 0,
          paddingLeft: theme.spacing(0.75),
        },
      },
    },
  };
});
export { useStyles };
