import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ShoppingSummary.style";
import {
  updateSummaryComponentListener,
  setOpenMobileShoppingCart,
  updateFacebookOptionListener,
} from "../../../utils/listeners";
import IconBadge from "../../../common/IconBadge/IconBadge";
import ShoppingCartIcon from "../../../common/Icons/ShoppingCartIcon";
import ButtonIcon from "../../../common/Button/ButtonIcon";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import { getCurrentScrollPosition } from "../../../hooks/useScrollPosition";
import clsx from "clsx";
import {
  LETTERS_TO_THE_EDITOR,
  SELECTED_TOWNS,
  COLUMN,
  MILESTONE,
  GRAY,
  POST_ON_FACEBOOK,
} from "../diyConstants";
import cache from "../../../utils/cache";
import ShoppingCart from "./ShoppingCart/ShoppingCart";

const ShoppingSummary = ({
  isMobileView,
  currentScrollPosition,
  form_type,
  is_edit_form,
  item_count_for_event_draft,
  town,
}) => {
  const classes = useStyles();
  const [townsSelected, setTownSelected] = useState(
    isMobileView ? cache.get(SELECTED_TOWNS) : []
  );
  const [openMobileShoppingSummary, setOpenMobileShoppingSummary] =
    useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [isFacebookOptionSelected, setIsFacebookOptionSelected] = useState(
    cache.get(POST_ON_FACEBOOK) === 1
  );

  setOpenMobileShoppingCart(setOpenMobileShoppingSummary);
  updateSummaryComponentListener(setTownSelected);

  // This runs third when clicking Facebook checkbox
  updateFacebookOptionListener(setIsFacebookOptionSelected);

  const getShoppingCartBadgeContent = () => {
    if (townsSelected.length <= 0) {
      return "0";
    } else {
      return townsSelected.length;
    }
  };

  return (
    <>
      {![LETTERS_TO_THE_EDITOR, COLUMN, MILESTONE].includes(form_type) && (
        <>
          {isMobileView ? (
            <>
              <ButtonIcon
                onClick={() => setOpenMobileShoppingSummary(true)}
                className={clsx(
                  getCurrentScrollPosition() > currentScrollPosition &&
                    classes.sticky_shopping_cart,
                  classes.shopping_cart_button_icon
                )}
                ariaLabel="Shopping Summary"
              >
                <IconBadge
                  icon={<ShoppingCartIcon />}
                  badgeContent={getShoppingCartBadgeContent()}
                />
              </ButtonIcon>
              <PopUpDialog
                onClose={() => setOpenMobileShoppingSummary(false)}
                openState={openMobileShoppingSummary}
                title={""}
                content={
                  <ShoppingCart
                    town={town}
                    itemCountForEventDraft={item_count_for_event_draft}
                    isMobileView={isMobileView}
                    townsSelected={townsSelected}
                    formType={form_type}
                    loadingCheckout={loadingCheckout}
                    setLoadingCheckout={setLoadingCheckout}
                    showFacebookBlock={isFacebookOptionSelected}
                    isEditForm={is_edit_form}
                    setOpenMobileShoppingCart={setOpenMobileShoppingCart}
                  />
                }
                titleClass={classes.dialog_title}
                closeButtonColor={GRAY}
                closeButtonClass={classes.dialog_close_button}
              />
            </>
          ) : (
            <ShoppingCart
              town={town}
              itemCountForEventDraft={item_count_for_event_draft}
              isMobileView={isMobileView}
              townsSelected={townsSelected}
              formType={form_type}
              loadingCheckout={loadingCheckout}
              setLoadingCheckout={setLoadingCheckout}
              showFacebookBlock={isFacebookOptionSelected}
              isEditForm={is_edit_form}
              setOpenMobileShoppingCart={setOpenMobileShoppingCart}
            />
          )}
        </>
      )}
    </>
  );
};

ShoppingSummary.propTypes = {
  isMobileView: PropTypes.bool,
  currentScrollPosition: PropTypes.number,
  form_type: PropTypes.string,
  is_edit_form: PropTypes.bool,
  item_count_for_event_draft: PropTypes.number,
};

ShoppingSummary.defaultProps = {
  isMobileView: false,
};

export default ShoppingSummary;
