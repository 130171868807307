import React, { useEffect, useState } from "react";
import clsx from "clsx";
import "date-fns";
import PropTypes from "prop-types";
import { useStyles } from "./DateRangePicker.style";
import Typography from "../../common/Typography/Typography";
import labels from "../../config/labels";
import DatePicker from "../../common/DatePicker/DatePicker";
import { cloneWithoutReferences } from "../../utils/helper";

const BLACK = "black";

const DateRangePicker = ({
  className,
  label,
  labelStyleProps,
  onDateRangeChange,
  defaultStartValue,
  defaultEndValue,
  endIconFillColor,
}) => {
  const classes = useStyles();
  const [dateRange, setDateRange] = useState({
    start: defaultStartValue,
    end: defaultEndValue,
  });

  const handleDateChange = (date, dateType) => {
    let newDate = cloneWithoutReferences(dateRange);
    if (dateType === "start") {
      newDate.start = date;
    } else {
      newDate.end = date;
    }
    setDateRange(newDate);
  };

  useEffect(() => {
    onDateRangeChange(dateRange);
  }, [dateRange]);

  return (
    <>
      {label && (
        <Typography
          level={labelStyleProps.level}
          color={labelStyleProps.color}
          bold={labelStyleProps.bold}
          className={labelStyleProps.className}
        >
          {label}
        </Typography>
      )}
      <div className={classes.container}>
        <DatePicker
          className={clsx(classes.width_45, className && className)}
          onChange={(date) => handleDateChange(date, "start")}
          defaultValue={defaultStartValue}
          disablePast={false}
          endIconFillColor={endIconFillColor}
        />
        <Typography
          level={labelStyleProps?.level ? labelStyleProps.level : "paragraph_1"}
          color={labelStyleProps?.color ? labelStyleProps.color : BLACK}
          bold={labelStyleProps?.bold ? labelStyleProps.bold : false}
          className={
            labelStyleProps?.className ? labelStyleProps.className : classes.to
          }
        >
          {labels.TO}
        </Typography>
        <DatePicker
          className={clsx(classes.width_45, className && className)}
          onChange={(date) => handleDateChange(date, "end")}
          defaultValue={defaultEndValue}
          disablePast={false}
          endIconFillColor={endIconFillColor}
        />
      </div>
    </>
  );
};

DateRangePicker.propTypes = {
  className: PropTypes.any,
  label: PropTypes.string,
  labelStyleProps: PropTypes.shape({
    level: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
    className: PropTypes.any,
  }),
  onDateRangeChange: PropTypes.func,
  defaultStartValue: PropTypes.any,
  defaultEndValue: PropTypes.any,
  endIconFillColor: PropTypes.string,
};

export default DateRangePicker;
