import withConfig from "../utils/withConfig";
import restClient from "../utils/restClient";

const today = new Date();
const aYearAgo = new Date(today.setDate(today.getDate() - today.getDay() - 1));

export const handleSearch = async (
  townId,
  dateRange,
  setMainObituary,
  setObituariesData,
  setIsSearch
) => {
  try {
    const response = await restClient.get(withConfig("GET_OBITUARIES"), {
      townId,
      dateRange,
    });

    if (dateRange?.start !== undefined && dateRange?.end !== null) {
      setIsSearch(true);
      setObituariesData(response?.data?.obituaries);
    } else {
      setMainObituary(response?.data?.obituaries[0]);
      setObituariesData(
        response?.data?.obituaries.filter(
          (obituary) => obituary.id !== response?.data?.obituaries[0].id
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const buildDropdownObjects = (obituariesArray) => {
  return obituariesArray.map((obituary) => {
    return {
      id: obituary.slug,
      name: obituary.name,
    };
  });
};

export const getNamesForDropdownSearch = async (
  townId,
  currentObitId,
  setNames
) => {
  try {
    let names;
    let foundObitName;

    const response = await restClient.get(
      withConfig("GET_ALL_OBITUARIES_NAMES"),
      {
        townId,
      }
    );

    if (currentObitId !== undefined && currentObitId !== null) {
      foundObitName = response?.data?.all_obituaries_names.find(
        (obituary_name) => obituary_name.id === currentObitId
      );
    }

    names = buildDropdownObjects(
      foundObitName
        ? response?.data?.all_obituaries_names.filter(
            (obituary_name) => foundObitName.id !== obituary_name.id
          )
        : response?.data?.all_obituaries_names
    );
    setNames(names);
  } catch (error) {
    console.log(error);
  }
};

const getServicesBlockTitle = (obituaryServiceType) => {
  switch (obituaryServiceType) {
    case "ObituaryServiceService":
      return "Service";
    case "ObituaryServiceVisitation":
      return "Visitation";
    case "ObituaryServiceBurial":
      return "Burial";
    case "ObituaryServiceCremation":
      return "Cremation";
  }
};

const createObituaryServiceObject = (obituaryService) => {
  return {
    name: obituaryService.name,
    type: getServicesBlockTitle(obituaryService.type),
    address: obituaryService.address,
    time_from: obituaryService.time_from,
    time_to: obituaryService.time_to,
    address: obituaryService.address,
    date: obituaryService.date,
    time_from_standard: obituaryService.time_from_standard,
    time_to_standard: obituaryService.time_to_standard,
  };
};

export const getObituaryServices = async (obituaryId, setServices) => {
  try {
    const response = await restClient.get(withConfig("GET_OBITUARY_SERVICES"), {
      obituaryId,
    });

    if (response?.data?.obituary_services?.length > 0) {
      let processedServicesData = [];

      response?.data?.obituary_services.map((obituaryService) => {
        processedServicesData.push(
          createObituaryServiceObject(obituaryService)
        );
      });

      setServices(processedServicesData);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getObituaryServicesPreview = async (
  obituaryPreviewServices,
  setServices
) => {
  try {
    let processedServicesData = [];
    let obituary_preview_services = JSON.parse(obituaryPreviewServices);
    obituary_preview_services.map((obituaryService) => {
      processedServicesData.push(createObituaryServiceObject(obituaryService));
    });
    setServices(processedServicesData);
  } catch (error) {
    console.log(error);
  }
};

export const printDates = ({ birth, death }) => {
  let dates = birth || "";

  if (birth && death) {
    dates += " — ";
  }

  dates += death || "";

  return dates;
};
