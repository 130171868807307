import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { values, down: below },
    mastheadHeights,
  } = theme;

  return {
    container: {
      display: "flex",
      justifyContent: "center",
      columnGap: spacing(8),
      margin: "0px auto",
      paddingTop: spacing(2),
      [below(values.md)]: {
        columnGap: 0,
        paddingTop: 0,
        paddingLeft: spacing(5),
        paddingRight: spacing(5),
        flexWrap: "wrap",
      },
    },
    masthead_padding: {
      paddingTop: `calc(${mastheadHeights.overall_desktop} + ${spacing(2)}px)`,
      [below(values.md)]: {
        paddingTop: `calc(${mastheadHeights.overall_mobile} + ${spacing(2)}px)`,
      },
    },
    page: {
      maxWidth: spacing(105),
      [below(values.md)]: {
        width: "100%",
        overflowX: "auto",
      },
    },
    right_blocks: {
      width: "320px",
      [below(values.md)]: {
        width: "100%",
      },
    },
    body: {
      paddingTop: spacing(3),
      "& p": {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
      },
      "& p > a": {
        color: palette.themeColor.secondary,
      },
      "& table": {
        width: "100% !important",
      },
      [below(values.xs)]: {
        "& div > iframe": {
          width: "100%",
        },
      },
    },
  };
});

export { useStyles };
