import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ArticleBox from "../ArticleBox/ArticleBox";
import { useStyles } from "./ArticlesList.style";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";

const ArticleList = ({ articles, isForArticlesIndex }) => {
  const classes = useStyles();

  const shouldShowDividerAfterThreeArticles = (articleIndex) => {
    return articleIndex % 3 === 2;
  };

  return (
    <div className={classes.column_articles_container}>
      {articles.map((article, index) => (
        <Fragment key={index}>
          <ArticleBox
            article={article}
            isForArticlesIndex={isForArticlesIndex}
          />
          {shouldShowDividerAfterThreeArticles(index) && isForArticlesIndex && (
            <HorizontalDivider className={classes.divider} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
      photo_url: PropTypes.string,
    })
  ),
  isForArticlesIndex: PropTypes.bool,
};

export default ArticleList;
