import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./EventsList.style";
import EventBlock from "../../../components/Event/EventBlock/EventBlock";
import EventListHeader from "../../../components/Event/EventListHeader/EventListHeader";
import labels from "../../../config/labels";
import clsx from "clsx";
import { getSubmitNewEventButton } from "../../../services/events";
import restClient from "../../../utils/restClient";
import withConfig from "../../../utils/withConfig";
import Pagination from "../../../common/Pagination/Pagination";
import usePagination from "../../../hooks/usePagination";
import Sponsor from "../../../components/Sponsor/Sponsor";

const PER_PAGE = 20;

const EventsList = ({ town, town_slug, sponsor_info, end_date }) => {
  const classes = useStyles();
  const [startDateValue] = useState(new Date());
  const [endDateValue] = useState(end_date);
  const [eventsData, setEventsData] = useState([]);
  const [categories, setCategories] = useState(undefined);

  let [page, setPage] = useState(1);

  const count = Math.ceil(eventsData.length / PER_PAGE);
  const items = usePagination(eventsData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    items.jump(p);
  };

  const handleSubmitSearch = async (categories, dateRange) => {
    const response = await restClient.get(withConfig("GET_EVENTS"), {
      categories,
      dateRange,
      townId: town.id,
    });
    setEventsData(response?.data?.events);
  };

  useEffect(() => {
    const loadCategories = async () => {
      const response = await restClient.get(
        withConfig("GET_CATEGORIES_FOR_EVENTS"),
        {
          townId: town.id,
        }
      );

      setCategories(response?.data?.categories);
    };

    if (categories === undefined) {
      loadCategories();
    }
  }, [categories]);

  const printSponsor = () => {
    if (sponsor_info !== null) {
      return (
        <div className={classes.sponsor_container}>
          <Sponsor sponsor_info={sponsor_info} />
        </div>
      );
    }
  };

  return (
    <>
      {printSponsor()}
      <EventListHeader
        townName={town?.name}
        eventCategories={categories !== undefined ? categories : []}
        startDateValue={startDateValue}
        endDateValue={endDateValue}
        onSubmitSearch={handleSubmitSearch}
      />
      <div className={classes.events_list}>
        {items.currentData().map((event, id) => {
          return (
            <Fragment key={id}>
              {id === 6 && (
                <div className={classes.submit_event} key={`button-${id}`}>
                  {getSubmitNewEventButton(labels.SUBMIT_AN_EVENT)}
                </div>
              )}
              <EventBlock event={event} townSlug={town_slug} />
            </Fragment>
          );
        })}
      </div>
      <div className={classes.bottom_container}>
        <div className={clsx(classes.submit_event, classes.submit_to_left)}>
          {getSubmitNewEventButton(labels.SUBMIT_AN_EVENT)}
        </div>
        {eventsData.length > PER_PAGE && (
          <div className={classes.pagination_container}>
            <Pagination
              count={count}
              page={page}
              onChange={handleChange}
              getItemAriaLabel={(type) => {
                console.log({ type });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

EventsList.propTypes = {
  town: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  town_slug: PropTypes.string,
  end_date: PropTypes.instanceOf(Date),
};

export default EventsList;
