import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useStyles } from "./TownSelectorByState.style";
import CheckboxGroup from "../../../common/CheckboxGroup/CheckboxGroup";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { buildStateObject } from "../../../services/townService";
import ShoppingSummary from "../ShoppingSummary/ShoppingSummary";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../../hooks/useMediaQuery";
import TownSearch from "../TownSearch/TownSearch";
import {
  CANDIDATE_STATEMENTS,
  CLASSIFIED,
  LEGAL_NOTICE,
  LETTERS_TO_THE_EDITOR,
  MILESTONE,
  REAL_ESTATE_LISTING,
} from "../diyConstants";

const DESKTOP_STATE_NAME = 0;
const MOBILE_STATE_NAME = 1;
const SHOPPING_CART_ICON_HEIGHT = 59;

const TownSelectorByState = ({
  title,
  subTitle,
  originalTownsByState,
  handleTownSelectionByCounties,
  counties,
  isAdvertiser,
  isContributorWithGrantedExemptions,
  grantedExemptionTownNames,
  advertiserTownNames,
  formType,
  handleCountySelection,
  town,
}) => {
  const classes = useStyles();
  const [townsByState] = useState(originalTownsByState);

  const [selectableTabs, setSelectableTabs] = useState(
    townsByState.map((townByState, index) => {
      return buildStateObject(townByState, index === 0);
    })
  );
  const [isSearchCollapsed, setIsSearchCollapsed] = useState(false);

  useEffect(() => {
    setSelectableTabs(
      townsByState.map((townByState, index) => {
        if (index === 0) {
          return buildStateObject(townByState, true);
        }
        return buildStateObject(townByState, false);
      })
    );
  }, [townsByState]);

  const [selectedState, setSelectedState] = useState(selectableTabs[0].state);
  const [scrollPosition, setScrollPosition] = useState(0);
  const selectorByStateHeaderRef = useRef();

  const shouldSelectTab = (stateName) => {
    const itemFound = selectableTabs.find((item) => item.state === stateName);
    return itemFound.isSelected;
  };

  const toggleSelectStatus = (stateName) => {
    const items = selectableTabs.map((selectableTab) => {
      if (selectableTab.state === stateName) {
        return buildStateObject(selectableTab, true);
      }
      return buildStateObject(selectableTab, false);
    });
    setSelectableTabs(items);
  };

  const getTownsByCountyOfSelectedState = (stateName) => {
    const itemFound = selectableTabs.find((item) => item.state === stateName);
    return itemFound.counties;
  };

  const handleTownsSelected = (countyId, isChecked) => {
    if (typeof countyId === "string") {
      handleCountySelection(countyId, isChecked);
    }
  };

  const handleTownSelection = (countyId, town) => {
    handleTownSelectionByCounties(town);
  };

  const getMobileStateName = (stateName) => {
    return `${stateName} sites`;
  };

  useEffect(() => {
    const offsetTop =
      selectorByStateHeaderRef.current.offsetTop +
      (selectorByStateHeaderRef.current.offsetHeight -
        SHOPPING_CART_ICON_HEIGHT);
    setScrollPosition(offsetTop);
  }, [selectorByStateHeaderRef]);

  const shouldShowExemptionMessage = () => {
    switch (formType) {
      case LETTERS_TO_THE_EDITOR:
      case MILESTONE:
      case CLASSIFIED:
      case REAL_ESTATE_LISTING:
      case CANDIDATE_STATEMENTS:
        return false;
      default:
        return true;
    }
  };

  const shouldShowAdvertiserMessage = () => {
    switch (formType) {
      case LETTERS_TO_THE_EDITOR:
      case MILESTONE:
        return false;
      case LEGAL_NOTICE:
        return advertiserTownNames?.length > 0;
      default:
        return true;
    }
  };

  const getFreeTownsMessage = () => {
    if (
      grantedExemptionTownNames &&
      grantedExemptionTownNames.length > 0 &&
      isContributorWithGrantedExemptions &&
      shouldShowExemptionMessage()
    ) {
      return (
        labels.EXEMPTION_SITES_MESSAGE_CONTRIBUTOR_1 +
        grantedExemptionTownNames.map((name) => " " + name) +
        labels.EXEMPTION_SITES_MESSAGE_CONTRIBUTOR_2
      );
    } else if (
      advertiserTownNames &&
      advertiserTownNames.length > 0 &&
      isAdvertiser &&
      shouldShowAdvertiserMessage()
    ) {
      return (
        labels.EXEMPTION_SITES_MESSAGE_ADVERTISER_1 +
        advertiserTownNames.map((name) => " " + name) +
        labels.EXEMPTION_SITES_MESSAGE_ADVERTISER_2
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <div ref={selectorByStateHeaderRef} className={classes.header_container}>
        <div className={classes.title_container}>
          <Typography
            level="h3_sub_header"
            className={classes.title}
            color="tapintoGreen"
            bold
          >
            {title}
          </Typography>
        </div>
        {screenWidthIsHigherThan(741) && (
          <div className={classes.exemption_disclaimer_container_desktop}>
            <Typography level="medium_12_px" color="gray">
              {getFreeTownsMessage()}
            </Typography>
          </div>
        )}
        {screenWidthIsLowerThan(740) && (
          <ShoppingSummary
            currentScrollPosition={scrollPosition}
            isMobileView={true}
            form_type={formType}
            town={town}
          />
        )}
      </div>
      {screenWidthIsLowerThan(741) && (
        <div className={classes.exemption_disclaimer_container_mobile}>
          <Typography level="medium_12_px" color="gray">
            {getFreeTownsMessage()}
          </Typography>
        </div>
      )}
      <div className={classes.state_tabs_container}>
        {townsByState.map((townByState, index) => {
          if (townByState.counties.length > 0) {
            return (
              <div
                key={`state-tab-${index}`}
                className={clsx(
                  classes.unselected_state_tab_highlight,
                  shouldSelectTab(townByState.state) &&
                    classes.selected_state_tab_highlight
                )}
              >
                <div
                  onClick={() => {
                    toggleSelectStatus(townByState.state),
                      setSelectedState(townByState.state);
                  }}
                  className={clsx(
                    classes.unselected_state_tab,
                    shouldSelectTab(townByState.state) &&
                      classes.selected_state_tab
                  )}
                >
                  <Typography
                    level="t3_text_3"
                    color="white"
                    bold
                    className={classes.state_label}
                  >
                    {town?.under_license_contract
                      ? townByState.state[DESKTOP_STATE_NAME]
                      : screenWidthIsLowerThan(600)
                      ? getMobileStateName(townByState.state[MOBILE_STATE_NAME])
                      : townByState.state[DESKTOP_STATE_NAME] + labels.SITES}
                  </Typography>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div
        className={clsx(
          classes.general_container,
          isSearchCollapsed && classes.general_container_augmented_height
        )}
      >
        <div
          className={clsx(
            classes.towns_by_county_container,
            isSearchCollapsed &&
              classes.towns_by_county_container_augmented_height
          )}
        >
          <div className={classes.subtitle_container}>
            <Typography level="t4_text_4" color="tapintoGreen" bold>
              {subTitle}
            </Typography>
          </div>
          <CheckboxGroup
            onChange={handleTownsSelected}
            onChildChange={handleTownSelection}
            items={getTownsByCountyOfSelectedState(selectedState)}
            withSelectorOnMainLevel={false}
          />
        </div>
        <div className={classes.search_container}>
          <TownSearch
            placeholder={
              town?.under_license_contract
                ? labels.SEARCH_FOR_A_PUBLICATION
                : labels.SEARCH_TAPINTO_SITE
            }
            itemsToSearchFrom={getTownsByCountyOfSelectedState(selectedState)}
            selectedState={selectedState}
            handleResultSelection={handleTownsSelected}
            areThereSearchResults={setIsSearchCollapsed}
            onChildChange={handleTownSelection}
            town={town}
          />
        </div>
      </div>
    </>
  );
};

TownSelectorByState.propTypes = {
  title: PropTypes.string,
  originalTownsByState: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.arrayOf(PropTypes.string),
      counties: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
          selected: PropTypes.bool,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              label: PropTypes.string,
              state: PropTypes.arrayOf(PropTypes.string),
              price: PropTypes.number,
              selected: PropTypes.bool,
            })
          ),
        })
      ),
    })
  ),
  handleTownSelectionByCounties: PropTypes.func,
  isAdvertiser: PropTypes.bool,
  isContributorWithGrantedExemptions: PropTypes.bool,
  grantedExemptionTownNames: PropTypes.arrayOf(PropTypes.string),
  advertiserTownNames: PropTypes.arrayOf(PropTypes.string),
  formType: PropTypes.string,
};

export default TownSelectorByState;
