import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import RealEstatesHeader from "../../../components/RealEstateListing/Header/Header";
import RealEstatesIndexCards from "../../../components/RealEstateListing/IndexCards/IndexCards";
import { useStyles } from "./Index.style";
import RealEstateListingFilterModal from "../../../components/Modals/RealEstateListingFilterModal/RealEstateListingFilterModal";
import RealEstatesFinder from "../../../components/RealEstateListing/Finder/Finder";
import LazyLoadCards from "../../../common/LazyLoadCards/LazyLoadCards";
import { getRealEstates } from "../../../services/realEstates";
import Button from "../../../common/Button/Button";
import labels from "../../../config/labels";
import clsx from "clsx";
import IndexSubheader from "../../../components/RealEstateListing/IndexSubheader/IndexSubheader";
import RealEstatesNoResultsFound from "./RealEstatesNoResultsFound";
import { useTown, withTownContextProvider } from "../../../context/TownContext";

const RealEstatesIndex = ({
  filters_params,
  pagination_params,
  all_towns,
  all_categories,
  current_town,
  sponsor_info,
  town_slug,
}) => {
  const classes = useStyles();
  const [openRealEstateFilterDialog, setOpenRealEstateFilterDialog] =
    useState(false);
  const handleOpenRealEstateFilterDialog = (state) => {
    setOpenRealEstateFilterDialog(state);
  };

  const [loadingRealEstateListings, setLoadingRealEstateListings] =
    useState(false);
  const [realEstateListings, setRealEstateListings] = useState([]);
  const [realEstateListingsPagination, setRealEstateListingsPagination] =
    useState(null);
  const { setTownSlug, isHalstonMedia } = useTown();

  const getRealEstateList = async () => {
    setLoadingRealEstateListings(true);
    const response = await getRealEstates(filters_params, pagination_params);
    if (response && response?.status === 200) {
      if (pagination_params.page == 0) {
        setRealEstateListings([]);
        setRealEstateListings(response?.data?.real_estates);
      } else {
        setRealEstateListings(
          realEstateListings.concat(response?.data?.real_estates)
        );
      }

      setRealEstateListingsPagination(response?.data?.pagination_params);
      setLoadingRealEstateListings(false);
    }
  };

  const loadRealEstatePage = async () => {
    pagination_params.page = pagination_params.page + 1;
    await getRealEstateList();
  };

  const scrollResultsToTop = () => {
    const finderHeight = document.getElementById(
      "real-estates-finder"
    ).offsetHeight;
    window.scrollTo({ top: finderHeight, behavior: "smooth" });
  };

  const loadRealEstateByFilter = async () => {
    setLoadingRealEstateListings(true);
    pagination_params.page = 0;
    await getRealEstateList();
    setLoadingRealEstateListings(false);
    scrollResultsToTop();
  };

  useEffect(() => {
    getRealEstateList();
  }, []);

  useEffect(() => {
    if (town_slug) {
      setTownSlug(town_slug);
    }
    () => setTownSlug(null);
  }, [town_slug]);

  return (
    <div>
      <RealEstatesFinder
        handleOpenFilterModal={() => handleOpenRealEstateFilterDialog(true)}
        handleRealEstateByFilter={() => loadRealEstateByFilter()}
        filtersParams={filters_params}
        allTowns={all_towns}
        allCategories={all_categories}
        currentTown={current_town}
      />

      <div
        className={clsx(
          classes.main_container,
          isHalstonMedia && classes.margin_top
        )}
      >
        <RealEstateListingFilterModal
          openState={openRealEstateFilterDialog}
          handleClose={() => handleOpenRealEstateFilterDialog(false)}
          handleRealEstateByFilter={() => loadRealEstateByFilter()}
          filtersParams={filters_params}
        />

        {!isHalstonMedia && (
          <RealEstatesHeader forIndex={true} sponsorInfo={sponsor_info} />
        )}

        <IndexSubheader
          townName={current_town.name}
          filtersParams={filters_params}
        />

        {realEstateListings.length >= 1 ? (
          <RealEstatesIndexCards realEstates={realEstateListings} />
        ) : (
          !loadingRealEstateListings && <RealEstatesNoResultsFound />
        )}

        {loadingRealEstateListings && <LazyLoadCards />}

        {realEstateListingsPagination &&
          realEstateListingsPagination.page <
            realEstateListingsPagination.total_pages && (
            <div className={classes.button_container}>
              <Button
                level="simple_button"
                color="primary"
                children={labels.SEE_MORE_LISTINGS}
                onClick={() => loadRealEstatePage()}
                classes={{
                  root: clsx(classes.button, classes.button_label),
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

RealEstatesIndex.propTypes = {
  filters_params: PropTypes.shape({
    town_id: PropTypes.number,
    category: PropTypes.any,
    property_status: PropTypes.string,
    min_price: PropTypes.number,
    max_price: PropTypes.number,
    number_of_bedrooms: PropTypes.number,
    number_of_bathrooms: PropTypes.number,
  }),
  pagination_params: PropTypes.shape({
    page: PropTypes.number,
    per: PropTypes.number,
  }),
  town_name: PropTypes.string,
  sponsor_info: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  town_slug: PropTypes.string,
};

export default withTownContextProvider(RealEstatesIndex);
