import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    header: {
      color: theme.palette.themeColor.primary,
      [theme.breakpoints.down(930)]: {
        fontSize: "35px",
      },
      [theme.breakpoints.down(500)]: {
        fontSize: "26px",
      },
    },
    form: {
      width: "60%",
      height: "40vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down(500)]: {
        width: "90%",
      },
    },
    button_container: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      width: "40% !important",
      [theme.breakpoints.down(500)]: {
        width: "100% !important",
      },
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: theme.palette.miscellaneous.white,
        fontWeight: 600,
      },
    },
    password_field: {
      "& input.MuiInputBase-input": {
        textAlign: "left",
      },
    },
  };
});

export { useStyles };
