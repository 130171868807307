import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    separator: {
      height: theme.spacing(1.5),
      margin: `0px ${theme.spacing(1)}px`,
    },
    price: {
      fontWeight: "600 !important",
      margin: "0px !important",
      padding: "0px !important",
    },
    address_container: {
      width: "70%",
      marginBottom: theme.spacing(2),
    },
    status_container: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
    title_bedrooms_and_bathrooms_container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: theme.spacing(3),
    },
    actions_container: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    button_container: {
      width: "60%",
    },
    carousel: {
      "& .swiper-container": {
        margin: "0px !important",
      },
    },
    property_information: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      paddingTop: 0,
    },
  };
});

export { useStyles };
