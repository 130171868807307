import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../../../../common/Button/Button";
import IndexHeaderWithFilters from "../../../Headers/Index/WithFilters/IndexHeaderWithFilters";
import MultipleAutocompleteWithChips from "../../../../common/MultipleAutocompleteWithChips/MultipleAutocompleteWithChips";
import withConfig from "../../../../utils/withConfig";
import {
  buildChipDataForMultipleAutocomplete,
  buildRedirectUrl,
  redirectToPage,
} from "../../../../utils/helper";
import { useStyles } from "./ClassifiedsIndexHeader.style";
import Typography from "../../../../common/Typography/Typography";
import labels from "../../../../config/labels";
import Search from "../../../../common/Icons/Search";
import ButtonIcon from "../../../../common/Button/ButtonIcon";

const ClassifiedsIndexHeader = ({ categories, townSlug }) => {
  const classes = useStyles();
  const [chosenCategories, setChosenCategories] = useState([]);
  const [filterByCategoryUrl, setFilterByCategoryUrl] = useState("");

  useEffect(() => {
    const currentUrl = document.URL;
    if (currentUrl.includes("category_ids=")) {
      let urlCategorySlugs = currentUrl.split("category_ids=")[1].split(",");

      if (currentUrl.includes("&page=")) {
        urlCategorySlugs = currentUrl
          .split("category_ids=")[1]
          .split("&page=")[0]
          .split(",");
      }

      const chosenCategories = categories.filter((c) =>
        urlCategorySlugs.find((slug) => c.slug == slug)
      );
      setChosenCategories(
        chosenCategories?.map((c) =>
          buildChipDataForMultipleAutocomplete(c.name, c.slug, classes.chip)
        )
      );
    }
  }, []);

  const handleChooseCategoriesToFilter = (values) => {
    let categorySlugs = [];

    setChosenCategories(values);
    categorySlugs = values.map((val) => val.value);

    if (categorySlugs.length > 0) {
      setFilterByCategoryUrl(
        buildRedirectUrl({
          type: "categories",
          params: { categorySlugs: categorySlugs.join(",") },
        })
      );
    } else {
      setFilterByCategoryUrl(
        buildRedirectUrl({
          type: "categories",
          params: { categorySlugs: "" },
        })
      );
    }
  };

  const redirectWithFilteredCategories = (e) => {
    if (e.keyCode === 13 || e.type === "click") {
      redirectToPage(filterByCategoryUrl, "_top");
    }
  };

  return (
    <IndexHeaderWithFilters
      title={labels.CLASSIFIEDS}
      className={classes.header_container}
      topFilters={
        <div className={classes.filter_with_button_container}>
          <div className={classes.filter_container}>
            <MultipleAutocompleteWithChips
              label={
                <Typography level="paragraph_1" color="gray" bold>
                  {labels.CLASSIFIEDS_CATEGORIES}
                </Typography>
              }
              options={categories}
              handleChange={handleChooseCategoriesToFilter}
              onKeyDown={(e) => redirectWithFilteredCategories(e)}
              preSelectedOptions={chosenCategories}
            />
          </div>
          <ButtonIcon
            children={
              <Search fillColor={"#606060"} className={classes.filter_icon} />
            }
            className={classes.search_button}
            onClick={(e) => redirectWithFilteredCategories(e)}
          />
        </div>
      }
      submitContentButton={
        <>
          {townSlug !== "halston-media" && (
            <Button
              level="simple_button"
              color="primary"
              children={labels.SUBMIT_A_CLASSIFIED}
              onClick={() => redirectToPage("/classifieds/new", "_top")}
            />
          )}
        </>
      }
    />
  );
};

ClassifiedsIndexHeader.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  townSlug: PropTypes.string,
};

export default ClassifiedsIndexHeader;
