import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    regular_text_field: {
      paddingBottom: spacing(2),
    },
    legal_entity: {
      display: "flex",
      flexDirection: "column",
      rowGap: spacing(0.5),
      padding: spacing(2),
    },
    legal_entity_container: {
      borderRadius: spacing(0.5),
      border: `1px solid ${palette.grayVariation.grayForBorders}`,
      display: "flex",
    },
    logo: {
      width: "35%",
      height: "auto",
      maxHeight: spacing(23.5),
      objectFit: "contain",
      padding: spacing(2),
    },
    checked_radio_button: {
      color: `${palette.themeColor.secondary} !important`,
    },
    group_label: {
      marginBottom: spacing(2),
    },
    legal_entity_headline: {
      marginBottom: `${spacing(2)}px !important`,
      marginTop: `${spacing(2)}px !important`,
    },
    form_control: {
      marginBottom: `${spacing(4)}px !important`,
      marginTop: `${spacing(4)}px !important`,
    },
    data_box: {
      width: "-webkit-fill-available",
    },
    data_box_container: {
      flexGrow: 1,
    },
    form_group: {
      gap: spacing(2),
    },
    checkbox: {
      "& .MuiTypography-root": {
        fontSize: "14px !important",
        fontWeight: "500 !important",
        color: "black",
      },
      "& .MuiFormControlLabel-root": {
        paddingLeft: 0,
      },
      "& span.MuiCheckbox-root": {
        color: palette.miscellaneous.gray,
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: palette.themeColor.primary,
      },
    },
  };
});

export { useStyles };
