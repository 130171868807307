import React from "react";
import Typography from "../../common/Typography/Typography";

const ThankYou = ({ customMessage }) => {
  return (
    <>
      {customMessage && (
        <div dangerouslySetInnerHTML={{ __html: customMessage }} />
      )}
    </>
  );
};

export default ThankYou;

// #primary
//   .article-index-box
//     %h2.box-header
//     .box-contents
//       - if @premium.present?
//         %p
//           Thank you for submitting premium content to TAPinto. It has been sent to our editorial staff for review and publication. It usually takes 24-72 hours for content to be published once it has been submitted.
//       - else
//         %p
//           Thank you for submitting content to TAPinto.net. Our editors will review your content for publication. You will receive an email with a link to the content when it has been published.

//       %p
//         If you do not receive the email or have other questions, please email
//         = mail_to 'editor@tapinto.net', 'Editor@TAPinto.net', encode: :hex
//         or call 908-279-0303.

//       %p
//         Thank you for your support.

//       %p
//       %p
//         For information about franchising a TAPinto site in your area,&nbsp;
//         %a#welcome-contribute{href: page_path('start-tap'), :'data-title' => "Franchise TAP"}> click here.

//       #timer_div
// - flash[:notice] = nil

// #secondary
//   = render 'shared/sidebar_ads'
