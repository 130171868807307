import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  post_submit_dialog_close_button: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: "absolute",
    "&.MuiIconButton-root": {
      height: theme.spacing(4),
      width: theme.spacing(4),
      position: "absolute",
      right: theme.spacing(3),
      top: theme.spacing(3),
    },
  },

  submitted_modal_content: {
    color: theme.palette.tapintoBranding.tapintoOrange,
    width: theme.spacing(75),
    height: theme.spacing(81.25),
    padding: `${theme.spacing(10)}px ${theme.spacing(10)}px 0 ${theme.spacing(
      10
    )}px`,
    textAlign: "center",
    [theme.breakpoints.down(750)]: {
      width: theme.spacing(62.5),
      marginTop: theme.spacing(2),
      padding: `${theme.spacing(2.5)}px ${theme.spacing(5)}px 0 ${theme.spacing(
        5
      )}px`,
    },
    [theme.breakpoints.down(601)]: {
      width: theme.spacing(50),
    },
    [theme.breakpoints.down(461)]: {
      width: theme.spacing(43.75),
    },
    [theme.breakpoints.down(401)]: {
      width: theme.spacing(35.5),
      marginBottom: theme.spacing(6),
    },
  },

  icon_container: {
    marginBottom: theme.spacing(5),
  },

  button_container: {
    width: "100%",
    display: "block",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },

  submit_request_button: {
    width: theme.spacing(23.8),
    display: "inline-block",
    height: theme.spacing(5),

    "&.MuiButtonBase-root": {
      marginTop: theme.spacing(4),
    },
    "&.MuiButton-root": {
      background: "linear-gradient(#a66114 3%, 20%, #f98c10)",
      borderRadius: 20,
      borderBottomRightRadius: 3,
      textTransform: "none",
    },
    "&.MuiButton-text": {
      fontSize: "20px",
      lineHeight: "22px",
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    "&.MuiButton-textPrimary": {
      color: theme.palette.miscellaneous.white,
    },
  },
}));

export { useStyles };
