import React from "react";
import Button from "../common/Button/Button";
import withConfig from "../utils/withConfig";

export const getSubmitNewEventButton = (label) => {
  return (
    <Button
      level="simple_button"
      color="primary"
      bold
      onClick={() => (window.location.href = withConfig("EVENTS_DIY_FORM"))}
    >
      {label}
    </Button>
  );
};

export const isEventAddressOnline = (givenAddress) => {
  console.log({ givenAddress });
  return givenAddress?.includes("http") || givenAddress?.includes(".com")
    ? true
    : false;
};
