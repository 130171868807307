import React, { useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./RealEstateListingFilterModal.style";
import Button from "../../../common/Button/Button";
import Checkbox from "../../../common/Checkbox/Checkbox";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import SliderRange from "../../../common/SliderRange/SliderRange";
import clsx from "clsx";
import { handleSubmitFilters } from "../../../utils/formsHelper";

const BLACK = "black";

const RealEstateListingFilterModal = ({
  handleClose,
  openState,
  handleRealEstateByFilter,
  filtersParams,
}) => {
  const classes = useStyles();

  const [defaultRangePrice] = useState([
    filtersParams.min_price,
    filtersParams.max_price,
  ]);

  const [sliderRangeLoaded, setSliderRangeLoaded] = useState(false);

  const [numberOfBedrooms, setNumberOfBedrooms] = useState(
    filtersParams.number_of_bedrooms
  );

  const [numberOfBathrooms, setNumberOfBathrooms] = useState(
    filtersParams.number_of_bedrooms
  );

  const handlePriceRangeValue = (newValue) => {
    filtersParams.min_price = newValue[0];
    filtersParams.max_price = newValue[1];
  };

  const handleNumberOfBedrooms = (value) => {
    setNumberOfBedrooms(value);
    filtersParams.number_of_bedrooms = value;
  };

  const handleNumberOfBathrooms = (value) => {
    setNumberOfBathrooms(value);
    filtersParams.number_of_bathrooms = value;
  };

  const handleReset = () => {
    setNumberOfBathrooms(0);
    setNumberOfBedrooms(0);
    filtersParams.number_of_bedrooms = 0;
    filtersParams.number_of_bathrooms = 0;
    filtersParams.min_price = defaultRangePrice[0];
    filtersParams.max_price = defaultRangePrice[1];
    setSliderRangeLoaded(!sliderRangeLoaded);
  };

  const sliderLabelFormat = (value) => {
    return `$${value}`;
  };

  const RealEstateFilterModalContent = () => {
    return (
      <form
        onSubmit={(e) =>
          handleSubmitFilters(e, {
            isModal: true,
            actions: {
              closeModal: handleClose,
              fetchFilteredItems: handleRealEstateByFilter,
            },
          })
        }
      >
        <div className={classes.filter_modal_content}>
          <div className={classes.filter_container}>
            <Typography level="t2_text_2" bold>
              {labels.REAL_ESTATES_FILTER_PRICE}
            </Typography>

            <div className={classes.slider_container}>
              <SliderRange
                loaded={sliderRangeLoaded}
                value={[filtersParams.min_price, filtersParams.max_price]}
                step={100000}
                min={defaultRangePrice[0]}
                max={defaultRangePrice[1]}
                onChange={handlePriceRangeValue}
                options={{
                  withInputFields: true,
                  customLabelFormat: sliderLabelFormat,
                  withCustomTooltipLabel: true,
                }}
              />
            </div>
          </div>
          <div className={classes.filter_container}>
            <Typography level="t2_text_2" bold>
              {labels.REAL_ESTATES_FILTER_BEDROOMS}
            </Typography>

            <div className={classes.checkboxes_container}>
              <Checkbox
                label={"Any"}
                checked={numberOfBedrooms == 0 ? true : false}
                onChange={() => handleNumberOfBedrooms(0)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"1+"}
                checked={numberOfBedrooms == 1 ? true : false}
                onChange={() => handleNumberOfBedrooms(1)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"2+"}
                checked={numberOfBedrooms == 2 ? true : false}
                onChange={() => handleNumberOfBedrooms(2)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"3+"}
                checked={numberOfBedrooms == 3 ? true : false}
                onChange={() => handleNumberOfBedrooms(3)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"4+"}
                checked={numberOfBedrooms == 4 ? true : false}
                onChange={() => handleNumberOfBedrooms(4)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"5+"}
                checked={numberOfBedrooms == 5 ? true : false}
                onChange={() => handleNumberOfBedrooms(5)}
                className={classes.filter_checkbox}
              />
            </div>
          </div>

          <div className={classes.filter_container}>
            <Typography level="t2_text_2" bold>
              {labels.REAL_ESTATES_FILTER_BATHROOMS}
            </Typography>

            <div className={classes.checkboxes_container}>
              <Checkbox
                label={"Any"}
                checked={numberOfBathrooms == 0 ? true : false}
                onChange={() => handleNumberOfBathrooms(0)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"1+"}
                checked={numberOfBathrooms == 1 ? true : false}
                onChange={() => handleNumberOfBathrooms(1)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"2+"}
                checked={numberOfBathrooms == 2 ? true : false}
                onChange={() => handleNumberOfBathrooms(2)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"3+"}
                checked={numberOfBathrooms == 3 ? true : false}
                onChange={() => handleNumberOfBathrooms(3)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"4+"}
                checked={numberOfBathrooms == 4 ? true : false}
                onChange={() => handleNumberOfBathrooms(4)}
                className={classes.filter_checkbox}
              />
              <Checkbox
                label={"5+"}
                checked={numberOfBathrooms == 5 ? true : false}
                onChange={() => handleNumberOfBathrooms(5)}
                className={classes.filter_checkbox}
              />
            </div>
          </div>
        </div>

        <div className={classes.actions_container}>
          <Button
            ariaLabel="reset-filters"
            level="simple_button"
            color="primary"
            children={labels.REAL_ESTATES_FILTER_RESET}
            classes={{
              root: clsx(
                classes.button,
                classes.white_button,
                classes.white_button_label
              ),
            }}
            onClick={handleReset}
          />

          <Button
            level="simple_button"
            ariaLabel="submit-filters"
            color="primary"
            children={labels.REAL_ESTATES_FILTER_APPLY}
            type="submit"
            classes={{
              root: clsx(
                classes.button,
                classes.orange_button,
                classes.orange_button_label
              ),
            }}
          />
        </div>
      </form>
    );
  };

  return (
    <PopUpDialog
      onClose={handleClose}
      openState={openState}
      title={labels.FILTER_TITLE}
      content={<RealEstateFilterModalContent />}
      titleClass={classes.title}
      titleTypographyClass={classes.title_typography}
      closeButtonClass={classes.close_button}
      closeButtonColor={BLACK}
      contentClass={classes.real_estate_filter_dialog}
    />
  );
};

RealEstateListingFilterModal.propTypes = {
  handleClose: PropTypes.func,
  openState: PropTypes.bool,
  handleRealEstateByFilter: PropTypes.func,
  filtersParams: PropTypes.shape({
    town_id: PropTypes.number,
    category: PropTypes.any,
    property_status: PropTypes.string,
    min_price: PropTypes.number,
    max_price: PropTypes.number,
    number_of_bedrooms: PropTypes.number,
    number_of_bathrooms: PropTypes.number,
  }),
};

export default RealEstateListingFilterModal;
