import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  filter_container: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: "left",
    [theme.breakpoints.down(960)]: {
      width: "90%",
    },
  },
  filter_modal_content: {
    width: "100%",
    textAlign: "center",
  },
  title: {
    "&.MuiDialogTitle-root": {
      margin: 0,
      background: theme.palette.miscellaneous.white,
      textAlign: "center",
      borderBottom: `1px solid ${theme.palette.grayVariation.grayForBorders}`,
    },
  },
  title_typography: {
    fontSize: "14px",
    paddingTop: theme.spacing(1),
    color: theme.palette.dark.black,
  },
  close_button: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    position: "absolute",
    right: theme.spacing(2),
    cursor: "pointer",
    "&.MuiIconButton-root": {
      height: theme.spacing(2),
      width: theme.spacing(2),
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  },
  real_estate_filter_dialog: {
    "&.MuiDialogContent-root": {
      overflow: "hidden",
      padding: `${theme.spacing(1)}px ${theme.spacing(4)}px !important`,
    },
  },
  actions_container: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(761)]: {
      width: "100%",
      display: "inherit",
      justifyContent: "flex-end",
    },
  },

  button: {
    width: "50%",
    height: theme.spacing(4),
    textTransform: "none !important",
    borderRadius: "0px !important",
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px !important`,
  },

  orange_button_label: {
    "& .MuiButton-label": {
      fontSize: "12px",
      fontFamily: "Montserrat",
      color: theme.palette.dark.black,
    },
  },
  white_button_label: {
    "& .MuiButton-label": {
      fontSize: "12px",
      fontFamily: "Montserrat",
      color: theme.palette.themeColor.primary,
    },
  },
  orange_button: {
    backgroundColor: `${theme.palette.themeColor.primary} !important`,
  },
  white_button: {
    backgroundColor: "transparent",
  },
  filter_checkbox: {
    "& .MuiTypography-root": {
      fontSize: "14px !important",
      fontWeight: "500 !important",
      color: "black",
    },
    "& .MuiFormControlLabel-root": {
      paddingLeft: 0,
    },
    "& span.MuiCheckbox-root": {
      color: theme.palette.miscellaneous.gray,
    },
    "& span.MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.themeColor.primary,
    },
  },
  slider_container: {
    marginTop: theme.spacing(4),
    width: "90%",
    margin: "auto",
  },
  checkboxes_container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
}));

export { useStyles };
