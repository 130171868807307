import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useStyles } from "./Header.style";
import RealEstatesLogo from "../../../common/Icons/Vectors/Logos/RealEstatesLogo";
import Button from "../../../common/Button/Button";
import Sponsor from "../../Sponsor/Sponsor";
import labels from "../../../config/labels";
import { redirectToPage } from "../../../utils/helper";
import withConfig from "../../../utils/withConfig";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import values from "../../../common/Theme/themes/breakpoints/values";

const RealEstatesHeader = ({ sponsorInfo, forIndex }) => {
  const classes = useStyles();
  const isMobile = screenWidthIsLowerThan(values.xs);

  const showSponsorAtTheBeginning = (shouldShowMobileLayout) => {
    return ((!shouldShowMobileLayout && forIndex) || !forIndex) && sponsorInfo;
  };

  const showSponsorAtTheEnd = (shouldShowMobileLayout) => {
    return shouldShowMobileLayout && forIndex && sponsorInfo;
  };

  const getHeaderContainerClasses = (shouldShowMobileLayout) => {
    return clsx(
      classes.header_container,
      forIndex && classes.header_container_index,
      (sponsorInfo || forIndex) && classes.justify_space_between,
      shouldShowMobileLayout &&
        forIndex &&
        classes.header_container_index_mobile,
      shouldShowMobileLayout &&
        !forIndex &&
        classes.header_container_show_mobile
    );
  };

  const getButtonClasses = (shouldShowMobileLayout) => {
    return clsx(
      classes.button,
      classes.button_label,
      shouldShowMobileLayout && classes.button_mobile
    );
  };

  const getSponsorContainerClasses = ({ fullWidth }) => {
    return clsx(
      classes.sponsor_container,
      fullWidth && classes.sponsor_container_full_width
    );
  };

  return (
    <>
      <div className={getHeaderContainerClasses(isMobile)}>
        {showSponsorAtTheBeginning(isMobile) && (
          <div className={getSponsorContainerClasses({ fullWidth: false })}>
            <Sponsor sponsor_info={sponsorInfo} />
          </div>
        )}

        <RealEstatesLogo
          {...(isMobile && {
            className: classes.mobile_logo,
          })}
        />

        {forIndex && (
          <Button
            level="simple_button"
            color="primary"
            children={labels.POST_YOUR_LISTING}
            onClick={() =>
              redirectToPage(withConfig("REAL_ESTATE_LISTING_DIY"), "_top")
            }
            classes={{
              root: getButtonClasses(isMobile),
            }}
          />
        )}
      </div>

      {showSponsorAtTheEnd(isMobile) && (
        <div className={getSponsorContainerClasses({ fullWidth: true })}>
          <Sponsor
            sponsor_info={sponsorInfo}
            {...(isMobile && {
              displayInMobile: true,
            })}
          />
        </div>
      )}
    </>
  );
};

RealEstatesHeader.propTypes = {
  sponsorInfo: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  forIndex: PropTypes.bool,
};

RealEstatesHeader.defaultProps = {
  forIndex: false,
};

export default RealEstatesHeader;
