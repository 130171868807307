import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import labels from "../../../../../../config/labels";
import TextField from "../../../../../../common/TextField/TextField";
import { useStyles } from "./FuneralHomeSelector.style";
import { BLACK } from "../../../../diyConstants";
import Typography from "../../../../../../common/Typography/Typography";
import RadioButton from "../../../../../../common/RadioButton/RadioButton";

const AT_FUNERAL_HOME = "at_funeral_home";
const OTHER_FUNERAL_HOME = "other";

const FuneralHomeSelector = ({
  funeralHomeNames,
  onChange,
  funeralHomeCounter,
  content,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(
    content?.funeral_home_id ? content?.funeral_home_id : OTHER_FUNERAL_HOME
  );

  const [atFuneralHome, setAtFuneralHome] = useState(
    content?.funeral_home_id ? true : false
  );

  const [name, setName] = useState(content?.name);
  const [address, setAddress] = useState(content?.address);

  const handleFuneralHomeSelection = (funeralHomeId) => {
    setSelected(funeralHomeId);
    setAtFuneralHome(true);
    onChange({
      at_funeral_home: true,
      funeral_home_id: funeralHomeId,
      funeralHomeCounter,
    });
  };

  const handleOtherFuneralHomeSelection = () => {
    setSelected(OTHER_FUNERAL_HOME);
    setAtFuneralHome(false);
    onChange({
      at_funeral_home: atFuneralHome,
      funeral_home_id: null,
    });
  };

  useEffect(() => {
    selected;
    onChange({
      funeral_home_id: selected,
      at_funeral_home: atFuneralHome,
      name,
      address,
      funeralHomeCounter,
    });
  }, [name, address]);

  useEffect(() => {
    onChange({
      funeral_home_id: selected,
      at_funeral_home: atFuneralHome,
      name,
      address,
      funeralHomeCounter,
    });
  }, []);

  return (
    <>
      <div className={classes.funeral_home_selection_container}>
        {funeralHomeNames.map((funeralHomeName, index) => (
          <div className={classes.radiobutton_input_container} key={index}>
            <RadioButton
              checked={selected === funeralHomeName.id}
              className={classes.radio_button}
              onChange={() => handleFuneralHomeSelection(funeralHomeName.id)}
              name="visitation-radio-button"
            />
            <Typography level="paragraph_2" color={BLACK}>
              {labels.AT + " " + funeralHomeName.name}
            </Typography>
          </div>
        ))}

        <div className={classes.radiobutton_address_input_container}>
          <div>
            <RadioButton
              checked={selected === OTHER_FUNERAL_HOME}
              className={classes.radio_button}
              onChange={handleOtherFuneralHomeSelection}
              name="visitation-radio-button"
            />
          </div>
          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              placeholder={labels.NAME}
              disabled={selected != OTHER_FUNERAL_HOME}
              defaultValue={content?.name}
            />
            <div className={classes.regular_text_field}>
              <TextField
                onChange={(e) => setAddress(e.target.value)}
                placeholder={labels.ADDRESS}
                disabled={selected != OTHER_FUNERAL_HOME}
                defaultValue={content?.address}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FuneralHomeSelector.propTypes = {
  funeralHomeNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  funeralHomeCounter: PropTypes.number,
};

export default FuneralHomeSelector;
