import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { realtorInfo } from "../../../../services/realEstates";
import Typography from "../../../../common/Typography/Typography";
import { useStyles } from "./RealtorInfo.style";
import EmailSVG from "../../../../common/Icons/EmailSVG";
import Website from "../../../../common/Icons/Website";
import Button from "../../../../common/Button/Button";
import Link from "../../../../common/Link/Link";
import RealEstateModalForForms from "../ModalForForms/ModalForForms";
import labels from "../../../../config/labels";
import {
  handleModalActions,
  isValidEmail,
  isValidUrl,
} from "../../../../utils/helper";
import ContactRealtorForm from "../ContactRealtor/ContactRealtor";
import { bool, string } from "prop-types";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import values from "../../../../common/Theme/themes/breakpoints/values";

const RealtorInfo = ({ info, slug, nameOnly }) => {
  const classes = useStyles();
  const [realtor, setRealtor] = useState(info);
  const [openContactForm, setOpenContactForm] = useState(false);
  const isMobile = screenWidthIsLowerThan(values.xs);

  const {
    realtor_name: name,
    email,
    agency_phone: phone,
    valid_website: website,
    simplified_website: websiteLabel,
    agency_name: agencyName,
  } = realtor || {};

  const sendUserToDial = () => {
    window.location.href = `tel:${phone}`;
  };

  const handleOpen = () => {
    handleModalActions({
      type: "realEstateContactForms",
      action: "open",
      setOpenModal: setOpenContactForm,
    });
  };

  const handleClose = () => {
    handleModalActions({
      type: "realEstateContactForms",
      action: "close",
      setOpenModal: setOpenContactForm,
    });
  };

  if (nameOnly) {
    useEffect(() => {
      const fetchRealtorInfo = async () => {
        const response = await realtorInfo(slug);

        if (response?.status === 200) {
          setRealtor(response?.data?.realtor_info);
        }
      };

      fetchRealtorInfo();
    }, [slug]);

    return name ? (
      <div className={classes.realtor_name_only}>
        <Typography level="paragraph_2" bold>
          {labels.REALTOR}
        </Typography>

        <Typography level="paragraph_2">{name}</Typography>
      </div>
    ) : (
      <></>
    );
  }

  return (
    <div className={classes.realtor_info}>
      {(name || agencyName) && (
        <div className={classes.names_container}>
          {name && (
            <Typography level={isMobile ? "paragraph_2" : "h4"} bold>
              {name}
            </Typography>
          )}

          {agencyName && (
            <Typography level={isMobile ? "t4_text_4" : "h5_sub_header_2"}>
              {agencyName}
            </Typography>
          )}
        </div>
      )}

      {(isValidUrl(website) || isValidEmail(email)) && (
        <div className={classes.info_container}>
          {isValidUrl(website) && (
            <div className={classes.website_container}>
              <Website fillColor="gray" className={classes.icon} />
              <Link
                level={isMobile ? "t1_text_1" : "paragraph_1"}
                url={website}
                children={websiteLabel}
                color="black"
                underlined
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          )}

          {isValidEmail(email) && (
            <div className={classes.email_container}>
              <EmailSVG fillColor="gray" className={classes.icon} />
              <Typography level={isMobile ? "t1_text_1" : "paragraph_1"}>
                {email}
              </Typography>
            </div>
          )}
        </div>
      )}

      {(phone || isValidEmail(email)) && (
        <div className={classes.actions_container}>
          {phone && (
            <Button
              level="simple_button"
              color="primary"
              children={labels.CALL}
              onClick={() => sendUserToDial()}
              classes={{
                root: clsx(classes.button, classes.button_label),
              }}
            />
          )}
          {isValidEmail(email) && (
            <Button
              level="simple_button"
              color="primary"
              children={labels.SEND_A_MESSAGE}
              onClick={() => handleOpen()}
              classes={{
                root: clsx(classes.filled_button, classes.button_label),
              }}
            />
          )}
        </div>
      )}

      {openContactForm && (
        <RealEstateModalForForms
          title={labels.CONTACT_REALTOR}
          content={<ContactRealtorForm slug={slug} handleClose={handleClose} />}
          openState={openContactForm}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

RealtorInfo.propTypes = {
  slug: string.isRequired,
  nameOnly: bool,
};

RealtorInfo.defaultProps = {
  nameOnly: false,
};

export default RealtorInfo;
