import { makeStyles } from "../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    non_profit_checkbox_container: {
      width: "100%",
      marginTop: theme.spacing(4),
    },
  };
});

export { useStyles };
