import { makeStyles } from "../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    add_new_entity_button: {
      marginBlock: spacing(2),
    }
  };
});

export { useStyles };
