export class Document {
  constructor(
    id,
    contributionId,
    contributionType,
    documentSize,
    documentUrl,
    thumbnailUrl,
    name,
    availablePages,
    thumbnailNumberImage,
    addToImagesCarousel,
    captions,
    credits
  ) {
    this.id = id;
    this.contributionId = contributionId;
    this.contributionType = contributionType;
    this.documentSize = documentSize;
    this.documentUrl = documentUrl;
    this.thumbnailUrl = thumbnailUrl;
    this.name = name;
    this.availablePages = availablePages;
    this.thumbnailNumberImage = thumbnailNumberImage;
    this.addToImagesCarousel = addToImagesCarousel;
    this.captions = captions;
    this.credits = credits;
  }
}
