import React from "react";
import PropTypes from "prop-types";
import DiyActions from "../../Actions/DiyActions";

const Actions = ({
  setLoadingCheckout,
  isEditForm,
  formType,
  setOpenMobileShoppingSummary,
  isRadioButtonChecked,
  townsSelected,
}) => {
  return (
    <DiyActions
      setLoadingCheckout={setLoadingCheckout}
      isEditForm={isEditForm}
      formType={formType}
      setOpenMobileShoppingSummary={setOpenMobileShoppingSummary}
      isRadioButtonChecked={isRadioButtonChecked}
      townsSelected={townsSelected}
    />
  );
};

Actions.propTypes = {
  setLoadingCheckout: PropTypes.func,
  isEditForm: PropTypes.bool,
  formType: PropTypes.string,
  setOpenMobileShoppingSummary: PropTypes.func,
  isRadioButtonChecked: PropTypes.bool,
  townsSelected: PropTypes.array,
};

export default Actions;
