import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    dropdown: {
      height: theme.spacing(4),
      "&.MuiAutocomplete-root": {
        height: theme.spacing(4),
        backgroundColor: theme.palette.miscellaneous.white,
        margin: `0px auto 0px auto`,
        "& div.MuiInputBase-root": {
          height: theme.spacing(4),
          paddingTop: theme.spacing(1),
          "& input.MuiInputBase-input": {
            boxShadow: "none",
            padding: 0,
          },
          "& input.MuiAutocomplete-inputFocused": {
            border: "none",
            backgroundColor: theme.palette.miscellaneous.white,
          },
          "& fieldset.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline":
            {
              border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
              borderRadius: "4px",
            },
        },
      },
    },
    disabled: {
      height: theme.spacing(4),
      "&.MuiAutocomplete-root": {
        height: theme.spacing(4),
        backgroundColor: theme.palette.grayVariation.grayForDisabledField,
        margin: `0px auto 0px auto`,
        "& div.MuiInputBase-root": {
          height: theme.spacing(4),
          paddingTop: theme.spacing(1),
          "& input.MuiInputBase-input": {
            boxShadow: "none",
            padding: 0,
          },
          "& input.MuiAutocomplete-inputFocused": {
            border: "none",
            backgroundColor: theme.palette.grayVariation.grayForDisabledField,
          },
          "& fieldset.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline":
            {
              border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
              borderRadius: "4px",
            },
        },
      },
    },
    margin_bottom_0: {
      marginBottom: 0,
    },
  };
});

export { useStyles };
