import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { values, down: below },
  } = theme;

  const defaults = {
    display: "flex",
    flexDirection: "column",
  };

  return {
    realtor_info: {
      ...defaults,
      alignItems: "center",
      rowGap: spacing(4),
      margin: spacing(5),
      [below(values.xs)]: {
        margin: 0,
        padding: spacing(2),
        rowGap: spacing(2),
      },
    },
    names_container: {
      ...defaults,
      alignItems: "center",
      rowGap: spacing(0.5),
      [below(values.xs)]: {
        textAlign: "center",
      },
    },
    info_container: {
      ...defaults,
      rowGap: spacing(3),
      width: "100%",
      alignItems: "center",
      [below(values.md)]: {
        width: "100%",
        rowGap: spacing(2),
      },
    },
    actions_container: {
      display: "flex",
      columnGap: spacing(3),
      justifyContent: "center",
      borderTop: `1px solid ${palette.miscellaneous.grayDivider}`,
      width: "100%",
      paddingTop: spacing(4),
      [below(values.xs)]: {
        flexWrap: "wrap",
        rowGap: spacing(2),
        paddingTop: spacing(2),
      },
    },
    email_container: {
      display: "flex",
      columnGap: spacing(4),
      [below(values.md)]: {
        alignItems: "center",
        columnGap: spacing(2),
      },
    },
    website_container: {
      display: "flex",
      columnGap: spacing(4),
      overflow: "hidden",
      lineBreak: "anywhere",
      [below(values.md)]: {
        alignItems: "center",
        columnGap: spacing(2),
      },
    },
    icon: {
      width: "24px",
      height: "24px",
    },
    button: {
      borderRadius: "0px !important",
      padding: `${spacing(0.5)}px ${spacing(3)}px !important`,
      border: `1px solid ${palette.themeColor.primary} !important`,
      [below(values.xs)]: {
        width: "100%",
      },
    },
    filled_button: {
      borderRadius: "0px !important",
      padding: `${spacing(0.5)}px ${spacing(3)}px !important`,
      backgroundColor: `${palette.themeColor.primary} !important`,
      [below(values.xs)]: {
        width: "100%",
      },
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        color: palette.dark.black,
        [below(values.xs)]: {
          fontSize: "12px",
        },
      },
    },
    realtor_name_only: {
      ...defaults,
      [below(values.xs)]: {
        marginTop: spacing(3),
      },
    },
  };
});

export { useStyles };
