import React from "react";
import labels from "../../config/labels";
import PropTypes from "prop-types";
import Typography from "../Typography/Typography";
import { useStyles } from "./ErrorMessages.style";

const ErrorMessages = ({ errors }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.subtitle}>
        <Typography level="h4_sub_header_4" color="red" bold>
          {labels.ERROR_SUBTITLE}
        </Typography>
      </div>
      <div className={classes.list_container}>
        <ul className={classes.list}>
          {errors &&
            errors.map((error, index) => (
              <li key={`error-${index}`}>
                <Typography level="paragraph_1" color="red">
                  {error}
                </Typography>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

ErrorMessages.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default ErrorMessages;
