import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Badge } from "@material-ui/core";
import { useStyles } from "./IconBadge.style";

const IconBadge = ({ icon, badgeContent, className }) => {
  const classes = useStyles();

  return (
    <Badge
      badgeContent={badgeContent}
      className={className}
      classes={{ badge: classes.custom_badge }}
    >
      {icon}
    </Badge>
  );
};

IconBadge.propTypes = {
  icon: PropTypes.node,
  badgeContent: PropTypes.node,
  className: PropTypes.any,
};

export default IconBadge;
