import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    title: {
      width: "100%",
      height: "120px",
      backgroundColor: "lightgray",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "56px",
    },
    subtitle: {
      width: "100%",
      padding: `${theme.spacing(2)}px ${theme.spacing(5)}px ${theme.spacing(
        2
      )}px ${theme.spacing(6)}px`,
    },
    list_container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "48px",
    },
    list: {
      display: "block",
      "& ::marker": {
        color: theme.palette.miscellaneous.red,
      },
    },
  };
});

export { useStyles };
