import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      width: "100%",
      height: theme.spacing(30),
      justifyContent: "center",
      flexWrap: "wrap",
      marginBottom: theme.spacing(3),
    },
    row: {
      width: "100%",
      textAlign: "center",
    },
  };
});

export { useStyles };
