import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
    },
    pagination_container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    no_classifieds_container: {
      borderTop: `1px solid ${theme.palette.light.lightGray}`,
      width: "100%",
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    no_classifieds: {
      fontWeight: "400 !important",
    },
  };
});

export { useStyles };
