import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    content: {
      maxWidth: theme.spacing(60),
      lineHeight: `${theme.spacing(2.5)}px`,
      padding: theme.spacing(2.5),
    },
    button: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& button.MuiButton-root": {
        height: theme.spacing(3),
        width: theme.spacing(10.5),
        border: `2px solid ${theme.palette.themeColor.secondary}`,
      },
    },
  };
});

export { useStyles };
