import withConfig from "../utils/withConfig";
import restClient from "../utils/restClient";

const getMapsEncodedAddress = (givenAddress) => {
  const regexSpaces = / +/g;
  const regexDotsOrCommas = /\.||\,/g;
  const urlEncodedAddress = givenAddress
    .replace(regexDotsOrCommas, "")
    .replace(regexSpaces, "%20");

  return urlEncodedAddress;
};

const getGoogleMapsGeocode = async (encodedAddress) => {
  const response = await restClient.get(
    `${withConfig(
      "GOOGLE_GEOCODE_PREFIX"
    )}${encodedAddress}&region=US&key=${withConfig("MAPS_API_KEY_TAPINTO")}`
  );
  return response;
};

export const getViewInMapsUrl = (givenAddress) => {
  return `${withConfig(
    "GOOGLE_MAPS_REDIRECT_URL_PREFIX"
  )}${getMapsEncodedAddress(givenAddress)}`;
};

export const buildMapMarkerCoordenates = async (givenAddress) => {
  try {
    const googleGeocodeApiResponse = await getGoogleMapsGeocode(givenAddress);
    // Enable next line if want to test with a fake response
    // const googleGeocodeApiResponse = {
    //   status: 200,
    //   data: {
    //     results: [
    //       {
    //         geometry: {
    //           location: {
    //             lat: 40.9175516,
    //             lng: -74.17175,
    //           },
    //         },
    //       },
    //     ],
    //     status: "OK",
    //   },
    // };

    console.log({ googleGeocodeApiResponse });

    if (
      googleGeocodeApiResponse.data?.status === "OK" &&
      googleGeocodeApiResponse.data?.results.length > 0
    ) {
      return googleGeocodeApiResponse.data?.results[0].geometry.location;
    }
  } catch (error) {
    console.log(error);
  }
};
