
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../common/Checkbox/Checkbox'
import labels from '../../../../../config/labels'
import { useStyles } from './DisplayEntityLogoCheckbox.style'
import useSubmitableContentForm from '../../../useSubmitableContentForm'
import { SHOW_ENTITY_LOGO } from '../../../diyConstants'

const DisplayEntityLogoCheckbox = ({is_checked}) => {

  const [displayLogo, setDisplayLogo] = useState(is_checked)

  const { setProperty } = useSubmitableContentForm();

  useEffect(() => {
    setProperty(SHOW_ENTITY_LOGO, displayLogo)
  }, [displayLogo])

  const classes = useStyles()

  return (
    <div>
      <Checkbox
        label={labels.DISPLAY_ENTITY_LOGO}
        onChange={() => setDisplayLogo(!displayLogo)}
        className={classes.checkbox}
        checked={displayLogo}
        checkboxColor={"secondary"}
      />
    </div>
  )
}

export default DisplayEntityLogoCheckbox
