import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import CheckCircle from "../../../../common/Icons/CheckCircle";
import Typography from "../../../../common/Typography/Typography";
import { useStyles } from "./FormSent.style";
import Button from "../../../../common/Button/Button";
import labels from "../../../../config/labels";

const FormSent = ({ messageForRealtor, handleClose }) => {
  const classes = useStyles();
  return (
    <div className={classes.main_container}>
      <CheckCircle />

      <Typography level="h3_sub_header_2" bold color="tapintoGreen">
        {labels.EMAIL_SENT}
      </Typography>

      {messageForRealtor ? (
        <Typography level="t4_text_4" color="gray">
          {labels.SENT_REALTOR_EMAIL_MESSAGE}
        </Typography>
      ) : (
        <Typography level="t4_text_4" color="gray">
          {labels.SENT_FRIEND_EMAIL_MESSAGE}
        </Typography>
      )}

      <Button
        ariaLabel="back-to-real-estate"
        type="button"
        level="simple_button"
        color="primary"
        children={labels.GO_BACK_TO_PROPERTY}
        classes={{
          root: clsx(classes.button, classes.button_label),
        }}
        onClick={handleClose}
      />
    </div>
  );
};

FormSent.propTypes = {
  messageForRealtor: PropTypes.bool,
  handleClose: PropTypes.func,
};

FormSent.defaultPropTypes = {
  messageForRealtor: false,
};

export default FormSent;
