import React, { Fragment } from "react";
import PropTypes, { arrayOf, node, object } from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const RadioButtonGroup = ({
  groupLabel,
  radioGroupProps,
  items,
  classes,
  formControlLabelProps,
  formControlProps,
}) => {
  return (
    <FormControl classes={classes?.form_control} {...formControlProps}>
      <FormLabel classes={classes?.group_label}>{groupLabel}</FormLabel>
      <RadioGroup {...radioGroupProps}>
        {items?.map((item, index) => (
          <Fragment key={"radio-group-item-" + index}>
            <FormControlLabel
              control={<Radio classes={classes?.radio_button} />}
              label={item?.label || item}
              value={item?.id || item}
              classes={classes?.form_control_label}
              {...formControlLabelProps}
            />
          </Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioButtonGroup.propTypes = {
  groupLabel: node,
  radioButtonProps: object,
  items: arrayOf(object),
  itemLabelProps: object,
  classes: PropTypes.object,
  formControlLabelProps: object,
  formControlProps: object,
};

export default RadioButtonGroup;
