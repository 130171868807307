import React, { useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import Link from "../../../common/Link/Link";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import ObituaryContributionRequestForm from "./ObituaryContributionRequestForm";

const RequestObituaryRole = ({ user_id, already_requested_access }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alreadyRequested, setAlreadyRequested] = useState(
    already_requested_access
  );

  const onSave = () => {
    setIsOpen(false);
    setAlreadyRequested(true);
  };

  const printMessage = () => {
    if (alreadyRequested) {
      return (
        <>
          {labels.ALREADY_REQUESTED_ACCESS}{" "}
          <a href="mailto:contact@tapinto.net">contact@tapinto.net</a>.
        </>
      );
    }
    return (
      <>
        {labels.REQUEST_OBITUARY_ROLE}{" "}
        <Link
          color="tapintoGreen"
          level={"body"}
          onClick={() => setIsOpen(true)}
          url="#"
        >
          here
        </Link>
      </>
    );
  };

  return (
    <>
      <Typography className={"column"} level={"body"}>
        {printMessage()}
      </Typography>

      <PopUpDialog
        onClose={() => setIsOpen(false)}
        openState={isOpen}
        title={labels.REQUEST_OBITUARY_CONTRIBUTION_ACCESS}
        content={
          <ObituaryContributionRequestForm
            userId={user_id}
            handleSave={onSave}
          />
        }
      />
    </>
  );
};

RequestObituaryRole.propTypes = {
  user_id: PropTypes.number.isRequired,
  already_requested_access: PropTypes.bool,
};

export default RequestObituaryRole;
