import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import EmailBox from "../../common/Icons/Social/EmailBox";
import FacebookBox from "../../common/Icons/Social/FacebookBox";
import LinkedInBox from "../../common/Icons/Social/LinkedInBox";
import TwitterBox from "../../common/Icons/Social/TwitterBox";
import WebsiteBox from "../../common/Icons/Social/WebsiteBox";
import Link from "../../common/Link/Link";
import Typography from "../../common/Typography/Typography";
import { useStyles } from "./UserInformationBlock.style";
import { isValidUrl } from "../../utils/helper";
import Phone from "../../common/Icons/Phone";

const UserInformationBlock = ({
  userEmail,
  userProfile,
  thumbnailImageUrl,
  townSocialLinks,
}) => {
  const classes = useStyles();
  const { facebook_url: facebook_formatted, twitter_url: x } =
    townSocialLinks || {};

  const shouldShowSocialLinks = () => {
    return (
      isValidUrl(facebook_formatted) ||
      isValidUrl(x) ||
      isValidUrl(userProfile.url) ||
      userEmail
    );
  };

  return (
    <div className={classes.user_info_container}>
      <div className={classes.image_container}>
        <img src={thumbnailImageUrl} />
      </div>
      <div className={classes.info_text_container}>
        <Typography level="h2" className={classes.full_name}>
          {userProfile.full_name}
        </Typography>
        <div
          dangerouslySetInnerHTML={{ __html: userProfile.bio }}
          className={classes.bio_container}
        />
        {shouldShowSocialLinks() && (
          <div className={classes.social_icons_container}>
            {isValidUrl(facebook_formatted) && (
              <Link
                url={facebook_formatted}
                children={
                  <FacebookBox
                    className={clsx(classes.facebook_icon, classes.social_icon)}
                  />
                }
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
            {isValidUrl(x) && (
              <Link
                url={x}
                children={<TwitterBox className={classes.social_icon} />}
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
            {isValidUrl(userProfile.linkedin) && (
              <Link
                url={userProfile.linkedin}
                children={<LinkedInBox className={classes.social_icon} />}
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
            {isValidUrl(userProfile.url) && (
              <Link
                url={userProfile.url}
                children={<WebsiteBox className={classes.social_icon} />}
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
            {userEmail && (
              <Link
                url={"mailto:" + userEmail}
                children={<EmailBox className={classes.social_icon} />}
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
            {userProfile?.phone_formatted && (
              <Link
                url={"tel:" + userProfile.phone_formatted}
                children={
                  <Phone fillColor="black" className={classes.phone_icon} />
                }
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

UserInformationBlock.propTypes = {
  userEmail: PropTypes.string,
  userProfile: PropTypes.object,
  thumbnailImageUrl: PropTypes.string,
};

export default UserInformationBlock;
