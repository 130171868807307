import React from "react";
import PropTypes from "prop-types";
import EventHeader from "../../../components/Event/EventHeader/EventHeader";
import { useStyles } from "./Event.style";
import EventBody from "../../../components/Event/EventBody/EventBody";
import { getCategoriesLinks } from "../../../utils/helper";
import UpcomingEvents from "../../../components/Event/UpcomingEvents/UpcomingEvents";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";

const Event = ({
  event,
  is_advertisement,
  town,
  is_event_preview,
  show_content_options,
  sponsor_info,
  edit_url,
}) => {
  const townSlug = town?.slug;

  const classes = useStyles();
  return (
    <div className={classes.event}>
      <EventHeader
        title={event.title}
        categories={getCategoriesLinks(
          event.categories || [],
          townSlug,
          true,
          {
            className: classes.categories,
            level: screenWidthIsLowerThan(960)
              ? "h5_sub_header_2"
              : "h4_sub_header_4",
            color: "gray",
            bold: true,
          },
          "events",
          is_event_preview
        )}
        editUrl={edit_url}
        showContentOptions={show_content_options}
        isAdvertisement={is_advertisement}
        sponsorInfo={sponsor_info}
      />
      <EventBody event={event} isEventPreview={is_event_preview} />
      {!is_event_preview && <UpcomingEvents event={event} town={town} />}
    </div>
  );
};

Event.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    timezone: PropTypes.string,
    starts: PropTypes.shape({
      humanized_date: PropTypes.string,
      humanized_time: PropTypes.string,
      standard: PropTypes.string,
    }),
    ends: PropTypes.shape({
      humanized_date: PropTypes.string,
      humanized_time: PropTypes.string,
      standard: PropTypes.string,
    }),
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    address: PropTypes.string,
    categories: PropTypes.array,
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    description: PropTypes.string,
    slug: PropTypes.string,
    path: PropTypes.string,
    contact_information: PropTypes.shape({
      name: PropTypes.string,
      phone_number: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string,
      facebook: PropTypes.string,
      twitter: PropTypes.string,
      instagram: PropTypes.string,
    }),
    all_day: PropTypes.bool,
  }),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      date: PropTypes.string,
      start_time: PropTypes.string,
      address: PropTypes.string,
      categories: PropTypes.array,
      media_content: PropTypes.shape({
        contentUrl: PropTypes.string,
        alt_text: PropTypes.string,
      }),
      slug: PropTypes.string,
    })
  ),
  town: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    name: PropTypes.string,
  }),
  is_advertisement: PropTypes.bool,
  is_event_preview: PropTypes.bool,
  show_content_options: PropTypes.bool,
  edit_url: PropTypes.string,
};

export default Event;
