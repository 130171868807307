import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "../Typography/Typography";
import { useStyles } from "./SelectTabs.style";
import Tab from "./Tab";

const SelectTabs = ({
  title,
  titleStyleProps,
  choices,
  seeMoreLabel,
  seeLessLabel,
  seeMoreLinkStyleProps,
  tabStyleProps,
  numberOfTabsToShow,
  getMultipleSelectedChoices,
  getSingleSelectedChoice,
  singleSelectedChoiceId,
  isMultiple,
  preSelectedChoices,
}) => {
  const classes = useStyles();
  const [collapseAllTabs, setCollapseAllTabs] = useState(false);

  const toggleTabs = () => {
    setCollapseAllTabs(!collapseAllTabs);
  };

  const SeeMoreOrLessLink = () => {
    return (
      <div
        className={
          seeMoreLinkStyleProps.seeMoreLinkContainerClass
            ? seeMoreLinkStyleProps.seeMoreLinkContainerClass
            : classes.link_container
        }
        onClick={toggleTabs}
      >
        <Typography
          level={seeMoreLinkStyleProps.level}
          color={seeMoreLinkStyleProps.color}
          underlined={seeMoreLinkStyleProps.underlined}
          bold={seeMoreLinkStyleProps.bold}
        >
          {collapseAllTabs ? seeLessLabel : seeMoreLabel}
        </Typography>
      </div>
    );
  };

  const showSeeMoreOrLessLink = () => {
    return choices.length > numberOfTabsToShow && seeMoreLabel && seeLessLabel;
  };

  function buildChoiceWithStatusObject(id, name, status) {
    return {
      id: id,
      name: name,
      status: status,
    };
  }

  function choicesWithStatus() {
    let choicesWithStatus = [];
    let preselectedChoicesIds = [];

    if (preSelectedChoices.length > 0) {
      preselectedChoicesIds = preSelectedChoices.map(
        (preselectedChoice) => preselectedChoice.id
      );
    }

    choicesWithStatus = choices.map((c) => {
      let choiceStatus = false;
      if (preselectedChoicesIds.includes(c.id)) {
        choiceStatus = true;
      }
      return buildChoiceWithStatusObject(c.id, c.name, choiceStatus);
    });

    return choicesWithStatus;
  }

  return (
    <>
      {choices && choices.length > 0 && (
        <>
          {title && (
            <Typography
              level={titleStyleProps.level}
              color={titleStyleProps.color}
              bold={titleStyleProps.bold}
              underlined={titleStyleProps.underlined}
            >
              {title}
            </Typography>
          )}
          <div
            className={
              tabStyleProps.tabsContainerClass
                ? tabStyleProps.tabsContainerClass
                : clsx(
                    classes.tabs_container,
                    collapseAllTabs && classes.tabs_container_collapsed
                  )
            }
          >
            {choicesWithStatus().map((choice) => (
              <Fragment key={choice.id}>
                <Tab
                  label={choice.name}
                  id={choice.id}
                  status={choice.status}
                  styleProps={tabStyleProps}
                  getMultipleSelectedChoices={getMultipleSelectedChoices}
                  getSingleSelectedChoice={getSingleSelectedChoice}
                  isMultiple={isMultiple}
                  singleSelectedChoiceId={singleSelectedChoiceId}
                />
              </Fragment>
            ))}
          </div>
          {showSeeMoreOrLessLink() && <SeeMoreOrLessLink />}
        </>
      )}
    </>
  );
};

SelectTabs.propTypes = {
  title: PropTypes.string,
  titleStyleProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
  }),
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  seeMoreLabel: PropTypes.string,
  seeLessLabel: PropTypes.string,
  seeMoreLinkStyleProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    seeMoreLinkContainerClass: PropTypes.any,
  }),
  tabStyleProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    unselectedTabClass: PropTypes.any,
    selectedTabClass: PropTypes.any,
    tabsContainerClass: PropTypes.any,
  }),
  numberOfTabsToShow: PropTypes.number,
  getMultipleSelectedChoices: PropTypes.func,
  getSingleSelectedChoice: PropTypes.func,
  isMultiple: PropTypes.bool,
  singleSelectedChoiceId: PropTypes.number,
  preSelectedChoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

export default SelectTabs;
