import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    date_picker: {
      "& div.MuiInputBase-root": {
        height: theme.spacing(4),
        "& input.MuiInputBase-input": {
          border: "none",
          boxShadow: "none",
          height: theme.spacing(4),
          padding: `0px ${theme.spacing(0.75)}px 0px ${theme.spacing(0.75)}px`,
        },
        "&.Mui-focused": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.themeColor.primary,
          },
          "& input.MuiInputBase-input": {
            border: "none",
            boxShadow: "none",
            height: theme.spacing(4),
            padding: theme.spacing(0.75),
          },
        },
        "&.MuiOutlinedInput-adornedEnd": {
          paddingRight: 0,
        },
        "& div.MuiInputAdornment-root": {
          "& button.MuiButtonBase-root": {
            padding: 0,
          },
        },
      },
    },
    date_picker_end_icon: {
      transform: "scale(0.5)",
    },
    date_picker_popover: {
      "& button.MuiButtonBase-root.MuiPickersDay-daySelected": {
        backgroundColor: theme.palette.themeColor.primary,
      },
    },
  };
});

export { useStyles };
