import { makeStyles } from "../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    row: {
      display: "flex",
    },
    icon_container: {
      flex: 1,
      marginBottom: `-${theme.spacing(3.5)}px`,
      [theme.breakpoints.down(961)]: {
        marginBottom: `-${theme.spacing(0.5)}px`,
        "& svg": {
          width: theme.spacing(8),
          height: theme.spacing(8),
        },
        "& span": {
          display: "block",
        },
      },
    },
    single_icon_container: {
      [theme.breakpoints.down(961)]: {
        "& svg": {
          width: theme.spacing(8),
          height: theme.spacing(8),
        },
        "& span": {
          display: "block",
        },
      },
    },
  };
});

export { useStyles };
