import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  user_info_container: {
    height: "20%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(960)]: {
      flexDirection: "column",
    },
  },
  image_container: {
    width: "auto",
    maxWidth: "18%",
    [theme.breakpoints.down(960)]: {
      maxWidth: "100%",
      maxHeight: theme.spacing(40),
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
    },
  },
  info_text_container: {
    width: "80%",
    [theme.breakpoints.down(960)]: {
      width: "100%",
    },
  },
  bio_container: {
    "& p": {
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
  },
  full_name: {
    fontWeight: "400 !important",
    marginBottom: theme.spacing(2),
  },
  facebook_icon: {
    width: theme.spacing(4),
  },
  social_icons_container: {
    width: "25%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(960)]: {
      width: "100%",
    },
  },
  social_icon: {
    marginRight: theme.spacing(1),
  },
  phone_icon: {
    paddingTop: theme.spacing(1),
  },
}));

export { useStyles };
