import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { values, down: below },
  } = theme;

  return {
    bulk_selector: {
      display: "flex",
      float: "right",
      "& > div > .MuiOutlinedInput-notchedOutline": {
        border: "unset !important",
        padding: "unset !important",
        margin: "unset !important",
        borderRadius: "unset !important",
      },
    },
    custom_root: {
      width: `${spacing(26)}px !important`,
      height: `${spacing(3)}px !important`,
      border: `1px solid ${palette.grayVariation.grayForCredits} !important`,
      borderRadius: `1px !important`,
      "&.MuiSelect-select.MuiSelect-select": {
        paddingTop: `${spacing(1)}px !important`,
        paddingBottom: `${spacing(1)}px !important`,
      },
    },
    go_button: {
      backgroundColor: `${palette.tapintoBranding.tapintoGreen} !important`,
      borderRadius: `1px !important`,
      color: `${palette.miscellaneous.white} !important`,
    },
    text_field: {
      "& fieldset": {
        borderRadius: "1px !important",
      },
    },
    row: {
      paddingTop: spacing(3),
    },
    caption: {
      paddingTop: `${spacing(3)}px !important`,
    },
    caption_limit: {
      color: `${palette.grayVariation.grayForSubLabel} !important`,
      paddingLeft: spacing(1),
    },
    pop_up: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: spacing(67),
        paddingLeft: spacing(8),
        paddingRight: spacing(8),
        paddingBottom: spacing(6),
        [below(values.sm)]: {
          maxWidth: spacing(62.5),
          padding: spacing(1),
          margin: spacing(2),
        },
      },
    },
    content: {
      overflowY: "unset !important",
    },
    close_button: {
      width: spacing(3),
      height: spacing(3),
      cursor: "pointer",
      position: "absolute",
      right: spacing(3),
      top: spacing(2),
    },
    block_title: {
      width: "100%",
      borderBottom: "1px solid black",
      paddingBottom: spacing(1),
    },
    no_title: {
      display: "block",
    },
    save_button: {
      width: "100%",
      height: spacing(4),
      backgroundColor: `${palette.miscellaneous.green} !important`,
      color: `${palette.miscellaneous.white} !important`,
      textTransform: "capitalize !important",
      borderRadius: `${spacing(1)}px !important`,
      "&.MuiButtonBase-root": {
        border: `0.5px solid black`,
        fontSize: `${spacing(2)}px !important`,
      },
    },
    cancel_button: {
      width: "100%",
      height: spacing(4),
      backgroundColor: `${palette.dark.red} !important`,
      color: `${palette.miscellaneous.white} !important`,
      "& svg": {
        marginRight: 6,
      },
      textTransform: "capitalize !important",
      borderRadius: `${spacing(1)}px !important`,
      "&.MuiButtonBase-root": {
        border: `0.5px solid black`,
        fontSize: `${spacing(2)}px !important`,
      },
    },
    bottom_actions: {
      marginTop: `${spacing(4)}px !important `,
      justifyContent: "center",
    },
  };
});

export { useStyles };
