import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../../common/Button/Button";
import Grid from "../../../common/Grid/Grid";
import labels from "../../../config/labels";
import { useStyles } from "./PDFActions.style";
import Typography from "../../../common/Typography/Typography";
import Checkbox from "../../../common/Checkbox/Checkbox";
import { useDocuments } from "../../../context/CMS/DocumentsContext/DocumentsContext";
import Select from "../../../common/Select/Select";
import { buildHtmlCode } from "../../../services/documents";
import CopyCodeToClipboard from "../../CopyHtmlToClipboard/CopyCodeToClipboard";
import Spinner from "../../../common/Spinner/Spinner";
import TextField from "../../../common/TextField/TextField";
import clsx from "clsx";
import { Document } from "../../../classes/Document";
import { isValidString } from "../../../utils/helper";

const TAPINTO_ORANGE = "#f98c10";

const PDFActions = ({ document, afterAction }) => {
  const classes = useStyles();
  const { saveDocumentInfo, isLoading } = useDocuments();
  const [creditsError, setCreditsError] = useState({
    error: false,
    subLabel: undefined,
  });
  const [formDocument, setFormDocument] = useState({ ...document });

  const handleSubmit = async () => {
    if (creditsError?.error) {
      return;
    }

    await saveDocumentInfo(formDocument);
    afterAction();
  };

  const pdfPages = () => {
    const result = [];
    for (let i = 1; i <= document.availablePages; i++) {
      result.push(i);
    }
    return result;
  };

  const handlePageChange = (event) => {
    setFormDocument({
      ...formDocument,
      thumbnailNumberImage: event.target.value,
    });
  };

  const handleCreditsChange = (value) => {
    if (isValidString(value)) {
      setCreditsError({ error: false, subLabel: undefined });
    }

    setFormDocument(() => {
      return {
        ...formDocument,
        credits: value,
      };
    });
  };

  useEffect(() => {
    if (isValidString(formDocument?.credits)) {
      setCreditsError({ error: false, subLabel: undefined });
    } else {
      setCreditsError({ error: true, subLabel: labels.MISSING_CREDITS });
    }
  }, [formDocument]);

  return (
    <>
      {isLoading && <Spinner withLoadingOverlay={true} />}
      <Grid container spacing={8}>
        <Grid item lg={6} xs={12} md={6}>
          <Grid item xs={12} className={classes.block_title}>
            <Typography level={"medium_16_px"} italic>
              {labels.PDF_ACTIONS}
            </Typography>
          </Grid>
          <Grid container spacing={2} className={classes.row}>
            <Grid item xs={11} className={classes.add_pdf_to_carousel_text}>
              <Typography level={"cms_form_label"}>
                {labels.ADD_PDF_TO_IMAGES_CAROUSEL}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Checkbox
                checked={
                  formDocument?.addToImagesCarousel
                    ? formDocument?.addToImagesCarousel
                    : false
                }
                onChange={() =>
                  setFormDocument((prev) => {
                    return {
                      ...formDocument,
                      addToImagesCarousel: !prev.addToImagesCarousel,
                    };
                  })
                }
                checkboxColor={TAPINTO_ORANGE}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.row}>
            <Grid item xs={10}>
              <Typography level={"cms_form_label"}>
                {labels.PDF_ACTION_LABELS.SELECT_PDF_PAGE_NUMBER}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Select
                selectItems={pdfPages()}
                value={formDocument.thumbnailNumberImage}
                onChange={handlePageChange}
                variant="outlined"
                selectClasses={{ root: classes.custom_root }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.row}>
            <Grid item xs={4} className={classes.preview_label}>
              <Typography level={"cms_form_label"}>{labels.PREVIEW}</Typography>
            </Grid>
            <Grid item xs={8}>
              <img
                src={document.thumbnailUrl}
                width={105}
                className={classes.image_thumbnail}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.bottom_container}>
            <Grid item xs={12}>
              <Typography
                level={"cms_form_label"}
                className={classes.embed_text}
                bold
              >
                {labels.PDF_ACTION_LABELS.YOU_CAN_EMBED_THIS_PDF_IN_THE_TEXT}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.embed_code}>
              <CopyCodeToClipboard
                htmlCode={document.documentUrl}
                label={labels.PDF_ACTION_LABELS.COPY_PDF_URL}
                language={"http"}
              />
            </Grid>
            <Grid item xs={12} className={classes.embed_code}>
              <CopyCodeToClipboard
                htmlCode={buildHtmlCode(
                  document.documentUrl,
                  document.name,
                  document.thumbnailUrl,
                  document.documentSize
                )}
                label={labels.PDF_ACTION_LABELS.COPY_HTML_FOR_PDF}
                language={"html"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={12} md={6}>
          <Grid item xs={12} className={classes.block_title}>
            <Typography level={"medium_16_px"} italic>
              {labels.CREDITS_AND_CAPTIONS}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <TextField
              defaultValue={formDocument?.credits}
              onChange={(event) => handleCreditsChange(event.target.value)}
              label={labels.CREDITS}
              error={creditsError?.error}
              required
              labelLevel={"cms_form_label"}
              labelColor={"black"}
              boldLabel={false}
              className={classes.text_field}
              sublabel={creditsError?.subLabel}
            />
          </Grid>
          <Grid item xs={12} className={clsx(classes.row, classes.caption)}>
            <TextField
              defaultValue={formDocument?.captions}
              onChange={(event) =>
                setFormDocument(() => {
                  return {
                    ...formDocument,
                    captions: event.target.value,
                  };
                })
              }
              isMultiline
              labelComponent={
                <div>
                  <Typography level={"cms_form_label"}>
                    {labels.CAPTION}
                  </Typography>
                  <Typography
                    level={"cms_form_label"}
                    className={classes.caption_limit}
                  >
                    {labels.CAPTION_LIMIT}
                  </Typography>
                </div>
              }
              className={classes.text_field}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.bottom_actions} container spacing={2}>
        <Grid item xs={6}>
          {""}
        </Grid>
        <Grid item xs={3}>
          <Button
            level="cms_button"
            variant="contained"
            onClick={() => afterAction()}
            className={classes.remove_button}
          >
            {labels.CANCEL}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            level="cms_button"
            variant="contained"
            onClick={handleSubmit}
            className={classes.save_button}
          >
            {labels.SAVE}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

PDFActions.propTypes = {
  document: PropTypes.instanceOf(Document).isRequired,
  afterAction: PropTypes.func,
};

export default PDFActions;
