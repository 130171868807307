import { useState, useEffect } from "react";
import { browserName, browserVersion } from "react-device-detect";

const useBrowserValidator = () => {
  const [isOldBrowser, setIsOldBrowser] = useState(false);

  useEffect(() => {
    const version = parseFloat(browserVersion);
    const checkBrowserCompatibilityForCkEditor5 = () => {
      switch (browserName) {
        case "Internet Explorer":
          return version <= 11;
        case "Safari":
          return version < 10.1;
        case "Chrome":
          return version < 61;
        case "Firefox":
          return version < 60;
        case "Edge":
          return version <= 18;
        default:
          return false;
      }
    };

    setIsOldBrowser(checkBrowserCompatibilityForCkEditor5());
  }, []);

  return isOldBrowser;
};

export default useBrowserValidator;
