import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    row: {
      display: "flex",
    },
    multiple_textfields_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    multiple_textfields: {
      width: "30%",
    },
    event_confirmation_container: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    event_registration_container: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(900)]: {
        "& span": {
          paddingTop: 0,
        },
      },
    },
    event_checkbox: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: theme.spacing(2),
      },
      "& span.MuiCheckbox-root": {
        color: theme.palette.themeColor.secondary,
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.themeColor.secondary,
      },
    },
    event_all_day_checkbox: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: 0,
      },
      "& span.MuiCheckbox-root": {
        color: theme.palette.miscellaneous.gray,
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.themeColor.secondary,
      },
    },
    event_virtual_event_checkbox: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: 0,
      },
      "& span.MuiCheckbox-root": {
        color: theme.palette.miscellaneous.gray,
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.themeColor.secondary,
      },
    },
    dialog_title: {
      "&.MuiDialogTitle-root": {
        margin: 0,
        background: theme.palette.miscellaneous.white,
      },
    },
    dialog_close_button: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      position: "absolute",
      cursor: "pointer",
      right: theme.spacing(2),
      top: theme.spacing(1.5),
    },
    regular_text_field: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    padding_top_bottom_24: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    padding_top_16: {
      paddingTop: theme.spacing(2),
    },
    link_container: {
      width: "100%",
      textAlign: "right",
    },
    summary_container: {
      marginTop: `-${theme.spacing(3)}px`,
      [theme.breakpoints.down(900)]: {
        marginTop: `-${theme.spacing(1)}px`,
        "& span": {
          fontSize: "14px !important",
        },
      },
    },
    edit_link: {
      cursor: "pointer",
      paddingLeft: theme.spacing(1),
    },
  };
});

export { useStyles };
