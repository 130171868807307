import React from "react";
import PropTypes from "prop-types";
import Link from "../../common/Link/Link";
import Typography from "../../common/Typography/Typography";
import labels from "../../config/labels";
import { useStyles } from "./ContactInformationLinks.style";

const ContactInformationLink = ({ type, url, isSecondarySocialLink }) => {
  const classes = useStyles();

  switch (type) {
    case "website":
      return (
        <Link
          color="black"
          level="paragraph_1"
          url={url}
          className={classes.website_link}
          children={labels.OUR_WEBSITE}
          target="_blank"
          rel="noopener noreferrer"
          underlined
        />
      );
    case "facebook":
      return (
        <>
          <Typography color="black" level="paragraph_1">
            {labels.FIND_US_ON}
          </Typography>
          <Link
            color="facebookBlue"
            level="paragraph_1"
            url={url}
            className={classes.facebook_link}
            children={labels.FACEBOOK}
            target="_blank"
            rel="noopener noreferrer"
            underlined
          />
        </>
      );
    case "twitter":
      return (
        <>
          {isSecondarySocialLink ? (
            <Typography color="black" level="paragraph_1">
              {labels.DASH_SEPARATOR}
            </Typography>
          ) : (
            <Typography color="black" level="paragraph_1">
              {labels.FIND_US_ON}
            </Typography>
          )}
          <Link
            color="twitterBlue"
            level="paragraph_1"
            url={url}
            className={classes.twitter_link}
            children={labels.TWITTER}
            target="_blank"
            rel="noopener noreferrer"
            underlined
          />
        </>
      );
      case "instagram":
        return (
          <>
            {isSecondarySocialLink ? (
              <Typography color="black" level="paragraph_1">
                {labels.DASH_SEPARATOR}
              </Typography>
            ) : (
              <Typography color="black" level="paragraph_1">
                {labels.FIND_US_ON}
              </Typography>
            )}
            <Link
              color="instagram_pink"
              level="paragraph_1"
              url={url}
              className={classes.instagram_link}
              children={labels.INSTAGRAM}
              target="_blank"
              rel="noopener noreferrer"
              underlined
            />
          </>
          );
        
    default:
      break;
  }
};

ContactInformationLink.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
  isSecondarySocialLink: PropTypes.bool,
};

ContactInformationLink.defaultProps = {
  isSecondarySocialLink: true,
};

export default ContactInformationLink;
