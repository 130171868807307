import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    social_network_icons_container: {},
    no_printing: {
      "@media print": {
        display: "none",
      },
    },
    no_display: {},
  };
});

export { useStyles };
