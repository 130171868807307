import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import RealEstateCard from "../../ContentCard/RealEstateCard/RealEstateCard";
import { useStyles } from "./IndexCards.style";

const RealEstatesIndexCards = ({ realEstates }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.cards_container,
        realEstates?.length >= 2 && classes.space_evenly
      )}
    >
      {realEstates.map((realEstate) => (
        <RealEstateCard
          content={realEstate}
          customClasses={{ card: classes.index_card }}
        />
      ))}
    </div>
  );
};

RealEstatesIndexCards.propTypes = {
  realEstates: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      address: PropTypes.string,
      bedrooms: PropTypes.string,
      bathrooms: PropTypes.string,
      property_status: PropTypes.string,
      url: PropTypes.string,
      social_media_information: {
        url: PropTypes.string,
        title: PropTypes.string,
      },
      media_content: PropTypes.arrayOf(
        PropTypes.shape({
          contentUrl: PropTypes.string,
          alt_text: PropTypes.string,
          isVideo: PropTypes.bool,
        })
      ),
    })
  ),
};

export default RealEstatesIndexCards;
