import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ApplyForExemption.style";

import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import ApplyForExemptionConfirmationModal from "./ApplyForExemptionConfirmationModal";
import ApplyForExemptionTownSelectModal from "./ApplyForExemptionTownSelectModal";
import ExemptionPostRequestModal from "./ExemptionPostRequestModal";
import { isValidString } from "../../../utils/helper";

const ApplyForExemption = ({
  shouldOpenApplyForExemptionTownPickerModal,
  shouldOpenApplyForExemptionConfirmationModal,
  envUrl,
  townsWithClusters,
  currentUserId,
  onCancel,
  onRequestSent,
  openPostSubmitModal,
  setOpenPostSubmitModal,
  successMessage,
  setSuccessMessage,
}) => {
  const classes = useStyles();

  const [openExemptionConfirmationModal, setOpenExemptionConfirmationModal] =
    useState(shouldOpenApplyForExemptionConfirmationModal);
  const [openChooseExemptionTownsModal, setOpenChooseExemptionTownsModal] =
    useState(shouldOpenApplyForExemptionTownPickerModal);

  const backToExemptionConfirmationModal = () => {
    setOpenChooseExemptionTownsModal(false),
      setOpenExemptionConfirmationModal(true);
  };

  useEffect(() => {
    setOpenChooseExemptionTownsModal(
      shouldOpenApplyForExemptionTownPickerModal
    );
  }, [shouldOpenApplyForExemptionTownPickerModal]);

  useEffect(() => {
    setOpenExemptionConfirmationModal(
      shouldOpenApplyForExemptionConfirmationModal
    );
  }, [shouldOpenApplyForExemptionConfirmationModal]);

  const handleConfirmationClose = () => {
    setOpenExemptionConfirmationModal(false);
    onCancel();
  };

  const handleTownPickerClose = () => {
    setOpenChooseExemptionTownsModal(false);
    onCancel();
  };

  return (
    <Fragment>
      <PopUpDialog
        onClose={handleConfirmationClose}
        openState={openExemptionConfirmationModal}
        title={""}
        content={
          <ApplyForExemptionConfirmationModal
            closeConfirmationDialog={handleConfirmationClose}
            openTownSelectionDialog={() =>
              setOpenChooseExemptionTownsModal(true)
            }
          />
        }
        titleClass={classes.exemption_dialog_title}
        closeButtonColor={"#F98C10"}
        closeButtonClass={classes.exemption_dialog_close_button}
      />
      <PopUpDialog
        onClose={
          shouldOpenApplyForExemptionConfirmationModal
            ? () => setOpenChooseExemptionTownsModal(false)
            : () => handleTownPickerClose()
        }
        onBack={
          shouldOpenApplyForExemptionConfirmationModal
            ? () => backToExemptionConfirmationModal
            : null
        }
        openState={openChooseExemptionTownsModal}
        title={""}
        content={
          <ApplyForExemptionTownSelectModal
            envUrl={envUrl}
            townsWithClusters={townsWithClusters}
            currentUserId={currentUserId}
            handleSuccess={setOpenPostSubmitModal}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            onRequestSent={onRequestSent}
          />
        }
        titleClass={classes.exemption_dialog_title}
        closeButtonColor={"#F98C10"}
        closeButtonClass={classes.exemption_dialog_close_button}
        backButtonColor={
          shouldOpenApplyForExemptionConfirmationModal ? "#F98C10" : ""
        }
        backButtonClass={
          shouldOpenApplyForExemptionConfirmationModal
            ? classes.exemption_dialog_back_button
            : ""
        }
      />
      <PopUpDialog
        onClose={() => setOpenPostSubmitModal(false)}
        openState={openPostSubmitModal}
        title={""}
        content={
          <ExemptionPostRequestModal
            submitExemptionRequestSuccessMessage={successMessage}
            closePostSubmitModal={() => setOpenPostSubmitModal(false)}
            closeTownPickerModal={() => setOpenChooseExemptionTownsModal(false)}
          />
        }
        titleClass={classes.exemption_dialog_title}
        closeButtonColor={"#F98C10"}
        closeButtonClass={classes.exemption_dialog_close_button}
      />
    </Fragment>
  );
};

ApplyForExemption.propTypes = {
  shouldOpenApplyForExemptionTownPickerModal: PropTypes.bool,
  shouldOpenApplyForExemptionConfirmationModal: PropTypes.bool,
  townsWithClusters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name_with_zip_code: PropTypes.string,
      cluster_towns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  currentUserId: PropTypes.number,
  envUrl: PropTypes.string,
  onRequestSent: PropTypes.func,
  openPostSubmitModal: PropTypes.bool,
  setOpenPostSubmitModal: PropTypes.func,
  successMessage: PropTypes.string,
  setSuccessMessage: PropTypes.func,
};

ApplyForExemption.defaultProps = {
  shouldOpenApplyForExemptionConfirmationModal: false,
};

export default ApplyForExemption;
