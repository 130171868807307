import React, { useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import { useStyles } from "./MainObituary.style";
import Link from "../../../common/Link/Link";
import Typography from "../../../common/Typography/Typography";
import { getTextFromHtml, isVideoPresent } from "../../../utils/helper";
import VideoPlayer from "../../../common/VideoPlayer/VideoPlayer";
import SocialNetworkShareButtons from "../../SocialNetworkShareButtons/SocialNetworkShareButtons";
import { printDates } from "../../../services/obituaries";

const MainObituary = ({ mainObituary }) => {
  const classes = useStyles();

  return (
    <div className={classes.main_obituary_container}>
      <div className={classes.image_container}>
        {isVideoPresent(mainObituary) && (
          <VideoPlayer
            url={mainObituary.video_url}
            width={"100%"}
            height={"250px"}
            showControls={true}
          />
        )}
        {!isVideoPresent(mainObituary) && (
          <Link
            url={mainObituary.obituary_url}
            children={
              <img
                alt={labels.MAIN_OBITUARY_IMAGE_ALT}
                src={mainObituary.photo_url}
                className={classes.image}
              />
            }
          />
        )}
      </div>
      <div className={classes.info_container}>
        <div className={classes.info}>
          <Link
            url={mainObituary.obituary_url}
            children={
              <Typography level="h2_header_2" color="black" bold>
                {mainObituary.name}
              </Typography>
            }
          />

          <Typography level="h2_header_2" color="black">
            {printDates({
              birth: mainObituary?.date_of_birth,
              death: mainObituary?.date_of_death,
            })}
          </Typography>
        </div>
        <div className={classes.description_container}>
          <div className={classes.description}>
            <Typography level="paragraph_2" color="black">
              <div
                dangerouslySetInnerHTML={{
                  __html: mainObituary.description,
                }}
              />
            </Typography>
          </div>
          <div className={classes.links_container}>
            <div className={classes.share_this_container}>
              <SocialNetworkShareButtons
                url={mainObituary.obituary_url}
                title={labels.SHARING_TITLE + mainObituary.name}
                socialNetworks={["facebook", "twitter"]}
                iconsSize={30}
                paddingWithinButtons={10}
                containerWidth={160}
              />
            </div>
            <Link
              url={mainObituary.obituary_url}
              children={
                <Typography level="paragraph_2" color="gray" bold underlined>
                  {labels.READ_MORE}
                </Typography>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MainObituary.propTypes = {
  mainObituary: PropTypes.shape({
    photo_url: PropTypes.string,
    name: PropTypes.string,
    date_of_birth: PropTypes.any,
    date_of_death: PropTypes.any,
    description: PropTypes.string,
    obituary_url: PropTypes.string,
  }),
};

export default MainObituary;
