import React from "react";
import PropTypes from "prop-types";

const TriangleBulletIcon = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.45986 4.29618C10.2398 4.65199 10.2398 5.75996 9.45986 6.11577L2.02825 9.50616C1.36602 9.80828 0.613191 9.32425 0.613191 8.59637L0.613191 1.81559C0.613192 1.0877 1.36602 0.603678 2.02825 0.905794L9.45986 4.29618Z"
        fill={fillColor}
      />
    </svg>
  );
};

TriangleBulletIcon.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

TriangleBulletIcon.defaultProps = {
  fillColor: "#7FC035",
};

export default TriangleBulletIcon;
