import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    actions: {
      display: "flex",
      justifyContent: "end",
    },
    edit_action_container: {
      display: "flex",
      justifyContent: "flex-end",
    },
  };
});

export { useStyles };
