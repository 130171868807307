import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import HorizontalDivider from "../../common/HorizontalDivider/HorizontalDivider";
import Link from "../../common/Link/Link";
import Typography from "../../common/Typography/Typography";
import { getUserContributions } from "../../services/users";
import { useStyles } from "./ContributionsBlock.style";
import labels from "../../config/labels";
import Spinner from "../../common/Spinner/Spinner";

const ContributionsBlock = ({ userSlug }) => {
  const classes = useStyles();

  const [contributions, setContributions] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [shouldRenderContributions, setShouldRenderContributions] =
    useState(false);

  const fetchUsersContributionList = async () => {
    setIsDataLoading(true);
    const response = await getUserContributions(userSlug);

    if (response && response?.status === 200) {
      setContributions(response?.data?.contributions);
      if (response?.data?.total_contributions > 0) {
        setShouldRenderContributions(true);
      }
    }

    setIsDataLoading(false);
  };

  useEffect(() => {
    fetchUsersContributionList();
  }, []);

  const ContentsList = ({ contents }) => {
    return (
      <>
        <HorizontalDivider className={classes.horizontal_divider} />

        {contents?.map((content, index) => (
          <div
            key={`content-list-item-${index}`}
            className={classes.content_block}
          >
            <Link
              level="h4_sub_header_4"
              children={content.name}
              url={content.path}
              className={classes.content_name_link}
              target="_blank"
            />
            <Typography level="t2_text_2" color="gray" bold>
              {content.published_at}
            </Typography>
          </div>
        ))}
      </>
    );
  };

  const ContributionsList = () => {
    if (shouldRenderContributions && !isDataLoading) {
      return <ContentsList contents={contributions} />;
    } else if (isDataLoading) {
      return <Spinner withLoadingOverlay={true} />;
    }
  };

  return (
    <div>
      {shouldRenderContributions && (
        <div>
          <Typography level="h3" bold>
            {labels.RECENT_CONTRIBUTIONS}
          </Typography>
          <ContributionsList />
        </div>
      )}
    </div>
  );
};

ContributionsBlock.propTypes = {
  userSlug: PropTypes.string,
};

export default ContributionsBlock;
