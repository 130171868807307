import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    pagination: {
      "& .Mui-selected": {
        backgroundColor: "unset !important",
        color: "#F98C10",
        fontWeight: "bold",
      },
      "& .MuiPaginationItem-page": {
        fontWeight: "bold",
      },
    },
  };
});

export { useStyles };
