import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    pickers_container: {
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%",
      marginBottom: theme.spacing(2),
    },
    picker_labels: {
      padding: 0,
      margin: `${theme.spacing(3)}px 0px 0px 0px`,
    },
    datepicker: {
      "&.MuiFormControl-marginNormal": {
        marginRight: theme.spacing(10),
      },
    },
    multiple_textfields_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(4),
    },
    multiple_textfields: {
      width: "45%",
    },
    event_confirmation_container: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    event_confirmation_checkbox: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: theme.spacing(2),
      },
      "& span.MuiCheckbox-root": {
        color: theme.palette.themeColor.secondary,
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.themeColor.secondary,
      },
    },
    dialog_title: {
      "&.MuiDialogTitle-root": {
        margin: 0,
        background: theme.palette.miscellaneous.white,
      },
    },
    dialog_close_button: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      position: "absolute",
      cursor: "pointer",
      right: theme.spacing(2),
      top: theme.spacing(1.5),
    },
    display_block: {
      display: "block",
    },
    padding_top_bottom_24: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    regular_text_field: {
      paddingBottom: theme.spacing(4),
    },
  };
});

export { useStyles };
