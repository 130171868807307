import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import labels from "../../config/labels";
import { useStyles } from "./AddToCalendarButton.style";
import PlusSign from "../Icons/PlusSign";
import useScript from "../../hooks/useScript";
import { getTextFromHtml } from "../../utils/helper";
import withConfig from "../../utils/withConfig";
import Typography from "../Typography/Typography";
import Tooltip from "../Tooltip/Tooltip";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";

const AddToCalendarButton = ({
  starts,
  ends,
  timezone,
  title,
  description,
  address,
  className,
  isEventPreview,
  textProps,
}) => {
  const classes = useStyles();
  useScript(withConfig("ADD_EVENT"));

  return (
    <>
      {isEventPreview ? (
        <Tooltip
          contents={
            <div className={classes.placeholder_button_for_preview}>
              <Typography
                color={textProps.color}
                level={textProps.level}
                bold={textProps.bold}
              >
                {labels.ADD_TO_CALENDAR}
              </Typography>
              <PlusSign
                className={classes.plus_icon}
                fillColor={textProps.endIconColor}
              />
            </div>
          }
          title={
            <div className={classes.tooltip_text}>{labels.PREVIEW_BUTTON}</div>
          }
          placement="top-start"
          arrow
        />
      ) : (
        <div
          className={clsx(
            "addeventatc",
            classes.button,
            className && className
          )}
          data-dropdown-y="down"
          data-dropdown-x="right"
          data-intel="false"
        >
          <Typography
            color={textProps.color}
            level={textProps.level}
            bold={textProps.bold}
          >
            {labels.ADD_TO_CALENDAR}
          </Typography>
          <PlusSign
            className={classes.plus_icon}
            fillColor={textProps.endIconColor}
          />
          <span className="start">{starts}</span>
          <span className="end">{ends}</span>
          <span className="timezone">{timezone}</span>
          <span className="title">{title}</span>
          <span className="description">{getTextFromHtml(description)}</span>
          <span className="location">{address}</span>
        </div>
      )}
    </>
  );
};

AddToCalendarButton.propTypes = {
  starts: PropTypes.string,
  ends: PropTypes.string,
  timezone: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  address: PropTypes.string,
  className: PropTypes.string,
  isEventPreview: PropTypes.bool,
  textProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    endIconColor: PropTypes.string,
  }),
};

AddToCalendarButton.defaultProps = {
  textProps: {
    color: "gray",
    level: "t2_text_2",
    bold: true,
    endIconColor: "#606060",
  },
};

export default AddToCalendarButton;
