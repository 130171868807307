import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    events_list: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      [theme.breakpoints.down(960)]: {
        marginLeft: theme.spacing(0.85),
      },
    },
    submit_event: {
      width: "100%",
      height: theme.spacing(6),
      textAlign: "center",
      flexGrow: 1,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& button.MuiButton-root": {
        textTransform: "none",
        width: theme.spacing(30),
        height: theme.spacing(6),
        background: theme.palette.themeColor.secondary,
        "& span.MuiButton-label": {
          fontSize: "16px",
          fontFamily: "Montserrat",
        },
      },
      [theme.breakpoints.down(960)]: {
        margin: "auto",
      },
    },
    submit_to_left: {
      width: "auto",
      textAlign: "left",
    },
    bottom_container: {
      display: "flex",
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),
      [theme.breakpoints.down(960)]: {
        flexDirection: "column-reverse",
        marginTop: 0,
      },
    },
    pagination_container: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-end",
      [theme.breakpoints.down(960)]: {
        justifyContent: "space-around",
      },
    },
    sponsor_container: {
      textAlign: "right",
    },
  };
});

export { useStyles };
