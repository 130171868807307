import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    list_type_block: {
      width: "45%",
      height: theme.spacing(30),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down(960)]: {
        width: "100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    top_section: {
      width: "100%",
      height: "80%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    info_container: {
      paddingTop: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      width: "60%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    image_container: {
      paddingTop: theme.spacing(0.5),
      width: "40%",
      textAlign: "right",
      "& img": {
        maxWidth: "100%",
        maxHeight: theme.spacing(22.5),
      },
    },
    read_more_link: {
      fontFamily: "Montserrat !important",
      textDecoration: "underline !important",
      "&:hover": {
        textDecoration: "none !important",
        color: theme.palette.dark.gray,
      },
    },
    link: {
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down(1000)]: {
        maxHeight: theme.spacing(6.25),
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
      },
    },
    bottom_section: {
      paddingTop: theme.spacing(1),
      height: "18%",
      display: "flex",
    },
    plus_icon: {
      marginLeft: theme.spacing(3.5),
      marginBottom: "-1px",
      [theme.breakpoints.down(960)]: {
        marginLeft: theme.spacing(2.5),
      },
    },
    social_networks_sharing: {
      width: "60%",
    },
  };
});

export { useStyles };
