import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const getColor = (color) => {
    switch (color) {
      case "green":
        return theme.palette.themeColor.secondary;
      case "red":
        return theme.palette.miscellaneous.red;

      default:
        break;
    }
  };
  return {
    title: {
      "&.MuiDialogTitle-root": {
        height: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: theme.spacing(1),
        margin: 0,
        background: (color) => getColor(color),
      },
    },
    title_typography: {
      fontSize: "14px",
      fontWeight: "700",
    },
    close_button: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      cursor: "pointer",
    },
    button: {
      "& button.MuiButton-root": {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: theme.spacing(3),
        width: theme.spacing(10.5),
        border: (color) => `2px solid ${getColor(color)}`,
      },
    },
  };
});

export { useStyles };
