import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { useDiy } from "../../../context/DiyContext";
import useSubmitableContentForm from "../useSubmitableContentForm";
import {
  ORGANIZATION_TYPE,
  ORGANIZATION_TYPES,
  ORANGE,
  GREEN,
  ORANGE_HEX,
  GREEN_HEX,
  NON_PROFIT_CHECKBOX,
} from "../diyConstants";
import PaymentsByContributionType from "./ByContributionType/PaymentsByContributionType";
import theme from "../../../common/Theme/themes/default";

const NONPROFIT = "nonprofit";
const GOVERNMENTAL = "governmental";
const POLITICAL_CANDIDATES = "political_candidates";

const PaymentTypes = ({
  isContributor,
  canApplyForExemption,
  townsWithClusters,
  currentUserId,
  envUrl,
  onChange,
  handleExemptionFlowStarted,
  organizationType,
}) => {
  const { showOrHideFacebookBlock, town } = useDiy();
  const { setProperty } = useSubmitableContentForm();
  const {
    palette: {
      dark: { gray },
      themeColor: { primary, secondary },
    },
  } = theme;

  const [selection, setSelection] = useState(
    !!organizationType ? organizationType : ORGANIZATION_TYPES.for_profit
  );

  const getOrganizationTypeSelected = (type) => {
    if (
      organizationType === undefined &&
      type === ORGANIZATION_TYPES.for_profit
    ) {
      return true;
    }
    if (organizationType === type) {
      return true;
    }
    return false;
  };

  const [priceSelectStatus, setPriceSelectStatus] = useState({
    profit: getOrganizationTypeSelected(ORGANIZATION_TYPES.for_profit),
    politicalCandidates: getOrganizationTypeSelected(
      ORGANIZATION_TYPES.political_candidates
    ),
    nonProfit: getOrganizationTypeSelected(ORGANIZATION_TYPES.non_profit),
    government: getOrganizationTypeSelected(ORGANIZATION_TYPES.government),
  });

  const [nonProfitCheckboxStatus, setNonProfitCheckboxStatus] = useReducer(
    (state, action) => {
      const newState = { ...state };

      if (
        getOrganizationTypeSelected(ORGANIZATION_TYPE.non_profit) ||
        priceSelectStatus.nonProfit
      ) {
        newState.selected = action.selected;
      }

      setProperty(NON_PROFIT_CHECKBOX, action.selected);
      return newState;
    },
    {
      selected: false,
    }
  );

  useEffect(() => {
    setProperty(ORGANIZATION_TYPE, selection);
    showOrHideFacebookBlock();
  }, [selection]);

  const colorFromTheme = (option) => {
    if (
      selection === option ||
      option === ORGANIZATION_TYPES.free ||
      option === "single" ||
      option === "primary"
    ) {
      return { key: "primary", hex: primary };
    }

    return {
      key: town?.under_license_contract ? "gray" : "secondary",
      hex: town?.under_license_contract ? gray : secondary,
    };
  };

  const colors = () => {
    return {
      profit: colorFromTheme(ORGANIZATION_TYPES.for_profit),
      non_profit: colorFromTheme(ORGANIZATION_TYPES.non_profit),
      political_candidates: colorFromTheme(
        ORGANIZATION_TYPES.political_candidates
      ),
      government: colorFromTheme(ORGANIZATION_TYPES.government),
      free: colorFromTheme(ORGANIZATION_TYPES.free),
      single: colorFromTheme("single"),
      primary: colorFromTheme("primary"),
      secondary: colorFromTheme("secondary"),
    };
  };

  const handleNonProfitSelection = () => {
    setSelection(ORGANIZATION_TYPES.non_profit);
    onChange(NONPROFIT);
  };

  const handleGovernmentSelection = () => {
    setSelection(ORGANIZATION_TYPES.government);
    onChange(GOVERNMENTAL);
  };

  const handleProfitSelection = () => {
    setSelection(ORGANIZATION_TYPES.for_profit);
    onChange("");
  };

  const handlePoliticalCandidatesSelection = () => {
    setSelection(ORGANIZATION_TYPES.political_candidates);
    onChange(POLITICAL_CANDIDATES);
  };

  const handleExemptionRequest = () => {
    handleExemptionFlowStarted();
  };

  return (
    <PaymentsByContributionType
      handleProfitSelection={handleProfitSelection}
      setPriceSelectStatus={setPriceSelectStatus}
      priceSelectStatus={priceSelectStatus}
      handlePoliticalCandidatesSelection={handlePoliticalCandidatesSelection}
      setNonProfitCheckboxStatus={setNonProfitCheckboxStatus}
      nonProfitCheckboxStatus={nonProfitCheckboxStatus}
      canApplyForExemption={canApplyForExemption}
      isContributor={isContributor}
      townsWithClusters={townsWithClusters}
      envUrl={envUrl}
      currentUserId={currentUserId}
      handleExemptionRequest={handleExemptionRequest}
      handleNonProfitSelection={handleNonProfitSelection}
      handleGovernmentSelection={handleGovernmentSelection}
      colors={colors()}
    />
  );
};

PaymentTypes.propTypes = {
  formType: PropTypes.string,
  canApplyForExemption: PropTypes.bool,
  isContributor: PropTypes.bool,
  townsWithClusters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name_with_zip_code: PropTypes.string,
      cluster_towns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  currentUserId: PropTypes.number,
  envUrl: PropTypes.string,
  handleExemptionFlowStarted: PropTypes.func,
  organizationType: PropTypes.string,
};

export default PaymentTypes;
