import React from "react";
import { useStyles } from "./Games.style";
import GamesTitle from "../../components/Games/GamesTitle/GamesTitle";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import withConfig from "../../utils/withConfig";
import { screenWidthIsHigherThan } from "../../hooks/useMediaQuery";
import values from "../../common/Theme/themes/breakpoints/values";
import { useTown } from "../../context/TownContext";
import Ad from "../../components/Ad/Ad";
import GamesIndex from "../../components/Games/Index/GamesIndex";
import GamesShow from "../../components/Games/Show/GamesShow";
import PropTypes from "prop-types";
import labels from "../../config/labels";
import Typography from "../../common/Typography/Typography";
import clsx from "clsx";
import useGames from "../../hooks/useGames";
import RightBlocksGames from "../../components/Games/RightBlocksGames/RightBlocksGames";
const LEADERBOARD_AD_SIZE = [728, 90];
const LEADERBOARD_MOBILE_AD_SIZE = [320, 100];
const PAGE_TYPE = {
  index: "index",
  show: "show",
};

const Games = ({ pageType, sponsor_info, dataSet }) => {
  const classes = useStyles();

  const { adTargets, town, rightBlocks, videoAd } = useTown();

  const { adBlocks } = useGames({ rightBlocks: rightBlocks });

  return (
    <>
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={withConfig("RECAPTCHA_KEY")}
        scriptProps={{ async: true, defer: true, appendTo: "body" }}
      >
        <div className={classes.container}>
          {screenWidthIsHigherThan(values.sm) ? (
            <div className={classes.top_leaderboard}>
              <Ad
                ad_unit={"tap_leaderboard_001"}
                size={LEADERBOARD_AD_SIZE}
                targets={adTargets}
              />
            </div>
          ) : (
            <div className={classes.top_leaderboard_mobile}>
              <Ad
                ad_unit={"leaderboard_mobile"}
                size={LEADERBOARD_MOBILE_AD_SIZE}
                targets={adTargets}
              />
            </div>
          )}
          <div className={classes.body_section}>
            <div>
              <GamesTitle sponsor_info={sponsor_info} />
              {pageType === PAGE_TYPE.index && (
                <Typography level="h4" className={classes.page_description}>
                  {labels.GAMES_PAGE_DESCRIPTION}
                </Typography>
              )}
            </div>
            <section className={classes.games_section}>
              <div>
                <div>
                  {pageType === PAGE_TYPE.index && <GamesIndex />}
                  {pageType === PAGE_TYPE.show && (
                    <GamesShow classes={classes} townSlug={town?.slug} dataSet={dataSet}/>
                  )}
                </div>
                <div
                  className={clsx(
                    classes.right_block,
                    pageType === PAGE_TYPE.show ? classes.extra_margin : ""
                  )}
                >
                  <RightBlocksGames
                    adBlocks={adBlocks}
                    adTargets={adTargets}
                    videoAd={videoAd}
                  />
                </div>
              </div>
            </section>
          </div>
          {screenWidthIsHigherThan(values.sm) ? (
            <div className={classes.secondary_leaderboard}>
              <Ad
                ad_unit={"tap_leaderboard_002"}
                size={LEADERBOARD_AD_SIZE}
                targets={adTargets}
              />
            </div>
          ) : (
            <div className={classes.secondary_leaderboard_mobile}>
              <Ad
                ad_unit={"leaderboard_mobile_1"}
                size={LEADERBOARD_MOBILE_AD_SIZE}
                targets={adTargets}
              />
            </div>
          )}
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
};

Games.propTypes = {
  pageType: PropTypes.string,
  sponsor_info: PropTypes.object,
};

export default Games;
