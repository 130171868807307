import React from "react";
import PropTypes from "prop-types";

const PlusSign = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6.42969" width="2.57143" height="15" fill={fillColor} />
      <rect
        x="15"
        y="6"
        width="2.57143"
        height="15"
        transform="rotate(90 15 6)"
        fill={fillColor}
      />
    </svg>
  );
};

PlusSign.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

PlusSign.defaultProps = {
  fillColor: "white",
};

export default PlusSign;
