import React from "react";
import ClickableIcon from "../../../../../common/Icons/ClickableIcon/ClickableIcon";
import PriceFiftyDollars from "../../../../../common/Icons/PriceFiftyDollars";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import labels from "../../../../../config/labels";
import { useDiy } from "../../../../../context/DiyContext";
import { useStyles } from "./ObituaryPayments.style";

const ObituaryPayments = ({ colors }) => {
  const isMobile = screenWidthIsLowerThan(values.md);
  const { town } = useDiy();
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <ClickableIcon
        icon={<PriceFiftyDollars fillColor={colors.single.hex} />}
        lowerText={
          town?.under_license_contract
            ? labels.PER_PUBLICATION
            : labels.PER_SITE
        }
        textColor={colors.single.key}
        textHoverColor={colors.single.key}
        className={classes.icon_container}
        typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
      />
    </div>
  );
};

export default ObituaryPayments;
