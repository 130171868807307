import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ShowLayout.style";
import ShowLayoutDesktop from "./Desktop/ShowLayoutDesktop";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import ShowLayoutMobile from "./Mobile/ShowLayoutMobile";

const RealEstateShowLayout = ({
  realEstateListing,
  backToRealEstateListingsUrl,
  contentTitle,
  editUrl,
  canEdit,
  showContentOptions,
  sponsorInfo,
  isContentPreview,
  canDelete,
}) => {
  const classes = useStyles();

  return screenWidthIsLowerThan(960) ? (
    <ShowLayoutMobile
      realEstateListing={realEstateListing}
      backToRealEstateListingsUrl={backToRealEstateListingsUrl}
      showContentOptions={showContentOptions}
      sponsorInfo={sponsorInfo}
      editUrl={editUrl}
      canEdit={canEdit}
      contentTitle={contentTitle}
      isContentPreview={isContentPreview}
      canDelete={canDelete}
    />
  ) : (
    <ShowLayoutDesktop
      realEstateListing={realEstateListing}
      backToRealEstateListingsUrl={backToRealEstateListingsUrl}
      showContentOptions={showContentOptions}
      sponsorInfo={sponsorInfo}
      editUrl={editUrl}
      canEdit={canEdit}
      contentTitle={contentTitle}
      isContentPreview={isContentPreview}
      canDelete={canDelete}
    />
  );
};

RealEstateShowLayout.propTypes = {
  realEstateListing: PropTypes.shape({
    price: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    address: PropTypes.string,
    url: PropTypes.string,
    slug: PropTypes.string,
    realtor_email: PropTypes.string,
    status: PropTypes.string,
    information_blocks: PropTypes.arrayOf(
      PropTypes.shape({
        section_id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            })
          ),
        ]),
      })
    ),
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        content_url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        is_video: PropTypes.bool,
        alt_text: PropTypes.string,
        captions: PropTypes.string,
        credits: PropTypes.string,
      })
    ),
  }),
  backToRealEstateListingsUrl: PropTypes.string,
  contentTitle: PropTypes.string,
  editUrl: PropTypes.string,
  showContentOptions: PropTypes.bool,
  sponsorInfo: PropTypes.shape({
    page_type: PropTypes.string,
    sponsor_attr: PropTypes.string,
    top_local_news: PropTypes.bool,
    town: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    columns_slug: PropTypes.arrayOf(PropTypes.string),
    sections_slug: PropTypes.arrayOf(PropTypes.string),
  }),
  isContentPreview: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default RealEstateShowLayout;
