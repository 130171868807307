import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "../../../common/TextField/TextField";
import { useStyles } from "./TownSearch.style";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import CheckboxGroup from "../../../common/CheckboxGroup/CheckboxGroup";

const TownSearch = ({
  placeholder,
  itemsToSearchFrom,
  searchFieldClassName,
  handleResultSelection,
  selectedState,
  areThereSearchResults,
  onChildChange,
  town,
}) => {
  const classes = useStyles();
  const [valueToSearch, setValueToSearch] = useState("");
  const [foundItems, setFoundItems] = useState([]);

  useEffect(() => {
    let foundTownsByCounty = [];
    if (valueToSearch.length >= 2) {
      foundTownsByCounty = itemsToSearchFrom.map((item) => {
        return {
          id: item.id,
          label: item.label,
          children: item.children.filter((child) =>
            child.label.toLowerCase().includes(valueToSearch.toLowerCase())
          ),
        };
      });
    }
    if (foundTownsByCounty.length >= 1) {
      foundTownsByCounty = foundTownsByCounty.filter(
        (item) => item.children.length > 0
      );
    }
    setFoundItems(foundTownsByCounty);
  }, [valueToSearch]);

  const getSearchTitle = () => {
    let searchTitle = "";
    if (town?.under_license_contract) {
      if (foundItems.length >= 1) {
        searchTitle =
          labels.PUBLICATIONS_LIKE +
          "'" +
          valueToSearch +
          "' in " +
          selectedState[0] +
          ":";
      } else if (foundItems.length < 1 && valueToSearch.length >= 2) {
        searchTitle =
          labels.PUBLICATION +
          "'" +
          valueToSearch +
          "'" +
          labels.WAS_NOT_FOUND_IN +
          selectedState[0];
      }
    } else {
      if (foundItems.length >= 1) {
        searchTitle = labels.FOUND_SITES_IN + selectedState[0] + ":";
      } else if (foundItems.length < 1 && valueToSearch.length >= 2) {
        searchTitle =
          labels.SITE +
          "'" +
          valueToSearch +
          "'" +
          labels.WAS_NOT_FOUND_IN +
          selectedState[0];
      }
    }

    return searchTitle;
  };

  const determineCollapsedStatus = () => {
    if (getSearchTitle() === "") {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    areThereSearchResults(determineCollapsedStatus());
  }, [determineCollapsedStatus()]);

  return (
    <>
      <TextField
        className={clsx(
          classes.search_field,
          searchFieldClassName && searchFieldClassName
        )}
        placeholder={placeholder}
        onChange={(e) => setValueToSearch(e.target.value)}
      />
      <div
        className={clsx(
          getSearchTitle() === ""
            ? classes.no_display
            : classes.search_results_container,
          classes.options
        )}
      >
        <div className={classes.padding_bottom_8}>
          <Typography level="t4_text_4" color="tapintoGreen" bold>
            {getSearchTitle()}
          </Typography>
        </div>
        <CheckboxGroup
          items={foundItems}
          showAllItemsCollapsed={true}
          onChange={handleResultSelection}
          onChildChange={onChildChange}
        />
      </div>
    </>
  );
};

TownSearch.propTypes = {
  placeholder: PropTypes.string,
  itemsToSearchFrom: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      selected: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          selected: PropTypes.bool,
          price: PropTypes.number,
          fb_price: PropTypes.number,
        })
      ),
    })
  ),
  searchFieldClassName: PropTypes.string,
  handleResultSelection: PropTypes.func,
  selectedState: PropTypes.array,
  areThereSearchResults: PropTypes.func,
  onChildChange: PropTypes.func,
};

export default TownSearch;
