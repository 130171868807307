import React, { useEffect, useState } from "react";
import useGames from "../../../hooks/useGames";
import PropTypes from "prop-types";
import Typography from "../../../common/Typography/Typography";
import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";
import withConfig from "../../../utils/withConfig";
import Divider from "../../../common/Divider/Divider";
import { pathToUrl } from "../../../utils/helper";

const GAME_SLUG = window.location.pathname.split("/").pop();
const GAME_ATTRIBUTES = {
  className: "pm-embed-div",
  dataPage: "date-picker",
  dataHeight: "900px",
};

const GamesShow = ({ classes, townSlug, dataSet }) => {
  const [gameSeries, setGameSeries] = useState();
  const { game } = useGames({ isForIndex: false, gameSlug: GAME_SLUG });
  const indexUrl = pathToUrl(withConfig("INDEX_GAMES_URL"), {
    town_slug: townSlug,
  });
  const showUrl = pathToUrl(withConfig("SHOW_GAME_URL"), {
    town_slug: townSlug,
    game_slug: GAME_SLUG,
  });
  const breadcrumbItems = [
    { url: indexUrl, label: "Games" },
    { url: showUrl, label: game?.game_name },
  ];

  useEffect(() => {
    setGameSeries(game);
  }, [game]);

  return (
    <>
      <div>
        <Breadcrumbs items={breadcrumbItems} />
        <Divider />
      </div>
      <Typography level="h4" className={classes.games_title}>
        {gameSeries?.game_name}
      </Typography>
      {gameSeries &&  (
        <div
          className={GAME_ATTRIBUTES.className}
          data-set={dataSet}
          data-page={GAME_ATTRIBUTES.dataPage}
          data-height={GAME_ATTRIBUTES.dataHeight}
        />
      )}
    </>
  );
};

GamesShow.propTypes = {
  classes: PropTypes.object,
  townSlug: PropTypes.string,
};

export default GamesShow;
