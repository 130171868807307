import React from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import Typography from "../../../../common/Typography/Typography";
import { FACEBOOK_BLUE, GREEN } from "../../diyConstants";
import { useStyles } from "./FacebookList.style";
import FacebookShoppingListRow from "../FacebookShoppingListRow/FacebookShoppingListRow";

const FacebookList = ({ facebookTownsSelected, itemCount }) => {
  const classes = useStyles();

  const townsCount = () => {
    const selected = facebookTownsSelected.filter(
      (town) => town.fb_selected
    ).length;
    return ` (${selected} / ${facebookTownsSelected.length})`;
  };

  return (
    <div className={classes.facebook_line_items_container}>
      <Typography level="paragraph_2" bold color={FACEBOOK_BLUE}>
        {labels.SHOPPING_CART_FACEBOOK}
        {townsCount()}
      </Typography>
      <div
        className={
          facebookTownsSelected.length > 0 ? classes.with_items : classes.empty
        }
      >
        {facebookTownsSelected.length > 0 ? (
          facebookTownsSelected.map((town) => (
            <FacebookShoppingListRow
              key={`town-shopping-cart-item-${town.id}`}
              town={town}
              isFacebook={true}
              itemQuantity={itemCount}
            />
          ))
        ) : (
          <Typography level="t1_text_1" color={GREEN} bold>
            {labels.NO_FACEBOOK_SELECTED}
          </Typography>
        )}
      </div>
    </div>
  );
};

FacebookList.propTypes = {
  facebookTownsSelected: PropTypes.array,
  itemCount: PropTypes.number,
};

export default FacebookList;
