import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { palette, spacing } = theme;
  return {
    container: {
      width: "100%",
      paddingBottom: spacing(2),
    },
    input_focused: {
      border: "none !important",
      boxShadow: "none !important",
      backgroundColor: "transparent !important",
    },
    focused: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${palette.themeColor.primary} !important`,
      },
    },
    paper: {
      borderRadius: "0px !important",
      paddingBottom: "0px !important",
    },
    option: {
      borderBottom: `1px solid ${palette.light.grey}`,
    },
    label: {
      paddingTop: spacing(5),
      color: palette.dark.gray,
    },
    label_error: {
      color: palette.miscellaneous.red,
    },
    root_error: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${palette.miscellaneous.red} !important`,
      },
    },
  };
});

export { useStyles };
