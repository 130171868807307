import { makeStyles } from "../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    payments_container: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
  };
});

export { useStyles };
