import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    divider: {
      margin: `${theme.spacing(1)}px 0px`,
      border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
      [theme.breakpoints.down(960)]: {
        margin: `${theme.spacing(2)}px 0px`,
      },
    },
    header: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(960)]: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
    sponsor: {
      width: "40%",
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down(960)]: {
        marginLeft: 0,
      },
      "& :first-child": {
        paddingBottom: 0,
      },
    },
    pagination_container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    columns_pagination: {
      "& .Mui-selected": {
        backgroundColor: "unset !important",
        color: theme.palette.tapintoBranding.greenVariationDark,
        fontWeight: "bold",
      },
      "& .MuiPaginationItem-page": {
        fontWeight: "bold",
      },
    },
  };
});

export { useStyles };
