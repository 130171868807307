import React, { useReducer } from "react";
import PropTypes from "prop-types";
import Typography from "../../common/Typography/Typography";
import { useStyles } from "./CopyCodeToClipboard.style";
import Button from "../../common/Button/Button";
import labels from "../../config/labels";
import Grid from "../../common/Grid/Grid";
import Snackbar from "../../common/Snackbar/Snackbar";
import {
  snackbarInitialState,
  snackbarReducer,
} from "../../reducers/snackbars/snackbarsReducers";
import Code from "../../common/Code/Code";

const CopyCodeToClipboard = ({ label, htmlCode, language, showCode }) => {
  const classes = useStyles();
  const [snackbarProps, setSnackbarProps] = useReducer(
    snackbarReducer,
    snackbarInitialState
  );

  const handleCopyClick = () => {
    navigator.clipboard.writeText(htmlCode);
    setSnackbarProps({
      message: labels.SUCCESSFULLY_COPIED_TO_CLIPBOARD,
      variant: "success center_in_screen",
      open: true,
    });
  };

  return (
    <>
      <Snackbar
        onClose={() => setSnackbarProps(snackbarInitialState)}
        open={snackbarProps.open}
        message={snackbarProps.message}
        variant={snackbarProps.variant}
      />
      <Grid container >
        {showCode && (
          <Grid item xs={8}>
            <Code code={htmlCode} language={language} />
          </Grid>
        )}
        {!showCode && (
          <Grid item xs={9}>
            <Typography level="t4_text_4">{label}</Typography>
          </Grid>
        )}
        <Grid item xs={3}>
          <Button
            level="cms_button"
            variant="contained"
            onClick={handleCopyClick}
            className={classes.copy_button}
          >
            {labels.COPY}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

CopyCodeToClipboard.propTypes = {
  label: PropTypes.string.isRequired,
  htmlCode: PropTypes.string.isRequired,
  language: PropTypes.string,
  showCode: PropTypes.bool,
};

CopyCodeToClipboard.defaultProps = {
  language: "html",
  showCode: false,
};

export default CopyCodeToClipboard;
