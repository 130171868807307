import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      height: "40vh",
      padding: theme.spacing(4),
      textAlign: "center",
    },
    button: {
      borderRadius: "0px !important",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px !important`,
      backgroundColor: `${theme.palette.themeColor.secondary} !important`,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: theme.palette.dark.black,
      },
    },
  };
});

export { useStyles };
