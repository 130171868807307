import { makeStyles } from "../../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    radiobutton_input_container: {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
      width: "100%",
    },
    radiobutton_address_input_container: {
      display: "flex",
      marginTop: theme.spacing(2),
      width: "100%",
    },
    radio_button: {
      "&.MuiButtonBase-root": {
        paddingLeft: 0,
      },
      "&.MuiButtonBase-root.Mui-checked": {
        color: theme.palette.themeColor.primary,
      },
    },
    regular_text_field: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  };
});

export { useStyles };
