import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    mobile_padding: {
      [theme.breakpoints.down(1160)]: {
        paddingLeft: (isMobile) => (isMobile ? 0 : theme.spacing(3)),
      },
    },
  };
});

export { useStyles };
