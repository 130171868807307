import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    column_articles_container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      width: "100%",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(960)]: {
        marginTop: theme.spacing(3),
      },
    },
    divider: {
      marginBottom: theme.spacing(2),
      width: "100%",
      border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
      [theme.breakpoints.down(960)]: {
        display: "none",
      },
    },
  };
});

export { useStyles };
