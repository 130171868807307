import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ObituaryBody from "../../../components/Obituary/ObituaryBody/ObituaryBody";
import { handleSearch } from "../../../services/obituaries";
import { getNamesForDropdownSearch } from "../../../services/obituaries";

const Obituary = ({
  obituary,
  back_to_index_url,
  town_id,
  town_slug,
  can_edit,
  edit_url,
  is_obituary_preview,
  obituary_preview_services,
  sponsor_info,
}) => {
  const [availableNames, setAvailableNames] = useState([]);

  useEffect(() => {
    getNamesForDropdownSearch(town_id, obituary.id, setAvailableNames);
  }, []);

  return (
    <>
      <ObituaryBody
        obituary={obituary}
        backToIndexUrl={back_to_index_url}
        handleSearch={handleSearch}
        availableNames={availableNames}
        townId={town_id}
        townSlug={town_slug}
        canEdit={can_edit}
        editUrl={edit_url}
        isObituaryPreview={is_obituary_preview}
        obituaryPreviewServices={obituary_preview_services}
        sponsorInfo={sponsor_info}
      />
    </>
  );
};

Obituary.propTypes = {
  obituary: PropTypes.shape({
    id: PropTypes.number,
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    name: PropTypes.string,
    date_of_birth: PropTypes.string,
    date_of_death: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
  }),
  back_to_index_url: PropTypes.string,
  town_id: PropTypes.number,
  town_slug: PropTypes.string,
  can_edit: PropTypes.bool.isRequired,
  edit_url: PropTypes.string,
  is_obituary_preview: PropTypes.bool,
  obituary_preview_services: PropTypes.any,
  sponsor_info: PropTypes.shape({
    page_type: PropTypes.string,
    sponsor_attr: PropTypes.string,
    top_local_news: PropTypes.bool,
    town: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    columns_slug: PropTypes.arrayOf(PropTypes.string),
    sections_slug: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default Obituary;
