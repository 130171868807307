import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    header_container: {
      width: "100%",
      [theme.breakpoints.down(960)]: {
        justifyContent: "center",
      },
    },
    filter_container: {
      width: "85%",
      textAlign: "left !important",
      [theme.breakpoints.down(960)]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    chip: {
      backgroundColor: `rgba(127, 192, 53, 0.3) !important`,
      margin: theme.spacing(1),
      maxWidth: 250,
      borderRadius: 6,
      "& span": {
        color: `${theme.palette.dark.gray} !important`,
      },
    },
    search_button: {
      backgroundColor: `rgba(127, 192, 53, 0.3) !important`,
      [theme.breakpoints.down(960)]: {
        bottom: theme.spacing(2),
      },
    },
    filter_icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    filter_with_button_container: {
      display: "flex",
      width: "40%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "flex-end",
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
  };
});

export { useStyles };
