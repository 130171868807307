import React from "react";
import PropTypes from "prop-types";
import Error404Vector from "../../common/Icons/Vectors/Errors/Error404Vector";
import Typography from "../../common/Typography/Typography";
import labels from "../../config/labels";
import { replaceInHtmlText } from "../../utils/helper";
import { useStyles } from "./Error.style";
import Error500Vector from "../../common/Icons/Vectors/Errors/Error500Vector";
import {
  TYPOGRAPHY_LEVEL,
  BACK_TO_HOMEPAGE,
  GO_BACK,
  TAPINTO_NET,
  INFO_AT_TAPINTO_NET,
  PHONE_CONTACT,
  NOT_FOUND,
  INTERNAL_SERVER_ERROR,
} from "./errorConstants";

const Error = ({ status }) => {
  const classes = useStyles();

  const getErrorTopContent = () => {
    switch (status) {
      case NOT_FOUND:
        return (
          <>
            <Error404Vector className={classes.svg_vector} />
            <Typography level={TYPOGRAPHY_LEVEL}>
              {labels.ERROR_404_PAGE_NOT_FOUND}
            </Typography>
          </>
        );
      case INTERNAL_SERVER_ERROR:
        return <Error500Vector className={classes.svg_vector} />;
      default:
        return <div className={classes.other_errors}>{status}</div>;
    }
  };

  return (
    <div className={classes.general_container}>
      {getErrorTopContent()}

      <div className={classes.text_container}>
        {status !== NOT_FOUND && (
          <>
            {replaceInHtmlText(
              labels.TECHNICAL_DIFFICULTIES,
              [
                {
                  toReplace: TAPINTO_NET,
                  toReplaceWith: labels.HTML_LINK_TAPINTO_NET,
                },
              ],
              classes.text_with_links
            )}

            <br />

            {replaceInHtmlText(
              labels.WE_HAVE_BEEN_ALERTED,
              [
                {
                  toReplace: INFO_AT_TAPINTO_NET,
                  toReplaceWith: labels.HTML_INFO_AT_TAPINTO_NET,
                },
                {
                  toReplace: PHONE_CONTACT,
                  toReplaceWith: labels.HTML_PHONE_CONTACT,
                },
              ],
              classes.text_with_links
            )}

            <br />
          </>
        )}

        {replaceInHtmlText(
          labels.YOU_CAN_GO_BACK_OR_GO_BACK_TO_HOMEPAGE,
          [
            {
              toReplace: BACK_TO_HOMEPAGE,
              toReplaceWith: labels.HTML_BACK_TO_HOMEPAGE,
            },
            {
              toReplace: GO_BACK,
              toReplaceWith: labels.HTML_GO_BACK,
            },
          ],
          classes.text_with_links
        )}

        <Typography level={TYPOGRAPHY_LEVEL}>
          {labels.WE_APOLOGIZE_FOR_THE_INCONVINIENCE}
        </Typography>
      </div>
    </div>
  );
};

Error.propTypes = {
  status: PropTypes.number,
};

export default Error;
