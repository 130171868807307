import React from "react";
import Typography from "../../common/Typography/Typography";
import labels from "../../config/labels";
import { useStyles } from "./SponsoredTag.style";

const SponsoredTag = () => {
  const classes = useStyles();
  return (
    <div className={classes.sponsored_content}>
      <Typography level="paragraph_2" color="gray">
        {labels.SPONSORED_CONTENT}
      </Typography>
    </div>
  );
};

export default SponsoredTag;
