import React, { useState } from "react";
import { useStyles } from "./BottomActions.style";
import PropTypes from "prop-types";
import { withDiyContextProvider } from "../../../context/DiyContext";
import {
  CANDIDATE_STATEMENTS,
  CONTENTS_WITH_BOTTOM_DISCLAIMER,
  DIY_FREE_CONTENT_FORMS,
  LETTERS_TO_THE_EDITOR,
} from "../diyConstants";
import Disclaimer from "../../Article/Disclaimer/Disclaimer";
import DiyActions from "../Actions/DiyActions";
import Spinner from "../../../common/Spinner/Spinner";

const BottomActions = ({
  form_type,
  is_edit_form,
  content_id,
  disclaimer_policy_page_links,
  article_disclaimer,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const BottomDisclaimer = () => {
    let category = "";
    let columnCategory = "";
    let pageLinkExists = false;

    switch (form_type) {
      case LETTERS_TO_THE_EDITOR:
        category = "Letters to The Editor";
        columnCategory = "letters_to_editor";
        pageLinkExists = disclaimer_policy_page_links?.find((item) =>
          item.page.includes("letters_to_editor")
        )
          ? true
          : false;
        break;
      case CANDIDATE_STATEMENTS:
        category = "Candidate Statements";
        columnCategory = "candidate_statement";
        pageLinkExists = disclaimer_policy_page_links?.find((item) =>
          item.page.includes("candidate_statement")
        )
          ? true
          : false;
        break;
      default:
        break;
    }

    return (
      pageLinkExists && (
        <div className={classes.bottom_disclaimer}>
          <Disclaimer
            categoryName={category}
            articleColumnCategory={columnCategory}
            disclaimerPolicyPageLinks={disclaimer_policy_page_links}
            isDiyForm={true}
            customDisclaimer={article_disclaimer}
          />
        </div>
      )
    );
  };

  return (
    <>
      {CONTENTS_WITH_BOTTOM_DISCLAIMER.includes(form_type) && (
        <BottomDisclaimer />
      )}

      {(DIY_FREE_CONTENT_FORMS.includes(form_type) || is_edit_form) && (
        <div className={classes.bottom_actions_container}>
          <DiyActions
            setLoadingCheckout={setLoading}
            isEditForm={is_edit_form}
            formType={form_type}
            containerClassName={classes.bottom_action_buttons_container}
            contentId={content_id}
          />
          {loading && (
            <Spinner
              withLoadingOverlay={true}
              loadingOverlayClassName={classes.loading_overlay}
              className={classes.spinner}
              withFadingLabels={true}
            />
          )}
        </div>
      )}
    </>
  );
};

BottomActions.propTypes = {
  form_type: PropTypes.string,
  is_edit_form: PropTypes.bool,
  content_id: PropTypes.number,
  disclaimer_policy_page_links: PropTypes.arrayOf(
    PropTypes.shape({
      page: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  article_disclaimer: PropTypes.string,
};

export default withDiyContextProvider(BottomActions);
