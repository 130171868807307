import React, { useEffect } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import TextField from "../../../../common/TextField/TextField";
import { useStyles } from "./MilestoneFields.style";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import {
  MANDATORY_FIELDS_FOR_MILESTONE,
  CONTACT_PERSON_PRIVATE,
  EMAIL,
  PHONE_NUMBER_PRIVATE,
  HEADLINE,
  MANDATORY_TOWN_OF_RESIDENCY_ERROR,
  TOWN_OF_RESIDENCY,
  CONTENT_ID,
} from "../../diyConstants";

const MilestoneFields = ({ formErrors, content }) => {
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();

  const shouldMarkError = (prop) => {
    let validation;

    if (prop === TOWN_OF_RESIDENCY) {
      validation = MANDATORY_TOWN_OF_RESIDENCY_ERROR;
      if (formErrors.includes(MANDATORY_TOWN_OF_RESIDENCY_ERROR)) {
        return true;
      }
    } else {
      validation = MANDATORY_FIELDS_FOR_MILESTONE.find(
        (mandatory) => mandatory.key === prop
      );

      if (formErrors?.length > 0 && formErrors.includes(validation.error)) {
        return true;
      }
    }
  };

  useEffect(() => {
    if (content?.id) {
      setProperty(HEADLINE, content?.name);
      setProperty(CONTACT_PERSON_PRIVATE, content?.contact_person);
      setProperty(PHONE_NUMBER_PRIVATE, content?.contact_phone);
      setProperty(EMAIL, content?.email);
      setProperty(TOWN_OF_RESIDENCY, content?.town_of_residency);
      setProperty(CONTENT_ID, content?.id);
    }
  }, [content]);

  return (
    <>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.HEADLINE}
          onChange={(e) => setProperty(HEADLINE, e.target.value)}
          error={shouldMarkError(HEADLINE)}
          defaultValue={content?.name}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          sublabel={labels.FOR_VERIFICATION_PURPOSES}
          label={labels.CONTACT_PERSON}
          onChange={(e) => setProperty(CONTACT_PERSON_PRIVATE, e.target.value)}
          error={shouldMarkError(CONTACT_PERSON_PRIVATE)}
          defaultValue={content?.contact_person}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          sublabel={labels.FOR_VERIFICATION_PURPOSES}
          label={labels.PHONE_NUMBER}
          onChange={(e) => setProperty(PHONE_NUMBER_PRIVATE, e.target.value)}
          error={shouldMarkError(PHONE_NUMBER_PRIVATE)}
          defaultValue={content?.contact_phone}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          sublabel={labels.FOR_VERIFICATION_PURPOSES}
          label={labels.EMAIL}
          onChange={(e) => setProperty(EMAIL, e.target.value)}
          error={shouldMarkError(EMAIL)}
          defaultValue={content?.email}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(TOWN_OF_RESIDENCY, e.target.value)}
          label={labels.TOWN_OF_RESIDENCY}
          error={shouldMarkError(TOWN_OF_RESIDENCY)}
          defaultValue={content?.town_of_residency}
          required
        />
      </div>
    </>
  );
};

MilestoneFields.propTypes = {
  formErrors: PropTypes.arrayOf(PropTypes.string),
};

export default MilestoneFields;
