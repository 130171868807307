import React, { useReducer, useState } from "react";
import { useStyles } from "./ResetPassword.style";
import labels from "../../../config/labels";
import {
  resetPasswordFormReducer,
  resetPasswordInitialState,
} from "../../../reducers/forms/formReducers";
import { resetPassword } from "../../../services/users";
import Spinner from "../../../common/Spinner/Spinner";
import ResetPasswordForm from "./Form/Form";
import AfterPasswordReset from "./AfterPasswordReset/AfterPasswordReset";
import Alert from "../../../common/Alert/Alert";

const ResetPassword = () => {
  const classes = useStyles();
  const urlParams = new URLSearchParams(window.location.search);

  const [passwordData, setPasswordData] = useReducer(resetPasswordFormReducer, {
    ...resetPasswordInitialState,
    reset_password_token: urlParams.get("reset_password_token"),
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const handleError = (fieldName) => {
    const markError = errors.filter((e) => e.key === fieldName);
    let errorMessage;
    if (markError.length > 0) {
      errorMessage = errors.find((e) => e.key === fieldName)?.message;
    }

    return { markError: markError.length > 0, errorMessage: errorMessage };
  };

  const clearErrors = () => {
    if (errors.length > 0) {
      setErrors([]);
    }
  };

  const validatePassword = () => {
    let foundErrors = [];
    if (passwordData?.password === null) {
      foundErrors.push({
        key: "password",
        message: labels.PASSWORD_TOO_SHORT_ERROR,
      });
    }

    if (passwordData?.password_confirmation === null) {
      foundErrors.push({
        key: "password-confirmation",
        message: labels.PASSWORD_CONFIRMATION_ERROR,
      });
    }

    return foundErrors;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    clearErrors();
    const foundErrors = validatePassword();

    if (foundErrors.length > 0) {
      setErrors(foundErrors);
    } else {
      const response = await resetPassword(passwordData);

      if (response?.status === 200) {
        setStatus({
          success: response?.data?.reset_password,
          messages: response?.data?.errors || [],
        });
      } else {
        setStatus({
          success: false,
          messages: ["Error code: " + response?.status],
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className={classes.form_container}>
      {loading && <Spinner withLoadingOverlay={true} />}
      {status?.success ? (
        <AfterPasswordReset />
      ) : (
        <>
          {status && !status?.success && (
            <div className={classes.alert_container}>
              <Alert
                title={labels.YOUR_REQUEST_WAS_NOT_PROCESSED}
                severity="error"
              />
            </div>
          )}
          <ResetPasswordForm
            setPasswordData={setPasswordData}
            handleError={handleError}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </div>
  );
};

export default ResetPassword;
