import React from "react";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import { useStyles } from "./MilestonePayments.style";
import ClickableIcon from "../../../../../common/Icons/ClickableIcon/ClickableIcon";
import PriceFree from "../../../../../common/Icons/PriceFree";

const MilestonePayments = ({ colors }) => {
  const isMobile = screenWidthIsLowerThan(values.md);
  const classes = useStyles();

  return (
    <div className={classes.payments_container}>
      <ClickableIcon
        icon={<PriceFree fillColor={colors.free.hex} />}
        textColor={colors.free.key}
        textHoverColor={colors.free.key}
        typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
      />
    </div>
  );
};

export default MilestonePayments;
