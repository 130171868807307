import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./Delete.style";
import Typography from "../../common/Typography/Typography";
import Button from "../../common/Button/Button";
import labels from "../../config/labels";
import ContentOptionsPopUp from "./ContentOptionsPopUp/ContentOptionsPopUp";

const Delete = ({ deleteUrl }) => {
  const classes = useStyles();
  const [openEditContentDialog, setOpenEditContentDialog] = useState(false);

  const onDeleteClick = () => {
    setOpenEditContentDialog(true);
  };

  return (
    <>
      <div className={classes.content_options_buttons}>
        <Button level="simple_button" color="red" bold onClick={onDeleteClick}>
          <Typography color="red" level="paragraph_2" bold>
            {labels.DELETE}
          </Typography>
        </Button>
      </div>

      <ContentOptionsPopUp
        openPopUp={openEditContentDialog}
        setOpenPopUp={setOpenEditContentDialog}
        url={deleteUrl}
        type="delete"
      />
    </>
  );
};

Delete.propTypes = {
  deleteUrl: PropTypes.string.isRequired,
};

export default Delete;
