import React from "react";
import PropTypes from "prop-types";

const Website = ({ className, fillColor }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M37.4344 52.2375C32.7601 53.8107 27.7073 53.8606 23.0029 52.3799C18.2985 50.8992 14.1851 47.9643 11.2545 43.9976C8.32396 40.0308 6.72729 35.2366 6.69428 30.3048C6.66126 25.373 8.1936 20.5579 11.0708 16.5522C13.948 12.5465 18.0218 9.55687 22.7059 8.01335C27.3901 6.46982 32.4431 6.45203 37.138 7.96252C41.8329 9.47301 45.9276 12.4339 48.833 16.4192C51.7383 20.4045 53.3045 25.2087 53.3063 30.1406C53.2309 32.0311 53.002 33.9123 52.6219 35.7656"
        stroke={fillColor}
        strokeWidth="2.8125"
      />
      <path
        d="M35.4938 47.9062C33.8171 49.8112 31.979 51.5677 30 53.1563"
        stroke={fillColor}
        strokeWidth="2.8125"
      />
      <path
        d="M30 6.5625C36.2911 12.059 40.1838 19.7974 40.8469 28.125C40.983 29.6367 41.0112 31.1562 40.9312 32.6719"
        stroke={fillColor}
        strokeWidth="2.8125"
      />
      <path
        d="M30 6.5625C26.5866 9.53764 23.8445 13.2041 21.9551 17.319C20.0657 21.434 19.0723 25.9034 19.0406 30.4312C19.0406 45.6187 25.8656 50.1188 29.9719 53.1563"
        stroke={fillColor}
        strokeWidth="2.8125"
      />
      <path
        d="M9.72186 18.6562H50.3906"
        stroke={fillColor}
        strokeWidth="2.8125"
      />
      <path d="M30 6.55313V53.1562" stroke={fillColor} strokeWidth="2.8125" />
      <path
        d="M10.3594 42.6375H34.725"
        stroke={fillColor}
        strokeWidth="2.8125"
      />
      <path
        d="M6.69376 30.4312L53.3063 29.8594"
        stroke={fillColor}
        strokeWidth="2.8125"
      />
      <path
        d="M50.2219 53.4375L54.375 49.275L46.875 41.775L51.1406 39.0469C51.2028 39.0101 51.2523 38.9554 51.2826 38.8899C51.313 38.8243 51.3227 38.7512 51.3105 38.68C51.2983 38.6088 51.2648 38.543 51.2143 38.4914C51.1639 38.4397 51.099 38.4046 51.0281 38.3906L36.6937 35.0344C36.6345 35.0223 36.5733 35.0251 36.5154 35.0425C36.4576 35.0599 36.4049 35.0914 36.3622 35.1341C36.3195 35.1768 36.288 35.2295 36.2706 35.2873C36.2532 35.3452 36.2504 35.4064 36.2625 35.4656L39.375 50.0719C39.3899 50.1429 39.4257 50.2078 39.4777 50.2584C39.5297 50.3091 39.5955 50.3431 39.6669 50.3561C39.7383 50.3692 39.8119 50.3608 39.8785 50.3318C39.945 50.3029 40.0015 50.2548 40.0406 50.1938L42.7219 45.9375L50.2219 53.4375Z"
        stroke={fillColor}
        strokeWidth="2.8125"
      />
    </svg>
  );
};

Website.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

Website.defaultProps = {
  fillColor: "black",
};

export default Website;
