const noCachedImgSrc = (imageSrc) => {
  if (!imageSrc) return "";
  let newChars = "";
  for (let i = 0; i < 3; i++) {
    newChars += String.fromCharCode(97 + Math.floor(Math.random() * 26)); // Código ASCII para letras minúsculas (97-122)
  }

  const newString = imageSrc.substring(0, imageSrc.length - 3) + newChars;

  return newString;
};

function convertBytestoKB(bytes) {
  const kb = (bytes / 1024).toFixed(2);
  return kb;
}

const buildHtmlCode = (pdfUrl, name, imgSrc, size) => {
  let printSize = `${size} BYTES`;
  if (size > 1024) {
    printSize = `${convertBytestoKB(size)} KB`;
  }
  const htmlContent = `<div
    style="
      display: flex;
      width: 100%;
      border-radius: 4px;
      border: 2px solid #d8d8d8;
      background: #fff;
      padding: 16px 8px;
    "
    data-attr-thumb="${noCachedImgSrc(imgSrc)}"
    data-attr-title="${name}"
    data-attr-size="${printSize} PDF FILE"
    data-attr-src="${pdfUrl}"
    data-attr-type="PDF"
  >
    <div style="width: 30%; padding-left: 16px">
      <img src="${noCachedImgSrc(imgSrc)}" width="120" />
    </div>
    <div style="width: 70%; padding-right: 26px; padding-top: 16px">
      <div>
        <div style="display: flex">
          <div style="width: 50px; display: flex">
            <img
              src="https://tapinto-production.s3.amazonaws.com/assets/svgs/download-icon.svg"
              alt="Download icon"
              width="18"
              height="22"
              style="margin: auto"
            />
          </div>
          <div style="width: calc(100% - 50px)">
            <span
              style="
                display: block;
                color: #000;
                text-overflow: ellipsis;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              "
            >
              <strong>${name}</strong>
            </span>
            <div
              style="
                padding-top: 8px;
                font-size: 14px;
                font-weight: 500;
                font-family: Montserrat;
                color: #777777;
              "
            >
              <span style="color: #777777">${printSize} PDF FILE</span>
            </div>
          </div>
        </div>
        <div
          style="
            background-color: black;
            color: white;
            width: 100%;
            height: 40px;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 24px;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: center;
          "
        >
          <a href="${pdfUrl}" target="_blank">SHOW PDF</a>
        </div>
      </div>
    </div>
  </div>`;

  return htmlContent;
};

export { buildHtmlCode, convertBytestoKB, noCachedImgSrc };
