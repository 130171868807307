import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    plus_icon: {
      marginBottom: "-1px",
      [theme.breakpoints.down(960)]: {
        marginLeft: 0,
      },
    },
    button: {
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      paddingLeft: `${theme.spacing(1.5)}px !important`,
      width: "55% !important",
      height: "100%",
      paddingTop: `${theme.spacing(1)}px !important`,
      zIndex: "1 !important",
      "&.addeventatc:hover": {
        backgroundColor: `${theme.palette.themeColor.primary} !important`,
      },
      "&.addeventatc": {
        display: "flex",
        justifyContent: "space-between",
        visibility: "hidden",
      },
      "& span.addeventatc_icon": {
        display: "none",
      },
      "& span.addeventatc_dropdown": {
        top: `${theme.spacing(4.3)}px !important`,
      },
      "& span.ateoffice365": {
        display: "none",
      },
      [theme.breakpoints.down(960)]: {
        width: "75% !important",
        paddingLeft: `${theme.spacing(1)}px !important`,
        "& .addeventatc_dropdown.topdown.addeventatc-selected": {
          transform: `scale(0.9) translateY(-${theme.spacing(
            1.5
          )}px) translateX(${theme.spacing(9.125)}px) !important`,
        },
      },
    },
    placeholder_button_for_preview: {
      backgroundColor: theme.palette.themeColor.primary,
      height: theme.spacing(4.25),
      width: "70%",
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& svg": {
        marginTop: "-5px",
      },
      "& :hover": {
        cursor: "pointer",
      },
    },
    tooltip_text: {
      fontSize: "12px !important",
    },
  };
});

export { useStyles };
