import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  modal_content: {
    width: theme.spacing(75),
    height: theme.spacing(90),
    paddingTop: theme.spacing(5),
    textAlign: "center",
    [theme.breakpoints.down(761)]: {
      width: theme.spacing(62.5),
      marginTop: theme.spacing(5),
      paddingTop: 0,
    },
    [theme.breakpoints.down(601)]: {
      width: theme.spacing(50),
    },
    [theme.breakpoints.down(461)]: {
      width: theme.spacing(43.75),
    },
    [theme.breakpoints.down(401)]: {
      width: theme.spacing(35.5),
      marginBottom: theme.spacing(6),
    },
  },

  text_container: {
    color: theme.palette.tapintoBranding.tapintoOrange,
    fontSize: "22px",
    lineHeight: "24px",
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },

  disclaimer_container: {
    color: theme.palette.light.gray,
    fontSize: "16px",
    lineHeight: "18px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  button_container: {
    paddingBottom: theme.spacing(4),
  },

  submit_request_button: {
    width: theme.spacing(26),
    display: "inline-block",
    height: theme.spacing(5),

    "&.MuiButtonBase-root": {
      marginTop: theme.spacing(4),
    },
    "&.MuiButton-root": {
      background: "linear-gradient(#a66114 3%, 20%, #f98c10)",
      borderRadius: 20,
      borderBottomRightRadius: 3,
      textTransform: "none",
    },
    "&.MuiButton-text": {
      fontSize: "20px",
      lineHeight: "22px",
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    "&.MuiButton-textPrimary": {
      color: theme.palette.miscellaneous.white,
    },
  },
  disabled_submit_request_button: {
    width: theme.spacing(26),
    display: "inline-block",
    height: theme.spacing(5),

    "&.MuiButtonBase-root": {
      marginTop: theme.spacing(4),
    },
    "&.MuiButton-root": {
      background: "linear-gradient(#777777 3%, 20%, #e3e3e3)",
      borderRadius: 20,
      borderBottomRightRadius: 3,
      textTransform: "none",
    },
    "&.MuiButton-text": {
      fontSize: "20px",
      lineHeight: "22px",
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    "&.MuiButton-textPrimary": {
      color: theme.palette.miscellaneous.white,
    },
  },

  multiple_towns_disabled_error_container: {
    width: "90%",
    textAlign: "left",
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },

  submit_error_container: {
    width: "100%",
    textAlign: "center",
    margin: `0px auto ${theme.spacing(3)}px auto`,
    backgroundColor: theme.palette.miscellaneous.lightRed,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(
      1
    )}px ${theme.spacing(3)}px`,
  },

  single_town_picker: {
    "&.MuiAutocomplete-root": {
      width: "90%",
      backgroundColor: theme.palette.miscellaneous.white,
      margin: `auto auto ${theme.spacing(3)}px auto`,
      "& label.MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
        color: theme.palette.tapintoBranding.tapintoOrange,
      },
      "& label.MuiFormLabel-root.MuiInputLabel-root": {
        color: theme.palette.miscellaneous.black,
      },
      "& div.MuiInputBase-root": {
        "& input.MuiInputBase-input": {
          boxShadow: "none",
        },
        "& input.MuiAutocomplete-inputFocused": {
          border: "none",
          backgroundColor: theme.palette.miscellaneous.white,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.palette.light.grey}`,
        },
      },
      "& div.MuiInputBase-root.Mui-focused": {
        "& input.MuiInputBase-input": {
          boxShadow: "none",
        },
        "& input.MuiAutocomplete-inputFocused": {
          border: "none",
          backgroundColor: theme.palette.miscellaneous.white,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.palette.tapintoBranding.tapintoOrange}`,
        },
      },
    },
  },

  missing_main_town_error: {
    "&.MuiAutocomplete-root": {
      width: "90%",
      backgroundColor: theme.palette.miscellaneous.white,
      margin: `auto auto ${theme.spacing(3)}px auto`,
      "& label.MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
        color: theme.palette.miscellaneous.red,
      },
      "& label.MuiFormLabel-root.MuiInputLabel-root": {
        color: theme.palette.light.grey,
      },
      "& div.MuiInputBase-root": {
        "& input.MuiInputBase-input": {
          boxShadow: "none",
        },
        "& input.MuiAutocomplete-inputFocused": {
          border: "none",
          backgroundColor: theme.palette.miscellaneous.white,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.miscellaneous.red}`,
        },
        "& fieldset.MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline":
          {
            border: `1px solid ${theme.palette.miscellaneous.red}`,
          },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.miscellaneous.red}`,
        },
      },
    },
  },
  button: {
    width: theme.spacing(10),
    display: "inline-block",
    height: theme.spacing(5),
    "&.MuiButtonBase-root": {
      marginRight: theme.spacing(3),
    },
    "&.MuiButton-root": {
      background: "linear-gradient(#a66114 3%, 20%, #f98c10)",
      borderRadius: 20,
      borderBottomRightRadius: 3,
      textTransform: "none",
    },
    "&.MuiButton-text": {
      fontSize: "20px",
      lineHeight: "22px",
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    "&.MuiButton-textPrimary": {
      color: theme.palette.miscellaneous.white,
    },
  },
  additional_towns_checkbox: {
    "&.MuiFormControlLabel-root": {
      paddingLeft: 0,
    },
    "& span.MuiCheckbox-root": {
      color: theme.palette.tapintoBranding.tapintoOrange,
    },
    "& span.MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.tapintoBranding.tapintoOrange,
    },
  },
  text_checkbox_container: {
    color: theme.palette.tapintoBranding.tapintoOrange,
    fontSize: "22px",
    lineHeight: "24px",
    marginLeft: theme.spacing(5),
    marginRight: 0,
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  },
  name_container: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(960)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  description_container: {
    [theme.breakpoints.down(960)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  name_more_about: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: `${theme.spacing(2)}px !important`,
    "&.MuiFormControl-root": {
      width: "90%",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      border: `2px solid ${theme.palette.light.grey}`,
    },
    "& div.Mui-focused": {
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.tapintoBranding.tapintoOrange,
      },
    },
    "& p.MuiFormHelperText-contained": {
      marginRight: 0,
      textAlign: "right",
    },
  },
}));

export { useStyles };
