import React from "react";
import PropTypes from "prop-types";

const Phone = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="25"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99069 1.58107L14.8642 10.6381C15.4602 11.7457 15.1125 12.6764 14.8642 13.0034C14.3864 13.4456 13.2473 14.5032 12.5134 15.1955C11.7796 15.8878 11.9019 17.5992 12.0548 18.3684C12.2268 19.2529 13.5226 22.0604 17.3296 26.214C22.0885 31.4059 24.8979 32.502 27.0194 33.4827C28.0514 33.9598 29.959 33.4827 30.2302 33.1942C30.8991 32.4828 32.3975 30.9098 33.0396 30.3098C33.6818 29.7099 34.5303 30.0598 34.8744 30.3098C37.55 31.8674 43.1192 35.1095 43.9907 35.6171C44.8622 36.1248 44.3538 36.8286 43.9907 37.117L38.3718 42.6551C38.066 43.3089 36.4683 44.455 32.5236 43.8089C27.5927 43.0012 20.8271 39.7707 12.5708 31.0021C4.31445 22.2335 2.42238 15.3109 1.67701 11.8496C1.08072 9.08055 1.92547 7.92678 2.42238 7.69603C4.35268 5.77309 8.33942 1.78875 8.84397 1.23494C9.34853 0.681137 9.81868 1.23494 9.99069 1.58107Z"
        fill={fillColor}
      />
      <path
        d="M9.99069 1.58107L14.8642 10.6381C15.4602 11.7457 15.1125 12.6764 14.8642 13.0034C14.3864 13.4456 13.2473 14.5032 12.5134 15.1955C11.7796 15.8878 11.9019 17.5992 12.0548 18.3684C12.2268 19.2529 13.5226 22.0604 17.3296 26.214C22.0885 31.4059 24.8979 32.502 27.0194 33.4827C28.0514 33.9598 29.959 33.4827 30.2302 33.1942C30.8991 32.4828 32.3975 30.9098 33.0396 30.3098C33.6818 29.7099 34.5303 30.0598 34.8744 30.3098C37.55 31.8674 43.1192 35.1095 43.9907 35.6171C44.8622 36.1248 44.3538 36.8286 43.9907 37.117L38.3718 42.6551C38.066 43.3089 36.4683 44.455 32.5236 43.8089C27.5927 43.0012 20.8271 39.7707 12.5708 31.0021C4.31445 22.2335 2.42238 15.3109 1.67701 11.8496C1.08072 9.08055 1.92547 7.92678 2.42238 7.69603C4.35268 5.77309 8.33942 1.78875 8.84397 1.23494C9.34853 0.681137 9.81868 1.23494 9.99069 1.58107Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M9.99069 1.58107L14.8642 10.6381C15.4602 11.7457 15.1125 12.6764 14.8642 13.0034C14.3864 13.4456 13.2473 14.5032 12.5134 15.1955C11.7796 15.8878 11.9019 17.5992 12.0548 18.3684C12.2268 19.2529 13.5226 22.0604 17.3296 26.214C22.0885 31.4059 24.8979 32.502 27.0194 33.4827C28.0514 33.9598 29.959 33.4827 30.2302 33.1942C30.8991 32.4828 32.3975 30.9098 33.0396 30.3098C33.6818 29.7099 34.5303 30.0598 34.8744 30.3098C37.55 31.8674 43.1192 35.1095 43.9907 35.6171C44.8622 36.1248 44.3538 36.8286 43.9907 37.117L38.3718 42.6551C38.066 43.3089 36.4683 44.455 32.5236 43.8089C27.5927 43.0012 20.8271 39.7707 12.5708 31.0021C4.31445 22.2335 2.42238 15.3109 1.67701 11.8496C1.08072 9.08055 1.92547 7.92678 2.42238 7.69603C4.35268 5.77309 8.33942 1.78875 8.84397 1.23494C9.34853 0.681137 9.81868 1.23494 9.99069 1.58107Z"
        fill={fillColor}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="22.9719"
          y1="1"
          x2="22.9719"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillColor} />
          <stop offset="1" stopColor={fillColor} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

Phone.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

Phone.defaultProps = {
  fillColor: "white",
};

export default Phone;
