import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import Typography from "../../../common/Typography/Typography";
import ArticleList from "../../../components/Column/ArticlesList/ArticlesList";
import { useStyles } from "./ColumnArticlesList.style";
import Sponsor from "../../../components/Sponsor/Sponsor";
import {
  buildRedirectUrl,
  getPageNumber,
  redirectToPage,
} from "../../../utils/helper";
import Pagination from "../../../common/Pagination/Pagination";

const ColumnArticlesList = ({
  column_name,
  articles,
  sponsor_info,
  pagination_attributes,
}) => {
  const classes = useStyles();

  const handleGotoNextPage = (e, p) => {
    const pageNumber = getPageNumber(p, pagination_attributes.current_page);
    const redirectUrl = buildRedirectUrl({
      type: "pagination",
      params: { pageNumber: pageNumber },
    });
    redirectToPage(redirectUrl, "_top");
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography level="h3" color="black">
          {column_name}
        </Typography>

        {sponsor_info && (
          <div className={classes.sponsor}>
            <Sponsor sponsor_info={sponsor_info} />
          </div>
        )}
      </div>

      <HorizontalDivider className={classes.divider} />
      <ArticleList articles={articles} isForArticlesIndex={true} />

      {pagination_attributes.show_pagination && (
        <div className={classes.pagination_container}>
          <Pagination
            count={pagination_attributes.total_pages}
            page={parseInt(pagination_attributes.current_page)}
            onChange={handleGotoNextPage}
            className={classes.columns_pagination}
          />
        </div>
      )}
    </Fragment>
  );
};

ColumnArticlesList.propTypes = {
  column_name: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
      photo_url: PropTypes.string,
    })
  ),
  sponsor_info: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  pagination_attributes: PropTypes.shape({
    show_pagination: PropTypes.bool,
    total_pages: PropTypes.number,
    current_page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    per: PropTypes.number,
  }),
};

export default ColumnArticlesList;
