import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    body_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginTop: theme.spacing(4),
      flexWrap: "wrap",
      fontFamily: "Montserrat",
      "& a:hover": {
        textDecoration: "underline !important",
      },
      [theme.breakpoints.up(960)]: {
        width: `calc(100% - ${theme.spacing(2)})`,
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down(1160)]: {
        paddingLeft: (isMobile) => (isMobile ? 0 : theme.spacing(2)),
      },
    },
    main_block_container: {
      width: "100%",
      display: "flex",
    },
    carousel_container: {
      width: "60%",
      "& [class*='image_container']": {
        height: theme.spacing(40),
      },
      [theme.breakpoints.down(960)]: {
        width: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        "& [class*='image_container']": {
          height: theme.spacing(25),
        },
        "& [class*='caption_credits']": {
          paddingLeft: theme.spacing(3),
          paddingTop: theme.spacing(8),
        },
      },
      [theme.breakpoints.down(1160)]: {
        marginLeft: (isMobile) => (isMobile ? 0 : theme.spacing(2)),
        margin: "auto",
      },
    },
    print_img: {
      display: "none",
      textAlign: "center",
      width: "100%",
      [`@media print`]: {
        display: "block !important",
        height: "auto",
        margin: 0,
      },
    },
    information_container: {
      width: "40%",
      display: "flex",
      flexDirection: "column",
      height: "auto",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      [theme.breakpoints.down(960)]: {
        width: "85%",
        marginLeft: theme.spacing(3),
        alignItems: "center",
        justifyContent: "flex-start",
        height: "auto",
        marginBottom: theme.spacing(5.25),
      },
      [`@media print`]: {
        width: "100%",
        margin: 0,
      },
      [theme.breakpoints.between(960, 1160)]: {
        paddingLeft: (isMobile) => (isMobile ? 0 : theme.spacing(3)),
        width: "37%",
      },
    },
    description: {
      width: "100%",
      fontSize: "18px",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      color: theme.palette.miscellaneous.black,
      "& a": {
        color: theme.palette.miscellaneous.blueLink,
      },
      [theme.breakpoints.down(1160)]: {
        paddingLeft: theme.spacing(3),
      },
    },
    carousel_dialog: {
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100vw !important",
        height: "100vh !important",
        [theme.breakpoints.down(960)]: {
          maxWidth: "auto !important",
          height: "auto !important",
        },
      },
    },
    carousel_dialog_content: {
      "& .swiper-slide": {
        width: "100% !important",
      },
      "& [class*='image_container']": {
        height: "80vh !important",
        [theme.breakpoints.down(960)]: {
          height: "auto !important",
        },
        "& img": {
          height: "100% !important",
          marginLeft: `-${theme.spacing(4.75)}px`,
          [theme.breakpoints.down(960)]: {
            marginLeft: 0,
          },
        },
      },
    },
    carousel_dialog_title: {
      "&.MuiDialogTitle-root": {
        margin: 0,
        background: theme.palette.themeColor.secondary,
      },
    },
    contact_information_container: {
      width: "50%",
      paddingRight: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      "& h4": {
        paddingBottom: theme.spacing(2),
      },
      "& span": {
        overflowWrap: "anywhere",
      },
      [theme.breakpoints.down(960)]: {
        width: "100%",
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down(1160)]: {
        paddingLeft: theme.spacing(3),
      },
    },
    map_container: {
      width: "50%",
      [theme.breakpoints.down(960)]: {
        width: "100%",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
  };
});

export { useStyles };
