import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    spinner: {
      display: "inline-block",
      position: "relative",
    },
    card_content: {
      backgroundColor: theme.palette.light.grey,
      height: "37vh",
      [theme.breakpoints.down(640)]: {
        height: "45vh",
      },
    },
  };
});

export { useStyles };
