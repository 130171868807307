import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    submit_content_button: {
      width: theme.spacing(30),
      height: theme.spacing(6),
      [theme.breakpoints.down(960)]: {
        width: "100%",
        marginTop: theme.spacing(1),
      },
      "& button.MuiButton-root": {
        textTransform: "none",
        width: theme.spacing(30),
        height: theme.spacing(6),
        background: theme.palette.themeColor.secondary,
        [theme.breakpoints.down(960)]: {
          width: "100%",
        },
        "& span.MuiButton-label": {
          fontSize: "16px",
          fontFamily: "Montserrat",
        },
      },
    },
    top_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(960)]: {
        display: "block",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: "center",
      },
    },
  };
});

export { useStyles };
