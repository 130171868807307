import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    website_link: {
      display: "block",
      width: "100%",
    },
    facebook_link: {
      color: theme.palette.facebook.facebookBlueDarkerVariant,
      "&:hover": {
        color: theme.palette.facebook.facebookBlueDarkerVariant,
      },
      "&:focus": {
        color: theme.palette.facebook.facebookBlueDarkerVariant,
      },
    },
    twitter_link: {
      color: `${theme.palette.miscellaneous.twitterBlue} !important`,
      "&:hover": {
        color: theme.palette.miscellaneous.twitterBlue,
      },
      "&:focus": {
        color: theme.palette.miscellaneous.twitterBlue,
      },
    },
    instagram_link: {
      color: `${theme.palette.miscellaneous.instagramPink} !important`,
      "&:hover": {
        color: theme.palette.miscellaneous.instagramPink,
      },
      "&:focus": {
        color: theme.palette.miscellaneous.instagramPink,
      },
    },
  };
});

export { useStyles };
