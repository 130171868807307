import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import HeaderWithCategories from "../../Headers/Show/WithCategories/HeaderWithCategories";

const EventHeader = ({
  title,
  categories,
  editUrl,
  showContentOptions,
  isAdvertisement,
  sponsorInfo,
}) => {
  return (
    <HeaderWithCategories
      isAdvertisement={isAdvertisement}
      showContentOptions={showContentOptions}
      sponsorInfo={sponsorInfo}
      title={
        screenWidthIsLowerThan(960) ? labels.EVENTS + "/ " : labels.EVENTS + " "
      }
      categories={categories}
      editUrl={editUrl}
      contentTitle={title}
      titleProps={{
        level: screenWidthIsLowerThan(960) ? "h3_sub_header" : "h1_header_1",
        color: "tapintoOrange",
        bold: true,
        underlined: false,
      }}
      contentTitleProps={{
        level: screenWidthIsLowerThan(960) ? "h3_sub_header" : "h1_header_1",
        color: "black",
        bold: true,
        underlined: false,
      }}
    />
  );
};

EventHeader.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array,
  editUrl: PropTypes.string,
  showContentOptions: PropTypes.bool,
  isAdvertisement: PropTypes.bool,
  sponsorInfo: PropTypes.shape({
    page_type: PropTypes.string,
    sponsor_attr: PropTypes.string,
    top_local_news: PropTypes.bool,
    town: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    columns_slug: PropTypes.arrayOf(PropTypes.string),
    sections_slug: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default EventHeader;
