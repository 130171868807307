import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    width_45: {
      "&.MuiFormControl-root": {
        width: "45%",
      },
      [theme.breakpoints.down(960)]: {
        "&.MuiFormControl-root": {
          width: "100%",
        },
      },
    },
    to: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down(960)]: {
        alignItems: "flex-end",
      },
    },
  };
});

export { useStyles };
