import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    selected_button: {
      "&.MuiButtonBase-root.Mui-checked": {
        color: theme.palette.themeColor.secondary,
      },
    },
  };
});

export { useStyles };
