import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    facebook_line_items_container: {
      marginTop: theme.spacing(2),
    },
    empty: {
      textAlign: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    with_items: {
      textAlign: "left",
      maxHeight: theme.spacing(10),
      overflowY: "scroll",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [`@media (max-height: 750px)`]: {
        maxHeight: theme.spacing(8.5),
      },
    },
  };
});

export { useStyles };
