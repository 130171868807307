import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    checkbox: {
      "& .MuiTypography-root": {
        fontSize: "16px !important",
        fontWeight: "700 !important",
        fontFamily: "inherit",
        color: palette.grayVariation.grayForCredits
      },
      "& .MuiFormControlLabel-root": {
        paddingLeft: 0,
      },
      "& span.MuiCheckbox-root": {
        color: palette.miscellaneous.gray,
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: palette.themeColor.secondary,
      },
    },
  };
});

export { useStyles };
