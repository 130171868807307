import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ShowLayoutDesktop.style";
import InformationBlock from "../InformationBlock/InformationBlock";
import PhotoGallery from "../../../../common/PhotoGallery/PhotoGallery";
import Back from "../../../../common/Icons/Back";
import SocialNetworkShareButtons from "../../../SocialNetworkShareButtons/SocialNetworkShareButtons";
import labels from "../../../../config/labels";
import Typography from "../../../../common/Typography/Typography";
import VerticalSeparator from "../../../../common/Icons/VerticalSeparator";
import ContactButtons from "../ContactButtons/ContactButtons";
import Link from "../../../../common/Link/Link";
import RealEstatesHeader from "../../Header/Header";
import Navbar from "../../../../common/Navbar/Navbar";
import { currencyFormatter } from "../../../../utils/helper";
import Actions from "../../Actions/Actions";
import { useTown } from "../../../../context/TownContext";
import RealtorInfo from "../../Forms/RealtorInfo/RealtorInfo";

const ShowLayoutDesktop = ({
  realEstateListing,
  backToRealEstateListingsUrl,
  sponsorInfo,
  editUrl,
  canEdit,
  isContentPreview,
  canDelete,
}) => {
  const classes = useStyles();
  const { isHalstonMedia } = useTown();

  return (
    <>
      {!isHalstonMedia && <RealEstatesHeader sponsorInfo={sponsorInfo} />}
      <div className={classes.layout_container}>
        <div className={classes.column}>
          <div className={classes.scroll_section_gallery}>
            <PhotoGallery photos={realEstateListing.media_content} />
          </div>
        </div>

        <div className={classes.column}>
          <div className={classes.static_section}>
            <div className={classes.static_section_top_bar}>
              <Link
                children={
                  <div className={classes.back_to_real_estates_items_container}>
                    <Back className={classes.back_icon} />
                    <Typography level="t1_text_1">
                      {labels.BACK_TO_REAL_ESTATES}
                    </Typography>
                  </div>
                }
                {...(!isContentPreview && { url: backToRealEstateListingsUrl })}
                className={classes.back_to_real_estates_link}
                level="t4_text_4"
                color="black"
              />
              <div className={classes.social_icons_container}>
                <SocialNetworkShareButtons
                  url={realEstateListing.social_media_information.url}
                  title={realEstateListing.name}
                  socialNetworks={["facebook", "twitter", "email"]}
                  iconsSize={25}
                  paddingWithinButtons={6}
                  alignment="right"
                  {...(isContentPreview && { disabled: true })}
                />
              </div>
            </div>

            <div className={classes.static_section_information}>
              {(canEdit || canDelete) && (
                <Actions
                  canEdit={canEdit}
                  canDelete={canDelete}
                  slug={realEstateListing.slug}
                  editUrl={editUrl}
                />
              )}

              <div className={classes.title_bedrooms_and_bathrooms_container}>
                <Typography level="h1_header_1" className={classes.price}>
                  {currencyFormatter.format(realEstateListing.price)}
                </Typography>

                <div>
                  <Typography
                    children={realEstateListing.bedrooms + " bed"}
                    level="paragraph_2"
                  />

                  <VerticalSeparator
                    fillColor="black"
                    className={classes.separator}
                  />
                  <Typography
                    children={realEstateListing.bathrooms + " bath"}
                    level="paragraph_2"
                  />
                </div>
              </div>

              <div className={classes.address_container}>
                <Typography level="paragraph_2">
                  {realEstateListing.address}
                </Typography>
              </div>

              <div className={classes.address_and_realtor_info_container}>
                <div className={classes.address_container}>
                  <Typography
                    children={realEstateListing.city}
                    level="paragraph_2"
                  />
                  <Typography children={", "} level="paragraph_2" />
                  <Typography
                    children={`${realEstateListing.state} ${realEstateListing.zip_code}`}
                    level="paragraph_2"
                  />
                </div>

                <RealtorInfo slug={realEstateListing.slug} nameOnly />
              </div>

              <div className={classes.status_and_button_container}>
                <div className={classes.property_status_container}>
                  <Typography
                    children={realEstateListing.property_status}
                    level="t2_text_2"
                    color="tapintoOrange"
                    bold
                  />
                </div>

                <div className={classes.contact_buttons_container}>
                  <ContactButtons
                    buttonToShow="contactRealtor"
                    slug={realEstateListing.slug}
                    disabled={isContentPreview}
                  />
                </div>
              </div>
            </div>

            <Navbar items={realEstateListing.information_blocks} />
          </div>

          <div className={classes.scroll_section}>
            {realEstateListing.information_blocks.map((block) => (
              <div id={block.section_id} key={block.section_id}>
                <InformationBlock
                  title={block.title}
                  content={block.content}
                  shouldShowContactRealtorButton
                  slug={realEstateListing.slug}
                  isContentPreview={isContentPreview}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

ShowLayoutDesktop.propTypes = {
  realEstateListing: PropTypes.shape({
    price: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    address: PropTypes.string,
    url: PropTypes.string,
    slug: PropTypes.string,
    realtor_email: PropTypes.string,
    status: PropTypes.string,
    information_blocks: PropTypes.arrayOf(
      PropTypes.shape({
        section_id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            })
          ),
        ]),
      })
    ),
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        isVideo: PropTypes.bool,
        alt_text: PropTypes.string,
        captions: PropTypes.string,
        credits: PropTypes.string,
      })
    ),
  }),
  backToRealEstateListingsUrl: PropTypes.string,
  sponsorInfo: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  editUrl: PropTypes.string,
  canEdit: PropTypes.bool,
  isContentPreview: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default ShowLayoutDesktop;
