import React from "react";
import PropTypes from "prop-types";
import { getErrorMessage } from "../../../../utils/formsHelper";
import Alert from "../../../../common/Alert/Alert";
import { useStyles } from "./Errors.style";

const FormErrors = ({ errors }) => {
  const classes = useStyles();
  return (
    <>
      {getErrorMessage({ errors: errors, fieldName: "send-failed" }) && (
        <div className={classes.container}>
          <Alert
            severity="error"
            content={getErrorMessage({
              errors: errors,
              fieldName: "send-failed",
            })}
          />
        </div>
      )}
      {getErrorMessage({ errors: errors, fieldName: "recaptcha" }) && (
        <div className={classes.container}>
          <Alert
            severity="error"
            content={getErrorMessage({
              errors: errors,
              fieldName: "recaptcha",
            })}
          />
        </div>
      )}
    </>
  );
};

FormErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      message: PropTypes.string,
    })
  ),
};

export default FormErrors;
