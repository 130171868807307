import React from "react";
import { useDiy } from "../../../../context/DiyContext";
import EventPayments from "./Event/EventPayments";
import ArticlePayments from "./Article/ArticlePayments";
import {
  BUSINESS_DIRECTORY_LISTING,
  CANDIDATE_STATEMENTS,
  CLASSIFIED,
  COLUMN,
  EVENT,
  FREELANCE,
  LEGAL_NOTICE,
  LETTERS_TO_THE_EDITOR,
  MILESTONE,
  OBITUARY,
  PRESS_RELEASE,
  REAL_ESTATE_LISTING,
} from "../../diyConstants";
import ClassifiedPayments from "./Classified/ClassifiedPayments";
import DirectoryPayments from "./Directory/DirectoryPayments";
import MilestonePayments from "./Milestone/MilestonePayments";
import ObituaryPayments from "./Obituary/ObituaryPayments";
import RealEstatePayments from "./RealEstate/RealEstatePayments";
import LegalNoticePayments from "./LegalNotice/LegalNoticePayment";

const PaymentsByContributionType = ({
  handleProfitSelection,
  setPriceSelectStatus,
  priceSelectStatus,
  handlePoliticalCandidatesSelection,
  setNonProfitCheckboxStatus,
  nonProfitCheckboxStatus,
  canApplyForExemption,
  isContributor,
  townsWithClusters,
  envUrl,
  currentUserId,
  handleExemptionRequest,
  handleNonProfitSelection,
  handleGovernmentSelection,
  colors,
}) => {
  const { contributionType } = useDiy();

  switch (contributionType) {
    case PRESS_RELEASE:
    case FREELANCE:
    case COLUMN:
    case CANDIDATE_STATEMENTS:
    case LETTERS_TO_THE_EDITOR:
      return (
        <ArticlePayments
          handleProfitSelection={handleProfitSelection}
          setPriceSelectStatus={setPriceSelectStatus}
          priceSelectStatus={priceSelectStatus}
          nonProfitCheckboxStatus={nonProfitCheckboxStatus}
          canApplyForExemption={canApplyForExemption}
          isContributor={isContributor}
          townsWithClusters={townsWithClusters}
          envUrl={envUrl}
          currentUserId={currentUserId}
          handleExemptionRequest={handleExemptionRequest}
          handleGovernmentSelection={handleGovernmentSelection}
          colors={colors}
          handleNonProfitSelection={handleNonProfitSelection}
          setNonProfitCheckboxStatus={setNonProfitCheckboxStatus}
          handlePoliticalCandidatesSelection={
            handlePoliticalCandidatesSelection
          }
        />
      );
    case EVENT:
      return (
        <EventPayments
          handleProfitSelection={handleProfitSelection}
          setPriceSelectStatus={setPriceSelectStatus}
          priceSelectStatus={priceSelectStatus}
          handlePoliticalCandidatesSelection={
            handlePoliticalCandidatesSelection
          }
          setNonProfitCheckboxStatus={setNonProfitCheckboxStatus}
          canApplyForExemption={canApplyForExemption}
          isContributor={isContributor}
          townsWithClusters={townsWithClusters}
          envUrl={envUrl}
          currentUserId={currentUserId}
          handleExemptionRequest={handleExemptionRequest}
          nonProfitCheckboxStatus={nonProfitCheckboxStatus}
          handleGovernmentSelection={handleGovernmentSelection}
          colors={colors}
          handleNonProfitSelection={handleNonProfitSelection}
        />
      );
    case CLASSIFIED:
      return (
        <ClassifiedPayments
          handleProfitSelection={handleProfitSelection}
          colors={colors}
        />
      );
    case BUSINESS_DIRECTORY_LISTING:
      return (
        <DirectoryPayments
          priceSelectStatus={priceSelectStatus}
          handleProfitSelection={handleProfitSelection}
          setPriceSelectStatus={setPriceSelectStatus}
          canApplyForExemption={canApplyForExemption}
          isContributor={isContributor}
          townsWithClusters={townsWithClusters}
          envUrl={envUrl}
          currentUserId={currentUserId}
          handleExemptionRequest={handleExemptionRequest}
          nonProfitCheckboxStatus={nonProfitCheckboxStatus}
          setNonProfitCheckboxStatus={setNonProfitCheckboxStatus}
          handleNonProfitSelection={handleNonProfitSelection}
          colors={colors}
        />
      );
    case MILESTONE:
      return <MilestonePayments colors={colors} />;
    case OBITUARY:
      return <ObituaryPayments colors={colors} />;
    case REAL_ESTATE_LISTING:
      return <RealEstatePayments colors={colors} />;
    case LEGAL_NOTICE:
      return <LegalNoticePayments colors={colors} />;
    default:
      return <></>;
  }
};

export default PaymentsByContributionType;
