import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import restClient from "../../../utils/restClient";
import withConfig from "../../../utils/withConfig";
import { Document } from "../../../classes/Document";
import { removeHtmlElementsByName } from "../../../utils/htmlHelper";
import useSubmitableContentForm from "../../../components/Diy/useSubmitableContentForm";
import { LEGAL_NOTICE_DOCUMENT_IDS, LEGAL_NOTICE_DOCUMENTS } from "../../../components/Diy/diyConstants";

const ERROR_VARIANT = "error center_in_screen";
const messages = {
  upload: {
    success: "Document(s) successfully uploaded.",
    error: "Error 500 while uploading document(s). Please try again later.",
  },
  update: {
    success: "Document successfully updated",
    error: "Error 500 while updating the document. Please try again later.",
  },
  remove: {
    success: "Document(s) successfully removed",
    error: "Error 500 while removing the document. Please try again later.",
  },
  create: {
    success: "Document successfully created",
    error: "Error 500 while creating the document. Please try again later.",
  },
};

const DocumentsContext = React.createContext({});

const DocumentsContextProvider = ({ children }) => {
  const [contribution, setContribution] = useState();
  const [documentInfo, setDocumentInfo] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [haveBulkActions, setHaveBulkActions] = useState(true);
  const [files, setFiles] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [bulkAction, setBulkAction] = useState("Add captions and credits");
  const [bulkCredits, setBulkCredits] = useState("");
  const [bulkCaptions, setBulkCaptions] = useState("");

  const { setProperty } = useSubmitableContentForm();

  const fetchDocuments = async () => {
    try {
      const response = await restClient.get(withConfig("FETCH_DOCUMENTS"), {
        contribution_type: contribution.contribution_type,
        contribution_id: contribution.contribution_id,
      });

      if (response?.status === 200) {
        const documents = [];
        for (let i = 0; i < response?.data?.files.length; i++) {
          const uploadedFile = response.data.files[i];
          documents.push(
            new Document(
              uploadedFile?.id,
              uploadedFile?.contribution_id,
              uploadedFile?.contribution_type,
              uploadedFile?.document_size,
              uploadedFile?.document_url,
              uploadedFile?.thumbnail_url,
              uploadedFile?.name,
              uploadedFile?.available_pages,
              uploadedFile?.thumbnail_number_image,
              uploadedFile?.add_to_images_carousel,
              uploadedFile?.captions,
              uploadedFile?.credits
            )
          );
        }
        setFiles(documents);

        const form = window.document.getElementById("article-form");

        removeHtmlElementsByName("article[article_document_ids][]");
        const carrouselDocuments = documents.filter((doc) => doc.addToImagesCarousel);
        if (contribution?.is_diy_form && carrouselDocuments.length > 0) {

          const carrouselDocumentsIds = carrouselDocuments.map((doc) => doc.id);

          setProperty(LEGAL_NOTICE_DOCUMENT_IDS, carrouselDocumentsIds);
          setProperty(LEGAL_NOTICE_DOCUMENTS, carrouselDocuments);
        }

        for (let i = 0; i < documents.length; i++) {
          const div = window.document.createElement("div");
          div.innerHTML = `<input type='hidden' name='article[article_document_ids][]' value='${documents[i]?.id}' />`;

          form.appendChild(div);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const saveDocumentInfo = async (document) => {
    try {
      setIsloading(true);
      const formData = new FormData();
      formData.append("id", document?.id);
      formData.append("thumbnail_number_image", document.thumbnailNumberImage);
      formData.append("add_to_images_carousel", document.addToImagesCarousel);
      formData.append("credits", document.credits);
      formData.append("captions", document.captions);
      formData.append("contribution_id", document.contributionId);
      formData.append("contribution_type", document.contributionType);

      const response = await restClient.post(
        withConfig("SAVE_DOCUMENT_INFO"),
        formData,
        true
      );
      console.log({ response });
      if (response?.status === 200) {
        setDocumentInfo({
          open: true,
          message: messages["update"]["success"],
          variant: "success center_in_screen",
        });
        await fetchDocuments();
        setIsloading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const removeDocument = async (document) => {
    try {
      const formData = new FormData();
      formData.append("id", document?.id);
      formData.append("contribution_id", document.contributionId);
      formData.append("contribution_type", document.contributionType);

      const response = await restClient.post(
        withConfig("REMOVE_DOCUMENT_INFO"),
        formData,
        true
      );

      if (response?.status === 200) {
        setDocumentInfo({
          open: true,
          message: messages["remove"]["success"],
          variant: "success center_in_screen",
        });
        await fetchDocuments();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const addFiles = async (files) => {
    try {
      setIsloading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }
      formData.append("contribution_type", contribution.contribution_type);
      formData.append("contribution_id", contribution.contribution_id);
      const response = await restClient.post(
        withConfig("UPLOAD_DOCUMENTS"),
        formData,
        true
      );

      if (response?.status === 200) {
        setDocumentInfo({
          open: true,
          message: messages["upload"]["success"],
          variant: "success center_in_screen",
        });
        await fetchDocuments();
        setIsloading(false);
      }
    } catch (e) {
      console.error(e);
      setDocumentInfo({
        open: true,
        message: messages["upload"]["error"],
        variant: ERROR_VARIANT,
      });
      setIsloading(false);
    }
  };

  const bulkCaptionAndCreditsUpdate = async (
    contributionId,
    contributionType
  ) => {
    try {
      const formData = new FormData();
      formData.append("contribution_type", contributionType);
      formData.append("contribution_id", contributionId);
      formData.append("caption", bulkCaptions);
      formData.append("credits", bulkCredits);
      formData.append("bulk_action", bulkAction);
      selectedDocuments.forEach((id) => {
        formData.append("selected_documents[]", id);
      });

      const response = await restClient.post(
        withConfig("BULK_CAPTION_AND_CREDITS_UPDATE"),
        formData,
        true
      );

      if (response?.status === 200) {
        setDocumentInfo({
          open: true,
          message:
            messages[bulkAction === "Delete" ? "remove" : "update"]["success"],
          variant: "success center_in_screen",
        });
        setBulkCaptions("");
        setBulkCredits("");
        setSelectedDocuments([]);
        await fetchDocuments();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCheckboxChange = (checkboxId) => {
    setSelectedDocuments(
      selectedDocuments.includes(checkboxId)
        ? selectedDocuments.filter((id) => id !== checkboxId)
        : [...selectedDocuments, checkboxId]
    );
  };

  useEffect(() => {
    if (contribution) {
      fetchDocuments();
      setHaveBulkActions(contribution.contribution_type !== "LegalNotice");
    }
  }, [contribution]);

  return (
    <DocumentsContext.Provider
      value={{
        haveBulkActions,
        setContribution,
        files,
        saveDocumentInfo,
        removeDocument,
        documentInfo,
        setDocumentInfo,
        addFiles,
        isLoading,
        bulkCaptionAndCreditsUpdate,
        handleCheckboxChange,
        selectedDocuments,
        bulkAction,
        setBulkAction,
        setBulkCaptions,
        setBulkCredits,
        bulkCaptions,
        bulkCredits,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

DocumentsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useDocuments = () => {
  const DocumentsContextValues = useContext(DocumentsContext);

  if (!DocumentsContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the DocumentsContextProvider"
    );
  }

  return DocumentsContextValues;
};

const withDocumentsContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <DocumentsContextProvider>
      <Component {...props} />
    </DocumentsContextProvider>
  );

  return ComponentWithProvider;
};

export { useDocuments, withDocumentsContextProvider };
