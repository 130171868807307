import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ComboBox from "../ComboBox/ComboBox";
import { useStyles } from "./Dropdown.style";
import Typography from "../Typography/Typography";

const Dropdown = ({
  label,
  error,
  getChosenOptionId,
  shouldPreselectFirstOption,
  placeholder,
  typographyLevel,
  labelColor,
  labelClassName,
  dropdownClassName,
  disabled,
  items,
  required,
  bold,
  defaultValue,
  onInputChange,
}) => {
  const classes = useStyles();

  const getLabelColor = () => {
    if (labelColor) {
      return labelColor;
    }
    return "gray";
  };

  return (
    <>
      {label && (
        <Typography
          level={typographyLevel}
          bold={bold}
          color={error ? "red" : getLabelColor()}
          className={labelClassName ? labelClassName : classes.margin_bottom_0}
        >
          {required ? label + "*" : label}
        </Typography>
      )}
      <ComboBox
        className={
          disabled
            ? classes.disabled
            : clsx(classes.dropdown, dropdownClassName && dropdownClassName)
        }
        items={items}
        isMultiple={false}
        shouldShowEndIcon={true}
        placeholder={placeholder}
        {...(shouldPreselectFirstOption && {
          defaultValue: {
            id: items[0].id,
            label: items[0].label,
            action: getChosenOptionId(items[0].id),
          },
        })}
        disabled={disabled}
        defaultValue={defaultValue}
        onInputChange={onInputChange}
      />
    </>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      labelComponent: PropTypes.object,
    })
  ),
  getChosenOptionId: PropTypes.func,
  shouldPreselectFirstOption: PropTypes.bool,
  placeholder: PropTypes.string,
  typographyLevel: PropTypes.string,
  labelColor: PropTypes.string,
  labelClassName: PropTypes.any,
  dropdownClassName: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  bold: PropTypes.bool,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    labelComponent: PropTypes.string,
  }),
  onInputChange: PropTypes.func,
};

Dropdown.defaultProps = {
  error: false,
  disabled: false,
  required: false,
  bold: true,
};

export default Dropdown;
