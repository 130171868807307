import React from "react";
import PropTypes from "prop-types";

const EmailBox = ({ className, backgroundFillColor, iconFillColor }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill={backgroundFillColor}
      />
      <path
        d="M24.8953 8H7.13824L14.4232 14.4495C14.7836 14.7417 15.3338 15.0757 16.0737 15.0757C16.7566 15.0757 17.4396 14.7417 17.7242 14.4495L24.8953 8Z"
        fill={iconFillColor}
      />
      <path
        d="M13.3988 15.6392L6 9.12711V21.6504C6 23.1532 7.06239 23.4455 7.59358 23.4037H24.4399C25.8514 23.4037 26.1663 22.2348 26.1473 21.6504V9.12711L18.7486 15.6392C18.1225 16.2028 17.1551 16.9542 16.0737 16.9542C14.9924 16.9542 13.8541 16.0775 13.3988 15.6392Z"
        fill={iconFillColor}
      />
    </svg>
  );
};

EmailBox.propTypes = {
  className: PropTypes.string,
  backgroundFillColor: PropTypes.string,
  iconFillColor: PropTypes.string,
};

EmailBox.defaultProps = {
  iconFillColor: "white",
  backgroundFillColor: "#F98C10",
};

export default EmailBox;
