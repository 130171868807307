import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./StickySocialNetworkShareButtons.style";
import { StickyShareButtons } from "sharethis-reactjs";

const StickySocialNetworkShareButtons = ({
  alignment,
  socialNetworks,
  title,
  top,
}) => {
  const classes = useStyles();
  const [showShareThis, setShowShareThis] = useState(false);
  const [shareThisConfig, setShareThisConfig] = useState({});

  const config = {
    alignment: alignment, // alignment of buttons (left, right)
    color: "social", // set the color of buttons (social, white)
    enabled: true, // show/hide buttons (true, false)
    font_size: 16, // font size for the buttons
    hide_desktop: false, // hide buttons on desktop (true, false)
    labels: "null", // button labels (cta, counts, null)
    language: "en", // which language to use (see LANGUAGES)
    min_count: 0, // hide react counts less than min_count (INTEGER)
    networks: socialNetworks,
    padding: 12, // padding within buttons (INTEGER)
    radius: 4, // the corner radius on each button (INTEGER)
    show_total: false, // show/hide the total share count (true, false)
    show_mobile: true, // show/hide the buttons on mobile (true, false)
    show_toggle: true, // show/hide the toggle buttons (true, false)
    size: 40, // the size of each button (INTEGER)
    top: top, // offset in pixels from the top of the page
  };

  const buildConfig = () => {
    if (title) {
      return { ...config, title: title };
    }

    return config;
  };

  useEffect(() => {
    setShareThisConfig(buildConfig());
    setShowShareThis(true);
  }, []);

  return (
    <div
      className={clsx(
        classes.social_network_icons_container,
        classes.no_printing
      )}
    >
      {showShareThis && <StickyShareButtons config={shareThisConfig} />}
    </div>
  );
};

StickySocialNetworkShareButtons.propTypes = {
  alignment: PropTypes.string,
  socialNetworks: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

StickySocialNetworkShareButtons.defaultProps = {
  alignment: "left",
  socialNetworks: ["facebook", "twitter", "email", "print"],
  top: "unset",
};

export default StickySocialNetworkShareButtons;
