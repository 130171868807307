import React from "react";
import CheckCircle from "../../../../common/Icons/CheckCircle";
import Typography from "../../../../common/Typography/Typography";
import labels from "../../../../config/labels";
import { useStyles } from "./AfterPasswordReset.style";

const AfterPasswordReset = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <CheckCircle />
      </div>
      <div className={classes.row}>
        <Typography level="paragraph_2" color="black">
          {labels.SUCCESSFUL_PASSWORD_RESET}
        </Typography>
      </div>
    </div>
  );
};

export default AfterPasswordReset;
