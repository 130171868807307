import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    tabs_container: {
      width: "100%",
      maxHeight: theme.spacing(15),
      overflow: "hidden",
      [theme.breakpoints.down(900)]: {
        maxHeight: theme.spacing(17.5),
      },
    },
    tabs_container_collapsed: {
      maxHeight: theme.spacing(70),
      overflowY: "scroll",
      [theme.breakpoints.down(900)]: {
        maxHeight: theme.spacing(50),
        overflowY: "scroll",
      },
    },
    tab: {
      width: "30%",
      display: "inline-flex",
      backgroundColor: theme.palette.dark.gray,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      cursor: "pointer",
      [theme.breakpoints.down(900)]: {
        width: "44%",
        height: theme.spacing(6.75),
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    selected_tab: {
      backgroundColor: theme.palette.themeColor.secondary,
    },
    link_container: {
      width: "100%",
      textAlign: "right",
      paddingRight: theme.spacing(4),
      cursor: "pointer",
    },
  };
});

export { useStyles };
