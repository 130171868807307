import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Typography from "../../common/Typography/Typography";
import { useDiy, withDiyContextProvider } from "../../context/DiyContext";
import useSubmitableContentForm from "./useSubmitableContentForm";
import { cloneWithoutReferences, getEnvUrl } from "../../utils/helper";
import { formErrorsListener } from "../../utils/listeners";
import TownSelectorByState from "./TownSelectorByState/TownSelectorByState";
import { useStyles } from "./DiyUpperSection.style";
import labels from "../../config/labels";
import { fetchTownsByStateAndCounty } from "../../services/getTownsByCounty";
import { buildTownsWithState } from "../../services/townService";
import SelectTabs from "../../common/SelectTabs/SelectTabs";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";
import ErrorMessages from "../../common/ErrorMessages/ErrorMessages";
import {
  MAIN_TOWN_IDS,
  SELECTED_TOWNS,
  OK,
  GREEN,
  GRAY,
  WHITE,
  FORM_TYPE,
  CATEGORIES,
  LETTERS_TO_THE_EDITOR,
  CANDIDATE_STATEMENTS,
  COLUMN,
  NEWS,
  EVENT,
  CLASSIFIED,
  PRESS_RELEASE,
  FREELANCE,
  FREELANCE_EMAIL,
  BUSINESS_DIRECTORY_LISTING,
  MILESTONE,
  OBITUARY,
  REAL_ESTATE_LISTING,
  MANDATORY_TOWN_ERROR,
  LETTERS_TO_THE_EDITOR_URL,
  LEGAL_NOTICE,
} from "./diyConstants";

import PaymentTypes from "./PaymentTypes/PaymentTypes";
import ArticleFields from "./FormFields/ArticleFields/ArticleFields";
import EventFields from "./FormFields/EventFields/EventFields";
import ClassifiedFields from "./FormFields/ClassifiedFields/ClassifiedFields";
import BusinessDirectoryListingFields from "./FormFields/BusinessDirectoryListingFields/BusinessDirectoryListingFields";
import MilestoneFields from "./FormFields/MilestoneFields/MilestoneFields";
import ObituaryFields from "./FormFields/ObituaryFields/ObituaryFields";
import restClient from "../../utils/restClient";
import withConfig from "../../utils/withConfig";
import RealEstateFields from "./FormFields/RealEstateFields/RealEstateFields";
import Snackbar from "../../common/Snackbar/Snackbar";
import SingleTownSelector from "./SingleTownSelector/SingleTownSelector";
import Link from "../../common/Link/Link";
import LegalNoticeFields from "./FormFields/LegalNoticeFields/LegalNoticeFields";

const IS_INITIAL_LOAD = true;

const DiyUpperSection = ({
  user_id,
  contribution_type,
  form_type,
  can_apply_for_exemption,
  towns_with_clusters,
  env,
  us_states,
  funeral_home_names,
  columns,
  is_advertiser,
  is_contributor,
  is_contributor_with_granted_exemptions,
  granted_exemption_town_names,
  advertiser_town_names,
  content,
  is_draft,
  is_edit_form,
  event_draft_dates_count,
  town,
}) => {
  const {
    updateShoppingSummary,
    addSelectedTownDiy,
    selectedTownsDiy,
    removeSelectedTownDiy,
    addMultipleTownsDiy,
    removeMultipleTownsDiy,
    handleSelectSingleTown,
    setTown,
    setFormType,
    setContributionType,
  } = useDiy();
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [states, setStates] = useState([]);
  const [counties, setCounties] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [organizationType, setOrganizationType] = useState(
    !!content?.organization_type ? content?.organization_type : ""
  );
  const [canApplyForExemption, setCanApplyForExemption] = useState(
    can_apply_for_exemption
  );
  const [singleSelectedCategoryId, setSingleSelectedCategoryId] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(is_draft ? true : false);

  const envUrl = getEnvUrl(env);
  formErrorsListener(setFormErrors);
  setProperty(FORM_TYPE, form_type);
  useEffect(() => {
    if (formErrors.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [formErrors]);

  const handleTownSelectionByCounties = (clickedTown) => {
    if (clickedTown.selected) {
      addSelectedTownDiy(clickedTown);
    } else {
      removeSelectedTownDiy(clickedTown);
    }
  };

  useEffect(() => {
    if (!is_edit_form) {
      setProperty(
        MAIN_TOWN_IDS,
        selectedTownsDiy.map((town) => (town.id ? town.id : town))
      );
    }
    setProperty(
      SELECTED_TOWNS,
      selectedTownsDiy.map((town) => town)
    );
    updateShoppingSummary(selectedTownsDiy);
  }, [selectedTownsDiy]);

  useEffect(() => {
    setTown(town);
    setFormType(form_type);
    setContributionType(contribution_type);
    return () => {
      setTown();
      setFormType();
      setContributionType();
    };
  }, [town, form_type, contribution_type]);

  const handleCountySelection = (countyId, isChecked) => {
    const foundCounty = counties.find((county) => county.id === countyId);
    const towns = [];
    for (let i = 0; i < foundCounty.children.length; i++) {
      const selectedTown = cloneWithoutReferences({
        ...foundCounty.children[i],
        selected: isChecked,
      });
      towns.push(selectedTown);
    }
    if (isChecked) {
      addMultipleTownsDiy(towns);
    } else {
      removeMultipleTownsDiy(towns);
    }
  };

  useEffect(async () => {
    const initializeCountiesAndStates = async () => {
      try {
        const fetchTownsByStateAndCountyResponse =
          await fetchTownsByStateAndCounty(
            user_id,
            contribution_type,
            organizationType,
            content?.id
          );

        if (fetchTownsByStateAndCountyResponse.status === OK) {
          const statesWithCounties =
            fetchTownsByStateAndCountyResponse?.data?.towns_by_county.filter(
              (fetchedTown) => fetchedTown?.counties?.length > 0
            );

          const townsWithCounties = statesWithCounties.map(
            (state) => state.counties
          );

          const onlyCounties = [];
          const defaultSelectedTowns = [];
          for (let i = 0; i < townsWithCounties.length; i++) {
            for (let j = 0; j < townsWithCounties[i].length; j++) {
              onlyCounties.push(townsWithCounties[i][j]);
              defaultSelectedTowns.push(
                townsWithCounties[i][j].children.filter(
                  (town) => town.selected === true
                )
              );
            }
          }

          addMultipleTownsDiy(
            defaultSelectedTowns.flat().filter((town) => town !== undefined),
            IS_INITIAL_LOAD
          );
          setCounties(onlyCounties);
          setStates(
            buildTownsWithState(
              fetchTownsByStateAndCountyResponse?.data?.towns_by_county,
              contribution_type
            )
          );
          setIsLoadingData(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (counties.length === 0 && form_type !== COLUMN) {
      await initializeCountiesAndStates();
    }
  }, [counties]);

  const handleSelectMultipleCategories = (category) => {
    if (category.selected) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      handleRemoveCategory(category.id, selectedCategories);
    }
  };

  const handleSelectSingleCategory = (category) => {
    if (category.selected) {
      setSelectedCategories([category]);
      setSingleSelectedCategoryId(category.id);
    }
  };

  const handleRemoveCategory = (categoryId, selectedCategoriesArray) => {
    setSelectedCategories(
      selectedCategoriesArray.filter((category) => category.id !== categoryId)
    );
  };

  useEffect(() => {
    const loadCategoriesByType = async () => {
      const response = await restClient.get(
        `${withConfig("GET_CATEGORIES_BY_TYPE_URL")}?content_type=${form_type}`
      );
      setCategories(response?.data?.categories);
    };

    if (shouldDisplayCategories() && categories.length === 0) {
      loadCategoriesByType();
    }
  }, [categories]);

  useEffect(() => {
    setProperty(
      CATEGORIES,
      selectedCategories.map((category) => category.id)
    );
  }, [selectedCategories]);

  useEffect(() => {
    if (is_edit_form || is_draft) {
      if (content?.categories) {
        setProperty(
          CATEGORIES,
          content?.categories.map((category) => category.id)
        );
        setSelectedCategories(content?.categories);
      }
      if (content?.category) {
        setSelectedCategories([content?.category]);
        setSingleSelectedCategoryId(content?.category?.id);
      }
      setProperty(MAIN_TOWN_IDS, content?.main_town_ids);
    }
    if (form_type === FREELANCE) {
      setProperty(FREELANCE_EMAIL, content?.freelance_email);
    }
  }, [content]);

  const getTitle = () => {
    switch (contribution_type) {
      case PRESS_RELEASE:
      case NEWS:
        return is_edit_form
          ? labels.EDIT_PRESS_RELEASE
          : labels.SUBMIT_A_PRESS_RELEASE;
      case CANDIDATE_STATEMENTS:
        return is_edit_form
          ? labels.EDIT_CANDIDATE_STATEMENT
          : labels.SUBMIT_A_CANDIDATE_STATEMENT;
      case LETTERS_TO_THE_EDITOR:
        return is_edit_form
          ? labels.EDIT_LETTER_TO_THE_EDITOR
          : labels.SUBMIT_A_LETTER_TO_THE_EDITOR;
      case EVENT:
        return is_edit_form ? labels.EDIT_EVENT : labels.SUBMIT_AN_EVENT;
      case CLASSIFIED:
        return is_edit_form
          ? labels.EDIT_CLASSIFIED
          : labels.SUBMIT_A_CLASSIFIED;
      case BUSINESS_DIRECTORY_LISTING:
        return is_edit_form
          ? labels.EDIT_DIRECTORY
          : labels.SUBMIT_A_NEW_DIRECTORY;
      case MILESTONE:
        return is_edit_form ? labels.EDIT_MILESTONE : labels.SUBMIT_A_MILESTONE;
      case OBITUARY:
        return is_edit_form ? labels.EDIT_OBITUARY : labels.SUBMIT_AN_OBITUARY;
      case REAL_ESTATE_LISTING:
        return is_edit_form
          ? labels.EDIT_REAL_ESTATE_LISTING
          : labels.SUBMIT_A_REAL_ESTATE_LISTING;
      case COLUMN:
        return is_edit_form
          ? labels.EDIT_COLUMN
          : labels.SUBMIT_A_NEW_COLUMN_ARTICLE;
      case FREELANCE:
        return labels.SUBMIT_A_FREELANCE_ARTICLE_NEWS;
      case LEGAL_NOTICE:
        return is_edit_form
          ? labels.EDIT_LEGAL_NOTICE
          : labels.SUBMIT_A_LEGAL_NOTICE;
      default:
        break;
    }
  };

  const shouldDisplayCategories = () => {
    switch (form_type) {
      case NEWS:
      case CANDIDATE_STATEMENTS:
      case LETTERS_TO_THE_EDITOR:
      case FREELANCE:
      case OBITUARY:
      case COLUMN:
      case LEGAL_NOTICE:
        return false;
      default:
        return true;
    }
  };

  const getFormFields = () => {
    switch (form_type) {
      case NEWS:
      case CANDIDATE_STATEMENTS:
      case LETTERS_TO_THE_EDITOR:
      case FREELANCE:
      case COLUMN:
        return (
          <ArticleFields
            formType={form_type}
            formErrors={formErrors}
            columns={columns}
            content={content}
          />
        );
      case EVENT:
        return (
          <EventFields
            formErrors={formErrors}
            usStates={us_states}
            content={content}
            isEditForm={is_edit_form}
            isDraft={is_draft}
            eventDraftDatesCount={event_draft_dates_count}
          />
        );
      case CLASSIFIED:
        return <ClassifiedFields formErrors={formErrors} content={content} />;
      case BUSINESS_DIRECTORY_LISTING:
        return (
          <BusinessDirectoryListingFields
            formErrors={formErrors}
            usStates={us_states}
            content={content}
          />
        );
      case MILESTONE:
        return <MilestoneFields formErrors={formErrors} content={content} />;
      case REAL_ESTATE_LISTING:
        return (
          <RealEstateFields
            formErrors={formErrors}
            usStates={us_states}
            content={content}
            isEditForm={is_edit_form}
          />
        );
      case OBITUARY:
        return (
          <ObituaryFields
            formErrors={formErrors}
            funeralHomeNames={funeral_home_names}
            content={content}
          />
        );
      case LEGAL_NOTICE:
        return (
          <LegalNoticeFields
            content={content}
            userId={user_id}
            formErrors={formErrors}
            usStates={us_states}
          />
        );
      default:
        break;
    }
  };

  const handleOrganizationTypeChange = (changeTo) => {
    setOrganizationType(changeTo);
    setCounties([]);
    setStates();
    setProperty(MAIN_TOWN_IDS, []);
    setProperty(SELECTED_TOWNS, []);
    updateShoppingSummary([]);
  };

  const getCategoriesFromContent = () => {
    if (content?.categories) {
      return content?.categories;
    }
    if (content?.category) {
      return [content?.category];
    }
  };

  const shouldShowTownPicker = () => {
    switch (form_type) {
      case COLUMN:
      case LETTERS_TO_THE_EDITOR:
        return false;

      default:
        return true;
    }
  };

  const buildTownPickerTitle = () => {
    console.log({ town });
    if (town.under_license_contract) {
      return labels.SELECT_PUBLICATIONS;
    } else {
      return contribution_type == LETTERS_TO_THE_EDITOR
        ? labels.ONE_TAPINTO_SITES
        : labels.SELECT_TAPINTO_SITES;
    }
  };

  return (
    <>
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          message={labels.SNACKBAR_SAVED_DRAFT_REDIRECTION}
          variant="warning center_top_below_masthead"
          useAction={true}
          onActionClick={() => setOpenSnackbar(false)}
        />
      )}
      {formErrors.length > 0 && <ErrorMessages errors={formErrors} />}
      <Typography
        bold
        level="h3_sub_header"
        color={GREEN}
        className={classes.header_title}
      >
        {getTitle()}
      </Typography>
      {!is_edit_form && (
        <PaymentTypes
          contributionType={contribution_type}
          isContributor={is_contributor}
          formType={form_type}
          canApplyForExemption={canApplyForExemption}
          townsWithClusters={towns_with_clusters}
          currentUserId={user_id}
          envUrl={envUrl}
          onChange={handleOrganizationTypeChange}
          handleExemptionFlowStarted={() => setCanApplyForExemption(false)}
          organizationType={
            !!content?.organization_type ? content?.organization_type : ""
          }
          town={town}
        />
      )}

      {form_type === LETTERS_TO_THE_EDITOR && (
        <>
          <div className={classes.lte_policy_link_container}>
            <Link
              children="Letters to the Editor Policy"
              level="t3_text_3"
              underlined
              url={LETTERS_TO_THE_EDITOR_URL}
              className={classes.lte_policy_link}
            />
          </div>

          <SingleTownSelector
            handleSelectTown={handleSelectSingleTown}
            {...((is_edit_form || is_draft) &&
              content?.main_town && {
                preselectedTown: content.main_town,
              })}
            error={
              formErrors.filter((error) => error === MANDATORY_TOWN_ERROR)
                ?.length
            }
            underLicense={town?.under_license_contract}
          />
        </>
      )}

      {shouldShowTownPicker() && !is_edit_form && (
        <>
          {!isLoadingData && states && states.length > 0 ? (
            <div className={classes.town_selector}>
              <TownSelectorByState
                title={buildTownPickerTitle()}
                subTitle={
                  town?.under_license_contract
                    ? labels.PUBLICATIONS_NETWORK
                    : labels.TAPINTO_SITES_NETWORK
                }
                originalTownsByState={states}
                handleTownSelectionByCounties={handleTownSelectionByCounties}
                counties={counties}
                isAdvertiser={is_advertiser}
                isContributorWithGrantedExemptions={
                  is_contributor_with_granted_exemptions
                }
                grantedExemptionTownNames={granted_exemption_town_names}
                advertiserTownNames={advertiser_town_names}
                formType={form_type}
                handleCountySelection={handleCountySelection}
                town={town}
              />
            </div>
          ) : (
            <div className={classes.loading_container}>
              <Typography level="h3_sub_header" color={GREEN} bold>
                {labels.LOADING}
              </Typography>
            </div>
          )}
        </>
      )}

      {shouldDisplayCategories() && (
        <SelectTabs
          title={labels.CHOOSE_CATEGORIES}
          seeMoreLabel={labels.SHOW_MORE_CATEGORIES}
          seeLessLabel={labels.SHOW_LESS_CATEGORIES}
          numberOfTabsToShow={6}
          choices={categories}
          isMultiple={contribution_type === MILESTONE ? false : true}
          getMultipleSelectedChoices={handleSelectMultipleCategories}
          getSingleSelectedChoice={handleSelectSingleCategory}
          singleSelectedChoiceId={singleSelectedCategoryId}
          titleStyleProps={{
            color: GRAY,
            bold: true,
            underlined: false,
            level: screenWidthIsLowerThan(900)
              ? "medium_17_px"
              : "h3_sub_header",
          }}
          seeMoreLinkStyleProps={{
            color: GREEN,
            bold: false,
            underlined: true,
            level: screenWidthIsLowerThan(900) ? "t2_text_2" : "paragraph_1",
            seeMoreLinkContainerClass: "",
          }}
          tabStyleProps={{
            color: WHITE,
            bold: true,
            underlined: false,
            level: screenWidthIsLowerThan(900) ? "t2_text_2" : "paragraph_2",
            unselectedTabClass: "",
            selectedTabClass: "",
            tabsContainerClass: "",
          }}
          preSelectedChoices={
            is_edit_form || is_draft ? getCategoriesFromContent() : []
          }
        />
      )}

      {getFormFields()}
    </>
  );
};

DiyUpperSection.propTypes = {
  user_id: PropTypes.number,
  contribution_type: PropTypes.string,
  user_roles: PropTypes.arrayOf(PropTypes.string),
  form_type: PropTypes.string,
  can_apply_for_exemption: PropTypes.bool,
  towns_with_clusters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name_with_zip_code: PropTypes.string,
      cluster_towns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  env: PropTypes.string,
  us_states: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  funeral_home_names: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  columns: PropTypes.arrayOf(PropTypes.shape),
  is_advertiser: PropTypes.bool,
  is_contributor: PropTypes.bool,
  is_contributor_with_granted_exemptions: PropTypes.bool,
  granted_exemption_town_names: PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    body: PropTypes.string,
    byline: PropTypes.string,
    subheading: PropTypes.string,
    excerpt: PropTypes.string,
    phone_number: PropTypes.string,
    facebook_client_url: PropTypes.string,
    organization_type: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
    bedrooms: PropTypes.string,
    bathrooms: PropTypes.string,
    lot_size: PropTypes.string,
    price: PropTypes.string,
    mls_number: PropTypes.string,
    mls_url: PropTypes.string,
    listing_url: PropTypes.string,
    sold: PropTypes.bool,
    under_contract: PropTypes.bool,
    realtor_name: PropTypes.string,
    realtor_phone: PropTypes.string,
    realtor_email: PropTypes.string,
    website: PropTypes.string,
    agency_name: PropTypes.string,
    agency_phone: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    venue: PropTypes.string,
    ci_name_admin: PropTypes.string,
    ci_phone_admin: PropTypes.string,
    ci_name: PropTypes.string,
    ci_phone: PropTypes.string,
    ci_email: PropTypes.string,
    all_day: PropTypes.bool,
    starts: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    ends: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    scheduled_dates: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    scheduled_hour: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  }),
  is_draft: PropTypes.bool,
  is_edit_form: PropTypes.bool,
  event_draft_dates_count: PropTypes.number,
};

export default withDiyContextProvider(DiyUpperSection);
