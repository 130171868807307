import React, { useEffect, useState } from "react";
import {
  getDropdownChoices,
  loadTownsWithClusters,
  loadTownsWithoutClusters,
} from "../../../services/townService";
import clsx from "clsx";
import labels from "../../../config/labels";
import TownPicker from "../../TownPicker/TownPicker";
import { useStyles } from "./SingleTownSelector.style";
import { bool, func, object } from "prop-types";
import Typography from "../../../common/Typography/Typography";

const SingleTownSelector = ({
  townsWithClusters,
  handleSelectTown,
  error,
  preselectedTown,
  underLicense,
}) => {
  const classes = useStyles();
  const [towns, setTowns] = useState([]);

  useEffect(() => {
    const fetchTowns = async () => {
      const response = townsWithClusters
        ? await loadTownsWithClusters()
        : await loadTownsWithoutClusters();

      if (response?.status === 200) {
        const fetchedTowns = townsWithClusters
          ? response?.data?.towns_with_clusters
          : response?.data?.towns_without_clusters;
        setTowns(fetchedTowns);
      }
    };

    if (towns.length === 0) {
      fetchTowns();
    }
  }, [towns, townsWithClusters]);

  return (
    <>
      <Typography
        level="h4"
        className={clsx(classes.label, error && classes.label_error)}
        bold
      >
        {labels.CHOOSE_A_SITE + "*"}
      </Typography>
      <TownPicker
        containerClassName={classes.container}
        isMultipleWithTags={false}
        townsAsItems={getDropdownChoices(towns, handleSelectTown)}
        isTownPickerMobile={false}
        placeholder={
          underLicense ? labels.SELECT_A_PUBLICATION : labels.SELECT_A_TOWN
        }
        withNoTownOption={false}
        comboBoxClasses={{
          root: error ? classes.root_error : "",
          focused: classes.focused,
          inputFocused: classes.input_focused,
          paper: classes.paper,
          option: classes.option,
        }}
        onComboboxInputChange={(e, value, reason) => {
          if (reason == "clear") handleSelectTown(undefined);
        }}
        defaultValue={preselectedTown}
      />
    </>
  );
};

SingleTownSelector.propTypes = {
  townsWithClusters: bool,
  handleSelectTown: func,
  preselectedTown: object,
  underLicense: bool,
};

SingleTownSelector.defaultProps = {
  townsWithClusters: false,
};

export default SingleTownSelector;
