import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Radio } from "@material-ui/core";
import { useStyles } from "./RadioButton.style";

const RadioButton = ({
  className,
  onChange,
  onClick,
  disabled,
  value,
  name,
  checked,
}) => {
  const classes = useStyles();

  const handleOnChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Radio
      disabled={disabled}
      className={clsx(className, classes.selected_button)}
      onChange={handleOnChange}
      name={name}
      checked={checked}
      value={value}
      onClick={onClick}
    />
  );
};

RadioButton.propTypes = {
  className: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  checked: PropTypes.bool,
};

export default RadioButton;
