import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  modal_content: {
    width: theme.spacing(75),
    height: theme.spacing(65),
    textAlign: "center",

    [theme.breakpoints.down(761)]: {
      width: theme.spacing(62.5),
    },
    [theme.breakpoints.down(601)]: {
      width: theme.spacing(50),
    },
    [theme.breakpoints.down(461)]: {
      width: theme.spacing(43.75),
    },
    [theme.breakpoints.down(401)]: {
      width: theme.spacing(35.5),
    },
  },

  text_container: {
    color: theme.palette.tapintoBranding.tapintoOrange,
    fontSize: "22px",
    lineHeight: "24px",
    marginTop: theme.spacing(14.75),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },

  button: {
    width: theme.spacing(10),
    display: "inline-block",
    height: theme.spacing(5),
    "&.MuiButtonBase-root": {
      marginTop: theme.spacing(9),
      marginRight: theme.spacing(3),
    },
    "&.MuiButton-root": {
      background: `linear-gradient(#a66114 3%, 20%, ${theme.palette.tapintoBranding.tapintoOrange})`,
      borderRadius: 20,
      borderBottomRightRadius: 3,
      textTransform: "none",
    },
    "&.MuiButton-text": {
      fontSize: "20px",
      lineHeight: "22px",
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    "&.MuiButton-textPrimary": {
      color: theme.palette.miscellaneous.white,
    },
  },
}));

export { useStyles };
