import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Spinner from "../../common/Spinner/Spinner";
import { useStyles } from "./LazyLoadCards.style";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";
import CardsList from "../../common/CardsList/CardsList";

const LazyLoadCards = ({ options, customClasses }) => {
  const classes = useStyles();

  const getNumberOfCardsPerRow = ({ smallScreen, mediumScreen }) => {
    if (smallScreen) {
      return 1;
    }
    if (mediumScreen) {
      return 2;
    }

    return 4;
  };

  const CardsPerRow = ({ smallScreen, mediumScreen }) => {
    let numberOfCardsPerRow = options?.autoLayout
      ? getNumberOfCardsPerRow({
          smallScreen: smallScreen,
          mediumScreen: mediumScreen,
        })
      : options?.cardsPerRow;
    const items = [...Array(numberOfCardsPerRow)];

    return items.map((_, index) => (
      <div
        key={index}
        className={clsx(
          classes.card_content,
          customClasses?.card_content && customClasses.card_content
        )}
      >
        <Spinner withLoadingOverlay={false} className={classes.spinner} />
      </div>
    ));
  };

  return (
    <CardsList
      items={CardsPerRow({
        smallScreen: screenWidthIsLowerThan(550),
        mediumScreen: screenWidthIsLowerThan(960),
      })}
      numberOfCardsPerRow={
        options?.autoLayout
          ? getNumberOfCardsPerRow({
              smallScreen: screenWidthIsLowerThan(550),
              mediumScreen: screenWidthIsLowerThan(960),
            })
          : options?.cardsPerRow
      }
      {...(customClasses?.container && {
        containerClass: customClasses?.container,
      })}
      {...(customClasses?.card && {
        cardClass: customClasses?.card,
      })}
    />
  );
};

LazyLoadCards.propTypes = {
  options: PropTypes.shape({
    autoLayout: PropTypes.bool,
    cardsPerRow: PropTypes.number,
  }),
  customClasses: PropTypes.shape({
    card_content: PropTypes.string,
    container: PropTypes.string,
    card: PropTypes.string,
  }),
};

LazyLoadCards.defaultProps = {
  options: {
    autoLayout: true,
  },
};

export default LazyLoadCards;
