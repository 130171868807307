import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

const fetchTownsByStateAndCounty = async (
  userId,
  contributionType,
  organizationType,
  content_id
) => {
  const response = await restClient.get(withConfig("GET_TOWNS_BY_COUNTY_URL"), {
    user_id: userId,
    contribution_type: contributionType,
    organization_type: organizationType,
    content_id: content_id,
  });
  return response;
};

export { fetchTownsByStateAndCounty };
