import React from "react";
import { useStyles } from "./GamesTitle.style";
import Games from "../../../common/Icons/Games";
import { useTown } from "../../../context/TownContext";
import Sponsor from "../../Sponsor/Sponsor";
import PropTypes from "prop-types";
import Link from "../../../common/Link/Link";
import { pathToUrl } from "../../../utils/helper";
import withConfig from "../../../utils/withConfig";

const GamesTitle = ({ sponsor_info }) => {
  const classes = useStyles();
  const {town} = useTown()
  return (
    <div className={classes.header_container}>
      <Link
      children={
        <Games townSlug={town?.slug} underLicense={town?.under_license_contract}/>
      }
      url={pathToUrl(withConfig("INDEX_GAMES_URL"),{
        town_slug: town?.slug
      })}
      />
      {sponsor_info && (<div className={classes.sponsor_container}>
        <Sponsor sponsor_info={sponsor_info} />
      </div>)}
    </div>
  );
};

GamesTitle.propTypes = {
  sponsor_info: PropTypes.object,
};

export default GamesTitle;
