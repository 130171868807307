import React from "react";
import PropTypes from "prop-types";
import Link from "../../../common/Link/Link";
import { useStyles } from "./ObituaryBlock.style";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { isVideoPresent } from "../../../utils/helper";
import VideoPlayer from "../../../common/VideoPlayer/VideoPlayer";
import SocialNetworkShareButtons from "../../SocialNetworkShareButtons/SocialNetworkShareButtons";
import { printDates } from "../../../services/obituaries";

const ObituaryBlock = ({ obituary }) => {
  const classes = useStyles();
  return (
    <div className={classes.obituary_block}>
      <div className={classes.image_container}>
        {isVideoPresent(obituary) && (
          <VideoPlayer
            url={obituary.video_url}
            width={"100%"}
            height={"100px"}
            showControls={true}
          />
        )}
        {!isVideoPresent(obituary) && (
          <Link
            url={obituary.obituary_url}
            children={
              <img alt="" src={obituary.photo_url} className={classes.image} />
            }
          />
        )}
      </div>
      <div className={classes.info_container}>
        <Link
          url={obituary.obituary_url}
          children={
            <Typography level="paragraph_1" color="black" bold>
              {obituary.name}
            </Typography>
          }
        />

        <Typography level="t2_text_2" color="gray">
          {printDates({
            birth: obituary?.date_of_birth,
            death: obituary?.date_of_death,
          })}
        </Typography>
        <div className={classes.links_container}>
          <div className={classes.share_this_container}>
            <SocialNetworkShareButtons
              url={obituary.obituary_url}
              title={labels.SHARING_TITLE + obituary.name}
              socialNetworks={["facebook", "twitter"]}
              iconsSize={20}
              paddingWithinButtons={5}
            />
          </div>
          <Link
            url={obituary.obituary_url}
            children={
              <Typography level="t1_text_1" color="gray" bold underlined>
                {labels.READ_MORE}
              </Typography>
            }
          />
        </div>
      </div>
    </div>
  );
};

ObituaryBlock.propTypes = {
  obituary: PropTypes.shape({
    obituary_url: PropTypes.string,
    date_of_birth: PropTypes.string,
    date_of_death: PropTypes.string,
    name: PropTypes.string,
    photo_url: PropTypes.string,
  }),
};
export default ObituaryBlock;
