import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

const applyForExemption = async (
  currentUserId,
  mainTownId,
  additionalTownIds,
  name,
  description
) => {
  const response = await restClient.post(
    withConfig("EXEMPTION_URL"),
    {
      user_id: currentUserId,
      additional_town_ids: additionalTownIds,
      main_town_id: mainTownId,
      government_or_non_profit_name: name,
      government_or_non_profit_description: description,
    },
    false
  );
  return response;
};

export { applyForExemption };
