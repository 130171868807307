import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import labels from "../../../../../config/labels";
import { useStyles } from "./EventDate.style";
import { GRAY, ORANGE_HEX } from "../../../diyConstants";
import DatePicker from "../../../../../common/DatePicker/DatePicker";
import TimePicker from "../../../../../common/TimePicker/TimePicker";
import Typography from "../../../../../common/Typography/Typography";
import Checkbox from "../../../../../common/Checkbox/Checkbox";
import Trashcan from "../../../../../common/Icons/Trashcan";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import { isValid } from "date-fns";

const noon = new Date("2022-01-01 12:00");
const one_pm = new Date("2022-01-01 13:00");
const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const EventDate = ({
  eventDateCounter,
  onChange,
  onRemoval,
  starts,
  eventStartTime,
  eventEndTime,
  isMainDate,
  dateId,
  allDay,
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(starts ? starts : date);
  const [startTime, setStartTime] = useState(noon);
  const [endTime, setEndTime] = useState(one_pm);
  const [isAllDayCheckboxSelected, setIsAllDayCheckboxSelected] = useState(
    allDay ? allDay : false
  );

  useEffect(() => {
    onChange({
      id: dateId,
      index: eventDateCounter,
      date: date,
      start_time: isValid(startTime)
        ? "12:00 AM"
        : startTime.toString().toLocaleLowerCase(),
      end_time: endTime?.toString()?.toLocaleLowerCase(),
      all_day: isAllDayCheckboxSelected,
    });
  }, [date, startTime, endTime, isAllDayCheckboxSelected]);

  const removeEventDate = () => {
    onRemoval(eventDateCounter, dateId);
  };

  const handleDateChange = (value) => {
    setDate(value);
  };

  const getSelectedStartTime = (time) => {
    setStartTime(time);
  };

  const getSelectedEndTime = (time) => {
    setEndTime(time);
  };

  const getDatePickerValues = () => {
    let datePickerValues = {
      startTime: noon,
      endTime: one_pm,
    };

    if (isAllDayCheckboxSelected) {
      datePickerValues = {
        startTime: null,
        endTime: null,
      };
    }
    return datePickerValues;
  };

  return (
    <>
      <div className={classes.pickers_container}>
        <div className={classes.single_picker}>
          <DatePicker
            className={classes.pickers}
            label={labels.EVENT_DATE}
            labelStyleProps={{
              level: screenWidthIsLowerThan(900) ? "t4_text_4" : "medium_17_px",
              color: GRAY,
              bold: true,
              className: classes.picker_labels,
            }}
            defaultValue={starts ? starts : date}
            onChange={handleDateChange}
            disablePast={false}
          />
        </div>

        <div className={classes.event_all_day_container}>
          <div>
            <Typography
              level={screenWidthIsLowerThan(900) ? "t4_text_4" : "medium_17_px"}
              color={GRAY}
              bold
            >
              {labels.ALL_DAY}
            </Typography>
          </div>
          <Checkbox
            checked={isAllDayCheckboxSelected}
            onChange={() =>
              setIsAllDayCheckboxSelected(!isAllDayCheckboxSelected)
            }
            className={classes.event_all_day_checkbox}
          />
        </div>

        <div className={classes.single_picker}>
          <TimePicker
            disabled={isAllDayCheckboxSelected}
            className={classes.pickers}
            label={labels.START_TIME}
            labelStyleProps={{
              level: screenWidthIsLowerThan(900) ? "t4_text_4" : "medium_17_px",
              color: GRAY,
              bold: true,
              className: classes.picker_labels,
            }}
            sublabel={labels.TIME_15_MINUTES_STEP}
            sublabelStyleProps={{
              level: screenWidthIsLowerThan(900) ? "t2_text_2" : "medium_13_px",
              color: GRAY,
              bold: true,
              className: classes.picker_labels,
            }}
            onChange={getSelectedStartTime}
            defaultValue={
              eventStartTime ? eventStartTime : getDatePickerValues().startTime
            }
            minutesStep={15}
          />
        </div>

        <div className={classes.single_picker}>
          <TimePicker
            disabled={isAllDayCheckboxSelected}
            className={classes.pickers}
            label={labels.END_TIME}
            labelStyleProps={{
              level: screenWidthIsLowerThan(900) ? "t4_text_4" : "medium_17_px",
              color: GRAY,
              bold: true,
              className: classes.picker_labels,
            }}
            sublabel={labels.TIME_15_MINUTES_STEP}
            sublabelStyleProps={{
              level: screenWidthIsLowerThan(900) ? "t2_text_2" : "medium_13_px",
              color: GRAY,
              bold: true,
              className: classes.picker_labels,
            }}
            onChange={getSelectedEndTime}
            defaultValue={
              eventEndTime ? eventEndTime : getDatePickerValues().endTime
            }
            minutesStep={15}
          />
        </div>
        <div className={classes.delete_event_date_cotainer}>
          {(eventDateCounter > 0 || !isMainDate) && (
            <div
              className={classes.trashcan_icon_container}
              onClick={removeEventDate}
            >
              <Trashcan fillColor={ORANGE_HEX} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

EventDate.propTypes = {
  eventDateCounter: PropTypes.number,
  onChange: PropTypes.func,
  onRemoval: PropTypes.func,
  starts: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  eventDateCounter: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  eventStartTime: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  eventEndTime: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  isMainDate: PropTypes.bool,
  dateId: PropTypes.number,
  allDay: PropTypes.bool,
};

EventDate.defaultProps = {
  isMainDate: true,
};

export default EventDate;
