import { makeStyles } from "../../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    regular_text_field: {
      width: "100%",
      paddingLeft: theme.spacing(4),
      marginTop: theme.spacing(1),
    },
  };
});

export { useStyles };
