import React from "react";
import PropTypes from "prop-types";

const AlertIconCircle = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="63"
      height="61"
      viewBox="0 0 63 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.5 3.95752C53.7033 8.68307 60 18.2695 60 29.3266C60 45.0668 47.2401 57.8266 31.5 57.8266C15.7599 57.8266 3 45.0668 3 29.3266C3 18.2695 9.29674 8.68307 18.5 3.95752"
        stroke={fillColor}
        strokeWidth="6"
        strokeLinecap="round"
      />
      <path
        d="M26.7764 1.37947C26.5972 1.46907 26.4888 1.65696 26.5009 1.85693L28.5009 34.857C28.5169 35.1209 28.7356 35.3267 29 35.3267H34C34.2644 35.3267 34.4831 35.1209 34.4991 34.857L36.4991 1.85693C36.5112 1.65696 36.4028 1.46906 36.2236 1.37947C35.1274 0.831374 33.3914 0.527258 31.6574 0.501754C29.9218 0.476227 28.0794 0.727961 26.7764 1.37947Z"
        fill={fillColor}
        stroke={fillColor}
        strokeLinejoin="round"
      />
      <circle cx="31.5" cy="42.3267" r="3.5" fill={fillColor} />
    </svg>
  );
};

AlertIconCircle.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

AlertIconCircle.defaultProps = {
  fillColor: "white",
};

export default AlertIconCircle;
