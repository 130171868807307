import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette, mastheadHeights } = theme;
  return {
    static_section: {
      position: "fixed",
      width: "100%",
      backgroundColor: palette.miscellaneous.white,
      zIndex: "99",
      top: mastheadHeights.mobile,
    },
    reduced_top: {
      top: mastheadHeights.reduced_mobile,
      transition: "top 0.3s",
    },
    static_section_top_bar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: `${spacing(1.5)}px 0px`,
    },
    back_to_real_estates_link: {
      textTransform: "uppercase",
    },
    back_to_real_estates_items_container: {
      display: "flex",
      alignItems: "center",
    },
    back_icon: {
      marginRight: spacing(1),
    },
    scroll_section: {
      paddingTop: (stickyHeightWithTopOffset) =>
        stickyHeightWithTopOffset
          ? `${stickyHeightWithTopOffset - 56}px`
          : "26vh",
      position: "relative",
      "& div": {
        scrollMarginTop: (stickyHeightWithTopOffset) =>
          stickyHeightWithTopOffset ? `${stickyHeightWithTopOffset}px` : "35vh",
        scrollSnapAlign: "start",
      },
    },
    scroll_section_for_gallery: {
      top: (stickyHeightWithTopOffset) =>
        stickyHeightWithTopOffset
          ? `${stickyHeightWithTopOffset - 56}px`
          : "8vh",
      marginBottom: "0px !important",
    },
    separator: {
      height: spacing(1.5),
      margin: `0px ${spacing(1)}px`,
    },
    price: {
      fontWeight: "500 !important",
      margin: "0px !important",
      padding: "0px !important",
    },
    address_container: {
      width: "70%",
      marginBottom: spacing(2),
    },
    status_container: {
      marginTop: spacing(4),
      marginBottom: spacing(3),
    },
    title_bedrooms_and_bathrooms_container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: spacing(3),
    },
    actions_container: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    button_container: {
      width: "60%",
    },
    carousel_container: {
      "& .swiper-container": {
        zIndex: "-1 !impoertant",
      },
    },
    property_information_block: {
      padding: `${spacing(2)}px 0px !important`,
    },
    button: {
      padding: "0px !important",
    },
    reduced_top_offset: {
      paddingTop: `${spacing(12)}px !important`,
    },
  };
});

export { useStyles };
