import React, { useEffect, useState } from "react";
import { getGames, getGameBySlug } from "../services/games";
import { showBlockSection } from "../utils/helpers/homepageHelper";

const GAME_TYPES = {
  sudoku: "sudoku",
  crossword: "crossword",
};
const useGames = ({ isForIndex, gameSlug, rightBlocks }) => {
  const [game, setGame] = useState({});
  const [games, setGames] = useState([]);
  const [sudokuGames, setSudokuGames] = useState([]);
  const [crosswordGames, setCrosswordGames] = useState([]);
  const [otherGames, setOtherGames] = useState([]);
  const [adBlocks, setAdBlocks] = useState([]);

  const fetchGames = async () => {
    if (isForIndex) {
      const response = await getGames();
      if (response) {
        setGames(response.data.games);
        setSudokuGames(
          response.data.games.filter(
            (game) => game.game_type === GAME_TYPES.sudoku
          )
        );
        setCrosswordGames(
          response.data.games.filter(
            (game) => game.game_type === GAME_TYPES.crossword
          )
        );
        setOtherGames(
          response.data.games.filter(
            (game) => game.game_type !== GAME_TYPES.sudoku
          )
        );
      } else {
        setGames([]);
        setSudokuGames([]);
        setCrosswordGames([]);
      }
    } else if (gameSlug) {
      const response = await getGameBySlug(gameSlug);
      if (response) {
        setGame(response.data.game);
      } else {
        setGame({});
      }
    }
  };

  const fetchAdsForGames = async () => {
    setAdBlocks(
      rightBlocks
        ?.filter(
          (block) =>
            showBlockSection("ad", block) && block?.ad_type === "Bullseye"
        )
        .slice(0, 2)
    );
  };

  useEffect(() => {
    fetchGames();
  }, []);

  useEffect(() => {
    fetchAdsForGames();
  }, [rightBlocks]);
  return {
    game,
    games,
    sudokuGames,
    crosswordGames,
    otherGames,
    adBlocks,
  };
};

export default useGames;
