import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../../../../config/labels";
import TextField from "../../../../../../common/TextField/TextField";
import { useStyles } from "./FuneralHomeInfo.style";

const FuneralHomeInfo = ({ onChange, funeralHomeCounter, content }) => {
  const classes = useStyles();
  const [name, setName] = useState(content?.id ? content?.name : "");
  const [address, setAddress] = useState(content?.id ? content?.address : "");

  useEffect(() => {
    onChange({ name, address, funeralHomeCounter });
  }, [name, address]);

  useEffect(() => {
    onChange({ name, address, funeralHomeCounter });
  }, []);

  return (
    <>
      <div className={classes.funeral_home_selection_container}>
        <div className={classes.regular_text_field}>
          <TextField
            onChange={(e) => setName(e.target.value)}
            placeholder={labels.NAME}
            defaultValue={content?.name}
          />
        </div>
        <div className={classes.regular_text_field}>
          <TextField
            onChange={(e) => setAddress(e.target.value)}
            placeholder={labels.ADDRESS}
            defaultValue={content?.address}
          />
        </div>
      </div>
    </>
  );
};

FuneralHomeInfo.propTypes = {
  onChange: PropTypes.func,
  funeralHomeCounter: PropTypes.number,
};

export default FuneralHomeInfo;
