import React from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import { getCategoriesLinks } from "../../../../utils/helper";
import HeaderWithCategories from "../../../Headers/Show/WithCategories/HeaderWithCategories";
import { useStyles } from "./ClassifiedShowHeader.style";

const ClassifiedShowHeader = ({
  categories,
  townSlug,
  contentTitle,
  editUrl,
  showContentOptions,
  sponsorInfo,
  isAdvertisement,
  isContentPreview,
}) => {
  const classes = useStyles();
  return (
    <>
      <HeaderWithCategories
        isAdvertisement={isAdvertisement}
        showContentOptions={showContentOptions}
        {...(screenWidthIsLowerThan(960) && { sponsorInfo: sponsorInfo })}
        title={labels.CLASSIFIEDS}
        categories={getCategoriesLinks(
          categories,
          townSlug,
          true,
          {
            className: classes.categories,
            level: screenWidthIsLowerThan(960)
              ? "h5_sub_header_2"
              : "h4_sub_header_4",
            color: "gray",
            bold: true,
          },
          "classifieds",
          isContentPreview
        )}
        editUrl={editUrl}
        contentTitle={contentTitle}
        contentTitleProps={{
          level: screenWidthIsLowerThan(960) ? "h3_sub_header" : "h1_header_1",
          color: "black",
          bold: true,
          underlined: false,
        }}
      />
    </>
  );
};

ClassifiedShowHeader.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  townSlug: PropTypes.string,
  contentTitle: PropTypes.string,
  editUrl: PropTypes.string,
  showContentOptions: PropTypes.bool,
  sponsorInfo: PropTypes.shape({
    page_type: PropTypes.string,
    sponsor_attr: PropTypes.string,
    top_local_news: PropTypes.bool,
    town: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    columns_slug: PropTypes.arrayOf(PropTypes.string),
    sections_slug: PropTypes.arrayOf(PropTypes.string),
  }),
  isAdvertisement: PropTypes.bool,
  isContentPreview: PropTypes.bool,
};

export default ClassifiedShowHeader;
