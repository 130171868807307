import { makeStyles } from "../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    row: {
      display: "flex",
    },
    icons_container: {
      marginTop: theme.spacing(4),
      flexWrap: "wrap",
    },
  };
});

export { useStyles };
