import React, { useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import { useStyles } from "./Document.style";
import PDFActions from "../PDFActions/PDFActions";
import Grid from "../../../common/Grid/Grid";
import Checkbox from "../../../common/Checkbox/Checkbox";
import Edit from "../../../common/Icons/Edit";
import Trashcan from "../../../common/Icons/Trashcan";
import ButtonIcon from "../../../common/Button/ButtonIcon";
import Typography from "../../../common/Typography/Typography";
import { useDocuments } from "../../../context/CMS/DocumentsContext/DocumentsContext";
import { Document as PDFDocument } from "../../../classes/Document";
import { convertBytestoKB } from "../../../services/documents";
import Button from "../../../common/Button/Button";

const BLACK = "#000000";
const GRAY = "#808080";

const Document = ({ document }) => {
  const classes = useStyles();
  const [actionsOpened, setActionsOpened] = useState(false);
  const {
    removeDocument,
    handleCheckboxChange,
    selectedDocuments,
    haveBulkActions,
  } = useDocuments();

  const handleRemove = async () => {
    if (confirm(labels.PDF_ACTION_LABELS.REMOVAL_CONFIRMATION)) {
      await removeDocument(document);
      setActionsOpened(false);
    }
  };

  return (
    <>
      <PopUpDialog
        content={
          <PDFActions
            document={document}
            afterAction={() => setActionsOpened(false)}
          />
        }
        openState={actionsOpened}
        onClose={() => setActionsOpened(false)}
        className={classes.pdf_actions_pop_up}
        contentClass={classes.content}
        titleClass={classes.no_title}
        closeButtonColor={BLACK}
        closeButtonClass={classes.close_button}
      />
      <Grid
        container
        spacing={2}
        row
        className={classes.container}
        rowSpacing={200}
      >
        {haveBulkActions && (
          <Grid item className={classes.checkbox_container}>
            <Checkbox
              value={document.id}
              checked={selectedDocuments.includes(document.id)}
              onChange={() => handleCheckboxChange(document.id)}
              checkboxColor={GRAY}
            />
          </Grid>
        )}
        <Grid item className={classes.thumbnail_container}>
          <img src={document.thumbnailUrl} height={"100%"} />
        </Grid>
        <Grid item className={classes.filename_container}>
          <Typography level={"t4_text_4"} className={classes.filename}>
            {document.name}
          </Typography>
          <Typography
            level={"t4_text_4"}
            color={"lightGray"}
            className={classes.size}
          >
            {convertBytestoKB(document.documentSize)} kb
          </Typography>
        </Grid>
        <Grid item className={classes.actions}>
          {haveBulkActions ? (
            <ButtonIcon
              onClick={() => setActionsOpened(true)}
              withDefaultStyles
              children={<Edit fillColor={BLACK} width={24} height={24} />}
              className={classes.edit_button}
            />
          ) : (
            <Button
              onClick={() => setActionsOpened(true)}
              level={"legal_notice_button"}
              children={<Typography bold>{labels.PDF_ACTIONS}</Typography>}
              className={classes.edit_button_text}
            />
          )}
          <ButtonIcon
            onClick={handleRemove}
            withDefaultStyles
            children={<Trashcan fillColor={BLACK} width={24} height={24} />}
            className={classes.edit_button}
          />
        </Grid>
      </Grid>
    </>
  );
};

Document.propTypes = {
  document: PropTypes.instanceOf(PDFDocument).isRequired,
};

export default Document;
