import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    sponsored_content: {
      paddingBottom: 10,
      display: "block",
      textAlign: "right",
      opacity: "0.7",
    },
  };
});

export { useStyles };
