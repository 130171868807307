import React from "react";
import PropTypes from "prop-types";
import PopUpDialog from "../../../../common/Dialog/PopUpDialog";
import { useStyles } from "./ModalForForms.style";

const RealEstateModalForForms = ({
  title,
  content,
  openState,
  handleClose,
  isRealtorInfo,
}) => {
  const classes = useStyles();

  return (
    <PopUpDialog
      onClose={handleClose}
      openState={openState}
      title={title}
      content={
        <div
          className={
            isRealtorInfo
              ? classes.dialog_info_container
              : classes.dialog_form_container
          }
        >
          {content}
        </div>
      }
      titleClass={isRealtorInfo ? classes.no_title : classes.dialog_title}
      closeButtonClass={
        isRealtorInfo ? classes.no_close_button : classes.dialog_close_button
      }
      closeButtonColor={"black"}
      titleColor={"black"}
    />
  );
};

RealEstateModalForForms.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  openState: PropTypes.bool,
  handleClose: PropTypes.func,
  isRealtorInfo: PropTypes.bool,
};

export default RealEstateModalForForms;
