import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    location_icon: {
      height: theme.spacing(2),
      width: "auto",
      paddingLeft: theme.spacing(1),
    },
    view_in_google_maps_link: {
      "& a": {
        color: theme.palette.dark.gray,
        textDecoration: "none",
      },
      "&:hover": {
        color: theme.palette.dark.gray,
        textDecoration: "none !important",
      },
    },
    add_to_calendar_button_event_page: {
      width: "70% !important",
      height: `${theme.spacing(4.25)}px !important`,
      paddingLeft: `${theme.spacing(1.5)}px !important`,
      marginBottom: theme.spacing(3),
      "& span.addeventatc_dropdown": {
        left: "0px !important",
        [theme.breakpoints.up(760)]: {
          width: "auto !important",
        },
      },
      [theme.breakpoints.between(960, 1070)]: {
        width: "100% !important",
      },
    },
    date_container: {
      marginBottom: theme.spacing(1),
    },
    text_container: {
      marginBottom: theme.spacing(2),
    },
    email: {
      marginBottom: `${theme.spacing(2)}px !important`,
    },
    contact_links_social_media_facebook: {
      color: `${theme.palette.facebook.facebookBlueDarkerVariant} !important`,
      textDecoration: "underline !important",
      "&:hover": {
        color: theme.palette.facebook.facebookBlueDarkerVariant,
      },
      "&:focus": {
        color: theme.palette.facebook.facebookBlueDarkerVariant,
      },
    },
    contact_links_social_media_twitter: {
      color: `${theme.palette.miscellaneous.twitterBlue} !important`,
      textDecoration: "underline !important",
      "&:hover": {
        color: theme.palette.miscellaneous.twitterBlue,
      },
      "&:focus": {
        color: theme.palette.miscellaneous.twitterBlue,
      },
    },
    contact_links: {
      display: "block",
      width: "100%",
      color: `${theme.palette.miscellaneous.black} !important`,
      textDecoration: "underline !important",
      "&:hover": {
        color: theme.palette.miscellaneous.black,
      },
      "&:focus": {
        color: theme.palette.miscellaneous.black,
      },
    },
    social_links_container: {
      display: "inline",
    },
    google_map_frames: {
      height: theme.spacing(4),
      width: theme.spacing(47.5),
      backgroundColor: theme.palette.themeColor.primary,
      [theme.breakpoints.down(960)]: {
        width: theme.spacing(40),
      },
    },
    google_map_top_frame: {
      borderTopRightRadius: theme.spacing(0.5),
      borderTopLeftRadius: theme.spacing(0.5),
    },
    google_map_bottom_frame: {
      borderBottomRightRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      marginBottom: theme.spacing(2),
    },
    view_in_google_maps_frame_link: {
      "& a": {
        color: theme.palette.miscellaneous.white,
      },
      "&:hover": {
        color: theme.palette.miscellaneous.white,
        textDecoration: "none",
      },
      "& svg": {
        marginLeft: theme.spacing(22.75),
      },
      [theme.breakpoints.down(960)]: {
        "& svg": {
          marginLeft: theme.spacing(15),
        },
      },
    },
    google_map: {
      width: theme.spacing(47.5),
      height: theme.spacing(29),
      [theme.breakpoints.down(960)]: {
        width: theme.spacing(40),
      },
    },
    google_map_element: {
      height: "100%",
    },
    location_info: {
      width: "90%",
      overflowWrap: "anywhere",
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    date: {
      paddingTop: theme.spacing(1),
    },
    location_online: {
      display: "flex",
      textDecoration: "underline !important",
      color: `${theme.palette.grayVariation.grayForSubTitle} !important`,
      marginBottom: theme.spacing(2),
      "& svg": {
        height: theme.spacing(3),
        width: theme.spacing(3),
        marginLeft: theme.spacing(1),
      },
    },
    venue_address_city_container: {
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down(960)]: {
        textAlign: "center",
      },
    },
    all_day_text: {
      textTransform: "none",
    },
  };
});

export { useStyles };
