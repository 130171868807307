import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    filters_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: theme.spacing(3),
      "& div.MuiFormControl-marginNormal": {
        marginBottom: "0px !important",
        marginTop: "0px !important",
      },
      [theme.breakpoints.down(960)]: {
        display: "block",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "& div.MuiFormControl-marginNormal": {
          marginTop: `${theme.spacing(2)}px !important`,
        },
      },
    },
    width: {
      width: "45%",
      [theme.breakpoints.down(960)]: {
        width: "100%",
        marginBottom: theme.spacing(2),
      },
    },
  };
});

export { useStyles };
