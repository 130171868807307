import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ExemptionPostRequestModal.style";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import BubbledButton from "../../../common/Button/BubbledButton";
import CheckCircle from "../../../common/Icons/CheckCircle";

const ExemptionPostRequestModal = ({
  submitExemptionRequestSuccessMessage,
  closePostSubmitModal,
  closeTownPickerModal,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.submitted_modal_content}>
      <div className={classes.icon_container}>
        <CheckCircle />
      </div>
      <Typography level="h5_sub_header_2" color="tapintoOrange" bold>
        {submitExemptionRequestSuccessMessage}
      </Typography>

      {submitExemptionRequestSuccessMessage && (
        <Typography level="h5_sub_header_2" color="tapintoOrange">
          <br />
          {labels.SUCCESSFUL_REQUEST_TEXT_1}
          <br />
          <br />
          {labels.SUCCESSFUL_REQUEST_TEXT_2}
        </Typography>
      )}

      <div className={classes.button_container}>
        <BubbledButton
          level="button_orange"
          color="primary"
          className={classes.submit_request_button}
          onClick={() => {
            closePostSubmitModal(), closeTownPickerModal();
          }}
        >
          {labels.I_UNDERSTAND}
        </BubbledButton>
      </div>
    </div>
  );
};

ExemptionPostRequestModal.propTypes = {
  submitExemptionRequestSuccessMessage: PropTypes.string,
  closePostSubmitModal: PropTypes.func,
  closeTownPickerModal: PropTypes.func,
};

export default ExemptionPostRequestModal;
