import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    regular_text_field: {
      paddingBottom: theme.spacing(2),
    },
    multiple_textfields_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    multiple_textfields_width_30: {
      width: "30%",
    },
    multiple_textfields_width_45: {
      width: "45%",
    },
    real_estate_checkbox: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: theme.spacing(2),
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.themeColor.secondary,
      },
    },
    checkbox_with_custom_label_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingBottom: theme.spacing(1),
    },
    checkbox_label_container: {
      width: "30%",
      [theme.breakpoints.down(900)]: {
        width: "50%",
      },
    },
    green_label: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  };
});

export { useStyles };
