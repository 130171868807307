import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    categories: {
      "& h5": {
        display: "contents",
      },
      "& h4": {
        display: "contents",
      },
      "&:hover": {
        "& h5": {
          color: `${theme.palette.themeColor.secondary} !important`,
        },
        "& h4": {
          color: `${theme.palette.themeColor.secondary} !important`,
        },
      },
      "&:focus": {
        "& h5": {
          color: `${theme.palette.themeColor.secondary} !important`,
        },
        "& h4": {
          color: `${theme.palette.themeColor.secondary} !important`,
        },
      },
    },
  };
});

export { useStyles };
