import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import TextField from "../../../../common/TextField/TextField";
import { useStyles } from "./ObituaryFields.style";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import {
  BLACK,
  MANDATORY_FIELDS_FOR_OBITUARY,
  NAME_OF_DECEASED,
  DATE_OF_BIRTH,
  DATE_OF_DEATH,
  VISITATION_INFO,
  SERVICE_INFO,
  CREMATION_INFO,
  BURIAL_INFO,
  CONTENT_ID,
} from "../../diyConstants";
import DatePicker from "../../../../common/DatePicker/DatePicker";
import ObituarySectionBlock from "./ObituarySectionBlock/ObituarySectionBlock";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";

const VISITATION = "visitation";
const SERVICE = "service";
const BURIAL = "burial";
const CREMATION = "cremation";

const ObituaryFields = ({ formErrors, funeralHomeNames, content }) => {
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();

  const [birthDate, setBirthDate] = useState(null);
  const [deathDate, setDeathDate] = useState(null);

  const shouldMarkError = (prop) => {
    let validation;

    validation = MANDATORY_FIELDS_FOR_OBITUARY.find(
      (mandatory) => mandatory.key === prop
    );
    if (formErrors.includes(validation.error)) {
      return true;
    }
  };

  const handleSectionBlockChange = (sectionBlockInfo, type) => {
    switch (type) {
      case VISITATION:
        setProperty(VISITATION_INFO, sectionBlockInfo);
        break;
      case SERVICE:
        setProperty(SERVICE_INFO, sectionBlockInfo);
        break;
      case BURIAL:
        setProperty(BURIAL_INFO, sectionBlockInfo);
        break;
      case CREMATION:
        setProperty(CREMATION_INFO, sectionBlockInfo);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setProperty(DATE_OF_BIRTH, birthDate);
  }, [birthDate]);

  useEffect(() => {
    setProperty(DATE_OF_DEATH, deathDate);
  }, [deathDate]);

  useEffect(() => {
    if (content?.id) {
      setProperty(DATE_OF_BIRTH, content?.date_of_birth);
      setProperty(DATE_OF_DEATH, content?.date_of_death);
      setProperty(NAME_OF_DECEASED, content?.name);
      setProperty(CONTENT_ID, content?.id);
      setProperty(VISITATION_INFO, content?.visitaitons);
      setProperty(SERVICE_INFO, content?.services);
      setProperty(BURIAL_INFO, content?.burial);
      setProperty(CREMATION_INFO, content?.cremation);
    }
  }, [content]);

  return (
    <>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.NAME_OF_DECEASED}
          onChange={(e) => setProperty(NAME_OF_DECEASED, e.target.value)}
          error={shouldMarkError(NAME_OF_DECEASED)}
          defaultValue={content?.name}
          required
        />
      </div>
      <div className={classes.multiple_datepickers_container}>
        <div>
          <DatePicker
            className={classes.datepicker}
            label={labels.DATE_OF_BIRTH}
            labelStyleProps={{
              level: screenWidthIsLowerThan(900) ? "medium_17_px" : "h4",
              color: BLACK,
              bold: true,
              className: classes.picker_labels,
            }}
            disablePast={false}
            onChange={(value) => setBirthDate(value)}
            defaultValue={
              content?.date_of_birth ? content?.date_of_birth : birthDate
            }
            openTo="year"
            readonly={true}
          />
        </div>
        <div>
          <DatePicker
            className={classes.datepicker}
            label={labels.DATE_OF_DEATH}
            labelStyleProps={{
              level: screenWidthIsLowerThan(900) ? "medium_17_px" : "h4",
              color: BLACK,
              bold: true,
              className: classes.picker_labels,
            }}
            disablePast={false}
            onChange={setDeathDate}
            defaultValue={
              content?.date_of_death ? content?.date_of_death : deathDate
            }
            readonly={true}
          />
        </div>
      </div>
      <ObituarySectionBlock
        funeralHomeNames={funeralHomeNames}
        onChange={handleSectionBlockChange}
        type={VISITATION}
        content={content?.visitations}
      />
      <ObituarySectionBlock
        funeralHomeNames={funeralHomeNames}
        onChange={handleSectionBlockChange}
        type={SERVICE}
        content={content?.services}
      />
      <ObituarySectionBlock
        funeralHomeNames={funeralHomeNames}
        onChange={handleSectionBlockChange}
        type={BURIAL}
        content={content?.burial}
      />
      <ObituarySectionBlock
        funeralHomeNames={funeralHomeNames}
        onChange={handleSectionBlockChange}
        type={CREMATION}
        content={content?.cremation}
      />
    </>
  );
};

ObituaryFields.propTypes = {
  formErrors: PropTypes.arrayOf(PropTypes.string),
  funeralHomeNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

export default ObituaryFields;
