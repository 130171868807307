import React from "react";
import PropTypes from "prop-types";

const Clock = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4783 4.08331C13.2083 4.08331 4.08203 13.23 4.08203 24.5C4.08203 35.77 13.2083 44.9166 24.4783 44.9166C35.7687 44.9166 44.9154 35.77 44.9154 24.5C44.9154 13.23 35.7687 4.08331 24.4783 4.08331ZM24.4987 40.8333C15.4745 40.8333 8.16536 33.5241 8.16536 24.5C8.16536 15.4758 15.4745 8.16665 24.4987 8.16665C33.5229 8.16665 40.832 15.4758 40.832 24.5C40.832 33.5241 33.5229 40.8333 24.4987 40.8333Z"
        fill={fillColor}
      />
      <path
        d="M25.5195 14.2917H22.457V26.5417L33.1758 32.9729L34.707 30.4617L25.5195 25.0104V14.2917Z"
        fill={fillColor}
      />
    </svg>
  );
};

Clock.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

Clock.defaultProps = {
  fillColor: "#F98C10",
};

export default Clock;
