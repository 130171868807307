import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

export const getGames = async () => {
    try {
        const response = await restClient.get(withConfig("GET_ALL_GAMES"));
        return response;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const getGameBySlug = async (gameSlug) => {
    try {
        const response = await restClient.get(withConfig("GET_GAME_BY_SLUG").replace(":slug", gameSlug));
        return response;
    } catch (e) {
        console.log(e);
        return false;
    }
};