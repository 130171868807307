import React, { useEffect, useRef } from "react";
import useBrowserValidator from "../../hooks/useBrowserValidator";
import Snackbar from "../../common/Snackbar/Snackbar";
import AlertIconCircle from "../../common/Icons/AlertIconCircle";
import { useStyles } from "./BrowserValidator.style";
import labels from "../../config/labels";

const BrowserValidator = ({ origin }) => {
  const classes = useStyles();
  const isOldBrowser = useBrowserValidator();
  const bannerContainer = useRef(null);

  const classSelector = () => {
    const newScrollYPosition = window.scrollY;
    if (newScrollYPosition < 110) {
      bannerContainer?.current?.classList?.remove(classes.banner_cms_scrolled);
      bannerContainer?.current?.classList?.remove(classes.banner_scrolled);
      origin == "CMS"
        ? bannerContainer?.current?.classList?.add(classes.banner_cms)
        : bannerContainer?.current?.classList?.add(classes.banner);
    } else {
      bannerContainer?.current?.classList?.remove(classes.banner_cms);
      bannerContainer?.current?.classList?.remove(classes.banner);
      origin == "CMS"
        ? bannerContainer?.current?.classList?.add(classes.banner_cms_scrolled)
        : bannerContainer?.current?.classList?.add(classes.banner_scrolled);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", classSelector);
    return () => {
      window.removeEventListener("scroll", classSelector);
    };
  }, []);

  return (
    isOldBrowser && (
      <div
        ref={bannerContainer}
        className={`${classes.banner_container}
          ${origin == "CMS" ? classes.banner_cms : classes.banner}`}
      >
        <Snackbar
          customIconBeforeMessage={<AlertIconCircle fillColor={"#802122"} />}
          open={true}
          message={labels.OLD_BROWSER_MESSAGE}
        />
      </div>
    )
  );
};

export default BrowserValidator;
