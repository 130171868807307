import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "../Typography/Typography";
import { useStyles } from "./SelectTabs.style";

const Tab = ({
  label,
  id,
  status,
  styleProps,
  getMultipleSelectedChoices,
  getSingleSelectedChoice,
  singleSelectedChoiceId,
  isMultiple,
}) => {
  const classes = useStyles();
  const [isSelectedTab, setIsSelectedTab] = useState(status);

  const handleTabSelect = () => {
    if (isMultiple) {
      setIsSelectedTab(!isSelectedTab);
      getMultipleSelectedChoices({
        id: id,
        name: label,
        selected: !isSelectedTab,
      });
    } else {
      getSingleSelectedChoice({
        id: id,
        name: label,
        selected: true,
      });
    }
  };

  const selectSingleTab = () => {
    if (id === singleSelectedChoiceId) {
      return true;
    }
    return false;
  };

  const getClasses = () => {
    if (
      styleProps.selectedTabClass &&
      styleProps.unselectedTabClass &&
      isMultiple
    ) {
      return clsx(
        styleProps.unselectedTabClass,
        isSelectedTab && styleProps.selectedTabClass
      );
    } else if (
      styleProps.selectedTabClass &&
      styleProps.unselectedTabClass &&
      isMultiple === false
    ) {
      return clsx(
        styleProps.unselectedTabClass,
        selectSingleTab() && styleProps.selectedTabClass
      );
    } else if (isMultiple) {
      return clsx(classes.tab, isSelectedTab && classes.selected_tab);
    } else if (isMultiple === false) {
      return clsx(classes.tab, selectSingleTab() && classes.selected_tab);
    }
  };

  return (
    <div onClick={handleTabSelect} className={getClasses()}>
      <Typography
        level={styleProps.level}
        color={styleProps.color}
        bold={styleProps.bold}
        underlined={styleProps.underlined}
      >
        {label}
      </Typography>
    </div>
  );
};

Tab.propTypes = {
  label: PropTypes.string,
  id: PropTypes.number,
  status: PropTypes.bool,
  styleProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    unselectedTabClass: PropTypes.any,
    selectedTabClass: PropTypes.any,
  }),
  getMultipleSelectedChoices: PropTypes.func,
  getSingleSelectedChoice: PropTypes.func,
  isMultiple: PropTypes.bool,
  singleSelectedChoiceId: PropTypes.number,
};

export default Tab;
