import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    information_block: {
      borderBottom: `1px solid ${theme.palette.grayVariation.grayForBorders}`,
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    map_block: {
      borderBottom: `1px solid ${theme.palette.grayVariation.grayForBorders}`,
      "& h3": {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(2),
      },
      "& span": {
        paddingLeft: theme.spacing(3),
      },
    },
    title: {
      fontWeight: "600 !important",
      marginBottom: theme.spacing(3),
    },
    html_or_list_content: {
      fontFamily: "Montserrat",
      fontSize: "13px",
      color: theme.palette.dark.black,
      fontWeight: 500,
    },
    google_map: {
      width: "100%",
      height: "40vh",
      marginTop: theme.spacing(2),
    },
    google_map_element: {
      height: "100%",
    },
    contact_info_content_label: {
      display: "block",
      width: "100%",
    },
    contact_info_content: {
      display: "block",
      width: "100%",
      paddingBottom: theme.spacing(2),
    },
    contact_buttons_container: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      [theme.breakpoints.down(760)]: {
        flexWrap: "wrap",
        marginTop: theme.spacing(2),
      },
    },
    overview_block_text_contracted: {
      maxHeight: "13vh",
      overflow: "hidden",
    },
    overview_block_text_expanded: {
      maxHeight: "initial",
      [theme.breakpoints.down(760)]: {
        maxHeight: "initial",
      },
    },
    button: {
      borderRadius: "0px !important",
      padding: `${theme.spacing(1)}px 0px !important`,
      marginTop: `${theme.spacing(2)} !important`,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        color: theme.palette.themeColor.primary,
        fontWeight: 700,
      },
    },
    contact_info_link: {
      overflowWrap: "anywhere",
      paddingBottom: theme.spacing(2),
      display: "block",
    },
  };
});

export { useStyles };
