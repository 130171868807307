import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Button from "../../../../common/Button/Button";
import { useStyles } from "./Form.style";
import PasswordField from "../../../../common/TextField/PasswordField/PasswordField";
import Typography from "../../../../common/Typography/Typography";
import labels from "../../../../config/labels";

const ResetPasswordForm = ({ setPasswordData, handleError, handleSubmit }) => {
  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
      <Typography level="h1" className={classes.header}>
        {labels.RESET_YOUR_PASSWORD}
      </Typography>

      <div>
        <PasswordField
          label={labels.NEW_PASSWORD}
          required
          className={classes.password_field}
          handlePasswordChange={(e) =>
            setPasswordData({ password: e.target.value })
          }
          error={handleError("password")?.markError}
        />
        {handleError("password")?.markError && (
          <Typography level="t1_text_1" color="red">
            {handleError("password")?.errorMessage}
          </Typography>
        )}
      </div>
      <div>
        <PasswordField
          label={labels.PASSWORD_CONFIRMATION}
          required
          className={classes.password_field}
          handlePasswordChange={(e) =>
            setPasswordData({ password_confirmation: e.target.value })
          }
          error={handleError("password-confirmation")?.markError}
        />
        {handleError("password-confirmation")?.markError && (
          <Typography level="t1_text_1" color="red">
            {handleError("password-confirmation")?.errorMessage}
          </Typography>
        )}
      </div>

      <div className={classes.button_container}>
        <Button
          type="submit"
          level="simple_button"
          color="primary"
          children={labels.RESET_PASSWORD}
          classes={{
            root: clsx(classes.button, classes.button_label),
          }}
          ariaLabel={labels.RESET_PASSWORD}
        />
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  setPasswordData: PropTypes.func,
  handleError: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default ResetPasswordForm;
