import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Select as MaterialSelect } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { useStyles } from "./Select.style";

const Select = ({
  selectItems,
  onChange,
  value,
  dropdownItemClasses,
  selectClasses,
  variant,
  disabled,
  menuItemsWithDivider,
  menuProps,
  className,
}) => {
  const classes = useStyles();

  return (
    <MaterialSelect
      classes={{
        select: clsx(
          classes.select,
          selectClasses?.root && selectClasses?.root
        ),
        icon: selectClasses?.icon,
        filled: clsx(
          classes.filled_variant,
          selectClasses?.filled_variant && selectClasses?.filled_variant
        ),
      }}
      value={value}
      onChange={onChange}
      variant={variant}
      disabled={disabled}
      MenuProps={menuProps}
      className={className}
    >
      {selectItems.map((item, index) => {
        return (
          <MenuItem
            classes={{
              root: dropdownItemClasses?.root,
              divider: dropdownItemClasses?.divider,
              gutters: dropdownItemClasses?.gutter,
            }}
            key={index}
            value={item}
            divider={menuItemsWithDivider}
          >
            {item}
          </MenuItem>
        );
      })}
    </MaterialSelect>
  );
};

Select.propTypes = {
  selectItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  menuItemsWithDivider: PropTypes.bool,
  dropdownItemClasses: PropTypes.shape({
    root: PropTypes.string,
    divider: PropTypes.string,
    gutters: PropTypes.string,
  }),
  selectClasses: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    nativeInput: PropTypes.string,
  }),
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  menuProps: PropTypes.shape({
    anchorOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(["top", "bottom"]),
      horizontal: PropTypes.oneOf(["left", "right"]),
    }),
    transformOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(["top", "bottom"]),
      horizontal: PropTypes.oneOf(["left", "right"]),
    }),
    getContentAnchorEl: PropTypes.func,
  }),
  className: PropTypes.string,
};

Select.defaultProps = {
  variant: "filled",
  menuProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  },
};

export default Select;
