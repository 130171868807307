import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import labels from "../../../../../../config/labels";
import { useStyles } from "./ServicePlace.style";
import {
  BLACK,
  ORANGE_HEX,
  REMOVABLE_SERVICES_ID,
} from "../../../../diyConstants";
import DatePicker from "../../../../../../common/DatePicker/DatePicker";
import TimeRangePicker from "../../../../../../common/TimeRangePicker/TimeRangePicker";
import FuneralHomeSelector from "../FuneralHomeSelector/FuneralHomeSelector";
import CloseCircle from "../../../../../../common/Icons/CloseCircle";
import FuneralHomeInfo from "../FuneralHomeInfo/FuneralHomeInfo";
import { isValid } from "date-fns";
import useSubmitableContentForm from "../../../../useSubmitableContentForm";
import cache from "../../../../../../utils/cache";

const midnight = new Date("2022-01-01 00:00");

const ServicePlace = ({
  funeralHomeNames,
  funeralHomeCounter,
  onChange,
  onRemoval,
  isLastOne,
  allowToSelectFuneralHome,
  content,
}) => {
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();
  const [date, setDate] = useState(content?.date ? content?.date : null);
  const [startTime, setStartTime] = useState(
    content?.time_from ? new Date(content?.time_from) : midnight
  );
  const [endTime, setEndTime] = useState(
    content?.time_to ? new Date(content?.time_to) : midnight
  );
  const [funeralInfo, setFuneralInfo] = useState();

  const handleFuneralHomeChange = (funeralHomeInfo) => {
    setFuneralInfo(funeralHomeInfo);
  };

  useEffect(() => {
    if (funeralInfo) {
      onChange({
        ...funeralInfo,
        id: content?.id,
        date,
        time_from: startTime?.toString()?.toLocaleLowerCase(),
        time_to: endTime?.toString()?.toLocaleLowerCase(),
      });
    }
  }, [date, startTime, endTime, funeralInfo]);

  const removeServiceInfo = () => {
    onRemoval(funeralHomeCounter);
    if (content?.id) {
      const removableIds = cache.get(REMOVABLE_SERVICES_ID);
      if (removableIds && removableIds.length > 0) {
        setProperty(REMOVABLE_SERVICES_ID, [
          ...cache.get(REMOVABLE_SERVICES_ID),
          content?.id,
        ]);
      } else {
        setProperty(REMOVABLE_SERVICES_ID, [content?.id]);
      }
    }
  };

  return (
    <>
      <div className={classes.date_time_range_pickers_container}>
        {isLastOne && (
          <CloseCircle
            onClick={removeServiceInfo}
            fillColor={ORANGE_HEX}
            className={classes.remove_date_time_field_icon}
          />
        )}
        <div className={classes.date_container}>
          <DatePicker
            className={classes.datepicker}
            label={labels.DATE}
            labelStyleProps={{
              level: "h4",
              color: BLACK,
              bold: true,
              className: classes.picker_labels,
            }}
            onChange={(value) => setDate(value)}
            disablePast={false}
            defaultValue={date}
          />
        </div>
        <div className={classes.time_range_picker_container}>
          <TimeRangePicker
            label={labels.TIME}
            labelStyleProps={{
              level: "t5_text_5",
              color: BLACK,
              bold: true,
              className: classes.picker_labels,
            }}
            sublabel={labels.TIME_15_MINUTES_STEP}
            sublabelStyleProps={{
              level: "t2_text_2",
              color: BLACK,
              bold: true,
              className: classes.picker_labels,
            }}
            getStartTime={(value) => setStartTime(value)}
            getEndTime={(value) => setEndTime(value)}
            timePickerClassName={classes.obituary_time_picker}
            defaultStartTime={startTime}
            defaultEndTime={endTime}
            minutesStep={15}
          />
        </div>
      </div>
      {allowToSelectFuneralHome && (
        <FuneralHomeSelector
          funeralHomeNames={funeralHomeNames}
          funeralHomeCounter={funeralHomeCounter}
          onChange={handleFuneralHomeChange}
          content={content}
        />
      )}
      {!allowToSelectFuneralHome && (
        <FuneralHomeInfo
          funeralHomeNames={funeralHomeNames}
          funeralHomeCounter={funeralHomeCounter}
          onChange={handleFuneralHomeChange}
          content={content}
        />
      )}
    </>
  );
};

const contentServiceType = () => {
  return PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    at_funeral_home: PropTypes.bool,
    date: PropTypes.string,
    name: PropTypes.string,
    time_from: PropTypes.string,
    time_to: PropTypes.string,
  });
};

ServicePlace.propTypes = {
  funeralHomeNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  funeralHomeCounter: PropTypes.number,
  onChange: PropTypes.func,
  onRemoval: PropTypes.func,
  isLastOne: PropTypes.bool,
  allowToSelectFuneralHome: PropTypes.bool,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(contentServiceType()),
    contentServiceType(),
  ]),
};

export default ServicePlace;
