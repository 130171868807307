import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import labels from "../../../config/labels";
import { useStyles } from "./RequestObituaryRole.style";
import Typography from "../../../common/Typography/Typography";
import TextField from "../../../common/TextField/TextField";
import Button from "../../../common/Button/Button";
import { isValidString } from "../../../utils/helper";
import restClient from "../../../utils/restClient";
import withConfig from "../../../utils/withConfig";

const ObituaryContributionRequestForm = ({ userId, handleSave }) => {
  const classes = useStyles();
  const [funeralHomeName, setFuneralHomeName] = useState("");
  const [funeralHomeAddress, setFuneralHomeAddress] = useState("");
  const [funeralHomePhone, setFuneralHomePhone] = useState("");
  const [funeralHomeNameError, setFuneralHomeNameError] = useState(false);
  const [funeralHomeAddressError, setFuneralHomeAddressError] = useState(false);
  const [funeralHomePhoneError, setFuneralHomePhoneError] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();

    let shouldFail = false;

    if (!isValidString(funeralHomeName)) {
      setFuneralHomeNameError(true);
      shouldFail = true;
    }
    if (!isValidString(funeralHomeAddress)) {
      setFuneralHomeAddressError(true);
      shouldFail = true;
    }
    if (!isValidString(funeralHomePhone)) {
      setFuneralHomePhoneError(true);
      shouldFail = true;
    }
    if (shouldFail) {
      return false;
    }

    const submitFormRequest = await restClient.post(
      withConfig("SUBMIT_OBITUARY_ROLE_REQUEST_URL"),
      {
        userId,
        funeralHomeName,
        funeralHomeAddress,
        funeralHomePhone,
        format: "json",
      }
    );

    if (submitFormRequest.status === 200) {
      handleSave();
    }
  };

  const handleFieldChange = (setter, errorHandler, value) => {
    setter(value);
    if (isValidString(value)) {
      errorHandler(false);
    }
  };

  return (
    <div className={classes.modal_content}>
      <div className={classes.row}>
        <Typography className={classes.provide_info} level={"body"} italic>
          {labels.PROVIDE_INFORMATION}
        </Typography>
      </div>
      <div className={classes.row}>
        <TextField
          labelLevel="paragraph_1"
          onChange={(e) =>
            handleFieldChange(
              setFuneralHomeName,
              setFuneralHomeNameError,
              e.target.value
            )
          }
          label={labels.FUNERAL_HOME_NAME}
          error={funeralHomeNameError}
          required
        />
      </div>
      <div className={classes.row}>
        <TextField
          labelLevel="paragraph_1"
          onChange={(e) =>
            handleFieldChange(
              setFuneralHomeAddress,
              setFuneralHomeAddressError,
              e.target.value
            )
          }
          label={labels.FUNERAL_HOME_ADDRESS}
          error={funeralHomeAddressError}
          required
        />
      </div>
      <div className={classes.row}>
        <TextField
          labelLevel="paragraph_1"
          defaultValue={funeralHomePhone}
          onChange={(e) =>
            handleFieldChange(
              setFuneralHomePhone,
              setFuneralHomePhoneError,
              e.target.value
            )
          }
          label={labels.FUNERAL_HOME_PHONE}
          error={funeralHomePhoneError}
          required
        />
      </div>

      <Button
        level="button"
        color="primary"
        onClick={submitForm}
        className={classes.button}
      >
        {labels.SUBMIT}
      </Button>
    </div>
  );
};

ObituaryContributionRequestForm.propTypes = {
  userId: PropTypes.number.isRequired,
  handleSave: PropTypes.func,
};

export default ObituaryContributionRequestForm;
