import { makeStyles } from "../../common/Theme/Theme";
import values from "../../common/Theme/themes/breakpoints/values";

const useStyles = makeStyles((theme) => {
  return {
    container_title: {
      padding: "1vh 0 1vh 0",
      [theme.breakpoints.down(1370)]: {
        paddingTop: "1vh",
        marginBottom: 0,
        fontSize: "30px !important",
        lineHeight: "36px",
      },
      [theme.breakpoints.down(420)]: {
        paddingTop: "16px",
        fontSize: "28px !important",
        lineHeight: "30px",
      },
    },
    container_title_mobile: {
      paddingTop: "16px",
      fontSize: "28px !important",
      lineHeight: "30px",
    },
    subheading_article: {
      padding: "1vh 0 1vh 0",
      [theme.breakpoints.down(1370)]: {
        paddingTop: 0,
        fontSize: "24px !important",
        lineHeight: "26px",
      },
      [theme.breakpoints.down(420)]: {
        fontSize: "22px !important",
        lineHeight: "24px",
      },
    },
    subheading_article_mobile: {
      fontSize: "22px !important",
    },
    body_container: {
      padding: 10,
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down(values.sm)]: {
        flexDirection: "column-reverse",
      },
    },

    border_top_bottom: {
      borderTop: `3px solid ${theme.palette.grayVariation.grayForBorder}`,
      borderBottom: `3px solid ${theme.palette.grayVariation.grayForBorder}`,
      paddingTop: 5,
    },
    heading_h2: {
      padding: "8px 0 8px 0",
    },
    heading_category: {
      textAlign: "right",
    },
    sponsored_content: {
      padding: theme.spacing(3),
      paddingRight: 0,
      display: "block",
      textAlign: "right",
      opacity: "0.7",
      [theme.breakpoints.down(values.md)]: {
        paddingBottom: theme.spacing(2),
      },
    },
    title_and_heading_container: {
      display: "block",
    },
    splitted: {
      width: "85%",
      [theme.breakpoints.down(values.sm)]: {
        width: "100%",
      },
    },
    edit_container: {
      marginTop: `${theme.spacing(2)}px !important`,
      marginBottom: `${theme.spacing(2)}px !important`,
    },
    sponsor: {
      [theme.breakpoints.up(values.sm)]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    sponsor_container: {
      display: "flex",
      justifyContent: "flex-end",

      "& [class^='sponsor_container']": {
        width: "fit-content !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        columnGap: theme.spacing(2),
      },
    },
  };
});

export { useStyles };
