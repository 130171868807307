import React, { useEffect, useState } from "react";
import { useStyles } from "./FacebookShoppingListRow.style";
import PropTypes from "prop-types";
import Typography from "../../../../common/Typography/Typography";
import labels from "../../../../config/labels";
import { FACEBOOK_BLUE, LETTERS_TO_THE_EDITOR } from "../../diyConstants";
import { useDiy, withDiyContextProvider } from "../../../../context/DiyContext";
import Checkbox from "../../../../common/Checkbox/Checkbox";

const FacebookShoppingListRow = ({
  town,
  formType,
  isFacebook,
  itemQuantity,
}) => {
  const classes = useStyles();
  const { updateFacebookTown } = useDiy();
  const [isSelected, setIsSelected] = useState(town?.fb_selected);

  const getTownPriceAccordingToFormType = (item) => {
    if (!isSelected) {
      return 0;
    }
    if (formType !== LETTERS_TO_THE_EDITOR) {
      return isFacebook ? item.fb_price : item.price;
    } else {
      return 0;
    }
  };

  const getTownPriceAccordingToFormTypeWithQuantity = (item, quantity) => {
    if (!isSelected) {
      return 0;
    }
    if (formType !== LETTERS_TO_THE_EDITOR) {
      return isFacebook ? item.fb_price * quantity : item.price * quantity;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    updateFacebookTown(town, isSelected);
  }, [isSelected]);

  return (
    <div className={classes.white_row}>
      <div className={classes.town_name_container}>
        <Typography level="t1_text_1" color={FACEBOOK_BLUE} bold>
          {town.label}
          {itemQuantity ? ` (x ${itemQuantity})` : ""}
        </Typography>
      </div>
      <div className={classes.state_name_container}>
        <Typography level="t1_text_1" color={FACEBOOK_BLUE}>
          {town.state[1]}
        </Typography>
      </div>
      <div className={classes.town_price_container}>
        <Typography level="t1_text_1" color={FACEBOOK_BLUE}>
          {labels.DOLLAR_SIGN}
          {itemQuantity
            ? getTownPriceAccordingToFormTypeWithQuantity(town, itemQuantity)
            : getTownPriceAccordingToFormType(town)}
        </Typography>
      </div>
      <Checkbox
        className={classes.checkbox}
        onChange={() => setIsSelected(!isSelected)}
        checkboxColor={"#475993"}
        checked={isSelected}
        dataAttribute={"town-facebook"}
      />
    </div>
  );
};

FacebookShoppingListRow.propTypes = {
  town: PropTypes.shape({
    label: PropTypes.string,
    price: PropTypes.number,
    state: PropTypes.arrayOf(PropTypes.string),
  }),
  formType: PropTypes.string,
  isFacebook: PropTypes.bool,
  itemQuantity: PropTypes.number,
};

export default withDiyContextProvider(FacebookShoppingListRow);
