import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PropTypes from "prop-types";
import RealEstateShowLayout from "../../../components/RealEstateListing/ShowLayout/ShowLayout";
import { useStyles } from "./Show.style";
import withConfig from "../../../utils/withConfig";
import { replaceIdInUrl } from "../../../utils/helper";
import { useTown, withTownContextProvider } from "../../../context/TownContext";

const RealEstateShowPage = ({
  real_estate_listing,
  edit_url,
  show_content_options,
  sponsor_info,
  is_content_preview,
  can_edit,
  town,
  can_delete,
}) => {
  const BACK_URL = town
    ? replaceIdInUrl(withConfig("REAL_ESTATES_TOWN_INDEX"), town.slug)
    : withConfig("REAL_ESTATES_INDEX");
  const classes = useStyles();
  const { setTownSlug } = useTown();

  useEffect(() => {
    if (town?.slug) {
      setTownSlug(town.slug);
    }
  }, [town]);

  return (
    <>
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={withConfig("RECAPTCHA_KEY")}
        scriptProps={{ async: true, defer: true, appendTo: "body" }}
      >
        <div className={classes.main_container}>
          {real_estate_listing && (
            <RealEstateShowLayout
              realEstateListing={real_estate_listing}
              showContentOptions={show_content_options}
              sponsorInfo={sponsor_info}
              editUrl={edit_url}
              canEdit={can_edit}
              canDelete={can_delete}
              contentTitle={real_estate_listing.name}
              isContentPreview={is_content_preview}
              backToRealEstateListingsUrl={BACK_URL}
            />
          )}
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
};

RealEstateShowLayout.propTypes = {
  realEstateListing: PropTypes.shape({
    price: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    address: PropTypes.string,
    url: PropTypes.string,
    slug: PropTypes.string,
    realtor_email: PropTypes.string,
    status: PropTypes.string,
    information_blocks: PropTypes.arrayOf(
      PropTypes.shape({
        section_id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            })
          ),
        ]),
      })
    ),
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        content_url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        is_video: PropTypes.bool,
        alt_text: PropTypes.string,
        captions: PropTypes.string,
        credits: PropTypes.string,
      })
    ),
  }),
  edit_url: PropTypes.string,
  show_content_options: PropTypes.bool,
  sponsor_info: PropTypes.shape({
    page_type: PropTypes.string,
    sponsor_attr: PropTypes.string,
    top_local_news: PropTypes.bool,
    town: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    columns_slug: PropTypes.arrayOf(PropTypes.string),
    sections_slug: PropTypes.arrayOf(PropTypes.string),
  }),
  is_content_preview: PropTypes.bool,
  can_edit: PropTypes.bool,
  can_delete: PropTypes.bool,
};

export default withTownContextProvider(RealEstateShowPage);
