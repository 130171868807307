import React from "react";
import PropTypes from "prop-types";

const ButtonShoppingCart = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.497455 2.33138e-07C0.222711 2.33138e-07 0 0.222719 0 0.497456C0 0.772192 0.222711 0.994911 0.497455 0.994911H2.41473L4.12473 9.06299C4.19239 9.36671 4.39144 9.61948 4.64292 9.61746H12.9338C13.1966 9.62111 13.4384 9.38284 13.4384 9.12C13.4384 8.85717 13.1966 8.61884 12.9338 8.62255H5.0471L4.83463 7.62764H13.5971C13.8198 7.62598 14.0303 7.45633 14.079 7.23901L15.2397 2.26446C15.3044 1.97576 15.0537 1.66031 14.7578 1.65818H3.57546L3.306 0.393819C3.25893 0.172932 3.04476 -0.000231913 2.81891 2.33138e-07H0.497455ZM3.78273 2.6531H6.37364L6.62237 4.14546H4.09883L3.78273 2.6531ZM7.38928 2.6531H10.5191L10.2704 4.14546H7.63801L7.38928 2.6531ZM11.5347 2.6531H14.1308L13.7837 4.14546H11.286L11.5347 2.6531ZM4.31128 5.14037H6.78819L7.03692 6.63273H4.62736L4.31128 5.14037ZM7.80383 5.14037H10.1046L9.85583 6.63273H8.05256L7.80383 5.14037ZM11.1202 5.14037H13.5505L13.2033 6.63273H10.8715L11.1202 5.14037ZM6.46692 10.2807C5.55702 10.2807 4.80873 11.029 4.80873 11.9389C4.80873 12.8488 5.55702 13.5971 6.46692 13.5971C7.37681 13.5971 8.1251 12.8488 8.1251 11.9389C8.1251 11.029 7.37681 10.2807 6.46692 10.2807ZM11.1098 10.2807C10.1999 10.2807 9.45165 11.029 9.45165 11.9389C9.45165 12.8488 10.1999 13.5971 11.1098 13.5971C12.0197 13.5971 12.768 12.8488 12.768 11.9389C12.768 11.029 12.0197 10.2807 11.1098 10.2807ZM6.46692 11.2756C6.83913 11.2756 7.13019 11.5667 7.13019 11.9389C7.13019 12.3111 6.83913 12.6022 6.46692 12.6022C6.09474 12.6022 5.80364 12.3111 5.80364 11.9389C5.80364 11.5667 6.09474 11.2756 6.46692 11.2756ZM11.1098 11.2756C11.482 11.2756 11.7731 11.5667 11.7731 11.9389C11.7731 12.3111 11.482 12.6022 11.1098 12.6022C10.7377 12.6022 10.4466 12.3111 10.4466 11.9389C10.4466 11.5667 10.7377 11.2756 11.1098 11.2756Z"
        fill={fillColor}
      />
      <path
        d="M0.497455 2.33138e-07C0.222711 2.33138e-07 0 0.222719 0 0.497456C0 0.772192 0.222711 0.994911 0.497455 0.994911H2.41473L4.12473 9.06299C4.19239 9.36671 4.39144 9.61948 4.64292 9.61746H12.9338C13.1966 9.62111 13.4384 9.38284 13.4384 9.12C13.4384 8.85717 13.1966 8.61884 12.9338 8.62255H5.0471L4.83463 7.62764H13.5971C13.8198 7.62598 14.0303 7.45633 14.079 7.23901L15.2397 2.26446C15.3044 1.97576 15.0537 1.66031 14.7578 1.65818H3.57546L3.306 0.393819C3.25893 0.172932 3.04476 -0.000231913 2.81891 2.33138e-07H0.497455ZM3.78273 2.6531H6.37364L6.62237 4.14546H4.09883L3.78273 2.6531ZM7.38928 2.6531H10.5191L10.2704 4.14546H7.63801L7.38928 2.6531ZM11.5347 2.6531H14.1308L13.7837 4.14546H11.286L11.5347 2.6531ZM4.31128 5.14037H6.78819L7.03692 6.63273H4.62736L4.31128 5.14037ZM7.80383 5.14037H10.1046L9.85583 6.63273H8.05256L7.80383 5.14037ZM11.1202 5.14037H13.5505L13.2033 6.63273H10.8715L11.1202 5.14037ZM6.46692 10.2807C5.55702 10.2807 4.80873 11.029 4.80873 11.9389C4.80873 12.8488 5.55702 13.5971 6.46692 13.5971C7.37681 13.5971 8.1251 12.8488 8.1251 11.9389C8.1251 11.029 7.37681 10.2807 6.46692 10.2807ZM11.1098 10.2807C10.1999 10.2807 9.45165 11.029 9.45165 11.9389C9.45165 12.8488 10.1999 13.5971 11.1098 13.5971C12.0197 13.5971 12.768 12.8488 12.768 11.9389C12.768 11.029 12.0197 10.2807 11.1098 10.2807ZM6.46692 11.2756C6.83913 11.2756 7.13019 11.5667 7.13019 11.9389C7.13019 12.3111 6.83913 12.6022 6.46692 12.6022C6.09474 12.6022 5.80364 12.3111 5.80364 11.9389C5.80364 11.5667 6.09474 11.2756 6.46692 11.2756ZM11.1098 11.2756C11.482 11.2756 11.7731 11.5667 11.7731 11.9389C11.7731 12.3111 11.482 12.6022 11.1098 12.6022C10.7377 12.6022 10.4466 12.3111 10.4466 11.9389C10.4466 11.5667 10.7377 11.2756 11.1098 11.2756Z"
        fill="url(#paint0_linear_3860_2192)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3860_2192"
          x1="7.625"
          y1="0"
          x2="7.625"
          y2="13.5971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillColor} />
          <stop offset="1" stopColor={fillColor} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

ButtonShoppingCart.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

ButtonShoppingCart.defaultProps = {
  fillColor: "white",
};

export default ButtonShoppingCart;
