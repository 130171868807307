import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    form: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    text_field: {
      width: "100%",
      marginBottom: `${theme.spacing(3)}px !important`,
      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
        borderColor: "transparent !important",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.dark.gray,
        backgroundColor: theme.palette.miscellaneous.white,
      },
      "& .MuiOutlinedInput-multiline": {
        padding: "0px !important",
        "& textarea": {
          padding: theme.spacing(2),
        },
      },
    },
    button: {
      borderRadius: "0px !important",
      marginTop: `${theme.spacing(3)}px !important`,
      marginBottom: `${theme.spacing(2)}px !important`,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px !important`,
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        color: theme.palette.dark.black,
      },
    },
    recaptcha_clause_text: {
      fontSize: "10px",
      fontWeight: 400,
      color: theme.palette.dark.gray,
      textAlign: "center",
      "& a": {
        color: theme.palette.miscellaneous.blueLink,
        fontSize: "10px",
        fontWeight: 400,
        textDecoration: "underline",
      },
    },
  };
});

export { useStyles };
