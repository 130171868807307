import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import { useStyles } from "./NonProfitCheckbox.style";
import Checkbox from "../../../common/Checkbox/Checkbox";
import Typography from "../../../common/Typography/Typography";

const NonProfitCheckbox = ({
  isNonProfitOptionSelected,
  handleChange,
  isChecked,
}) => {
  const classes = useStyles();
  return (
    isNonProfitOptionSelected && (
      <div className={classes.non_profit_checkbox_container}>
        <Checkbox
          label={
            <Typography level="t4_text_4">
              {labels.NON_PROFIT_CHECKBOX_LABEL}
            </Typography>
          }
          onChange={handleChange}
          checkboxColor={"#7FC035"}
          checked={isChecked}
        />
      </div>
    )
  );
};

NonProfitCheckbox.propTypes = {
  isNonProfitOptionSelected: PropTypes.bool,
  handleChange: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default NonProfitCheckbox;
