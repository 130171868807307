import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    time_picker: {
      "& div.MuiInputBase-root": {
        height: theme.spacing(4),
        "& input.MuiInputBase-input": {
          border: "none",
          boxShadow: "none",
          height: theme.spacing(4),
          padding: `0px ${theme.spacing(0.75)}px 0px ${theme.spacing(0.75)}px`,
        },
        "&.Mui-focused": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.themeColor.primary,
          },
          "& input.MuiInputBase-input": {
            border: "none",
            boxShadow: "none",
            height: theme.spacing(4),
            padding: theme.spacing(0.75),
          },
        },
        "&.MuiOutlinedInput-adornedEnd": {
          paddingRight: 0,
        },
        "& div.MuiInputAdornment-root": {
          "& button.MuiButtonBase-root": {
            padding: 0,
          },
        },
      },
    },
    time_picker_end_icon: {
      transform: "scale(0.5)",
    },
    time_picker_popover: {
      "& div.MuiPopover-paper": {
        "& div.MuiPickersBasePicker-container": {
          "& div.MuiPickersToolbar-toolbar": {
            backgroundColor: theme.palette.themeColor.primary,
          },
          "& div.MuiPickersBasePicker-pickerView": {
            "& div.MuiPickersClock-container": {
              "& div.MuiPickersClock-clock": {
                "& div.MuiPickersClock-pin": {
                  backgroundColor: theme.palette.themeColor.primary,
                },
                "& div.MuiPickersClockPointer-pointer": {
                  backgroundColor: theme.palette.themeColor.primary,
                  "& div.MuiPickersClockPointer-thumb": {
                    backgroundColor: theme.palette.themeColor.primary,
                    borderColor: theme.palette.themeColor.primary,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
});

export { useStyles };
