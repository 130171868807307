import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Card from "../Card/Card";
import { useStyles } from "./CardsList.style";

const CardsList = ({
  items,
  raisedCard,
  containerClass,
  cardClass,
  numberOfCardsPerRow,
}) => {
  const classes = useStyles();

  const getCardClass = () => {
    let classforCard = [cardClass ? cardClass : ""];
    switch (numberOfCardsPerRow) {
      case 4:
        classforCard.push(classes.four_cards_per_row);
        break;
      case 3:
        classforCard.push(classes.three_cards_per_row);
        break;
      case 2:
        classforCard.push(classes.two_cards_per_row);
        break;
      case 1:
        classforCard.push(classes.one_card_per_row);
        break;
      default:
        break;
    }
    return classforCard;
  };

  return (
    <div className={clsx(classes.container, containerClass && containerClass)}>
      {items.map((item, index) => (
        <Fragment key={`card-${index}`}>
          <Card
            children={item}
            raised={raisedCard}
            classes={{ root: clsx(getCardClass()) }}
          />
        </Fragment>
      ))}
    </div>
  );
};

CardsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  raisedCard: PropTypes.bool,
  containerClass: PropTypes.string,
  cardClass: PropTypes.string,
  numberOfCardsPerRow: PropTypes.number,
};

CardsList.defaultProps = {
  raisedCard: true,
  numberOfCardsPerRow: 4,
};

export default CardsList;
