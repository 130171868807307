import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    header_container: {
      height: "auto",
      borderBottom: `4px ${theme.palette.dark.gray} solid`,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
      "& div.MuiInputAdornment-positionEnd": {
        marginLeft: "0px !important",
      },
      "& input.MuiInputBase-input": {
        paddingRight: "0px !important",
      },
      [theme.breakpoints.down(960)]: {
        paddingBottom: theme.spacing(3),
      },
    },
    header_title_container: {
      width: "100%",
    },
    left_container: {
      width: "45%",
      "& h5": {
        marginBottom: `0px !important`,
      },
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    right_container: {
      width: "45%",
      paddingTop: theme.spacing(0.25),
      [theme.breakpoints.down(960)]: {
        width: "100%",
        paddingTop: theme.spacing(1.75),
      },
    },
    dropdown_label: {
      marginBottom: `${theme.spacing(2.5)}px !important`,
    },
  };
});

export { useStyles };
