import { makeStyles } from "../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    buttons_container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
      marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    options_button: {
      width: "48%",
      padding: `${theme.spacing(0.5)}px 0px !important`,
      borderRadius: "0px !important",
      "& .MuiButton-label": {
        fontSize: "13px",
        fontWeight: 600,
        textTransform: "none",
      },
    },
    save_draft_button: {
      border: `1px solid ${theme.palette.themeColor.primary} !important`,
      "& .MuiButton-label": {
        color: theme.palette.themeColor.primary,
      },
    },
    preview_button: {
      backgroundColor: `${theme.palette.themeColor.secondary} !important`,
      "& .MuiButton-label": {
        color: theme.palette.dark.black,
        fontWeight: 400,
      },
    },
    checkout_button: {
      marginTop: `${theme.spacing(3)}px !important`,
      width: "100%",
      borderRadius: "0px !important",
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      "& .MuiButton-label": {
        fontSize: "13px",
        fontWeight: 700,
        textTransform: "none",
        color: theme.palette.light.white,
      },
    },
    shopping_cart_icon: {
      marginRight: theme.spacing(1),
      transition: "background-color .2s linear",
    },
    full_width: {
      width: "100%",
    },
  };
});

export { useStyles };
