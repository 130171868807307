import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

export const sendRealEstatesForm = async (
  formPayload,
  slug,
  toRealtor,
  token
) => {
  try {
    const response = await restClient.get_serializer(
      withConfig("SEND_REAL_ESTATES_CONTACT_EMAIL"),
      {
        user_message: formPayload,
        slug: slug,
        to_realtor: toRealtor,
        token: token,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
