import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    custom_badge: {
      backgroundColor: theme.palette.themeColor.primary,
      color: theme.palette.miscellaneous.white,
      fontWeight: "700 !important",
    },
  };
});

export { useStyles };
