import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    row: { display: "flex" },
    facebook_sites_container: { marginTop: theme.spacing(2) },
    facebook_yes_container: {
      display: "flex",
      justifyContent: "space-between",
    },
    yes: {
      paddingRight: theme.spacing(2),
    },
    left_container: {
      width: "80%",
      [theme.breakpoints.down(961)]: {
        display: "block",
        width: "100%",
      },
    },
    right_container: {
      display: "block",
      width: "20%",
      textAlign: "center",
    },
    per_page: {
      display: "block",
    },
    fifty_dollars: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    facebook_inputs_container: {
      backgroundColor: theme.palette.facebook.lightBlue,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      [theme.breakpoints.down(961)]: {
        display: "block",
        width: "100%",
        "& h4": {
          fontSize: "17px",
        },
      },
    },
    facebook_autocomplete_container: {
      width: "90% !important",
      marginTop: theme.spacing(1),
      "& input:focus": {
        boxShadow: `0 0 ${theme.spacing(1)}px ${
          theme.palette.facebook.lightBlue
        }`,
      },
      [theme.breakpoints.down(961)]: {
        width: "100% !important",
      },
    },
    facebook_sites: {
      flex: 1,
      padding: theme.spacing(1),
    },

    facebook_excerpt: {
      flex: 1,
      padding: theme.spacing(1),
      [theme.breakpoints.down(961)]: {
        display: "block",
        width: "100%",
      },
    },
    towns_container: {
      border: "2px solid #D8D8D8",
      borderRadius: 2,
    },
    facebook_chip: {
      backgroundColor: `${theme.palette.facebook.facebookBlue} !important`,
      color: `${theme.palette.miscellaneous.white} !important`,
      margin: theme.spacing(1),
      maxWidth: 250,
    },
    remove_icon_container: {
      height: "100%",
      padding: theme.spacing(1),
      "& :hover": {
        color: "red",
        cursor: "pointer",
      },
    },
    remove_town: {
      width: `${theme.spacing(2)}px !important`,
      height: "100%",
    },
    excerpt: {
      padding: theme.spacing(1),
      width: "100%",
    },
    excerpt_header: {
      marginTop: theme.spacing(1),
    },
    facebook_url: {
      backgroundColor: "transparent !important",
      color: "black !important",
      "& :focus": {
        backgroundColor: "transparent !important",
      },
    },
    mobile_fb_box: {
      display: "inline-flex",
      alignItems: "flex-start",
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
    },
    mobile_facebook_container: {
      marginBottom: theme.spacing(2),
      textAlign: "center",
    },
  };
});

export { useStyles };
