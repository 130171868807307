import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import Button from "../../../common/Button/Button";
import { useStyles } from "./ContentOptionsDialogBody.style";

const ContentOptionsDialogBody = ({
  className,
  contentTypographyProps,
  buttonTypographyProps,
  buttonClassName,
  buttonText,
  contentText,
  onButtonClick,
}) => {
  const classes = useStyles();

  return (
    <div className={className ? className : classes.content}>
      <div>
        <Typography
          level={contentTypographyProps.level}
          bold={contentTypographyProps.bold}
          color={contentTypographyProps.color}
        >
          {contentText}
        </Typography>
      </div>

      <div className={buttonClassName ? buttonClassName : classes.button}>
        <Button
          level="simple_button"
          color="primary"
          bold
          onClick={onButtonClick}
        >
          <Typography
            color={buttonTypographyProps.color}
            level={buttonTypographyProps.level}
            bold={buttonTypographyProps.bold}
          >
            {buttonText}
          </Typography>
        </Button>
      </div>
    </div>
  );
};

ContentOptionsDialogBody.defaultProps = {
  contentTypographyProps: {
    level: "t2_text_2",
    bold: true,
    color: "black",
  },
  buttonTypographyProps: {
    level: "t1_text_1",
    bold: true,
    color: "tapintoGreen",
  },
  contentText: labels.EDIT_CONTENT_POP_UP_TEXT,
  buttonText: labels.CONTINUE,
};

ContentOptionsDialogBody.propTypes = {
  className: PropTypes.string,
  contentTypographyProps: PropTypes.shape({
    level: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
  }),
  buttonTypographyProps: PropTypes.shape({
    level: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
  }),
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string,
  contentText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default ContentOptionsDialogBody;
