import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import { useStyles } from "./CheckboxGroup.style";
import clsx from "clsx";
import TriangleBulletIcon from "../Icons/TriangleBulletIcon";
import Typography from "../Typography/Typography";

const CheckboxGroup = ({
  className,
  onChange,
  onChildChange,
  items,
  showAllItemsCollapsed,
  withSelectorOnMainLevel,
}) => {
  const classes = useStyles();
  const [parentChecked, setParentChecked] = useState([]);
  const [itemsWithSelectedOption, setItemsWithSelectedOption] = useState(items);
  const [expandableItems, setExpandableItems] = useState(
    getExpandableItems(items)
  );

  function getExpandableItems(items) {
    return items.map((item) => {
      return {
        id: item.id,
        collapsed: false,
      };
    });
  }

  const handleChangeParent = (isChecked, item) => {
    item.selected = isChecked;
    setParentChecked([...parentChecked, item]);
    const modifiedItems = itemsWithSelectedOption.map(
      (itemWithSelectedOption) => {
        if (itemWithSelectedOption.id === item.id) {
          for (let i = 0; i < itemWithSelectedOption.children.length; i++) {
            itemWithSelectedOption.children[i].selected = item.selected;
          }
        }
        return itemWithSelectedOption;
      }
    );
    setItemsWithSelectedOption(modifiedItems);
    onChange(item.id, isChecked);
  };

  const handleChildOnChange = (child, parent) => {
    const parentSelected = itemsWithSelectedOption.find(
      (item) => item.id === parent.id
    );
    const childSelected = parentSelected.children.find(
      (item) => item.id === child.id
    );
    childSelected.selected = !childSelected.selected;
    const unselected = parentSelected.children.find((item) => !item.selected);

    if (!unselected) {
      parentSelected.selected = true;
      setParentChecked([...parentChecked, parentSelected]);
    } else {
      parentSelected.selected = false;
      setParentChecked([...parentChecked, parentSelected]);
    }

    onChildChange(parent.id, child);
  };

  useEffect(() => {
    for (let i = 0; i < items.length; i++) {
      const selected = items[i].children.find(
        (child) => child.selected === true
      );
      if (selected) {
        onChange(items);
      }
    }
    setExpandableItems(getExpandableItems(items));
    setItemsWithSelectedOption(items);
  }, [items]);

  const shouldCollapse = (id) => {
    const itemFound = expandableItems.find((item) => item.id === id);
    if (itemFound && !showAllItemsCollapsed) {
      return itemFound.collapsed;
    } else {
      return !itemFound.collapsed;
    }
  };

  const toggleCollapseStatus = (id) => {
    const items = expandableItems.map((expandableItem) => {
      if (expandableItem.id === id) {
        return {
          id: expandableItem.id,
          collapsed: !expandableItem.collapsed,
        };
      }
      return {
        id: expandableItem.id,
        collapsed: expandableItem.collapsed,
      };
    });
    setExpandableItems(items);
  };

  const shouldBeIntermediate = (item) => {
    const selected = item.children.filter((child) => child.selected === true);

    if (selected.length > 0 && selected.length < item.children.length) {
      return true;
    }

    return false;
  };

  const withoutParentSelector = (item) => {
    return (
      <div
        onClick={() => toggleCollapseStatus(item.id)}
        className={classes.county_selector}
      >
        <div className={classes.triangle_icon_container}>
          <TriangleBulletIcon
            className={clsx(
              classes.triangle_icon_normal,
              shouldCollapse(item.id) && classes.triangle_icon_down
            )}
          />
        </div>
        <Typography level="t4_text_4" bold color="gray">
          {item.label.replace(" County", "")}
        </Typography>
      </div>
    );
  };

  const withParentSelector = (item) => {
    return (
      <>
        <div
          className={classes.triangle_icon_container}
          onClick={() => toggleCollapseStatus(item.id)}
        >
          <TriangleBulletIcon
            className={clsx(
              classes.triangle_icon_normal,
              shouldCollapse(item.id) && classes.triangle_icon_down
            )}
          />
        </div>
        <FormControlLabel
          label={item.label.replace(" County", "")}
          className={classes.parent_bold}
          control={
            <Checkbox
              checked={item.selected || false}
              indeterminate={shouldBeIntermediate(item)}
              onChange={(event) =>
                handleChangeParent(event.target.checked, item)
              }
            />
          }
        />
      </>
    );
  };

  return (
    <>
      {itemsWithSelectedOption.map((item, index) => (
        <Fragment key={`group-${index}`}>
          <div className={clsx(classes.options, className)}>
            {item.label &&
              !withSelectorOnMainLevel &&
              withoutParentSelector(item)}
            {item.label && withSelectorOnMainLevel && withParentSelector(item)}

            <div
              className={
                shouldCollapse(item.id) || !item.label
                  ? classes.unfolded_children
                  : classes.hidden
              }
            >
              {item.children.map((child) => (
                <div key={`child-${index}-${child.id}`}>
                  <Box
                    sx={{
                      display: "block",
                      flexDirection: "column",
                      ml: 3,
                    }}
                  >
                    <FormControlLabel
                      label={child.label}
                      control={
                        <Checkbox
                          checked={child.selected}
                          indeterminate={false}
                          id={`checkbox-${child.id}`}
                          onChange={() => handleChildOnChange(child, item)}
                        />
                      }
                    />
                  </Box>
                </div>
              ))}
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
};

CheckboxGroup.propTypes = {
  onChange: PropTypes.func,
  onChildChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      selected: PropTypes.bool,
    })
  ),
  className: PropTypes.any,
  showAllItemsCollapsed: PropTypes.bool,
  withSelectorOnMainLevel: PropTypes.bool,
};

CheckboxGroup.defaultTypesProps = {
  showAllItemsCollapsed: false,
};

export default CheckboxGroup;
