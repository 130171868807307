import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    contact_info: {
      height: "auto",
      paddingTop: theme.spacing(5),
      paddingRight: theme.spacing(2),
      overflowWrap: "anywhere",
      [theme.breakpoints.down(960)]: {
        paddingTop: 0,
        paddingRight: 0,
        justifyContent: "center",
      },
    },
    contact_info_header: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  };
});

export { useStyles };
