import { useState, useEffect } from "react";

const getCurrentScroll = () => {
  const { pageYOffset } = window;
  return pageYOffset;
};

const getCurrentScrollPosition = () => {
  const [position, setPosition] = useState(getCurrentScroll());

  useEffect(() => {
    const handleScroll = () => {
      setPosition(getCurrentScroll());
    };
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return position;
};

export { getCurrentScrollPosition };
