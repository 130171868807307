import { makeStyles } from "../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    single_icon_container: {
      [theme.breakpoints.down(961)]: {
        "& svg": {
          width: theme.spacing(8),
          height: theme.spacing(8),
        },
        "& span": {
          display: "block",
        },
      },
    },
    payments_container: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
  };
});

export { useStyles };
