import React from "react";
import useGames from "../../../hooks/useGames";
import labels from "../../../config/labels";
import GamesCard from "../../Games/GamesCard/GamesCard";

const GamesIndex = () => {
  const { sudokuGames, otherGames } = useGames({ isForIndex: true });

  return (
    <>
      {/*todo: most popular games section is not implemented yet.*/}
      {/* <GamesCard content={games} sectionTitle={labels.MOST_POPULAR_TITLE} /> */}
      <GamesCard content={sudokuGames} sectionTitle={labels.SUDOKU_TITLE} cardType={"horizontal"}/>
      <GamesCard
        content={otherGames}
        sectionTitle={labels.MORE_GAMES_TITLE}
        cardType={"vertical"}
      />
    </>
  );
};

export default GamesIndex;
