import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

export const getSingleRealEstate = async (realEstateSlug) => {
  try {
    const response = await restClient.get(
      withConfig("GET_REAL_ESTATE_SINGLE"),
      {
        slug: realEstateSlug,
        action: "show",
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getRealEstates = async (filtersParams, paginationParams) => {
  try {
    const response = await restClient.get_serializer(
      withConfig("GET_REAL_ESTATES"),
      {
        filters_params: filtersParams,
        pagination_params: paginationParams,
        action: "index",
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const realtorInfo = async (slug) => {
  try {
    const response = await restClient.get(withConfig("REALTOR_INFO"), {
      slug,
    });
    return response;
  } catch (e) {
    throw e;
  }
};
