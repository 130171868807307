import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      backgroundImage:
        "url('https://tapinto-production.s3.amazonaws.com/uploads/real_estates/images/real_estate_no_results.png')",
      height: theme.spacing(45),
      paddingLeft: "10%",
      paddingRight: "10%",
      textAlign: "center",
      display: "flex",
      [theme.breakpoints.down(760)]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    text_container: { margin: "auto" },
    title: { fontSize: theme.spacing(6), display: "block" },
    subtitle: { fontSize: theme.spacing(4) },
  };
});

export { useStyles };
