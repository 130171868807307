import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    body_container: {
      paddingBottom: theme.spacing(2),
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      overflowWrap: "anywhere",
      [theme.breakpoints.down(960)]: {
        paddingTop: theme.spacing(2),
      },
    },
    single_block: {
      height: theme.spacing(28),
    },
    categories_container: {
      width: "90%",
      lineHeight: "12px",
    },
    categories: {
      "&.MuiTypography-colorPrimary": {
        color: `${theme.palette.grayVariation.grayForEventCategories} !important`,
      },
      "& span": {
        color: `${theme.palette.grayVariation.grayForEventCategories} !important`,
      },
      "&:hover": {
        textDecoration: "underline !important",
        color: `${theme.palette.grayVariation.grayForEventCategories} !important`,
      },
    },
    button: {
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      textTransform: "none !important",
      width: "45%",
      borderRadius: "0px !important",
      [theme.breakpoints.down(960)]: {
        width: "50%",
      },
    },
    classified_link: {
      padding: 0,
      maxHeight: "unset",
      overflow: "unset",
      display: "unset",
      "-webkit-line-clamp": "unset",
      "-webkit-box-orient": "unset",
    },
    published_at: {
      width: "100%",
    },
    bottom_section: {
      paddingTop: theme.spacing(1),
      display: "flex",
    },
    social_networks_sharing: {
      width: "50%",
    },
  };
});

export { useStyles };
