import { useEffect } from "react";

const useScript = (url = null, scriptContent = null) => {
  useEffect(() => {
    const script = document.createElement("script");

    if (url) {
      script.src = url;
      script.async = true;
      script.defer = true;
    }

    script.type = "text/javascript";

    if (scriptContent) {
      script.innerHTML = scriptContent;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
