import { makeStyles } from "../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    exemption_link: {
      marginTop: theme.spacing(3),
      cursor: "pointer",
      paddingTop: theme.spacing(2),
      width: "40%",
      [theme.breakpoints.down(900)]: {
        width: "100%",
      },
    },
    tooltip_text: {
      fontSize: "12px !important",
    },
    hidden: {
      display: "none",
    },
  };
});

export { useStyles };
