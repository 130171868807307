import labels from "../config/labels";
import {
  contactRealtor,
  sendRealEstatesForm,
  sendToAFriend,
} from "../services/contactForms";
import { isValidEmail, isValidPhoneNumber } from "./helper";

export const validateContactFormFields = ({ email, phone, message }) => {
  let errorsArray = [];

  if (!isValidEmail(email)) {
    errorsArray.push({
      key: "email",
      message: labels.REAL_ESTATE_ERROR_EMAIL,
    });
  }

  if (message === null || message?.length < 8) {
    errorsArray.push({
      key: "message",
      message: labels.REAL_ESTATE_ERROR_MESSAGE,
    });
  }

  if (
    phone !== null &&
    phone !== undefined &&
    phone !== "" &&
    !isValidPhoneNumber(phone)
  ) {
    errorsArray.push({
      key: "phone",
      message: labels.REAL_ESTATE_ERROR_PHONE,
    });
  }

  return errorsArray;
};

export const handleTextfieldChange = ({ value, setFormPayload }) => {
  setFormPayload(value);
};

export const shouldShowError = ({ errors, fieldName }) => {
  return errors.find((error) => error.key === fieldName) ? true : false;
};

export const getErrorMessage = ({ errors, fieldName }) => {
  return errors.find((error) => error.key === fieldName)?.message;
};

export const handleSubmitRealtorForms = async (
  e,
  { formData, formType, contentSlug, setErrors, submitProgress, token }
) => {
  e.preventDefault();
  let response;
  let fieldValidations = [];
  let errors = [];
  const isContacRealtorForm = formType === "contactRealtor";

  submitProgress({ sending: true });

  if (formData.email && formData.message?.length > 8) {
    response = await sendRealEstatesForm(
      formData,
      contentSlug,
      isContacRealtorForm,
      token
    );

    submitProgress({ sending: false, sent: response?.data?.email_sent });

    if (!response?.data?.email_sent) {
      errors.push({
        key: "send-failed",
        message: "Your email could not be sent. Please try again later.",
      });
    }

    if (!response?.data?.valid_recaptcha) {
      errors.push({
        key: "recaptcha",
        message: "reCAPTCHA could not be verified. Please try again later.",
      });
    }
  } else {
    fieldValidations = validateContactFormFields({
      email: formData.email,
      message: formData.message,
      phone: formData.phone,
    });

    submitProgress({ sending: false });
  }

  setErrors(errors.concat(fieldValidations));
};

export const handleSubmitFilters = (e, { actions, isModal }) => {
  e.preventDefault();
  actions?.fetchFilteredItems();
  if (isModal) {
    actions?.closeModal();
  }
};
