import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  games_title: {
    fontSize: theme.spacing(4),
    fontWeight: 700,
    paddingBlock: `${theme.spacing(2)}px`,
    marginBottom: 0,
    textTransform: "uppercase",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(3),
    backgroundColor: "#f7f7f7",
  },
  page_description: {
    color: theme.palette.miscellaneous.black,
    fontSize: theme.spacing(2.5),
    fontWeight: 400,
    [theme.breakpoints.down(768)]: {
      paddingLeft: theme.spacing(0),
    },
  },
  top_leaderboard: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: `${theme.spacing(6)}px 0 ${theme.spacing(3)}px 0`,
    "& .ads_container": {
      marginBottom: 0,
    },
  },
  top_leaderboard_mobile: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: `${theme.spacing(5)}px 0 ${theme.spacing(1)}px 0`,
  },
  secondary_leaderboard: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBlock: theme.spacing(7),
  },
  secondary_leaderboard_mobile: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .ads_container": {
      marginBottom: 0,
      "& #leaderboard_mobile_1": {
        marginTop: 0,
      },
    },
  },
  body_section: {
    paddingBlock: theme.spacing(7),
    paddingInline: theme.spacing(11.75),
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.palette.miscellaneous.white,
    [theme.breakpoints.down(1260)]: {
      paddingInline: theme.spacing(3),
      paddingBlock: theme.spacing(4),
    },
  },
  games_section: {
    "& > div > div:first-child": {
      width: "75%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      [theme.breakpoints.down(1260)]: {
        width: "100%",
      },
    },
    "& > div > div:last-child": {
      width: "25%",
      [theme.breakpoints.down(1260)]: {
        width: "100%",
      },
    },
    "& > div": {
      display: "flex",
      justifyContent: "space-between",
      gap: theme.spacing(3),
      [theme.breakpoints.down(1260)]: {
        flexDirection: "column",
        gap: theme.spacing(0),
        "& > div": {
          width: "100%",
        },
      },
    },
    width: "100%",
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down(1260)]: {
      paddingInline: theme.spacing(0),
      paddingRight: theme.spacing(0),
      alignSelf: "center",
    },
  },
  right_block: {
    width: theme.spacing(45),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(1260)]: {
      marginTop: theme.spacing(3),
      width: "100%",
    },
  },
  extra_margin: {
    marginTop: theme.spacing(11),
  },
}));

export { useStyles };
