import React from "react";
import PropTypes from "prop-types";

const TwitterBox = ({ className, backgroundFillColor, iconFillColor }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="2" fill="black" />
      <g clipPath="url(#clip0_8970_2980)">
        <path
          d="M8.04531 7L14.9958 16.4552L8 24.1429H9.57375L15.6966 17.4111L20.6444 24.1429H26L18.6598 14.1559L25.1693 7H23.5956L17.956 13.2L13.4009 7H8.04531ZM10.3591 8.18036H12.8209L23.6862 22.9656H21.2244L10.3591 8.18036Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8970_2980">
          <rect
            width="18"
            height="17.1429"
            fill="white"
            transform="translate(8 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

TwitterBox.propTypes = {
  className: PropTypes.string,
  backgroundFillColor: PropTypes.string,
  iconFillColor: PropTypes.string,
};

TwitterBox.defaultProps = {
  iconFillColor: "white",
  backgroundFillColor: "#00ACEE",
};

export default TwitterBox;
