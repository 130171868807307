import React from "react";
import { useStyles } from "./RealEstatesNoResultsFound.style";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
const RealEstatesNoResultsFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.main_container}>
      <div className={classes.text_container}>
        <Typography
          level="t3_text_3"
          color="black"
          bold
          className={classes.title}
        >
          {labels.NO_RESULTS_TITLE}
        </Typography>

        <Typography
          level="t3_text_3"
          color="black"
          className={classes.subtitle}
        >
          {labels.NO_RESULTS_SUBTITLE}
        </Typography>
      </div>
    </div>
  );
};

export default RealEstatesNoResultsFound;
