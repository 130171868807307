import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    datepicker: {
      "&.MuiFormControl-marginNormal": {
        marginRight: 0,
      },
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(900)]: {
        display: "block",
      },
    },
    to: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  };
});

export { useStyles };
