import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./Breadcrumbs.styles";
import clsx from "clsx";
import { Breadcrumbs as MaterialBreadcrumbs } from "@material-ui/core";
import Link from "../Link/Link";

const Breadcrumbs = ({ items, color }) => {
  const classes = useStyles();

  useEffect(() => {
    const includeLdScript = () => {
      const jsonldScript = window.document.createElement("script");
      jsonldScript.setAttribute("type", "application/ld+json");
      const structuredData = {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        itemListElement: items.map((item, index) => {
          return {
            "@type": "ListItem",
            position: index + 1,
            name: item.label,
            item: item.url,
          };
        }),
      };

      jsonldScript.textContent = JSON.stringify(structuredData);
      window.document.head.appendChild(jsonldScript);
    };
    if (items.length > 0) {
      includeLdScript();
    }
  }, [items]);

  const builBreadcrumbs = () => {
    const linkedItems = items.map((item, index) => {
      return (
        <Link
          url={item.url}
          color={color ? color : index + 1 === items.length ? "black" : "gray"}
          level="medium_13_px"
          key={`breadcrumb-item-${index}`}
          className={classes.breadcrumb}
        >
          {item.label}
        </Link>
      );
    });
    return linkedItems;
  };

  const printSeparator = () => {
    return (
      <>
        <div className={clsx(classes.separator, color && classes[color])}>
          {">"}
        </div>
      </>
    );
  };

  return (
    <>
      <MaterialBreadcrumbs separator={printSeparator()} aria-label="breadcrumb">
        {builBreadcrumbs()}
      </MaterialBreadcrumbs>
    </>
  );
};

Breadcrumbs.propTypes = { items: PropTypes.array };

export default Breadcrumbs;
