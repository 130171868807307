import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Pagination from "../../../common/Pagination/Pagination";
import ClassifiedsIndexHeader from "../../../components/Classified/Headers/Index/ClassifiedsIndexHeader";
import ClassifiedSingleBlock from "../../../components/Classified/SingleBlock/ClassifiedSingleBlock";
import Sponsor from "../../../components/Sponsor/Sponsor";
import {
  buildRedirectUrl,
  getPageNumber,
  redirectToPage,
} from "../../../utils/helper";
import { useStyles } from "./ClassifiedsIndex.style";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";

const ClassifiedsIndex = ({
  classifieds,
  categories,
  pagination_attributes,
  sponsor_info,
  town_slug,
}) => {
  const classes = useStyles();

  const handleGotoNextPage = (e, p) => {
    const pageNumber = getPageNumber(p, pagination_attributes.current_page);
    const redirectUrl = buildRedirectUrl({
      type: "pagination",
      params: { pageNumber: pageNumber },
    });
    redirectToPage(redirectUrl, "_top");
  };

  return (
    <div className={classes.main_container}>
      {sponsor_info && (
        <div>
          <Sponsor sponsor_info={sponsor_info} />
        </div>
      )}

      <ClassifiedsIndexHeader categories={categories} townSlug={town_slug} />

      {classifieds.length > 0 ? (
        <>
          {classifieds.map((classified, index) => (
            <Fragment key={index}>
              <ClassifiedSingleBlock
                classified={classified}
                townSlug={town_slug}
              />
            </Fragment>
          ))}
        </>
      ) : (
        <div className={classes.no_classifieds_container}>
          <Typography
            level="h4_sub_header_4"
            color="gray"
            className={classes.no_classifieds}
          >
            {labels.NO_CLASSIFIEDS}
          </Typography>
        </div>
      )}

      {pagination_attributes.show_pagination && (
        <div className={classes.pagination_container}>
          <Pagination
            count={pagination_attributes.total_pages}
            page={parseInt(pagination_attributes.current_page)}
            onChange={handleGotoNextPage}
            className={classes.columns_pagination}
          />
        </div>
      )}
    </div>
  );
};

ClassifiedsIndex.propTypes = {
  classifieds: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      published_at: PropTypes.string,
      excerpt: PropTypes.string,
      photo_url: PropTypes.string,
      video_url: PropTypes.string,
      photo_alt_text: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  pagination_attributes: PropTypes.shape({
    show_pagination: PropTypes.bool,
    total_pages: PropTypes.number,
    current_page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    per: PropTypes.number,
  }),
  sponsor_info: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    town: PropTypes.string,
    sections_slug: PropTypes.arrayOf(PropTypes.string),
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    top_local_news: PropTypes.bool,
    page_type: PropTypes.string,
  }),
  town_slug: PropTypes.string,
};

export default ClassifiedsIndex;
