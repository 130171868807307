import React from "react";
import PropTypes from "prop-types";
import Typography from "../../../../common/Typography/Typography";
import { useStyles } from "./IndexHeaderWithFilters.style";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";

const IndexHeaderWithFilters = ({
  title,
  titleProps,
  topFilters,
  bottomFilters,
  className,
  submitContentButton,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <div className={classes.top_container}>
        <Typography
          level={titleProps.level}
          color={titleProps.color}
          bold={titleProps.bold}
          underlined={titleProps.underlined}
          className={titleProps.className}
        >
          {title}
        </Typography>

        {screenWidthIsLowerThan(960) ? (
          <>
            {submitContentButton && (
              <div className={classes.submit_content_button}>
                {submitContentButton}
              </div>
            )}
            {topFilters}
          </>
        ) : (
          <>
            {topFilters}
            {submitContentButton && (
              <div className={classes.submit_content_button}>
                {submitContentButton}
              </div>
            )}
          </>
        )}
      </div>

      {bottomFilters}
    </div>
  );
};

IndexHeaderWithFilters.propTypes = {
  title: PropTypes.string,
  titleProps: PropTypes.shape({
    color: PropTypes.string,
    level: PropTypes.string,
    bold: PropTypes.bool,
    underlined: PropTypes.bool,
    className: PropTypes.string,
  }),
  topFilters: PropTypes.node,
  bottomFilters: PropTypes.node,
  className: PropTypes.string,
  submitContentButton: PropTypes.node,
};

IndexHeaderWithFilters.defaultProps = {
  titleProps: {
    color: "tapintoOrange",
    level: "h4",
    bold: false,
    underlined: false,
  },
};

export default IndexHeaderWithFilters;
