import React, { useState, useEffect } from "react";
import "date-fns";
import clsx from "clsx";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Calendar from "../../common/Icons/Calendar";
import { useStyles } from "./DatePicker.style";
import Typography from "../../common/Typography/Typography";
import format from "date-fns/format";
import { isValid } from "date-fns";

const DatePicker = ({
  className,
  label,
  labelStyleProps,
  customFormat,
  endIcon,
  disabled,
  defaultValue,
  onChange,
  disablePast,
  openTo,
  endIconFillColor,
  readonly,
  placeholder,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const defineFormat = () => {
    if (disabled) {
      return "";
    } else if (customFormat) {
      return customFormat;
    } else {
      return "MM/dd/yyyy";
    }
  };

  const handleChange = (date, dateValue) => {
    const isValidDate = isValid(new Date(date));
    if (isValidDate) {
      handleAcceptedDate(date);
    }
    setValue(dateValue);
  };

  const handleAcceptedDate = (date) => {
    if (date) {
      onChange(format(date, "MM/dd/yyyy"));
    }
  };

  return (
    <>
      {label && (
        <Typography
          level={labelStyleProps.level}
          color={labelStyleProps.color}
          bold={labelStyleProps.bold}
          className={labelStyleProps.className}
        >
          {label}
        </Typography>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={clsx(classes.date_picker, className)}
          disableToolbar
          disablePast={disablePast}
          variant="inline"
          format={defineFormat()}
          margin="normal"
          value={disabled ? null : value}
          onChange={handleChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          placeholder={placeholder}
          keyboardIcon={
            endIcon ? (
              endIcon
            ) : (
              <Calendar
                className={classes.date_picker_end_icon}
                fillColor={
                  JSON.parse(localStorage.getItem("townTheme"))
                    ?.primary_color ?? endIconFillColor
                }
              />
            )
          }
          inputVariant="outlined"
          PopoverProps={{ className: classes.date_picker_popover }}
          disabled={disabled}
          invalidDateMessage={disabled || value === "" ? "" : "Invalid Date Format"}
          autoOk
          onAccept={handleAcceptedDate}
          openTo={openTo}
          inputProps={{ readOnly: readonly }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

DatePicker.propTypes = {
  className: PropTypes.any,
  label: PropTypes.string,
  labelStyleProps: PropTypes.shape({
    level: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
    className: PropTypes.any,
  }),
  customFormat: PropTypes.string,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  disablePast: PropTypes.bool,
  openTo: PropTypes.string,
  endIconFillColor: PropTypes.string,
  readonly: PropTypes.bool,
  placeholder: PropTypes.string,
};

DatePicker.defaultProps = {
  disablePast: true,
  openTo: "date",
  endIconFillColor: "#F98C10",
  readonly: false,
};

export default DatePicker;
