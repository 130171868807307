import React from "react";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import { useStyles } from "./LegalNoticePayment.style";
import ClickableIcon from "../../../../../common/Icons/ClickableIcon/ClickableIcon";
import PriceTwentyFiveDollars from "../../../../../common/Icons/PriceTwentyFiveDollars";
import labels from "../../../../../config/labels";

const LegalNoticePayments = ({ colors }) => {
  const isMobile = screenWidthIsLowerThan(values.md);
  const classes = useStyles();

  return (
    <div className={classes.payments_container}>
      <ClickableIcon
        icon={<PriceTwentyFiveDollars fillColor={colors.single.hex} />}
        lowerText={labels.PER_SITE}
        textColor={colors.single.key}
        textHoverColor={colors.single.key}
        className={classes.single_icon_container}
        typographyLevel={isMobile ? "medium_16_px" : "h5_sub_header_2"}
      />
    </div>
  );
};

export default LegalNoticePayments;
