import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    header: {
      lineHeight: "32px",
      display: "inline",
      [theme.breakpoints.down(960)]: {
        lineHeight: "22px",
        marginRight: theme.spacing(2),
      },
    },
    header_container: {
      paddingTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      borderTop: `2px solid ${theme.palette.grayVariation.grayForBorder}`,
      [theme.breakpoints.down(960)]: {
        paddingRight: theme.spacing(3),
      },
    },
    content_title: {
      lineHeight: "38px",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down(960)]: {
        lineHeight: "26px",
      },
      [`@media print`]: {
        lineHeight: "42px",
      },
    },
    title_and_categories_container: {
      [theme.breakpoints.up(960)]: {
        display: "inline-flex",
        flexWrap: "wrap",
        width: "80%",
        alignItems: "center",
        "& h1": {
          marginRight: theme.spacing(1),
        },
      },
    },
  };
});

export { useStyles };
