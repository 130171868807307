import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { useStyles } from "./UpcomingEvents.style";
import EventBlock from "../EventBlock/EventBlock";
import restClient from "../../../utils/restClient";
import withConfig from "../../../utils/withConfig";

const UpcomingEvents = ({ event, town }) => {
  const classes = useStyles();
  const [events, setEvents] = useState();

  useEffect(() => {
    const loadUpcomingEvents = async () => {
      const response = await restClient.get(withConfig("GET_UPCOMING_EVENTS"), {
        townId: town?.id,
        eventId: event.id,
      });

      setEvents(response?.data?.events);
    };
    if (events === undefined) {
      loadUpcomingEvents();
    }
  }, [events]);

  return (
    <>
      {events !== undefined && events.length >= 1 && (
        <>
          <div className={classes.upcoming_events_title}>
            <Typography level="h4_sub_header_4" bold>
              {labels.UPCOMING_EVENTS}
            </Typography>
          </div>
          <div className={classes.events_list}>
            {events.map((event, id) => {
              return (
                <Fragment key={id}>
                  <EventBlock event={event} townSlug={town?.slug} />
                </Fragment>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

UpcomingEvents.propTypes = {
  event: PropTypes.any,
};

export default UpcomingEvents;
