import React from "react";
import PropTypes from "prop-types";

const LinkedInBox = ({ className, backgroundFillColor, iconFillColor }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill={backgroundFillColor}
      />
      <path
        d="M7.3932 25V12.695H10.6216V25H7.3932ZM9.0074 10.9797C8.41415 10.9797 7.93127 10.7891 7.55876 10.4079C7.18625 10.0267 7 9.554 7 8.98983C7 8.42567 7.18625 7.95299 7.55876 7.57179C7.93127 7.1906 8.41415 7 9.0074 7C9.60065 7 10.0835 7.18297 10.456 7.54892C10.8285 7.89962 11.0148 8.35705 11.0148 8.92122C11.0148 9.51588 10.8285 10.0114 10.456 10.4079C10.0973 10.7891 9.61445 10.9797 9.0074 10.9797Z"
        fill={iconFillColor}
      />
      <path
        d="M20.385 12.5121C21.268 12.5121 22.0544 12.7103 22.7443 13.1067C23.4479 13.4879 23.9997 14.0826 24.3998 14.8907C24.7999 15.6836 25 16.7052 25 17.9555V25H21.7716V18.5044C21.7716 17.5133 21.5716 16.7814 21.1715 16.3088C20.7851 15.8361 20.2333 15.5997 19.5159 15.5997C19.0054 15.5997 18.5432 15.7217 18.1293 15.9657C17.7292 16.1944 17.4119 16.5527 17.1773 17.0407C16.9566 17.5286 16.8462 18.1537 16.8462 18.9161V25H13.6178V12.695H16.7014V16.1029L16.1219 15.0737C16.522 14.2503 17.0946 13.6175 17.8396 13.1754C18.5846 12.7332 19.4331 12.5121 20.385 12.5121Z"
        fill={iconFillColor}
      />
    </svg>
  );
};

LinkedInBox.propTypes = {
  className: PropTypes.string,
  backgroundFillColor: PropTypes.string,
  iconFillColor: PropTypes.string,
};

LinkedInBox.defaultProps = {
  iconFillColor: "white",
  backgroundFillColor: "#0077B5",
};

export default LinkedInBox;
