import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    filled_variant: {
      backgroundColor: `${theme.palette.miscellaneous.white} !important`,
    },
    select: {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: "0px !important",
      height: "-webkit-fill-available !important",
      fontFamily: "Montserrat, sans-serif !important",
    },
  };
});

export { useStyles };
