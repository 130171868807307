import { makeStyles } from "../../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    date_time_range_pickers_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(970)]: {
        display: "block",
      },
    },
    padding_left_32: {
      paddingLeft: theme.spacing(4),
    },
    time_range_picker_container: {
      marginTop: theme.spacing(2),
      width: "50%",
      [theme.breakpoints.down(970)]: {
        width: "95%",
        marginLeft: theme.spacing(4),
      },
    },
    datepicker: {
      display: "block",
    },
    remove_date_time_field_icon: {
      width: theme.spacing(3),
      cursor: "pointer",
      marginRight: `-${theme.spacing(2.5)}px`,
      marginTop: theme.spacing(-5),
      [theme.breakpoints.down(970)]: {
        marginRight: theme.spacing(2),
      },
    },
    date_container: {
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(2),
    },
  };
});

export { useStyles };
