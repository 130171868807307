import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      borderRadius: "0px !important",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px !important`,
      [theme.breakpoints.down(760)]: {
        width: "100%",
      },
    },
    send_to_a_friend_button: {
      border: `1px solid ${theme.palette.themeColor.primary} !important`,
      marginRight: `${theme.spacing(2)}px !important`,
      [theme.breakpoints.down(760)]: {
        marginRight: "0px !important",
        marginBottom: `${theme.spacing(2)}px !important`,
      },
    },
    contact_realtor_button: {
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        color: theme.palette.dark.black,
        [theme.breakpoints.down(1640)]: {
          fontSize: "12px !important",
        },
      },
    },
  };
});

export { useStyles };
