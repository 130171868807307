import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    four_cards_per_row: {
      width: "23%",
    },
    three_cards_per_row: {
      width: "32%",
    },
    two_cards_per_row: {
      width: "47%",
    },
    one_card_per_row: {
      width: "100%",
    },
  };
});

export { useStyles };
