import React, { useEffect, useMemo, useState, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import { defaultStyles, useStyles } from "./DocumentsUploader.style";
import Button from "../../common/Button/Button";
import clsx from "clsx";
import PropTypes from "prop-types";
import labels from "../../config/labels";
import Spinner from "../../common/Spinner/Spinner";
import Alert from "../../common/Alert/Alert";
import Document from "./Document/Document";
import { useDocuments } from "../../context/CMS/DocumentsContext/DocumentsContext";
import Typography from "../../common/Typography/Typography";

const DEFAULT_OPTIONS = {
  programmatic: true,
  disabled: false,
  multipleFiles: true,
  facebookPreview: true,
  allowDeletePhoto: true,
  maxFiles: 30,
  labels: {
    submitButton: labels.SAVE_FILES,
    chooseFilesButton: labels.BROWSE_FILES,
    instructions: labels.DRAG_AND_DROP_DOCUMENTS_INSTRUCTIONS,
  },
  placeholders: {
    previews: labels.PREVIEW_PHOTO_PLACEHOLDER,
  },
  alerts: {
    variant: "filled",
  },
  preExistingThumbsUrls: [],
};

const DocumentsUploader = ({
  customStyles,
  options,
  statusMessage,
  handleError,
  acceptedDocumentType,
}) => {
  const styles = { ...defaultStyles, ...customStyles };
  const classes = useStyles();
  const allOptions = { ...DEFAULT_OPTIONS, ...options };
  const [thumbnails, setThumbnails] = useState([]);
  const { files, addFiles, isLoading } = useDocuments();

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noKeyboard: allOptions?.programmatic,
    noClick: allOptions?.programmatic,
    disabled: allOptions?.disabled,
    useFsAccessApi: false,
    onError: handleError,
    maxFiles: allOptions?.multipleFiles ? allOptions.maxFiles : 1,
    accept: acceptedDocumentType,
    onDrop: (droppedFiles, rejections) => {
      addFiles(droppedFiles);

      rejections?.map((rejection) => {
        rejection?.errors?.map((error) => {
          if (error.code === "too-many-files") {
            handleError(labels.SINGLE_PHOTO_VALIDATION_ERROR);
            addFiles([]);
          }
        });
      });
    },
  });

  const style = useMemo(
    () => ({
      ...styles.base_container,
      ...(isFocused ? styles.focused_container : {}),
      ...(isDragAccept ? styles.accept_container : {}),
      ...(isDragReject ? styles.reject_container : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    const buildThumbnails = () => {
      const documents = files.map((file, index) => (
        <Fragment key={`${file.name}-${index}`}>
          <Document document={file} />
        </Fragment>
      ));
      setThumbnails(documents);
    };
    if (files.length > 0) {
      buildThumbnails();
    } else {
      setThumbnails([]);
    }
  }, [files]);

  return (
    <div className={classes.form}>
      {statusMessage?.error && (
        <div className={classes.alert_container}>
          <Alert
            variant={allOptions?.alerts?.variant}
            severity="error"
            content={statusMessage?.error}
          />
        </div>
      )}

      {statusMessage?.success && (
        <div className={classes.alert_container}>
          <Alert
            variant={allOptions?.alerts?.variant}
            severity="success"
            content={statusMessage?.success}
          />
        </div>
      )}

      {isLoading && <Spinner withLoadingOverlay={true} />}

      <div {...getRootProps({ style })}>
        <input {...getInputProps({})} />
        <Typography
          level="medium_18_px"
          color={"black"}
          className={classes.instructions}
        >
          {allOptions?.labels?.instructions}
        </Typography>
        {allOptions?.programmatic && (
          <Button
            onClick={open}
            level="cms_outlined_button"
            color="primary"
            children={allOptions?.labels?.chooseFilesButton}
            variant={"outlined"}
            classes={{
              root: clsx(
                classes.button,
                classes.button_label,
                classes.choose_photos_button
              ),
            }}
          />
        )}
      </div>

      <div className={classes.thumbs_container}>
        {thumbnails.length > 0 ? (
          thumbnails
        ) : (
          <p>{allOptions?.placeholders?.previews}</p>
        )}
      </div>
    </div>
  );
};

DocumentsUploader.propTypes = {
  customStyles: PropTypes.shape({
    base_container: PropTypes.object,
    focused_container: PropTypes.object,
    accept_container: PropTypes.object,
    reject_container: PropTypes.object,
  }),
  options: PropTypes.shape({
    programmatic: PropTypes.bool,
    disabled: PropTypes.bool,
    multipleFiles: PropTypes.bool,
    facebookPreview: PropTypes.bool,
    allowDeletePhoto: PropTypes.bool,
    preExistingThumbsUrls: PropTypes.arrayOf(
      PropTypes.shape({ thumbnail: PropTypes.string })
    ),
    labels: PropTypes.shape({
      submitButton: PropTypes.string,
      chooseFilesButton: PropTypes.string,
      instructions: PropTypes.string,
    }),
    placeholders: PropTypes.shape({
      previews: PropTypes.string,
    }),
    alerts: PropTypes.shape({
      variant: PropTypes.string,
    }),
  }),
  handleDeletePhoto: PropTypes.func,
  statusMessage: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  acceptedDocumentType: PropTypes.object,
};

DocumentsUploader.defaultProps = {
  customStyles: {},
  options: {},
  acceptedDocumentType: "image/*",
};

export default DocumentsUploader;
