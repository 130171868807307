import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, down: below },
  } = theme;
  return {
    cards_container: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    space_evenly: {
      justifyContent: "space-evenly",
    },
    index_card: {
      maxWidth: spacing(25),
      marginRight: spacing(2),
      [below(values.md)]: {
        marginRight: 0,
      },
    },
  };
});

export { useStyles };
