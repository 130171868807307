import { makeStyles } from "../../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    pickers_container: {
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      width: "100%",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(900)]: {
        width: "100%",
      },
    },
    single_picker: {
      width: "25%",
    },
    pickers: {
      "&.MuiFormControl-marginNormal": {
        marginRight: theme.spacing(5),
        marginTop: 0,
        [theme.breakpoints.down(900)]: {
          marginRight: 0,
        },
      },
      "& div.MuiInputBase-root": {
        "& input.MuiInputBase-input": {
          padding: 0,
          paddingLeft: theme.spacing(0.75),
        },
      },
    },
    picker_labels: {
      padding: 0,
      margin: 0,
    },
    event_all_day_container: {
      width: "15%",
    },
    event_all_day_checkbox: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: 0,
      },
      "& span.MuiCheckbox-root": {
        color: theme.palette.miscellaneous.gray,
      },
      "& span.MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.themeColor.secondary,
      },
    },
    delete_event_date_cotainer: {
      width: "5%",
    },
    trashcan_icon_container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
  };
});

export { useStyles };
